import { createSlice } from '@reduxjs/toolkit';
import capitalize from 'lodash/capitalize';

import { ProfileStatus } from '../personal';

import { VerificationType } from './verification.types';

const verificationSlice = createSlice({
  name: 'verification',
  initialState: {
    verificationInfo: <VerificationType>{
      verificationStatus: null,
      status: '',
    },
  },
  reducers: {
    setVerificationStatus(state, { payload }) {
      let status = '';
      const verificationStatus = payload;

      if (Object.values(ProfileStatus).includes(payload)) {
        status = capitalize(payload);
      }

      return {
        ...state,
        verificationInfo: {
          ...state.verificationInfo,
          status,
          verificationStatus,
        },
      };
    },
  },
});

export const { setVerificationStatus } = verificationSlice.actions;
export const verificationReducer = verificationSlice.reducer;
