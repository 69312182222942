import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import currencies from 'components/countriesCurrency/currency.json';
import { useQueryBuilder } from 'hooks/useQueryBuilder';
import { getResourceManager } from 'resources';
import { updateSendMoneyState } from 'store/slices/transaction';
import { ICurrency } from 'types/exchange';

import { CurrencyQuery } from './types';

type ObjectKey = keyof typeof currencies;

export const useCheckCurrency = ({ enabled, country, options }: CurrencyQuery) => {
  const dispatch = useDispatch();

  const handleGetCurrency = useCallback(
    (country: string) => {
      const currentCurrency = (currencies as ICurrency)[country as ObjectKey];

      return getResourceManager()
        .currency.checkCurrency(currentCurrency)
        .then((res) => {
          dispatch(
            updateSendMoneyState({
              fromCurrency: res?.currency ?? res?.defaultCurrency,
            }),
          );

          return res;
        });
    },
    [dispatch],
  );

  const response = useQueryBuilder({
    enabled,
    key: `check-currency-${country}`,
    method: async () => handleGetCurrency(country),
    options,
  });

  return {
    ...response,
    handleGetCurrency,
  };
};
