import { axiosInstance } from './axiosInstance';

export const DocumentConfirmationApi = {
  uploadFile: async (data: any) => {
    return axiosInstance.post('verification/upload-document', data);
  },

  getUploadedFile: async (typeId: string) => {
    return axiosInstance.get(`verification/get-document/${typeId}`);
  },

  deleteUploadedFile: async (typeId: string) => {
    return axiosInstance.delete(`verification/delete-document/${typeId}`);
  },
};
