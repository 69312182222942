import { motion } from 'framer-motion';

import { propTypes } from './types';

export const PageAnimation = ({ extra = false, children }: propTypes) => {
  return (
    <motion.div
      initial={{ opacity: 0, position: 'absolute', top: '100px', zIndex: -1000 }}
      animate={{ opacity: 1, position: 'inherit', zIndex: extra ? 9999 : 110 }}
      exit={{ opacity: 0, position: 'absolute', top: '100px', zIndex: -1000 }}
      style={{ width: '100%', height: '100%' }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};
