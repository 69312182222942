import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { PrimaryButtonComp, TextField } from 'components';
import { InfoTips } from 'components/InfoTips';
import { I18n } from 'components/Translation';
import { InputFieldContainer } from 'components/fields';
import { CONSTANTS } from 'config';
import { toastAlert, translate } from 'helpers';
import { useValidationSchema } from 'hooks';
import { useAppProvider, useTranslation } from 'providers';
import { getResourceManager } from 'resources';
import { getMonitoring } from 'services/monitoring';

import { Container } from '../TransactionHistory/styles';

import { getValidationProfilePasswordSchema } from './getValidationSchema';
import styles from './style.module.scss';
import {
  ButtonBox,
  ButtonsWrapper,
  InputContainer,
  InputFields,
  SecondaryButton,
  SetPasswordWrapper,
} from './styles';
import { PasswordFormProps } from './types';

type Props = {
  isSetPassword: boolean;
  isChangePassword: boolean;
  isSetPasswordAvailable: boolean;
  isProfilePasswordSet: boolean;
  onCancel: () => void;
  setIsChangePassword: (state: boolean) => void;
  setIsSetPassword: (state: boolean) => void;
  refetchProfileData: any;
  refetchProfileSettings: any;
};

export const PersonalPasswordForm = ({
  isSetPassword,
  isChangePassword,
  isSetPasswordAvailable,
  isProfilePasswordSet,
  onCancel,
  setIsChangePassword,
  setIsSetPassword,
  refetchProfileData,
  refetchProfileSettings,
}: Props) => {
  const { t } = useTranslation();
  const { setLoader, loadType, setLoadType, loader } = useAppProvider();

  const passwordSchema = useValidationSchema(
    getValidationProfilePasswordSchema,
    isSetPassword,
    isChangePassword,
  );

  const form = useForm<PasswordFormProps>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(passwordSchema),
    defaultValues: {
      password: '',
      passwordConfirmation: '',
      currentPassword: '',
    },
  });

  const { reset, formState, handleSubmit: onSubmit } = form;
  const { isValid } = formState;

  const handleSubmitPassword = async (values: PasswordFormProps) => {
    setLoadType(CONSTANTS.SAVE);
    setLoader(true);

    getResourceManager()
      .user.profile.updateProfilePassword(values)
      .then(() => {
        setIsChangePassword(false);
        setIsSetPassword(false);
        toastAlert('success', translate(t, 'data_successfully_saved_local'));
        refetchProfileData?.();
        refetchProfileSettings?.();
        reset();
      })
      .catch((error) => {
        getMonitoring()?.captureException(error);
        toastAlert('error', error?.message || translate(t, 'error_something_went_wrong'));
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit(handleSubmitPassword)} noValidate>
        {/* -------------------------------PASSWORD------------------------------- */}
        {/* SET PASSWORD BUTTON */}
        {isSetPasswordAvailable && (
          <ButtonBox>
            <PrimaryButtonComp
              className="set-password"
              type="button"
              width={CONSTANTS.HUNDREED_PERCENT}
              text={<I18n tKey="set_password" />}
              click={() => {
                setIsSetPassword(true);
              }}
              valid
            />
          </ButtonBox>
        )}
        {/* InputField CURRENT password */}
        {isChangePassword && (
          <InputContainer className={styles['field_wrapper']}>
            <InputFieldContainer
              name="currentPassword"
              type="password"
              label={<I18n tKey="current_password" />}
            />
          </InputContainer>
        )}

        {(isChangePassword || isSetPassword) && (
          <>
            <SetPasswordWrapper>
              <InputFields>
                <InputContainer>
                  <InputFieldContainer
                    name="password"
                    className="password"
                    type="password"
                    label={<I18n tKey="new_password" />}
                  />
                </InputContainer>
                <InputContainer>
                  <InputFieldContainer
                    type="password"
                    className="passwordConfirmation"
                    name="passwordConfirmation"
                    label={<I18n tKey="confirm_password" />}
                  />
                  <Container>
                    <InfoTips message={translate(t, 'password_hint_message_1')} isPreLine>
                      <TextField variant="P13" color="n800">
                        <I18n tKey="password_hint" />
                      </TextField>
                    </InfoTips>
                  </Container>
                </InputContainer>
              </InputFields>
            </SetPasswordWrapper>
          </>
        )}

        <ButtonsWrapper>
          {(isChangePassword || isSetPassword) && (
            <ButtonBox>
              <PrimaryButtonComp
                className="cancel-btn"
                type="button"
                text={<I18n tKey="cancel" />}
                valid={isChangePassword || isSetPassword}
                click={() => {
                  reset();
                  onCancel();
                }}
              />
            </ButtonBox>
          )}
          {(isChangePassword || isSetPassword) && (
            <PrimaryButtonComp
              type="submit"
              text={<I18n tKey="save_changes" />}
              valid={isValid && !loader}
              typeOfLoad={loadType}
            />
          )}
          {/* CHANGE PASSWORD BUTTON */}
          {isProfilePasswordSet && !isChangePassword && (
            <SecondaryButton
              type="button"
              onClick={() => {
                setIsChangePassword(true);
              }}
            >
              <TextField variant="H7" color="p500">
                <I18n tKey="change_password" />
              </TextField>
            </SecondaryButton>
          )}
        </ButtonsWrapper>
      </form>
    </FormProvider>
  );
};
