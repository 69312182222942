import styled from 'styled-components';

const SuccesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 647px) {
    padding: 0 16px;
  }
`;

const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > .titleText {
    margin: 18px 0 8px;
    font-weight: 500;
    @media (max-width: 647px) {
      font-size: 26px;
      line-height: 32px;
    }
  }
  > .button {
    background: #3848c6;
    border-radius: 6px;
    padding: 16px 24px;
    width: 144px;
    cursor: pointer;
  }
  @media (max-width: 1600px) {
    margin-bottom: 64px;
  }
`;

const FooterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > .text-container {
    margin: 39px 0;
    display: flex;
    @media (max-width: 647px) {
      margin: 19px 0;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
    > .help-contact-text {
      display: flex;
      flex-wrap: nowrap;
      cursor: pointer;
    }
  }
`;

const HelpContactContainer = styled.div`
  display: flex;
  gap: 40px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0 24px;
  > .text {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;

const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid #f0f0f2;
`;

export { SuccesWrapper, SuccessMessage, FooterSection, HelpContactContainer, TextWrapper, Line };
