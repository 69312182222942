import { ICardType } from './types';
import VisaSVG from 'assets/images/Visa.svg';
import MasterSVG from 'assets/images/Mastercard.svg';
import UnionPaySVG from 'assets/images/UnionPay.svg';

export const CardTypeComponent = ({ type }: ICardType) => {
  switch (type) {
    case 'visa':
      return <img src={VisaSVG} />;
    case 'mastercard':
      return <img src={MasterSVG} />;
    case 'union_pay':
      return <img src={UnionPaySVG} />;
    default:
      return <></>;
  }
};
