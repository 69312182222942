import { createContext, FC, ReactNode, useContext } from 'react';

import { CountryType } from 'resources/countries';

import { useCountriesContext } from './useCountriesContext';

interface CountriesContext {
  countries: CountryType[] | undefined;
  sendingCountries: CountryType[] | undefined;
  receivingCountries: CountryType[] | undefined;
  isCountryListLoading: boolean;
}

const Context = createContext<CountriesContext>({
  countries: [],
  sendingCountries: [],
  receivingCountries: [],
  isCountryListLoading: false,
});

/**
 * Provide countries from API
 */
export const CountriesProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const state = useCountriesContext();

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

/**
 * @return {object}
 */
export const useCountries = () => {
  const context = useContext(Context);

  if (!context) throw new Error('useCountries must be used within a CountriesProvider');

  return context;
};
