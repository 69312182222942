import { Logo } from 'assets/images';
import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';
import { AppRoutes } from 'routes/constants';

import {
  StyledP,
  WrapperD,
  WrapperM,
  StyledLink,
  StyledTitle,
  StyledLeftPart,
  StyledStepHeader,
  StyledProgressBar,
} from './style';
import { IProps } from './types';

export const StepHeader = ({
  width,
  step,
  isFinalPageHeader = false,
  totalSteps = '6',
  stepDescription = '',
}: IProps) => (
  <div>
    <StyledStepHeader>
      <StyledLeftPart>
        <StyledTitle>
          <StyledLink to={AppRoutes.moneySteps.sendMoney}>
            <Logo />
          </StyledLink>
        </StyledTitle>
        {!isFinalPageHeader && (
          <WrapperD>
            <StyledP as="p">
              <I18n tKey="step" /> {step} <I18n tKey="of" /> {totalSteps}{' '}
              {!stepDescription ? (
                <I18n
                  tKey={`personal_identification_${step}`}
                  fallBacktKey="personal_identification_1"
                />
              ) : (
                <I18n tKey={stepDescription} />
              )}
            </StyledP>
          </WrapperD>
        )}
      </StyledLeftPart>
      <WrapperD>
        <StyledLink to={AppRoutes.main}>
          <TextField variant="P7">
            <I18n tKey="back_to_home" />
          </TextField>
        </StyledLink>
      </WrapperD>
      <WrapperM>
        {!isFinalPageHeader && (
          <StyledP margin="0 24px 0 0">
            <TextField className="step-color" variant="P7">
              <I18n tKey="step" /> {step} / {totalSteps}
            </TextField>
          </StyledP>
        )}
        <StyledLink to={AppRoutes.main}>
          <TextField variant="H7">
            <I18n tKey="home" />
          </TextField>
        </StyledLink>
      </WrapperM>
    </StyledStepHeader>
    <StyledProgressBar width={width}>
      <div />
    </StyledProgressBar>
  </div>
);
