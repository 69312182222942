import { FormEvent } from 'react';

export const dataEntryAccess = (
  e: FormEvent<HTMLInputElement>,
  dataLength: number = Infinity,
): boolean => {
  return (
    (/^[0-9]*$/.test(e.currentTarget.value) || e.currentTarget.value === '') &&
    e.currentTarget.value.length <= dataLength
  );
};

export const dataNumberSpacesAccess = (
  e: FormEvent<HTMLInputElement>,
  dataLength: number = Infinity,
): boolean => {
  return e.currentTarget.value.length <= dataLength;
};

export const phoneNumberEntryAccess = (e: FormEvent<HTMLInputElement>) => {
  return (
    Number(e.currentTarget.value) || e.currentTarget.value === '+' || e.currentTarget.value === ''
  );
};
