import isEmpty from 'lodash/isEmpty';

import { defaultT } from 'config';

const templateRegexp = /{[\da-zA-Z]+}/gi;
const templateBracketsRegexp = /[{}]/gi;

class Translation {
  private localTranslations: Record<string, string> = {};

  private remoteTranslations: Record<string, string> = {};

  constructor() {
    this.localTranslations = defaultT;
  }

  public getTranslation(key: string) {
    const remote = this.remoteTranslations?.[key];
    const local = this.localTranslations?.[key];

    return remote || local;
  }

  public setRemoteTranslations(t: Record<string, string>) {
    if (isEmpty(t)) {
      throw new Error('Remote translation is empty');
    }

    this.remoteTranslations = t;
  }

  public getTranslationsPerKey(key: string) {
    const remote = this.remoteTranslations?.[key];
    const local = this.localTranslations?.[key];

    const localInterpolations = local
      ?.match(templateRegexp)
      ?.map((key) => key.replace(templateBracketsRegexp, ''));
    const remoteInterpolations = remote
      ?.match(templateRegexp)
      ?.map((key) => key.replace(templateBracketsRegexp, ''));

    return { remote, local, localInterpolations, remoteInterpolations };
  }
}

export const translation = new Translation();
