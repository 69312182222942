import styled from 'styled-components';

export const InputContainer = styled.div`
  input {
    margin-top: 25px;
  }
  position: relative;
  .email {
    width: auto;
    border-radius: 8px;
    transition: all 0.1s linear;
    > label > .input-container > input {
      width: 100%;
    }
  }
  > .label {
    pointer-events: none;
    position: absolute;
    top: calc(50% - 12px);
    left: 15px;
    transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    box-sizing: border-box;
  }
  .on-focus {
    font-size: 13px;
    top: 6px;
    left: 17px;
    color: #4c4e57;
  }
`;
