import { useEffect, useMemo, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { DocumentConfirmationApi } from 'apiListURL/documentConfirmation';
import { VerificationApi } from 'apiListURL/verification';
import { TextField, FileUploader, UploadedFileName } from 'components';
import { I18n } from 'components/Translation';
import { toastAlert } from 'helpers';
import countRouter from 'helpers/countRouter';
import nextStep from 'helpers/nextStepDefine';
import { AppRoutes } from 'routes/constants';
import { getMonitoring } from 'services/monitoring';
import { setSteps } from 'store/slices/steps';

import {
  MarginDiv,
  UnderLine,
  ContinueButton,
  IdConfirmationWrapper,
  UploadedFileWrapper,
} from './styles';
import { propsType, uploadedFile } from './types';

export const DocumentConfirmation = ({
  title,
  description,
  stepCount,
  routeTo = '',
  stepDescription,
  typeId,
}: propsType) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [uploadedFile, setUploadedFile] = useState<uploadedFile | null>(null);
  const [uploadingFileName, setUploadingFileName] = useState<string | null>(null);
  const [isPreparing, setIsPreparing] = useState(false);
  const [isLastRoute, setIsLastRoute] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const isStandAlone = useMemo(() => {
    const standAloneParam = new URLSearchParams(location.search).get('is_stand_alone');
    return standAloneParam === 'true';
  }, [location.search]);

  const routeToFinal = isStandAlone ? AppRoutes.private.increaseMonthlyLimitThankYou : routeTo;

  const onFileChange = (file: File) => {
    if (uploadedFile) return;
    const formData = new FormData();
    formData.append('typeId', typeId);
    formData.append('document', file);
    if (params.recheckId) {
      formData.append('uniqueId', params.recheckId);
    }

    setUploadingFileName(file.name);

    DocumentConfirmationApi.uploadFile(formData)
      .then(() => {
        DocumentConfirmationApi.getUploadedFile(typeId)
          .then((res) => {
            setUploadedFile(res?.data?.data);
          })
          .catch((err) => {
            getMonitoring()?.captureException(err);
            toastAlert('error', err?.response?.data?.message);
          })
          .finally(() => {
            setUploadingFileName(null);
          });
      })
      .catch((err) => {
        setUploadingFileName(null);
        getMonitoring()?.captureException(err);
        toastAlert('error', 'Something went wrong, please try later');
      })
      .finally(() => {
        setIsPreparing(false);
      });
  };

  const handleClick = () => {
    setIsPreparing(true);
    if (uploadedFile) {
      if (params.recheckId) {
        VerificationApi.getRecheckInformation(params.recheckId).then((res) => {
          routeToFinal
            ? navigate(routeToFinal)
            : navigate(nextStep(res?.data?.data, params.recheckId));
        });
      } else {
        VerificationApi.getVerificationStatus().then((res) => {
          setIsPreparing(false);
          routeToFinal ? navigate(routeToFinal) : navigate(nextStep(res?.data?.data));
        });
      }
    }

    isLastRoute &&
      VerificationApi.postVerificationSubmit({ uniqueId: params.recheckId } as {
        uniqueId: string;
      });
  };

  const handleDelete = () => {
    setLoading(true);

    DocumentConfirmationApi.deleteUploadedFile(typeId)
      .then(() => {
        setUploadedFile(null);
      })
      .catch((err) => {
        getMonitoring()?.captureException(err);
        toastAlert('error', err?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setUploadedFile(null);
    const getUploadedFile = () => {
      DocumentConfirmationApi.getUploadedFile(typeId)
        .then((res) => {
          if (Object.keys(res?.data?.data).length !== 0) {
            setUploadedFile(res?.data?.data);
          }
        })
        .catch(() => {});
    };
    getUploadedFile();
  }, [location.pathname]);

  useEffect(() => {
    setIsPreparing(false);
    if (params.recheckId) {
      VerificationApi.getRecheckInformation(params.recheckId).then((res) => {
        setIsLastRoute(countRouter(res?.data?.data));
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(
      setSteps({
        stepNumber: stepCount,
        show: true,
        totalSteps: 3,
        stepDescription,
      }),
    );
  }, []);

  return (
    <>
      <IdConfirmationWrapper>
        <TextField variant="H3">
          <I18n tKey={title} />
        </TextField>
        <TextField variant="IL_P7">
          <I18n tKey={description} />
        </TextField>
        <MarginDiv variant="M32" />
        <FileUploader onFileChange={onFileChange} uploadedFile={uploadedFile} />
        {(!!uploadedFile || uploadingFileName) && (
          <UploadedFileWrapper>
            <MarginDiv variant="M24" />
            {!!uploadedFile && (
              <TextField variant="H6">
                <I18n tKey="uploaded_files" />
              </TextField>
            )}
            {!!uploadingFileName && (
              <TextField variant="H6">
                <I18n tKey="loading" />
              </TextField>
            )}
            <MarginDiv variant="M16" />
            {!!uploadedFile && (
              <UploadedFileName
                name={uploadedFile?.file_name}
                isLoading={isLoading}
                handleDelete={handleDelete}
              />
            )}
            {!!uploadingFileName && (
              <UploadedFileName
                name={uploadingFileName}
                isLoading={isLoading}
                handleDelete={handleDelete}
                uploadingFileName={uploadingFileName}
              />
            )}
          </UploadedFileWrapper>
        )}

        <MarginDiv variant="M32" />
        <UnderLine />
        <MarginDiv variant="M20" />
        <ContinueButton
          isPreparing={isPreparing}
          className={!uploadedFile ? 'emptyFile' : ''}
          onClick={handleClick}
        >
          <TextField variant="IL_P8">
            {!isPreparing ? (
              isLastRoute || isStandAlone ? (
                <I18n tKey={isStandAlone ? 'finish' : 'submit'} />
              ) : (
                <I18n tKey="continue" />
              )
            ) : (
              <Oval
                height={24}
                width={100}
                color="#3848C6"
                wrapperStyle={{}}
                wrapperClass="spinner"
                ariaLabel="oval-loading"
                secondaryColor="#ABB3ED"
                strokeWidth={2}
                strokeWidthSecondary={2}
                visible
              />
            )}
          </TextField>
        </ContinueButton>
      </IdConfirmationWrapper>
    </>
  );
};
