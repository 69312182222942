export const theme = () => ({
  colors: {
    natural: {
      white: '#FFFFFF',
      900: '#222229',
      800: '#4C4E57',
      700: '#686B78',
      600: '#9294A0',
      500: '#A3A5AF',
      400: '#C7C9D1',
      300: '#D0D2D9',
      200: '#E3E5EB',
      100: '#F0F0F2',
      50: '#F8F8FA',
    },
    primary: {
      700: '#192476',
      600: '#3342B8',
      500: '#3848C6',
      400: '#4858CF',
      300: '#8994E6',
      200: '#ABB3ED',
      100: '#DDE0F8',
    },

    accentOne: {
      600: '#293FCB',
      500: '#2D48ED',
      400: '#4F66F5',
    },

    accentTwo: {
      500: '#00BAFF',
      300: '#70D9FF',
      200: '#ADE9FF',
      100: '#EBFAFF',
    },

    accentThree: {
      500: '#FFD13E',
    },

    warning: {
      500: '#FDAD54',
      300: '#FED9AE',
      200: '#FFECD7',
      100: '#FFF5EB',
    },

    error: {
      500: '#FF5C5C',
      300: '#FFC2C2',
      200: '#FFD6D6',
      100: '#FFEBEB',
      50: '#FFF7F7',
    },

    success: {
      500: '#46C277',
      300: '#C2EBD2',
      200: '#E0F5E9',
      100: '#F0FAF4',
    },
  },
});
