import { ReactNode } from 'react';
import { Controller, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';

import { CountrySelect } from 'components/CountrySelect';
import { ISelector } from 'components/RandomSelector/types';
import { useRegister } from 'hooks';
import { CountryType } from 'resources/countries';

type Props = {
  name: string;
  options?: RegisterOptions<FieldValues, string> | undefined;
  placeholder?: string;
  label?: ReactNode;
  preventDisabled?: boolean;
  checkFields?: boolean;
  disabled?: boolean;
  type?: string;
  className?: string;
  data?: ISelector['data'];
  value?: string | number;
  isFocus?: boolean;
  countries?: CountryType[];
};

export const CountrySelectFieldContainer = (props: Props) => {
  const { watch, register, formState } = useFormContext();
  const {
    label,
    className,
    checkFields,
    disabled: preventDisabled,
    value,
    name,
    options,
    countries,
  } = props;

  const { errors, touchedFields } = formState;

  const field = useRegister({
    ...register(name, options),
    errors,
    touchedFields,
    withRef: false,
  });

  const { disabled } = field;

  /**
   * Это свойство нужно оборачивать в отслеживаемое
   * _
   * Если так не делать, то из-за вложенности компонентов может случится такое,
   * что formState.isDirty не будет изменяться по мере изменений в полях ввода
   */
  const watchedValue = watch(field.name);
  const finalValue = value ?? watchedValue;

  return (
    <Controller
      {...field}
      render={({ field: { onChange: defaultOnChange } }) => (
        <>
          <CountrySelect
            title={label}
            value={finalValue}
            className={className}
            onChange={defaultOnChange}
            disabled={disabled || preventDisabled}
            error={!!(field.error && field.isTouched) || !!(!watchedValue && checkFields)}
            countries={countries}
          />
        </>
      )}
    />
  );
};

CountrySelectFieldContainer.displayName = 'CountrySelectFieldContainer';
