import { PrimaryButtonComp } from 'components/PrimaryButtonComp/PrimaryButtonComp';
import { translate } from 'helpers';
import { useTranslation } from 'providers/translationProvider/useTranslation';

import classes from './translation.module.scss';

export const TranslationEditToggle = () => {
  const { isTranslationManagementAllowed, isEditVisibility, handleToggleEdit, t } =
    useTranslation();

  if (!isTranslationManagementAllowed) return null;

  return (
    <div className={classes['edit-toggle']}>
      <PrimaryButtonComp
        type="button"
        click={handleToggleEdit}
        text={translate(t, isEditVisibility ? 'hide_edit_buttons' : 'show_edit_buttons')}
        valid
      />
    </div>
  );
};
