import { envConfig } from 'config/env';
import { safetyParse } from 'helpers';
import { getResourceManager } from 'resources';
import { getMonitoring } from 'services/monitoring';

/**
 * Update auth session every call
 */
const updateToken = async () => {
  const supaBaseData = window.localStorage.getItem(envConfig.provider.supabaseApp);
  if (supaBaseData) {
    const { value, hasError } = safetyParse<{ refresh_token: string; access_token: string }>(
      supaBaseData,
    );

    if (hasError || typeof value === 'string') {
      localStorage.clear();

      return;
    }

    await getResourceManager()
      .auth.refreshSession(value.refresh_token)
      .then((res) => {
        const { access_token: accessToken, refresh_token: refreshToken } = res?.data?.session ?? {};

        if (accessToken && refreshToken) {
          getResourceManager()
            .auth.setSession(accessToken, refreshToken)
            .then((res) => {
              if (res?.data?.session?.refresh_token) {
                localStorage.setItem(
                  envConfig.provider.supabaseApp,
                  // @ts-ignore
                  JSON.stringify(res?.data?.session),
                );
              }
            });
        }
      })
      .catch((err) => {
        getMonitoring()?.captureException(err);
        console.error(err.messages);
      });
  } else {
    localStorage.clear();
  }
};

export default updateToken;
