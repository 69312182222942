import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { ProfileApi } from 'apiListURL';
import { VerticalDots } from 'assets/images';
import { TextField } from 'components';
import { translate } from 'helpers';
import { useOnClickOutside } from 'hooks';
import { useTranslation } from 'providers';
import { AppRoutes } from 'routes/constants';
import { updateSenderDetailsCardData } from 'store/slices/transaction';

import { StyledDropdownItem, StyledDropdownMenu } from './dropdown_styles';
import { StyledDropdown } from './styles';

type Data = {
  value: string;
  label: string;
};

interface IProps {
  index?: number;
  datas: Data[];
  id?: string;
  setIsModalOpen?: (data: boolean) => void;
  setCardDigits?: (data: string) => void;
  getCards?: () => void;
  data?: any;
  setDeletedCardId?: (data: string) => void;
  setCardType?: (data: string) => void;
}

export const DropdownWrapper = ({
  datas,
  id = '',
  setIsModalOpen = () => {},
  getCards = () => {},
  setCardDigits = () => {},
  data = {},
  setDeletedCardId = () => {},
  setCardType = () => {},
}: IProps) => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputField = useRef() as React.MutableRefObject<HTMLInputElement>;
  useOnClickOutside(inputField, () => setIsOpenMenu(false));
  const { t } = useTranslation();

  const handleClick = (item: Data) => {
    if (item.label === `${translate(t, 'dropdown_delete')}`) {
      setIsModalOpen(true);
      setDeletedCardId(id as string);
      setCardDigits(data?.pan?.slice(-4));
      setCardType(data?.type);
    } else if (item.label === `${translate(t, 'dropdown_make_default')}`) {
      ProfileApi.makeDefaultCard(id).then(() => {
        dispatch(updateSenderDetailsCardData({ dataId: id, defaultCardId: id }));
        getCards();
      });
    } else if (item.label === `${translate(t, 'dropdown_send_money')}`) {
      navigate(AppRoutes.moneySteps.sendMoney);
    }
  };

  return (
    <StyledDropdown ref={inputField} onClick={() => setIsOpenMenu(!isOpenMenu)}>
      <VerticalDots />
      {isOpenMenu && (
        <StyledDropdownMenu className="dropdown-items" isTextDropdown={isOpenMenu}>
          {datas?.map((item: Data, index: number) => (
            <StyledDropdownItem
              className="item"
              key={`${item.label}-${index}`}
              onClick={() => {
                dispatch(updateSenderDetailsCardData({ dataId: id }));
                handleClick(item);
              }}
            >
              <TextField
                variant="P9"
                className={item.label === translate(t, 'dropdown_delete') ? 'delete' : ''}
                color="n900"
              >
                {item.label}
              </TextField>
            </StyledDropdownItem>
          ))}
        </StyledDropdownMenu>
      )}
    </StyledDropdown>
  );
};
