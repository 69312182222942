import { envConfig } from 'config/env';
import { Response } from 'resources/types';
import { Fetcher } from 'utils';

import { ENDPOINTS } from './endpoints';
import {
  CodeRequestParams,
  PhoneRequestParams,
  ProfileResponse,
  TLocation,
  TProfileDataRequest,
  TProfilePasswordRequest,
  TResetNewPassword,
  TUpdatedProfileData,
} from './types';

export class ProfileRepo {
  private fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  public async getProfile(): Promise<Response<ProfileResponse>> {
    const { data } = await this.fetcher.fetch<
      PhoneRequestParams,
      { data: Response<ProfileResponse> }
    >({
      url: `${ENDPOINTS.getProfile}`,
      method: 'GET',
    });

    return data;
  }

  public async phoneVerify(body: PhoneRequestParams): Promise<boolean> {
    const { data } = await this.fetcher.fetch<PhoneRequestParams, { data: Response<unknown> }>({
      url: `${ENDPOINTS.phoneVerify}`,
      method: 'POST',
      data: body,
    });

    return data?.status;
  }

  public async checkPhoneIsEmpty(phone: string): Promise<boolean> {
    const { data } = await this.fetcher.fetch<
      { phoneNumber: string },
      { data: Response<{ isInUse: boolean }> }
    >({
      url: `${ENDPOINTS.checkPhone}`,
      method: 'POST',
      data: { phoneNumber: phone },
    });

    return data?.data?.isInUse;
  }

  public async codeVerify(body: CodeRequestParams): Promise<boolean> {
    const { data } = await this.fetcher.fetch<CodeRequestParams, { data: Response<unknown> }>({
      url: `${ENDPOINTS.codeVerify}`,
      method: 'POST',
      data: body,
    });

    return data?.status;
  }

  public async getCurrentLocation() {
    const { data } = await this.fetcher.fetch<null, { data: TLocation }>({
      url: ENDPOINTS.location,
      method: 'GET',
    });

    return data.data;
  }

  public async updateProfileData(payload: TProfileDataRequest) {
    const { data } = await this.fetcher.fetch<
      TProfileDataRequest,
      { data: Response<TUpdatedProfileData> }
    >({
      url: ENDPOINTS.updateProfile,
      method: 'POST',
      data: payload,
    });

    return data.data;
  }

  public async updateProfilePassword(payload: TProfilePasswordRequest) {
    const tokenObject = localStorage.getItem(envConfig.provider.supabaseApp);
    const refreshToken = tokenObject ? JSON.parse(tokenObject).refresh_token : null;

    const { data } = await this.fetcher.fetch<TProfilePasswordRequest, { data: Response<unknown> }>(
      {
        url: ENDPOINTS.updateProfilePassword,
        method: 'POST',
        data: payload,
        headers: {
          Refresh: refreshToken,
        },
      },
    );

    return data.status;
  }

  public async resetPassword(payload: TResetNewPassword): Promise<boolean> {
    const tokenObject = localStorage.getItem(envConfig.provider.supabaseApp);
    const refreshToken = tokenObject ? JSON.parse(tokenObject).refresh_token : null;

    const { data } = await this.fetcher.fetch<
      TProfilePasswordRequest,
      { data: Response<ProfileResponse> }
    >({
      url: ENDPOINTS.resetProfilePassword,
      method: 'POST',
      data: payload,
      headers: {
        Refresh: refreshToken,
      },
    });

    return data.status;
  }
}
