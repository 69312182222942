import styled from 'styled-components';

export const PaginationStyles = styled.div`
  width: auto;
  height: 32px;
  border: 1px solid #c7c9d1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  @media (max-width: 647px) {
    width: fit-content;
  }
`;

export const PaginationButton = styled.div`
  width: 33px;
  padding: 6px;
  border-right: 1px solid #c7c9d1;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  &:nth-last-child(1) {
    border-right: none;
  }
  &.active {
    background: #f0f0f2;
    pointer-events: none;
    &:nth-child(1) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:nth-child(2) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
  &.last_active {
    border: none;
    background: #f0f0f2;
    pointer-events: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &.hide_button {
    display: none;
  }
  &.click_next,
  &.click_prev {
    width: 57px;
    &:active {
      background: #f0f0f2;
    }
  }
`;
