import styled from 'styled-components';

export const DropFileInputWrapper = styled.div`
  position: relative;

  &.dragover {
    opacity: 0.5;
  }
`;

export const DropFileInput = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: auto;
  opacity: 0;
  cursor: pointer;
`;
