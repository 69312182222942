import { useQueryBuilder } from 'hooks/useQueryBuilder';
import { getResourceManager } from 'resources';

export const usePurposeOptions = () => {
  return useQueryBuilder({
    key: 'purpose-options',
    method: async () => getResourceManager().options.getPurposes(),
    enabled: true,
  });
};
