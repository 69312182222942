import { AppRoutes } from 'routes/constants';
import { VerificationRouter } from 'store/slices/verification';

const nextStep = (verificationRouter: VerificationRouter, recheckId = '') => {
  const routingSteps = {
    id_passed: verificationRouter.id_passed,
    address_passed: verificationRouter.address_passed,
    sof_passed: verificationRouter.sof_passed,
    zs_passed: verificationRouter.zs_passed,
  };

  const routers = Object.keys(routingSteps);

  if (routers.length > 0) {
    const routeSteps: string[] = [];
    routers.forEach((route) => {
      // @ts-ignore
      if (!verificationRouter[route]) {
        routeSteps.push(route);
      }
    });
    return recheckId
      ? `${AppRoutes.private.increaseMonthlyLimit}/${routeSteps[0]}/${recheckId}`
      : `/${AppRoutes.private.increaseMonthlyLimit}/${routeSteps[0]}`;
  } else {
    return '/';
  }
};

export default nextStep;
