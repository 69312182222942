import { Logo } from 'assets/images';
import { TextField } from 'components';
import { I18n } from 'components/Translation';
import { SecondaryButton } from 'pages/Personal/styles';

import styles from './styles.module.scss';

type Props = {
  onLogout: () => void;
};

export const ClosedScreen = (props: Props) => (
  <div className={styles['wrapper']}>
    <div className={styles['header']}>
      <Logo />
    </div>
    <div className={styles['message_block']}>
      <TextField variant="P8" className={styles['error_message']}>
        <I18n tKey="closed_profile_alert_message" />
      </TextField>

      <div>
        <SecondaryButton type="button" onClick={props.onLogout}>
          <TextField variant="H7" color="p500">
            <I18n tKey="logout" />
          </TextField>
        </SecondaryButton>
      </div>
    </div>
  </div>
);
