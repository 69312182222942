import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { ReactElement } from 'react';

import { StyledLink, Image, LangDropdown, TextField } from 'components';
import { ExternalRoutes } from 'routes/constants';

import { Wrapper, ChildrenWrapper, ImageSection, Privacysection, Links } from './styles';

interface IAuthLayout {
  img: ReactElement | string;
  imgWidth?: string;
  children: ReactJSXElement;
  imgMaxWidth?: string;
}

export default function AuthLayout(props: IAuthLayout): ReactJSXElement {
  const { img, imgWidth, children, imgMaxWidth } = props;
  return (
    <Wrapper>
      <ChildrenWrapper>{children}</ChildrenWrapper>
      <ImageSection>
        <Image height="100%" width={imgWidth} image={img} imgMaxWidth={imgMaxWidth} />
        <Privacysection>
          <LangDropdown isLeftSide />
          <Links>
            <StyledLink isExternal url={ExternalRoutes.termsOfUse}>
              <TextField variant="P10" color="white">
                Terms of Use
              </TextField>
            </StyledLink>
            <StyledLink isExternal url={ExternalRoutes.privacyPolicy}>
              <TextField variant="P10" color="white">
                Privacy Policy
              </TextField>
            </StyledLink>
          </Links>
        </Privacysection>
      </ImageSection>
    </Wrapper>
  );
}
