import styled from 'styled-components';

const StyledHeader = styled.div<{ auth?: boolean; pt?: boolean }>`
  width: 100%;
  padding: ${({ pt }) => (pt ? '0 40px' : '30px 40px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  box-sizing: border-box;
  max-height: 84px;
  border-bottom: 1px solid #f0f0f2;
  // Depend on page animation
  z-index: 99999;
  @media (max-width: 1279px) {
    max-height: 68px;
    padding: 24px;
  }
  @media (max-width: 647px) {
    max-height: 60px;
    padding: 18px 16px;
  }

  &.login {
    padding: 30px 40px 30px 0;
    > span {
      @media (max-width: 1279px) {
        display: block !important;
      }
    }
  }
`;

const StyledLeftPart = styled.div<{ pt?: boolean }>`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.2px;
  > div {
    margin-right: 24px;
  }
`;

const StyledRightPart = styled.div<{ pt?: boolean }>`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.2px;
  .month_limit {
    color: #686b78;
  }
  > :first-child {
    margin-right: 8px;
    @media (max-width: 1279px) {
      display: none;
    }
  }
  > :nth-child(2) {
    margin-right: 40px;
    @media (max-width: 1279px) {
      margin-right: 0;
    }
  }
  > :nth-child(2) {
    position: relative;
    right: 0;
    left: 0;
    text-align: right;
    align-items: right;
    > div {
      justify-content: flex-end;
      left: auto;
      right: 0;
      // Depend on page animation
      z-index: 99999;
      > button {
        padding: 8px 16px;
        margin: 0;
        width: 184px;
        height: 40px;
        :hover {
          background: #f4f4f6;
        }
      }
    }
  }
  button {
    padding: 0;
  }
`;

const StyledTitle = styled.h3`
  margin-left: 56px;
  font-family: Poppins;
  font-size: 24px;
  margin: 0 48px 0 0;
`;

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`;

const LinkWrapper = styled.span<{ active?: boolean }>`
  padding: ${({ active }) => (active ? '30px 0 27px' : '30px 0')};
  margin-left: 24px;
  bottom: 3px;
  border-bottom: ${({ active }) => active && '3px solid #2D48ED'};
  @media (max-width: 1600px) {
    padding: ${({ active }) => (active ? '30px 0 27px' : '30px 0')};
  }
  @media (max-width: 1279px) {
    display: none;
  }
`;

const Burger = styled.div`
  display: none;
  margin-top: 5px;
  @media (max-width: 1279px) {
    display: block;
    position: relative;
    z-index: 111;
    cursor: pointer;
  }
`;

const Menu = styled.div`
  width: 200px;
  position: absolute;
  top: 30px;
  border-radius: 8px;
  padding: 10px 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 14px 5px rgba(0, 0, 0, 0.2);
  > :hover {
    background: #f0f0f2;
    cursor: pointer;
  }
`;

const MenuItem = styled.div`
  padding: 5px 20px;
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled.div`
  margin-top: 5px;
  margin-right: 0 !important;
`;

const BackButton = styled.div``;

const LimitTextWrapper = styled.div`
  margin: 4px 36px 4px 0;

  & > :first-child {
    margin-right: 8px;
  }
  & > :last-child {
    color: #2d48ed;
  }
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderStyles = {
  StyledHeader,
  StyledLeftPart,
  StyledRightPart,
  StyledTitle,
  StyledFlex,
  LinkWrapper,
  Burger,
  Menu,
  MenuItem,
  LogoWrapper,
  BackButton,
  LimitTextWrapper,
  FlexColumnContainer,
};
