import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
`;

export const LimitForMobileWrapper = styled.div`
  display: none;
  @media (max-width: 1279px) {
    display: flex;
    width: 100%;
    height: 84px;
  }
`;

export const LimitForMobile = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
  height: 68px;
  width: 100%;
  background: #eff0fb;
  opacity: 0.8;
  border-radius: 8px;
  margin: 16px;
  position: relative;

  > div {
    > div {
      > a {
        position: absolute;
        margin-right: 12px;
        right: 0;
      }
    }
  }
`;
