import { createSlice } from '@reduxjs/toolkit';

import { Action } from '../../types';

import { TSteps } from './steps.types';

const stepsSlice = createSlice({
  name: 'steps',
  initialState: <TSteps>{
    stepNumber: 0,
    show: false,
    totalSteps: 3,
    stepDescription: '',
  },
  reducers: {
    setSteps(state, action: Action<TSteps>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setSteps } = stepsSlice.actions;
export const stepsReducer = stepsSlice.reducer;
