import { VerificationRouter } from 'store/slices/verification';

const countRouter = (verificationRouter: VerificationRouter) => {
  const routingSteps = {
    id_passed: verificationRouter.id_passed,
    address_passed: verificationRouter.address_passed,
    // TODO: is not needed at this stage
    // sof_passed: verificationRouter.sof_passed,
    zs_passed: verificationRouter.zs_passed,
  };
  const routers = Object.keys(routingSteps);

  const remainingRouters: string[] = [];

  routers.forEach((router) => {
    // @ts-ignore
    if (!routingSteps[router]) {
      remainingRouters.push(router);
    }
  });

  return remainingRouters.length === 1;
};

export default countRouter;
