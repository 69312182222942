import { FC, ReactNode, useMemo } from 'react';

import { TranslationEditToggle } from 'components/Translation/TranslationEditToggle';
import { TranslationPopup } from 'components/Translation/TranslationPopup';

import { TranslationContext } from './context';
import { useTranslationContext } from './useTranslationContext';

export const TranslationProvider: FC<{ children: ReactNode; defaultLang: string }> = ({
  children,
  defaultLang = 'en',
}) => {
  const state = useTranslationContext(defaultLang);
  const contextState = useMemo(() => state, [state]);

  return (
    <TranslationContext.Provider value={contextState}>
      {children}

      <TranslationEditToggle />
      <TranslationPopup />
    </TranslationContext.Provider>
  );
};
