import { createSlice } from '@reduxjs/toolkit';

import { SupportType } from './support.types';

const supportSlice = createSlice({
  name: 'support',
  initialState: {
    supportInfo: <SupportType>{
      supportType: '',
      description: '',
    },
  },
  reducers: {
    setSupportType(state, { payload }) {
      return {
        ...state,
        supportInfo: {
          ...state?.supportInfo,
          supportType: payload,
        },
      };
    },
    setSupportDescription(state, { payload }) {
      return {
        ...state,
        supportInfo: {
          ...state?.supportInfo,
          description: payload,
        },
      };
    },
  },
});

export const { setSupportType, setSupportDescription } = supportSlice.actions;
export const supportReducer = supportSlice.reducer;
