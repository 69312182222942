import { ReactNode, FC } from 'react';

import { useAppProvider, useAuth, useTranslation } from 'providers';
import { useCountries } from 'providers/countriesProvider';

import { SplashScreen, FrozenScreen } from './components';

type Props = {
  children: ReactNode;
};

/**
 * This component need for checking user state and try to get initial application state if it's possible
 *
 * Currently we need to use container, because all general app information we can get only with context providers,
 * like auth, translation and app provider
 */
export const InitedContainer: FC<Props> = ({ children }) => {
  const { isAuth, profile, isProfileLoading, isSettingsLoading } = useAuth();
  const { isTranslationLoaded } = useTranslation();
  const { isCountryListLoading } = useCountries();
  const { isPersistDone } = useAppProvider();

  const isAuthStateLoading = isProfileLoading || isSettingsLoading;
  const isUnauthStatesLoading = isPersistDone && isTranslationLoaded && !isCountryListLoading;

  /**
   * If User unauthorized, we try to check only translation and persist states
   * If User authorized, we need to check profile and settings loading states too
   */
  const isInited = !isAuth ? isUnauthStatesLoading : isUnauthStatesLoading && !isAuthStateLoading;

  // if user unauthorized and isInited ivalid, show splash
  if (!isInited) {
    return <SplashScreen />;
  }

  return <FrozenScreen profile={profile}>{children}</FrozenScreen>;
};
