import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';
import { translate } from 'helpers';
import { useTranslation } from 'providers';

import { StyledErrorText, StyledLabel, StyledTitle } from '../styles';

import { styles, StyledSelect, StyledFlex, StyledSpan, SelectWrapper } from './styles';
import { ISelector, IData } from './types';

export const RandomSelector = ({
  name,
  onBlur,
  onFocus,
  title = '',
  placeholder = '',
  error = false,
  className = '',
  onChange = () => {},
  data,
  value,
  disabled = false,
  inputType,
}: Omit<ISelector, 'onChange'> & { onChange?(value: string): void }) => {
  const { t } = useTranslation();
  const datas = (data as IData[])?.map((element: IData, ind) => {
    return {
      value: element?.value,
      label: (
        <StyledFlex key={ind}>
          <StyledSpan disable={disabled}>{element?.value}</StyledSpan>
        </StyledFlex>
      ),
    };
  });

  const handleInputType = (event: string, inputType: string) => {
    if (inputType === 'number') return event.replace(/[^0-9]/gi, '');
    if (inputType === 'string') return event.replace(/[^a-z]/gi, '');

    return event;
  };

  return (
    <SelectWrapper>
      <StyledLabel error={error} className={className} isDisabled={disabled}>
        <StyledTitle as="div" marginBottom="0" marginTop="-4px">
          <TextField variant="P7">{title}</TextField>
        </StyledTitle>
        <StyledSelect
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          isDisabled={disabled}
          onInputChange={(e: string) => handleInputType(e, inputType as string)}
          options={datas}
          placeholder={!placeholder ? translate(t, 'please_select') : placeholder}
          aria-labelledby="aria-label"
          styles={styles}
          onChange={(option: any) => (onChange ? onChange(option?.value) : {})}
          value={datas?.filter((el) => el?.value === value)}
        />
      </StyledLabel>
      {error && (
        <StyledErrorText>
          <I18n tKey="required_field" />
        </StyledErrorText>
      )}
    </SelectWrapper>
  );
};
