import Select from 'react-select';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
  .menu::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  .menu::-webkit-scrollbar-thumb {
    background: #d0d2d9;
  }
  .css-1hb7zxy-IndicatorsContainer {
    display: ${(props) => props.isDisabled && 'none'};
  }
`;

export const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSpan = styled.span<{ disable?: boolean }>`
  margin-left: 8px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ disable }) => (disable ? '#6e6f78' : '#222229')};
`;

export const styles = {
  dropdownIndicator: (styles: any, state: any) => {
    return {
      ...styles,
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      color: '#222229',
      border: 'none',
      marginTop: '-20px',
      padding: 0,
    };
  },
  control: (styles: any) => {
    return {
      ...styles,
      width: '100%',
      border: 'none',
      position: 'relative',
      boxShadow: 'none',
      marginBottom: '-3px',
      minHeight: '35.5px',
      ':focus': {
        border: 'none',
        outline: 'none',
      },
    };
  },
  container: (styles: any) => {
    return {
      ...styles,
      ':focus': {
        border: 'none',
        outline: 'none',
        marginBottom: '-5px',
      },
    };
  },
  valueContainer: (styles: any) => {
    return {
      ...styles,
      padding: '0px',
      marginRight: '20px',
    };
  },
  indicatorSeparator: (styles: any) => {
    return {
      ...styles,
      display: 'none',
    };
  },
  menuList: (styles: any) => {
    return {
      ...styles,
      maxHeight: 333,
    };
  },
  menu: (styles: any) => {
    return {
      ...styles,
      maxHeight: 333,
      width: 'calc(100% + 32px)',
      marginLeft: '-16px',
      marginTop: '18px',
      '::-webkit-scrollbar': {
        width: '10px',
      },
      '::-webkit-scrollbar-track': {
        background: 'red',
      },
    };
  },
  option: (styles: any, { isSelected }: any) => {
    return {
      ...styles,
      color: '#333333',
      '&:hover': {
        background: '#F4F4F6 !important',
      },
      background: isSelected && '#F4F4F6 !important',
      div: {
        background: isSelected && '#F4F4F6 !important',
      },
      '&:active': {
        background: '#F4F4F6 !important',
        div: {
          background: '#F4F4F6 !important',
        },
      },
    };
  },
  placeholder: (styles: any) => {
    return {
      ...styles,
      color: '#222229',
    };
  },
};
