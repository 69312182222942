import { axiosInstance } from './axiosInstance';

interface submitType {
  uniqueId: string;
}

export const VerificationApi = {
  postVerificationStatus: async () => {
    return axiosInstance.post('verification/start');
  },
  getVerificationStatus: async () => {
    return axiosInstance.get('verification/get');
  },
  postIdentityVerification: async () => {
    return axiosInstance.post('verification/identity-verification');
  },
  getRecheckInformation: async (id: string) => {
    return axiosInstance.get(`verification/get-recheck-information/${id}`);
  },
  postVerificationSubmit: async (data: submitType) => {
    return axiosInstance.post('verification/submit', data as submitType);
  },
};
