import { createContext, FC, ReactNode, useContext } from 'react';
import { QueryObserverResult } from 'react-query';

import { IProfile } from 'store/slices/personal';
import { TUserSettings } from 'types/settings';

import { useAuthContext } from './useAuthContext';

interface AuthContext {
  isStep: boolean;
  isAuth: boolean;
  settings?: TUserSettings;
  profile?: IProfile;
  isLoading?: boolean;
  isProfileLoading?: boolean;
  isSettingsLoading?: boolean;
  isPreventPrivateRedirect: boolean;
  refetchProfileData: () => Promise<QueryObserverResult<IProfile, unknown>> | undefined;
  refetchProfileSettings: () => Promise<QueryObserverResult<TUserSettings, unknown>> | undefined;
}

const Context = createContext<AuthContext>({
  settings: undefined,
  isStep: false,
  isAuth: false,
  isLoading: false,
  isProfileLoading: false,
  isSettingsLoading: false,
  profile: undefined,
  isPreventPrivateRedirect: false,
  refetchProfileData: () => undefined,
  refetchProfileSettings: () => undefined,
});

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const state = useAuthContext();

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

/**
 * @return {object}
 */
export const useAuth = () => {
  const context = useContext(Context);

  if (!context) throw new Error('useAuth must be used within a AuthProvider');

  return context;
};
