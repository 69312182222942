import styled, { css } from 'styled-components';
import { ifProp, prop, theme } from 'styled-tools';

interface IButton {
  color?: string;
  margin?: string;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  padding?: string;
  cursor?: string;
  width?: string;
  height?: string;
  fullWidth?: boolean;
  bold?: boolean;
  center?: boolean;
  bg?: string;
  border?: string;
  radius?: string;
}

const StyledLabel = styled.label<{ error: boolean; isDisabled?: boolean }>`
  position: relative;
  border: ${({ error }) => (error ? '1px solid #FF5C5C' : '1px solid #C7C9D1')};
  background: ${({ error, isDisabled }) =>
    error ? '#FFF7F7' : isDisabled ? 'hsl(0,0%,95%)' : 'transparent'};
  opacity: ${({ isDisabled }) => (isDisabled ? '1' : 'none')};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8.5px 16px 11px;
  color: #9294a0;
  font-family: Lato-Regular;
  font-weight: 400;
  display: block;
  height: 100%;

  &.five_step_label {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  > p {
    font-family: 'Lato-Regular';
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #4c4e57;
  }
  > div {
    > div {
      background: ${({ error, isDisabled }) => (error ? '#FFF7F7' : isDisabled ? '' : 'white')};
    }
  }
  :hover {
    border-color: #a3a5af;
  }
  :focus-within {
    border: 2px solid #2d48ed;
    padding: 7.5px 15px 10px;
  }
  :disabled {
    background: #f4f4f6;
  }
  .date > * {
    border: none;
  }
  @media (max-width: 682px) {
    width: 100%;
  }
`;

const StyledTitle = styled.p<{ marginBottom?: string; marginTop?: string; error?: boolean }>`
  margin: 0;
  flex-wrap: nowrap;
  color: ${({ error }) => (error ? '#FF5C5C' : '#4C4E57')} !important;
  margin-top: ${({ marginTop }) => marginTop || 'inherit'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 'inherit'};
`;

const StyledUnderInputText = styled(StyledTitle)`
  margin-top: 3px;
  font-family: 'Lato-Regular';
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  &.country {
    margin-top: 13px;
  }

  &.country_select {
    margin-top: 5px;
  }
`;

const StyledErrorText = styled(StyledUnderInputText)`
  color: #ff5c5c !important;
`;

const StyledFlag = styled.div`
  display: flex;
  img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

const StyledPage = styled.div`
  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat;
  @media screen and (max-width: 1279px) {
    width: auto;
    margin: 0 16px;
  }
`;

const StyledContent = styled.div<{ isAlignLeftPage?: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ isAlignLeftPage }) => (isAlignLeftPage ? 'flex-start' : 'center')};
  box-sizing: border-box;
`;

const SocialButton = styled.button<IButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme('colors.natural.900')};
  border: none;
  ${ifProp(
    'top',
    css`
      margin-top: ${prop('top')}px;
    `,
  )}

  ${ifProp(
    'left',
    css`
      margin-left: ${prop('left')}px;
    `,
  )}
  :hover {
    border: 1px solid #3848c6;

    > div {
      color: ${theme('colors.primary.500')} !important;
    }
  }
  :active {
    border: 1px solid #3848c6;
    box-shadow: 0 0 0 1px #3848c6;
    outline: 5px solid rgba(24, 97, 242, 0.24);
    background-color: transparent !important;
  }
  :focus {
    background-color: #eff0fb;
  }
  :disabled {
    opacity: 0.3;
    background-color: transparent !important;
    border: 1px solid #e3e5eb !important;
    outline: none;
    box-shadow: none;

    > div {
      color: #000 !important;
    }
  }
`;

const StyledFooterWithButtons = styled.div<{ isReviewPage?: boolean }>`
  border-top: 1px solid #f4f4f6;
  padding: 20px 0 68px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${({ isReviewPage }) =>
    isReviewPage &&
    css`
      @media screen and (max-width: 1279px) {
        button {
          :first-of-type {
            width: 50%;
            margin-right: 16px;
          }
          :last-of-type {
            width: 50%;
          }
        }
      }
    `}
  .send-button {
    width: 173px;
    height: 56px;
  }
`;

export {
  StyledFlag,
  StyledPage,
  StyledLabel,
  StyledTitle,
  SocialButton,
  StyledContent,
  StyledErrorText,
  StyledFooterWithButtons,
  StyledUnderInputText,
};
