export const ENDPOINTS = {
  phoneVerify: 'user/phone-verify',
  checkPhone: 'user/check-phone-number',
  codeVerify: 'user/code-verify',
  location: '/user/get-location',
  updateProfile: '/user/update-profile',
  updateProfilePassword: '/user/update-profile/password',
  resetProfilePassword: '/user/reset-password',
  getProfile: '/profile/get',
};
