import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';

import { ProfileApi } from 'apiListURL';
import Password from 'assets/images/password.png';
import Shapes from 'assets/images/shapes.png';
import { EmailForm, TextField, SocialAuth, StyledLink, PrimaryButtonComp } from 'components';
import { StyledLinkText } from 'components/PrimaryButtonComp/style';
import { I18n } from 'components/Translation';
import { CONSTANTS } from 'config';
import { translate, toastAlert } from 'helpers';
import AuthLayout from 'layout/auth_layout';
import { useAppProvider, useAuth, useTranslation } from 'providers';
import { getResourceManager } from 'resources';
import { AppRoutes } from 'routes/constants';
import { getMonitoring } from 'services/monitoring';

import { Line } from '../styles';

import {
  Wrapper,
  LinkWrapper,
  ButtonWrapper,
  MainContainer,
  PasswordContainer,
  ForgetPassContainer,
} from './styles';

export const SignIn = (): ReactJSXElement => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [valid, setValid] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { setLoader, loadType, setLoadType } = useAppProvider();
  const { t } = useTranslation();
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleSignIn = async () => {
    setLoader(true);
    setLoadType('Sign In');

    ProfileApi.checkUser({
      email,
    })
      .then(async (data) => {
        if (!data?.data?.userExists) {
          navigate(AppRoutes.public.signUp, {
            state: { email, signInError: translate(t, 'email_doesnt_exist_local') },
          });
        } else if (data?.data?.passwordSet) {
          setIsPassword(true);
        } else {
          const { error } = await getResourceManager().auth.signInWithEmail(email);

          !error?.message
            ? navigate(AppRoutes.public.success, {
                state: { email, userExists: data?.data?.userExists },
              })
            : setErrorMessage(error?.message);
        }
      })
      .catch((e) => {
        getMonitoring()?.captureException(e);
        toastAlert(
          'error',
          e.response?.data?.message || translate(t, 'error_something_went_wrong'),
        );
        setLoginError(e);
      })
      .finally(() => {
        setLoader(false);
        setValid(false);
      });
  };

  const handleSignInWithPassword = async () => {
    setLoader(true);
    setLoadType('Sign In');
    const { error } = await getResourceManager()
      .auth.signInWithPassword({
        email,
        password,
      })
      .finally(() => {
        setLoader(false);
      });

    if (!error) {
      navigate(AppRoutes.main);
    } else {
      setLoginError(error.message);
    }
  };

  const handleUserKeyPress = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submitButtonRef?.current?.click?.();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', (e: KeyboardEvent) => handleUserKeyPress(e));

    return () => {
      window.removeEventListener('keydown', (e: KeyboardEvent) => handleUserKeyPress(e));
    };
  }, []);

  useEffect(() => {
    const error =
      errorMessage &&
      setTimeout(() => {
        setErrorMessage('');
      }, 60000);
    return () => {
      clearTimeout(error);
    };
  }, [errorMessage]);

  const forgotPassUrl = useMemo(
    () => `${AppRoutes.public.forgetPass}?email=${encodeURIComponent(email)}`,
    [email],
  );

  if (isAuth) {
    return <Navigate to={AppRoutes.main} />;
  }

  return (
    <>
      {isPassword ? (
        <AuthLayout img={Password}>
          <Wrapper>
            <PasswordContainer>
              <TextField variant="H3" fw="500" bottom="8">
                <I18n tKey="password" />
              </TextField>
              <EmailForm
                passwordInput={isPassword}
                handleData={(err: boolean, _: string, passwordValue: string) => {
                  setValid(!!err);
                  setPassword(passwordValue);
                }}
              />
              <TextField className="error-message" variant="e500">
                {loginError || ''}
              </TextField>
              <ForgetPassContainer>
                <StyledLink url={forgotPassUrl}>
                  <TextField variant="P7" color="a500">
                    <I18n tKey="forgot_password" />
                  </TextField>
                </StyledLink>
              </ForgetPassContainer>
              <SocialAuth type="sign_in" />
            </PasswordContainer>
            <ButtonWrapper>
              <Line />
              <PrimaryButtonComp
                ref={submitButtonRef}
                width={CONSTANTS.HUNDREED_PERCENT}
                text={<I18n tKey="continue" />}
                valid={valid}
                click={() => handleSignInWithPassword()}
                typeOfLoad={loadType}
              />
            </ButtonWrapper>
          </Wrapper>
        </AuthLayout>
      ) : (
        <AuthLayout img={Shapes} imgMaxWidth="80%">
          <Wrapper>
            <MainContainer>
              <TextField variant="H3" fw="500" bottom="24">
                <I18n tKey="login" />
              </TextField>
              <EmailForm
                passwordInput={isPassword}
                handleData={(err: boolean, emailValue: string) => {
                  setValid(!!err);
                  setEmail(emailValue);
                }}
              />
              {!!errorMessage && (
                <TextField className="error-message" variant="P7">
                  {errorMessage}
                </TextField>
              )}
              <LinkWrapper>
                <TextField className="middle-text" variant="P7">
                  <I18n tKey="dont_have_account" />
                </TextField>
                <StyledLink url={AppRoutes.public.signUp}>
                  <StyledLinkText>
                    <TextField variant="P7" color="a500">
                      <I18n tKey="sign_up_here" />
                    </TextField>
                  </StyledLinkText>
                </StyledLink>
              </LinkWrapper>
              <SocialAuth type="sign_in" />
            </MainContainer>
            <ButtonWrapper>
              <Line />
              <PrimaryButtonComp
                ref={submitButtonRef}
                width={CONSTANTS.HUNDREED_PERCENT}
                text={<I18n tKey="continue" />}
                valid={valid}
                click={() => handleSignIn()}
                typeOfLoad={loadType}
              />
            </ButtonWrapper>
          </Wrapper>
        </AuthLayout>
      )}
    </>
  );
};
