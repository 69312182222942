import { useQueryBuilder } from 'hooks/useQueryBuilder';
import { getResourceManager } from 'resources';

import { ProfileSettingsQuery } from './types';

export const useProfileSettingsQuery = (props: ProfileSettingsQuery) =>
  useQueryBuilder({
    key: 'profile-settings',
    method: async () => getResourceManager().user.settings.getSettings(),
    ...props,
  });
