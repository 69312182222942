export enum AnalyticPageActions {
  entrance = 'entrance',
}

export type AnalyticAction = AnalyticPageActions;

export enum AnalyticCategory {
  Page = 'Page',
}

export type AnalyticEvent<T = Record<string, unknown>> = {
  categroy: AnalyticCategory;
  action: AnalyticAction;
} & T;
