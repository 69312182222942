import { useQueryBuilder } from 'hooks/useQueryBuilder';
import { getResourceManager } from 'resources';

import { TranslationsQuery } from './types';

export const useTranslationQuery = (props: TranslationsQuery) => {
  const { lang, ...otherProps } = props;
  const key = `translation-lang-${lang}`;

  return useQueryBuilder({
    key,
    method: async () => getResourceManager().translation.getTranslatedData(lang),
    enabled: true,
    ...otherProps,
  });
};
