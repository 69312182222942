import { usePersonal } from 'pages/Personal/hooks';
import { useAppProvider } from 'providers';

import { SenderDetails } from './SenderDetails';

export const SenderDetailsContainer = () => {
  const { isProfileLoaded, profile } = usePersonal();
  const { isPersistDone } = useAppProvider();

  if (isPersistDone && isProfileLoaded && !!profile) {
    return <SenderDetails />;
  }

  return null;
};
