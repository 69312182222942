import styled from 'styled-components';

const Wrapper = styled.div`
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  > :nth-child(2) {
    width: 100%;
  }
  @media (max-width: 960px) {
    width: 100%;
  }

  @media (max-width: 647px) {
    padding: 0 16px;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  .middle-text {
    color: #686b78;
  }
`;
const MainContainer = styled.div`
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > :nth-child(3) {
    width: 100%;
  }
  .subtitle {
    text-align: center;
    color: #686b78;
  }
  @media (max-width: 647px) {
    width: 100%;
  }
  .error-message {
    padding: 0 6px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #ff5c5c;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  > * {
    width: 600px;
    @media (max-width: 647px) {
      width: 100%;
    }
  }
  > button {
    margin: 20px 0;
    padding: 16px 24px;
    background: #3848c6;
    border-radius: 6px;
  }
`;

export { Wrapper, MainContainer, LinkWrapper, ButtonWrapper };
