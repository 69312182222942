import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import verifiedIcon from 'assets/icons/verifiedIcon.svg';
import { Loader } from 'components/Loader';
import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';
import { VerificationStatus } from 'components/VerificationStatus';
import { useAuth } from 'providers';
import { AppRoutes } from 'routes/constants';
import { RootState } from 'store';
import { ProfileStatus } from 'store/slices/personal';

import { HeaderStyles } from './styles';

export const Limit = () => {
  const { settings, profile, isLoading } = useAuth();

  const isVerifiedProfile = profile?.status_w === ProfileStatus.VERIFIED;

  const verification = useSelector(
    (state: RootState) => state?.verification?.verificationInfo?.verificationStatus,
  );
  const verificationStatus = useSelector(
    (state: RootState) => state?.verification?.verificationInfo?.status,
  );
  const isPersonalInfoFilled = useSelector(
    (state: RootState) => state?.personalInfo?.isPersonalInfoFilled,
  );

  const { alreadyTransferred, settings: insertedtSettings } = settings || {};
  const { defaultCurrency, startLimit, upgradeLimit } = insertedtSettings || {};

  const maxLimitValue = useMemo(() => {
    const limit = isVerifiedProfile ? upgradeLimit : startLimit;

    return limit || '0';
  }, [isVerifiedProfile, upgradeLimit, startLimit]);

  const currentValue = useMemo(
    () => alreadyTransferred?.amountForSixLastMonth || '0',
    [alreadyTransferred?.amountForSixLastMonth],
  );

  const currency = defaultCurrency || 'EUR';

  return (
    <HeaderStyles.FlexColumnContainer>
      <HeaderStyles.LimitTextWrapper>
        <TextField variant="P9" className="month_limit">
          <I18n tKey="month_limit_header" />
        </TextField>
        {verification === ProfileStatus.ACTIVE ? (
          <Link
            to={
              isPersonalInfoFilled
                ? AppRoutes.private.increaseMonthlyLimit
                : AppRoutes.private.goToProfile
            }
          >
            <TextField variant="P9" className="increase_limits" style={{ color: '#2D48ED' }}>
              <I18n tKey="increase_limits" />
            </TextField>
          </Link>
        ) : (
          <VerificationStatus status={verificationStatus} icon={verifiedIcon} />
        )}
      </HeaderStyles.LimitTextWrapper>
      <TextField variant="P10">
        {isLoading ? (
          <Loader variant="sm" />
        ) : (
          <>
            {currentValue} {currency} / {maxLimitValue} {currency}
          </>
        )}
      </TextField>
    </HeaderStyles.FlexColumnContainer>
  );
};
