import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import { FocusEvent, useEffect, useState, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { IframeSecure3d } from 'Secure3d';
import checkImg from 'assets/images/check.svg';
import {
  TextField,
  RoundCheckbox,
  CardTypeComponent,
  PrimaryButtonComp,
  PrototypeOfStepPagesForm,
} from 'components';
import { Button, CheckboxContent, PrimaryButton } from 'components/PrimaryButtonComp/style';
import {
  ButtonWrapper,
  StyledFooterWithButtonsOfFormPage,
} from 'components/PrototypeOfStepPages/styles';
import { I18n } from 'components/Translation';
import { InputFieldContainer, RandomSelectorFieldContainer } from 'components/fields';
import { CONSTANTS, getErrorTranslation } from 'config';
import { MONTHS_AS_NUMBER } from 'config/months';
import {
  translate,
  toastAlert,
  dataEntryAccess,
  dataNumberSpacesAccess,
  getUniqueId,
  checkIsUnionPay,
} from 'helpers';
import { useValidationSchema } from 'hooks';
import { useAppProvider, useSendMoneySteps, useTranslation } from 'providers';
import { useCardsData } from 'queries/card';
import { useFoundsOptions, usePurposeOptions } from 'queries/options';
import { getResourceManager } from 'resources';
import { CardData } from 'resources/card';
import {
  CardAuthFailure,
  CardAuthFinish,
  CardAuthStart,
  CardFormStart,
} from 'resources/card/types';
import { AppRoutes } from 'routes/constants';
import { getMonitoring } from 'services/monitoring';
import { RootState } from 'store';
import { resetFormState, updateSenderDetailsCardDataAction } from 'store/actionCreator';
import { setPartialCardsState } from 'store/slices/cards';
import { updateSenderDetailsCardData, ISenderDetailsCardData } from 'store/slices/transaction';
import { sendMoneySourceCurrencySelector } from 'store/slices/transaction/sender/sender.selectors';
import { ICardsTypes } from 'types/cards';

import {
  Table,
  CardType,
  StyledUpperPart,
  StyledContainer,
  StyledSectionTitle,
  StyledCardInfoUpperPart,
  StyledStepPagesLowerPart,
  StyledSectionWithEqualFields,
  StyledSectionWithNotEqualFields,
  StyledSectionWithHorizontalFields,
} from '../../style';
import { InputContainer } from '../../styledSenderDetails';
import { ICardData } from '../../type/transaction';
import { getValidationSchema } from '../getValidationSchema';
import { useCardFlow } from '../hooks/useCardFlow';
import style from '../style.module.scss';

import { filterCardsByCurrency } from './utils';

type ObjectKey = keyof typeof MONTHS_AS_NUMBER;

export const CardDetailsWS = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isCard] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [checkFields, setCheckFields] = useState<boolean>(false);
  const [activeCheckbox, setActiveCheckbox] = useState<number>(0);
  const [cardData] = useState<ICardData>({
    pan: '',
    date: '',
    cardHolderName: '',
    isSave: false,
  });

  const { loader, setLoader, loadType, setLoadType } = useAppProvider();
  const { handleNextStep } = useSendMoneySteps();
  const { t } = useTranslation();

  const senderDetailsData = useSelector(
    (state: RootState) => state?.senderDetails.data.senderDetailsData,
  );
  const senderDetailsCard = useSelector(
    (state: RootState) => state?.senderCard.data.senderDetailsCard,
  );
  const sourceCurrency = useSelector(sendMoneySourceCurrencySelector);

  const schema = useValidationSchema(getValidationSchema);

  const {
    defaultCardId,
    isNewCard,
    dataId: selectedCardId,
    isSave: isSaveCard,
  } = senderDetailsCard ?? {};

  const handleNextPage = useCallback(() => {
    handleNextStep();
    setLoader(false);
  }, [handleNextStep, setLoader]);

  const handleBack = () => {
    navigate(AppRoutes.moneySteps.senderDetails);
  };

  const defaultValues = useMemo(
    () => ({
      isNewCard: senderDetailsCard?.isNewCard ?? !selectedCardId,
      purpose_payment: senderDetailsCard?.purpose_payment ?? '',
      source_founds: senderDetailsCard?.source_founds ?? '',
      cardNumber: !senderDetailsCard?.pan?.includes('*')
        ? senderDetailsCard?.pan?.replace(CONSTANTS.REGEXP.NON_SPACES, '')
        : '',
      cvv: '',
      expDate: cardData?.date || senderDetailsCard?.expDate,
      cardHolderName: cardData?.cardHolderName || senderDetailsCard?.cardHolderName,
      isSave: isSaveCard,
      dataId: selectedCardId || '',
      defaultCardId: defaultCardId || '',
    }),
    [senderDetailsCard, defaultCardId, selectedCardId, cardData, isSaveCard],
  );

  const form = useForm<ISenderDetailsCardData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValues,
    },
  });

  const { setValue, watch, handleSubmit, getValues } = form;
  const [isNewCardValue, isSaveValue, cardNumberValue] = watch([
    'isNewCard',
    'isSave',
    'cardNumber',
  ]);

  const { data: sourceFoundsData } = useFoundsOptions();
  const { data: purposePaymentData } = usePurposeOptions();
  const { data: cardsData, refetch: refetchCards } = useCardsData({
    options: {
      onSettled: (cards) => {
        const availableCards = filterCardsByCurrency(sourceCurrency, cards);

        if (!availableCards?.length) {
          setValue('isNewCard', true);
          dispatch(updateSenderDetailsCardData({ isNewCard: true }));
        }
      },
    },
  });

  const isUnionPay = useMemo(() => checkIsUnionPay(cardNumberValue || ''), [cardNumberValue]);

  const cards = useMemo(
    () => filterCardsByCurrency(sourceCurrency, cardsData),
    [cardsData, sourceCurrency],
  );

  const handleDispatchWithoutAuthorize = async () => {
    const cardId: string = selectedCardId || cards?.[activeCheckbox]?.id || '';
    const result = await getResourceManager()
      .card.check(cardId)
      .catch((error) => {
        getMonitoring()?.captureException(error);
        return undefined;
      })
      .finally(() => setLoader(false));

    if (result) {
      handleNextPage();
    }

    if (!result) {
      toastAlert('error', translate(t, 'card_unavailable'));
      refetchCards();
    }
  };

  const handleDropFormData = useCallback(() => {
    resetFormState(dispatch);
  }, [dispatch]);

  const handleFailure = useCallback(
    (data: CardAuthFailure) => {
      const errorTranslation = getErrorTranslation(data?.failureMessage || '');
      const errorMessage = errorTranslation
        ? translate(t, errorTranslation)
        : data?.failureMessage || data?.reason || translate(t, 'error_something_went_wrong');

      toastAlert('error', errorMessage);

      getMonitoring()?.captureException(data);

      if (data.failureMessage) {
        setErrorMessage(errorMessage);
      }

      handleDropFormData();

      setLoader(false);
    },
    [t, handleDropFormData, setLoader],
  );

  const handleFormStart = useCallback(
    (res: CardFormStart) => {
      const values = getValues();

      updateSenderDetailsCardDataAction(
        dispatch,
        {
          ...values,
          pan: values.cardNumber,
        },
        selectedCardId,
      );

      if (res?.has3d) {
        dispatch(
          setPartialCardsState({
            formData: res?.secureForm,
            isHidden: res.isHidden,
            isAutoSubmit: res.isAutoSubmit,
          }),
        );
      } else {
        resetFormState(dispatch);
        handleNextPage();
      }
    },
    [dispatch, getValues, handleNextPage, selectedCardId],
  );

  const handleAuthFinish = useCallback(
    (data: CardAuthFinish) => {
      handleDropFormData();
      dispatch(
        updateSenderDetailsCardData({
          pan: data?.pan.replace(CONSTANTS.REGEXP.NON_SPACES, ''),
          dataId: data?.id,
        }),
      );

      if (senderDetailsCard.isSave) {
        toastAlert('success', translate(t, 'card_successfully_saved_local'));
      }

      handleNextPage();
      setLoader(false);
    },
    [dispatch, handleDropFormData, handleNextPage, senderDetailsCard.isSave, setLoader, t],
  );

  const { handleStartAuth } = useCardFlow({
    onFail: handleFailure,
    onFormStart: handleFormStart,
    onFormFinish: () => {
      handleDropFormData();
    },
    onAuthFinish: handleAuthFinish,
  });

  const onSubmit = (values: ISenderDetailsCardData) => {
    setLoader(true);
    setLoadType('Step Two');

    let monthNumber: string;
    if (Number(senderDetailsData?.month)) {
      monthNumber = senderDetailsData?.month as string;
    } else {
      monthNumber = `0${
        MONTHS_AS_NUMBER?.[senderDetailsData?.month?.toLowerCase() as ObjectKey]
      }`.slice(-2);
    }

    const privetCardData: CardAuthStart = {
      cardHolder: values?.cardHolderName,
      expMonth: values.expDate ? values.expDate.slice(0, 2) : '',
      expYear: values.expDate ? `20${values.expDate.slice(-2)}` : '',
      pan: values?.cardNumber?.replace(CONSTANTS.REGEXP.NON_SPACES, '') as string,
      country: senderDetailsData?.country,
      first_name: senderDetailsData?.firstName,
      last_name: senderDetailsData?.lastName,
      cvv: values?.cvv || '',
      isSave: values?.isSave,
      dateOfBirth: `${senderDetailsData?.year}-${monthNumber}-${senderDetailsData?.day}`,
      currency: sourceCurrency,
    };

    updateSenderDetailsCardData({
      cardHolderName: values?.cardHolderName,
      expDate: values?.expDate,
      pan: values?.cardNumber?.replace(CONSTANTS.REGEXP.NON_SPACES, '') as string,
    });

    if (!((selectedCardId || cards?.[activeCheckbox]?.id) && !isNewCardValue)) {
      handleStartAuth(privetCardData, getUniqueId());
    } else {
      handleDispatchWithoutAuthorize();
    }
  };

  const handleUpdateSenderDetailsDataByKey = useCallback(
    (key: keyof ISenderDetailsCardData) => (value: string) => {
      dispatch(updateSenderDetailsCardData({ [key]: value }));
    },
    [dispatch],
  );

  const handleUpdateSenderDetailsDataByKeyValue = useCallback(
    (payload: Partial<ISenderDetailsCardData>) => {
      dispatch(updateSenderDetailsCardData(payload));
    },
    [dispatch],
  );

  useEffect(() => {
    if (cards?.length && !isNewCard) {
      const defaultCard = cards?.find((card) => card?.isDefault);

      // Try to get default card as selected, if we have not previosly selected card
      if (defaultCard && !selectedCardId) {
        handleUpdateSenderDetailsDataByKeyValue({
          dataId: defaultCard?.id,
          defaultCardId: defaultCard?.id,
          cardHolderName: defaultCard?.holder,
          pan: defaultCard?.pan,
        });

        return () => {};
      }

      if (!selectedCardId || !defaultCardId) {
        const lastAddedCard = cards?.[cards.length - 1];

        handleUpdateSenderDetailsDataByKeyValue({
          dataId: lastAddedCard?.id,
          defaultCardId: lastAddedCard?.id,
          cardHolderName: lastAddedCard?.holder,
          pan: lastAddedCard?.pan,
        });
      }
    }

    return () => {};
  }, [
    cards,
    selectedCardId,
    senderDetailsCard,
    defaultCardId,
    isNewCard,
    handleUpdateSenderDetailsDataByKeyValue,
  ]);

  const handleResetCardData = () => {
    setValue('isSave', false);
    setValue('cardHolderName', '');
    setValue('expDate', '');
    setValue('pan', '');
    setValue('cvv', '');
  };

  const handleChooseCardsFlow = () => {
    if (loader) return;

    const isNewCard = !isNewCardValue;

    if (!isNewCard && defaultCardId) {
      handleUpdateSenderDetailsDataByKeyValue({
        dataId: defaultCardId,
      });
    } else {
      handleUpdateSenderDetailsDataByKeyValue({ dataId: '' });
      setActiveCheckbox(-1);
    }

    setValue('isNewCard', isNewCard);
    handleUpdateSenderDetailsDataByKeyValue({
      isNewCard,
      isSave: false,
      cardHolderName: '',
      expDate: '',
      pan: '',
      cvv: '',
    });
    handleResetCardData();
    setErrorMessage('');
  };

  const handleChooseAvailableCards = (ind: number, card: CardData) => {
    setActiveCheckbox(ind);

    setValue('isNewCard', false);

    handleResetCardData();

    handleUpdateSenderDetailsDataByKeyValue({
      defaultCardId: card?.id,
      dataId: card?.id,
      isSave: false,
      isNewCard: false,
      cardHolderName: card?.holder,
      expDate: '',
      pan: card?.pan,
      cvv: '',
    });
  };

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <PrototypeOfStepPagesForm
            smallTitle={<I18n tKey="senders_payment_info" />}
            subtitle={<I18n tKey="complete_personal_payment_details_local" />}
          >
            <StyledStepPagesLowerPart>
              <StyledContainer>
                <StyledSectionTitle>
                  <I18n tKey="info_about_your_payment" />
                </StyledSectionTitle>
                <StyledSectionWithHorizontalFields marginBottom="24px">
                  <RandomSelectorFieldContainer
                    name="purpose_payment"
                    className="firstChild set-cursor"
                    type="string"
                    data={purposePaymentData}
                    label={<I18n tKey="purpose_of_payment" />}
                    onChange={handleUpdateSenderDetailsDataByKey('purpose_payment')}
                    disabled={loader}
                    checkFields={checkFields}
                  />
                  <RandomSelectorFieldContainer
                    name="source_founds"
                    type="string"
                    className="secondChild set-cursor"
                    data={sourceFoundsData}
                    label={<I18n tKey="source_of_funds" />}
                    onChange={handleUpdateSenderDetailsDataByKey('source_founds')}
                    disabled={loader}
                    checkFields={checkFields}
                  />
                </StyledSectionWithHorizontalFields>
              </StyledContainer>
              <StyledUpperPart isAlignLeftPage>
                {!!cards?.length && (
                  <StyledContainer>
                    <StyledSectionTitle>
                      <I18n tKey="select_payment_card" />
                    </StyledSectionTitle>
                    <StyledCardInfoUpperPart>
                      <Table>
                        <tbody>
                          {cards?.map((card, index) => {
                            return (
                              <tr key={card?.id}>
                                <td className="first-row">
                                  {isNewCardValue ? (
                                    <RoundCheckbox
                                      handleActiveIndex={(ind: number) => {
                                        if (loader) return;

                                        handleChooseAvailableCards(ind, card);
                                      }}
                                      setIndex={1}
                                      active={0}
                                      cardId={card?.id}
                                    />
                                  ) : (
                                    <RoundCheckbox
                                      handleActiveIndex={(ind: number) => {
                                        if (loader) return;

                                        handleChooseAvailableCards(ind, card);
                                      }}
                                      setIndex={index}
                                      active={activeCheckbox}
                                      cardId={card?.id}
                                    />
                                  )}
                                  <CardType>
                                    <CardTypeComponent type={card?.type} />
                                  </CardType>
                                  <TextField variant="P7" color="n800">
                                    {CONSTANTS.CARD_TYPES[card?.type as keyof ICardsTypes]}{' '}
                                    <I18n tKey="ending_in" />{' '}
                                    {card?.pan?.substring(card?.pan?.length - 4)}
                                  </TextField>
                                </td>
                                <td className="second-row">
                                  <TextField variant="P7" color="n800">
                                    {card?.exp_month < 10 ? `0${card?.exp_month}` : card?.exp_month}{' '}
                                    / {String(card?.exp_year).slice(-2)}
                                  </TextField>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </StyledCardInfoUpperPart>
                    <ButtonWrapper margin="0" className={style.button_wrapper}>
                      <Button
                        onClick={handleChooseCardsFlow}
                        className={style.button}
                        type="button"
                      >
                        {isNewCardValue ? (
                          <div className={style.close_button_text_wrapper}>
                            <div className={style.close_button_text}>
                              <I18n tKey="add_new_payment_card" />
                              <CloseIcon />
                            </div>
                          </div>
                        ) : (
                          <I18n tKey="add_new_payment_card" />
                        )}
                      </Button>
                    </ButtonWrapper>
                  </StyledContainer>
                )}

                {(!cards?.length || isNewCardValue) && (
                  <StyledContainer>
                    <StyledSectionTitle>
                      {isCard ? (
                        <I18n tKey="new_payment_card" />
                      ) : (
                        <I18n tKey="your_payment_card_info" />
                      )}
                    </StyledSectionTitle>
                    <StyledSectionWithNotEqualFields jc="flex-start">
                      <InputContainer className={style['card_number']}>
                        <InputFieldContainer
                          name="cardNumber"
                          onChange={handleUpdateSenderDetailsDataByKey('pan')}
                          onValidate={(e: FocusEvent<HTMLInputElement>) =>
                            !!dataNumberSpacesAccess(e, 19)
                          }
                          checkFields={checkFields}
                          onBlur={() => handleUpdateSenderDetailsDataByKey('dataId')('')}
                          text={<I18n tKey="card_number" />}
                          hint={(isUnionPay && <I18n tKey="supportedUnionPayCard" />) || ''}
                          disabled={loader}
                          mask={CONSTANTS.REGEXP.CARD_NUMBER_MASK}
                          placeholder="1234 5678 9012 3456"
                        />
                      </InputContainer>
                      <InputContainer className={style.date}>
                        <InputFieldContainer
                          name="expDate"
                          onBlur={handleUpdateSenderDetailsDataByKey('expDate')}
                          checkFields={checkFields}
                          text={<I18n tKey="expiration_date" />}
                          disabled={loader}
                          mask={CONSTANTS.REGEXP.EXP_DATE_MASK}
                          placeholder="02/28"
                        />
                      </InputContainer>
                    </StyledSectionWithNotEqualFields>
                    <StyledSectionWithEqualFields marginBottom="13px">
                      <InputContainer className={style.cardholder}>
                        <InputFieldContainer
                          name="cardHolderName"
                          onBlur={handleUpdateSenderDetailsDataByKey('cardHolderName')}
                          disabled={loader}
                          checkFields={checkFields}
                          label={<I18n tKey="cardholder_name_label" />}
                        />
                      </InputContainer>
                      <InputContainer>
                        <InputFieldContainer
                          name="cvv"
                          disabled={loader}
                          onValidate={(e: FocusEvent<HTMLInputElement>) =>
                            /^[0-9]*$/.test(e.target.value) && !!dataEntryAccess(e, 3)
                          }
                          checkFields={checkFields}
                          label={<I18n tKey="cvv" />}
                          className="cvv-number"
                        />
                      </InputContainer>
                    </StyledSectionWithEqualFields>
                    {errorMessage && (
                      <TextField className="error-message" variant="P7">
                        {errorMessage}
                      </TextField>
                    )}
                    <ButtonWrapper margin="0">
                      <CheckboxContent>
                        <input
                          onChange={() => {
                            dispatch(
                              updateSenderDetailsCardData({ isSave: !isSaveValue, dataId: '' }),
                            );
                            setValue('isSave', !isSaveValue);
                          }}
                          type="checkbox"
                          id="agree1"
                          name="isSave"
                          disabled={loader}
                          checked={isSaveCard}
                        />
                        <label htmlFor="agree1">
                          <img src={checkImg} alt="check" />
                          <TextField className="check-text" variant="P7" color="n800">
                            <I18n tKey="save_card" />
                          </TextField>
                        </label>
                      </CheckboxContent>
                    </ButtonWrapper>
                  </StyledContainer>
                )}
              </StyledUpperPart>

              <StyledFooterWithButtonsOfFormPage>
                <PrimaryButton type="button" disabled={loader} width="84px" onClick={handleBack}>
                  <I18n tKey="back" />
                </PrimaryButton>
                <PrimaryButtonComp
                  width="84px"
                  text={<I18n tKey="next" />}
                  valid={!loader}
                  typeOfLoad={loadType}
                  click={() => {
                    setCheckFields(true);
                  }}
                />
              </StyledFooterWithButtonsOfFormPage>
            </StyledStepPagesLowerPart>
          </PrototypeOfStepPagesForm>
        </form>
      </FormProvider>
      <IframeSecure3d />
    </>
  );
};
