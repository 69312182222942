import { FocusEvent } from 'react';
import MaskedInput from 'react-text-mask';

import './maskedInput.scss';
import { ReactComponent as Flag } from 'assets/images/czech-republic.svg';
import { I18n } from 'components/Translation';

import {
  StyledLabel,
  StyledTitle,
  StyledErrorText,
  StyledFlag,
  StyledUnderInputText,
} from '../styles';

import {
  InputWrapper,
  InputContainer,
  StyledInput,
  StyledCurrency,
  StyledInputContainer,
  ErrorBox,
} from './styled';
import { InputType } from './types';

export const InputField = ({
  error = false,
  isPhone = false,
  isEmail,
  text,
  className,
  width,
  type,
  hint,
  placeholder,
  value,
  currnency,
  onChange,
  name,
  disabled,
  onBlur = () => {},
  errorMessage,
  pattern,
  onFocus,
  dateMask = [],
}: InputType) => {
  return (
    <InputWrapper>
      <StyledInputContainer className={className} width={width} isDisabled={disabled}>
        <StyledLabel error={error} className="focusDiv five_step_label">
          <StyledTitle error={error}>{text}</StyledTitle>
          <InputContainer>
            {dateMask?.length === 0 ? (
              <StyledInput
                disabled={disabled}
                error={error}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                name={name}
                onBlur={(e: FocusEvent<HTMLInputElement>) => onBlur(e)}
                pattern={pattern}
                onFocus={onFocus}
              />
            ) : (
              <MaskedInput
                mask={dateMask}
                className="masked_input"
                disabled={disabled}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                name={name}
                onBlur={(e: FocusEvent<HTMLInputElement>) => onBlur(e)}
                pattern={pattern}
                onFocus={onFocus}
              />
            )}
            {isPhone ? (
              <StyledFlag>
                <Flag />
              </StyledFlag>
            ) : isEmail || !currnency ? null : (
              <StyledCurrency>{currnency}</StyledCurrency>
            )}
          </InputContainer>
        </StyledLabel>
      </StyledInputContainer>
      <ErrorBox className="input-error">
        {error && (
          <StyledErrorText>{errorMessage || <I18n tKey="required_field" />}</StyledErrorText>
        )}
        {hint && !error && <StyledUnderInputText>{hint}</StyledUnderInputText>}
      </ErrorBox>
    </InputWrapper>
  );
};
