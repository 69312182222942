import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -18px;
`;

export const MessageContainer = styled.div`
  display: flex;
  position: relative;
  width: 224px;
  height: auto;
  background: #111928;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  flex: none;
  order: 4;
  flex-grow: 0;
  z-index: 4;

  > :first-child {
    text-align: center;
    margin: 5px auto;
    color: white;
  }
`;

export const Arrow = styled.div`
  margin-top: 40px;
  margin-left: 102px;
  position: absolute;
  width: 15px;
  height: 15px;
  rotate: 45deg;
  border: 1px solid transparent;
  box-sizing: border-box;
  -webkit-clip-path: polygon(50% 50%, 0 100%, 100% 100%, 100% 0, 50% 50%);
  clip-path: polygon(50% 50%, 0 100%, 100% 100%, 100% 0, 50% 50%);
  border-radius: 4px;

  background: #111928;

  position: absolute;
  bottom: -7px;
`;
