import { Logo } from 'assets/images';
import { Loader } from 'components';

import styles from './styles.module.scss';

export const SplashScreen = () => {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['header']}>
        <Logo />
      </div>
      <div className={styles['message_block']}>
        <Loader variant="lg" />
      </div>
    </div>
  );
};
