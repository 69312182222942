import { yupResolver } from '@hookform/resolvers/yup';
import { FocusEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { PrimaryButtonComp, TextField, TwoFactorAuthentication } from 'components';
import { I18n } from 'components/Translation';
import {
  InputFieldContainer,
  RandomSelectorFieldContainer,
  CountrySelectFieldContainer,
} from 'components/fields';
import { CONSTANTS } from 'config';
import { MONTHS, MONTHS_AS_NUMBER } from 'config/months';
import { phoneNumberEntryAccess, toastAlert, translate } from 'helpers';
import { dayData } from 'helpers/datData';
import { YEAR_OPTIONS } from 'helpers/years';
import { useValidationSchema } from 'hooks';
import { IErrorData } from 'pages/steps/PhoneVerification/types';
import { monthData } from 'pages/steps/RecipientDetails/monthData';
import { BirthBox } from 'pages/steps/stylesRecipientDetails';
import { useAppProvider, useTranslation } from 'providers';
import { useCountries } from 'providers/countriesProvider';
import { usePhoneCheckMutation, usePhoneVerifyMutation } from 'queries/profile/mutation';
import { getResourceManager } from 'resources';
import { TProfileDataRequest } from 'resources/user/profile/types';
import { getMonitoring } from 'services/monitoring';
import { RootState } from 'store';
import { IProfileData, setIsPersonalInfoFilled } from 'store/slices/personal';
import { setDateOfBirth, updateSenderDetailsData } from 'store/slices/transaction';

import { getValidationProfileSchema } from './getValidationSchema';
import { usePersonal, usePersonalPhone } from './hooks';
import { ButtonsWrapper, InfoWrapper, InputContainer, InputFields, RowContent } from './styles';

type Props = {
  isSetPassword: boolean;
  setIsChangePassword: (state: boolean) => void;
  setIsSetPassword: (state: boolean) => void;
  refetchProfileData: any;
  refetchProfileSettings: any;
};

export const PersonalForm = ({
  isSetPassword,
  setIsChangePassword,
  setIsSetPassword,
  refetchProfileData,
  refetchProfileSettings,
}: Props) => {
  const { sendingCountries } = useCountries();

  const dispatch = useDispatch();

  const isPersonalInfoFilled = useSelector(
    (state: RootState) => state?.personalInfo?.isPersonalInfoFilled,
  );

  const { t } = useTranslation();
  const { setLoader, loadType, setLoadType } = useAppProvider();

  const [firstSave, setFirstSave] = useState<boolean>(false);
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<IErrorData>({
    isError: false,
    message: '',
  });

  const { defaultValues, profile, state, setState } = usePersonal();

  const payloadRef = useRef<IProfileData>(defaultValues);
  const schema = useValidationSchema(getValidationProfileSchema);
  const form = useForm<IProfileData>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: { ...defaultValues },
  });

  const { formState, handleSubmit: onSubmit, watch, setValue, setError, clearErrors } = form;

  const { isValid } = formState;

  const [firstNameValue, lastNameValue, dayValue, monthValue, yearValue, country, phone] = watch([
    'firstName',
    'lastName',
    'day',
    'month',
    'year',
    'country',
    'phone',
  ]);

  const { handlePhoneVerify } = usePhoneVerifyMutation({
    onError: (e: Error) => {
      setErrorMessage({
        isError: true,
        message: e.message || translate(t, 'error_something_went_wrong'),
      });
    },
    onSettled: () => {
      setLoader(false);
    },
  });

  const { handlePhoneIsEmpty } = usePhoneCheckMutation({
    definedPhone: profile.phone,
    onError: () => {
      setLoader(false);
      setError('phone', {
        type: 'CUSTOM',
        message: translate(t, 'error_phone_already_use'),
      });
    },
  });

  const onPhoneUpdate = useCallback((phone: string) => setValue('phone', phone), [setValue]);

  usePersonalPhone({ country, phone, onUpdate: onPhoneUpdate });

  const submit = async (values: IProfileData) => {
    setLoader(true);
    setLoadType(CONSTANTS.SAVE);
    let monthNumber;
    const { city, country, firstName, lastName, phone, street, zip, day, month, year } = values;

    if (Number(month)) {
      monthNumber = month;
    } else {
      //  @ts-ignore
      monthNumber = `0${MONTHS_AS_NUMBER?.[month.toLowerCase()]}`.slice(-2);
    }

    const profileRequestData: TProfileDataRequest = {
      firstName,
      lastName,
      city,
      country,
      phone,
      street,
      zipCode: zip,
      dateOfBirth: `${year}-${monthNumber}-${day}`,
    };

    setIsChangePassword(false);
    setIsSetPassword(false);
    getResourceManager()
      .user.profile.updateProfileData(profileRequestData)
      .then(() => {
        setIsSetPassword(false);
        dispatch(setIsPersonalInfoFilled(true));
        toastAlert('success', translate(t, 'data_successfully_saved_local'));
        setFirstSave(true);
        refetchProfileData?.();
        refetchProfileSettings?.();
      })
      .catch((error) => {
        getMonitoring()?.captureException(error);
        toastAlert('error', error?.message || translate(t, 'error_something_went_wrong'));
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const checkVerificationCodeHandler = async (values: IProfileData) => {
    if (!isPersonalInfoFilled) {
      await handlePhoneIsEmpty.mutateAsync(values.phone || '');

      setErrorMessage({
        isError: false,
        message: '',
      });

      if (!isOpenPopup) {
        setIsOpenPopup(true);
        setLoader(true);
        setLoadType(CONSTANTS.PHONE);

        await handlePhoneVerify.mutateAsync(values.phone || '');
      }
    }
  };

  const handleSubmit = async (values: IProfileData) => {
    payloadRef.current = { ...values };
    checkVerificationCodeHandler(values);
  };

  const handleSubmitTwoFactor = () => {
    submit({
      ...payloadRef.current,
    });
  };

  useEffect(() => {
    clearErrors('phone');
  }, [phone, clearErrors]);

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit(handleSubmit)} noValidate>
        <InfoWrapper>
          <TextField variant="H6" color="n900">
            <I18n tKey="info_about_you" />
          </TextField>
          <InputFields>
            <RowContent>
              <InputContainer>
                <InputFieldContainer
                  name="firstName"
                  className="first-name"
                  type="text"
                  label={<I18n tKey="first_name" />}
                  disabled={!!profile?.first_name || firstSave}
                  value={firstNameValue?.replace(CONSTANTS.REGEXP.NON_SPACES, '')}
                />
              </InputContainer>
              <InputContainer>
                <InputFieldContainer
                  name="lastName"
                  className="last-name"
                  type="text"
                  disabled={!!profile?.last_name || firstSave}
                  value={lastNameValue?.replace(CONSTANTS.REGEXP.NON_SPACES, '')}
                  label={<I18n tKey="last_name" />}
                />
              </InputContainer>
            </RowContent>
          </InputFields>
        </InfoWrapper>

        <InfoWrapper>
          <TextField variant="H6" color="n900">
            <I18n tKey="date_of_birth" />
          </TextField>
          <BirthBox className="birth">
            <InputContainer>
              <RandomSelectorFieldContainer
                name="day"
                className="day"
                label={<I18n tKey="day" />}
                data={dayData(state.amountOfDays)}
                value={dayValue || ''}
                type="number"
                onChange={(value: string) => {
                  dispatch(updateSenderDetailsData({ day: value }));
                  dispatch(setDateOfBirth());
                }}
                disabled={!!profile?.date_of_birth}
                placeholder={translate(t, 'select')}
              />
            </InputContainer>
            <RandomSelectorFieldContainer
              name="month"
              className="month"
              label={<I18n tKey="month" />}
              data={monthData(t)}
              value={
                profile?.date_of_birth
                  ? // @ts-ignore
                    translate(t, MONTHS[profile?.date_of_birth?.split('-')?.[1]])
                  : monthValue
              }
              type="string"
              onChange={(value: string) => {
                dispatch(updateSenderDetailsData({ month: value }));
                if (value) {
                  const month = value?.toLowerCase();

                  setState({
                    monthNumber: MONTHS_AS_NUMBER?.[month],
                  });
                }
              }}
              disabled={!!profile?.date_of_birth}
            />
            <RandomSelectorFieldContainer
              name="year"
              className="year"
              label={<I18n tKey="year" />}
              data={YEAR_OPTIONS}
              value={yearValue}
              type="number"
              onChange={(value: string) => {
                dispatch(updateSenderDetailsData({ year: value }));
                setState({
                  yearNumber: value,
                });
              }}
              disabled={!!profile?.date_of_birth}
            />
          </BirthBox>
        </InfoWrapper>

        <InfoWrapper>
          <TextField variant="H6" color="n900">
            <I18n tKey="your_contact_address" />
          </TextField>
          <InputFields>
            <RowContent>
              <InputContainer>
                <CountrySelectFieldContainer
                  className="country"
                  name="country"
                  label={<I18n tKey="your_country_residence" />}
                  disabled={!!profile?.country || firstSave}
                  countries={sendingCountries}
                />
              </InputContainer>
              <InputContainer>
                <InputFieldContainer
                  name="city"
                  className="city"
                  type="text"
                  disabled={!!profile?.city || firstSave}
                  label={<I18n tKey="your_city" />}
                />
              </InputContainer>
            </RowContent>
            <RowContent>
              <InputContainer>
                <InputFieldContainer
                  name="street"
                  className="street"
                  type="street"
                  disabled={!!profile?.street || firstSave}
                  label={<I18n tKey="your_full_address" />}
                />
              </InputContainer>
              <InputContainer style={{ width: '100%' }}>
                <InputFieldContainer
                  name="zip"
                  className="zip-code"
                  type="zipCode"
                  disabled={!!profile?.zip || firstSave}
                  label={<I18n tKey="zip_code" />}
                />
              </InputContainer>
            </RowContent>
          </InputFields>
        </InfoWrapper>

        <InfoWrapper>
          <TextField variant="H6" color="n900">
            <I18n tKey="your_contact_details" />
          </TextField>
          <InputFields>
            <RowContent>
              <InputContainer>
                <InputFieldContainer
                  name="email"
                  className="email"
                  type="email"
                  disabled
                  label={<I18n tKey="email_input" />}
                />
              </InputContainer>
              <InputContainer>
                <InputFieldContainer
                  name="phone"
                  className="phone"
                  /**
                   * Здесь кастомная логика валидации, пока не убрал в onBlur из-за этого
                   */
                  onValidate={(e: FocusEvent<HTMLInputElement>) => !!phoneNumberEntryAccess(e)}
                  disabled={!!profile?.phone || firstSave}
                  label={<I18n tKey="enter_your_phone_number" />}
                />
              </InputContainer>
            </RowContent>
          </InputFields>
        </InfoWrapper>

        {/* -------------------------------BUTTONS------------------------------- */}
        {!isPersonalInfoFilled && (
          <ButtonsWrapper>
            <div />
            <PrimaryButtonComp
              type="submit"
              text={<I18n tKey="save_changes" />}
              valid={!isSetPassword && isValid}
              typeOfLoad={loadType}
            />
          </ButtonsWrapper>
        )}
        {isOpenPopup && (
          <TwoFactorAuthentication
            setIsOpenPopup={setIsOpenPopup}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            onSubmit={handleSubmitTwoFactor}
          />
        )}
      </form>
    </FormProvider>
  );
};
