import { IAllStepsData } from 'pages/steps/type/transaction';
import { Fetcher } from 'utils';

import { TransactionRepo } from './TransactionRepo';

export class TransactionService {
  private repo: TransactionRepo;

  constructor(fetcher: Fetcher) {
    this.repo = new TransactionRepo(fetcher);
  }

  public async process(payload: IAllStepsData) {
    const data = await this.repo.process(payload);

    return data;
  }

  public async repeatTransaction(id: number) {
    const data = await this.repo.repeatTransaction(id);

    return data;
  }
}
