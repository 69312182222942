import styled, { css } from 'styled-components';

export const StyledDropdown = styled.div``;

export const StyledDropdownButton = styled.button<{ isTextDropdown: boolean }>`
  font-family: Commissioner-Regular;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 4px solid transparent;
  box-sizing: border-box;
  ${({ isTextDropdown }) =>
    isTextDropdown
      ? css`
          font-weight: 600;
          color: #fff;
          border-radius: 11px;
          padding: 2px 0;
          background: transparent;
          :hover {
            color: #dde0f8;
            div {
              color: #3848c6;
            }
          }
          :focus {
            border-color: rgba(255, 255, 255, 0.24);
          }
        `
      : css`
          font-weight: 400;
          color: #222229;
          border-radius: 6px;
          padding: 8px 16px;
          background: transparent;
          :hover,
          :focus {
            color: #3848c6;
            svg path {
              fill: #3848c6;
            }
            div {
              color: #3848c6;
            }
          }
          svg path {
            fill: #222229;
          }
        `}
`;

export const StyledArrow = styled.div<{ show?: boolean }>`
  margin-left: 7px;
  transform: ${({ show }) => (show ? 'rotate(180deg)' : 0)};
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
`;

export const StyledDropdownMenu = styled.div<{ isTextDropdown: boolean }>`
  padding: 8px 0;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(34, 34, 41, 0.1);
  border-radius: 8px;
  position: absolute;
  top: 40px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  ${({ isTextDropdown }) =>
    isTextDropdown
      ? css`
          top: 33px;
          left: -16px;
        `
      : css`
          left: 0;
        `}
`;

export const StyledDropdownItem = styled.button`
  display: flex;
  align-items: center;
  padding: 6px 16px;
  font-size: 16px;
  color: #222229;
  border: none;
  width: 160px;
  text-align: left;
  background: transparent;
  cursor: pointer;
  > svg {
    margin-right: 16px;
  }
  :hover {
    background: #f4f4f6;
    > svg path {
      fill: #2d48ed;
    }
  }
`;
