const SendMoneyStepRoutes = {
  sendMoney: '/send-money',
  senderDetails: '/sender-details',
  cardVerification: '/card_verification',
  phoneVerification: '/phone_verification',
  recipientDetails: '/recipient_details',
  approveTransaction: '/approve_transaction',
};

const UnauthorizedRoutes = {
  signIn: '/sign-in',
  signUp: '/sign-up',
  forgetPass: '/forget-password',
  complaintsPolicy: '/complaints-policy',
  success: '/success',
};

const AuthorizedRoutes = {
  passwordRecover: '/password-recover',
  myCards: '/my_cards',
  transferSuccess: '/transfer-success',
  transferDenied: '/transfer-denied',
  transferDeclined: '/transfer-declined',
  history: '/history',
  personal: '/personal',
  goToProfile: '/go-to-profile',
  increaseMonthlyLimit: '/increase-monthly-limit',
  increaseMonthlyLimitWithId: '/increase-monthly-limit/id_passed',
  increaseMonthlyLimitWithIdAndRecheckId: '/increase-monthly-limit/id_passed/:recheckId',
  increaseMonthlyLimitWithAddress: '/increase-monthly-limit/address_passed',
  increaseMonthlyLimitWithAddressAndRecheckId: '/increase-monthly-limit/address_passed/:recheckId',
  increaseMonthlyLimitWithSofPassed: '/increase-monthly-limit/sof_passed',
  increaseMonthlyLimitWithSofPassedAndRecheckId: '/increase-monthly-limit/sof_passed/:recheckId',
  increaseMonthlyLimitWithZsPassed: '/increase-monthly-limit/zs_passed',
  increaseMonthlyLimitWithZsPassedAndRecheckId: '/increase-monthly-limit/zs_passed/:recheckId',
  increaseMonthlyLimitThankYou: '/increase-monthly-limit/thank-you',
};

export const AppRoutes = {
  main: '/',
  moneySteps: SendMoneyStepRoutes,
  public: UnauthorizedRoutes,
  private: AuthorizedRoutes,
};

export const ExternalLinks = {
  support: 'https://support.remitiva.com/help',
};

export const RoutesNameByPath = {
  '': 'Main Page',
  '/': 'Main Page',
  [SendMoneyStepRoutes.sendMoney]: 'Send Money Step',
  [SendMoneyStepRoutes.senderDetails]: 'Sender Details Step',
  [SendMoneyStepRoutes.phoneVerification]: 'Phone Verification Step',
  [SendMoneyStepRoutes.cardVerification]: 'Card Verification Step',
  [SendMoneyStepRoutes.recipientDetails]: 'Recipient Details Step',
  [SendMoneyStepRoutes.approveTransaction]: 'Approve Transaction Step',

  [UnauthorizedRoutes.complaintsPolicy]: 'Complaints Policy',
  [UnauthorizedRoutes.forgetPass]: 'Forget Password',
  [UnauthorizedRoutes.signIn]: 'Sign In',
  [UnauthorizedRoutes.signUp]: 'Sign Up',
  [UnauthorizedRoutes.success]: 'Success Page',

  [AuthorizedRoutes.goToProfile]: 'Go to Profile',
  [AuthorizedRoutes.passwordRecover]: 'Password Recover',
  [AuthorizedRoutes.history]: 'History',
  [AuthorizedRoutes.increaseMonthlyLimit]: 'Increase Monthly Limit',
  [AuthorizedRoutes.transferSuccess]: 'Transfer Success',
  [AuthorizedRoutes.transferDenied]: 'Transfer Denied',
  [AuthorizedRoutes.transferDeclined]: 'Transfer Declined',
  [AuthorizedRoutes.personal]: 'Personal',
  [AuthorizedRoutes.myCards]: 'User Cards',
  [AuthorizedRoutes.increaseMonthlyLimitWithId]: 'Increase Monthly Limit With Id',
  [AuthorizedRoutes.increaseMonthlyLimitWithAddress]: 'Increase Monthly Limit With Id With Address',
  [AuthorizedRoutes.increaseMonthlyLimitWithSofPassed]: 'Increase Monthly Limit With Id With Sof',
  [AuthorizedRoutes.increaseMonthlyLimitWithZsPassed]: 'Increase Monthly Limit With Id With Zs',
  [AuthorizedRoutes.increaseMonthlyLimitThankYou]: 'Increase Monthly Limit Thank You',
};

export const ExternalRoutes = {
  privacyPolicy: 'https://remitiva.com/privacy-policy',
  termsOfUse: 'https://remitiva.com/terms-conditions',
};
