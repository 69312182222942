import { configureStore, combineReducers, Middleware } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk, { ThunkAction } from 'redux-thunk';

import { envConfig } from 'config/env';

import { cardsSliceReducer } from './slices/cards';
import { languageReducer } from './slices/language';
import { paymentHistoryReducer } from './slices/paymentHistory';
import { personalInfoReducer } from './slices/personal';
import { stepsReducer } from './slices/steps';
import { supportReducer } from './slices/support';
import {
  senderDetailsReducer,
  senderRecipientReducer,
  senderCardReducer,
  sendMoneyReducer,
} from './slices/transaction';
import { verificationReducer } from './slices/verification';
import { Action } from './types';

const reducers = combineReducers({
  senderCard: senderCardReducer,
  senderDetails: senderDetailsReducer,
  senderRecipient: senderRecipientReducer,
  sendMoney: sendMoneyReducer,
  cards: cardsSliceReducer,
  personalInfo: personalInfoReducer,
  paymentHistory: paymentHistoryReducer,
  language: languageReducer,
  verification: verificationReducer,
  support: supportReducer,
  steps: stepsReducer,
});

const persistConfig = {
  key: 'persist-key',
  storage,
  blacklist: ['cards', 'paymentHistory'],
  whitelist: [
    'sendMoney',
    'senderRecipient',
    'senderCard',
    'senderDetails',
    'personalInfo',
    'language',
    'verification',
    'support',
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const getMiddlewares = () => {
  const middleware: Middleware<unknown>[] = [thunk];

  if (envConfig.isReduxLoggerEnabled) {
    const logger = createLogger();

    middleware.push(logger);
  }

  return middleware;
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getMiddlewares(),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
