import get from 'lodash/get';
import {
  ChangeHandler,
  FieldErrorsImpl,
  FieldValues,
  UseFormRegisterReturn,
} from 'react-hook-form';

type Props = UseFormRegisterReturn<string> & {
  errors?: Partial<FieldErrorsImpl<FieldValues>>;
  touchedFields?: Partial<Readonly<FieldValues>>;
  isShowError?: boolean;
  withRef?: boolean;
};

export type RegisterField = {
  name: string;
  error?: boolean | undefined;
  helperText?: string | undefined;
  isTouched?: boolean | undefined;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  min?: string | number | undefined;
  max?: string | number | undefined;
  maxLength?: number | undefined;
  minLength?: number | undefined;
  pattern?: string | undefined;
  required?: boolean | undefined;
  disabled?: boolean | undefined;
};

export const useRegister = ({
  errors,
  touchedFields,
  withRef = true,
  isShowError = true,
  ...props
}: Props) => {
  const { name } = props;
  const error = get(errors, name)?.message;
  const isTouched: boolean = get(touchedFields, name);

  const { ref, ...restProps } = props;

  return {
    ...restProps,
    ...(!!error && isShowError
      ? {
          error: !!error,
          helperText: error as string,
        }
      : {}),
    ...(withRef ? { ref } : {}),
    isTouched,
  };
};
