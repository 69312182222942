import * as yup from 'yup';

import { translate } from 'helpers';
import { TranslationDataType } from 'resources/translation';
import { getValidations } from 'validation';

export const getValidationSchema = (
  t: TranslationDataType,
  testRegx: (string: string) => boolean,
) => {
  const { letters, card } = getValidations(t);

  return yup.object().shape({
    firstName: letters.engRequired,
    lastName: letters.engRequired,
    cardNumber: card.standart.test(
      'is-the-same',
      `${translate(t, 'sender_receiving_same_local')}`,
      (value) => {
        return !testRegx(value as string);
      },
    ),
    cardholderName: yup
      .string()
      .matches(/^/, `${translate(t, 'required_field')}`)
      .required(`${translate(t, 'required_field')}`)
      .typeError(`${translate(t, 'must_be_string')}`),
  });
};
