import styled from 'styled-components';

export const NotificationWrapperStyle = styled.div<{ isRotate: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  background-color: #111928;
  padding: 6px 12px 6px 8px;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
  top: ${({ isRotate }) => (isRotate ? '101px' : '-62px')};
  left: -21px;
`;
export const NotificationTitle = styled.div`
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 10px;
`;

export const NotificationBody = styled.div``;

export const LineWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const NotificationDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #f0f0f2;
  margin: 0 10px;
`;

export const NotificationText = styled.div`
  font-size: 13px;
  line-height: 18px;
`;

export const ArrowWrapper = styled.div<{ isRotate: boolean }>`
  position: absolute;
  bottom: ${({ isRotate }) => (isRotate ? '120px' : '-10px')};
  rotate: ${({ isRotate }) => (isRotate ? '180deg' : '0')};
`;
