import React from 'react';

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    localStorage.removeItem('persist:persist-key');
    window.location.reload();
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      return <>{this.props.children}</>;
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
