import { useMutation } from 'react-query';

import { getResourceManager } from 'resources';
import { Question } from 'resources/question/types';

export const useAddQuestionMutation = () => {
  const addQuestion = useMutation((payload: Question) => {
    return getResourceManager().question.addQuestion(payload);
  });

  return addQuestion;
};
