import styled from 'styled-components';

export const PopUpWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(34, 34, 41, 0.5);
  left: 0;
  top: 0;
  z-index: 999999999;
`;

export const Wrapper = styled.div`
  position: absolute;
  width: 440px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 8px;

  @media screen and (max-width: 648px), {
    width: auto;
  }
`;

export const UnderLine = styled.div`
  width: 100%;
  border: none;
  border-top: 1px solid #e5e7eb;
  background: #e5e7eb;
  border-radius: 16px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  width: 100%;
`;

export const InputFields = styled.div`
  width: calc(100% - 40px);
  height: 72px;
  border-radius: 8px;
  margin: 20px 0;
  > * {
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
`;

export const SecondaryButton = styled.button<{ width?: string }>`
  height: 56px;
  display: flex;
  align-items: center;
  align-content: center;
  border: 1px solid red;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-family: Commissioner-Regular;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  .cancel-text {
    margin: 0 auto;
  }
  @media (max-width: 647px) {
    width: ${({ width }) => width};
    height: 56px;
  }
  :hover {
    color: #4858cf;
    border: 1px solid #3848c6;
  }
  :disabled {
    border: 1px solid #dde0f8;
    color: #abb3ed;
  }
  :active {
    box-shadow: 0 0 0 1px #3848c6;
    outline: 5px ridge rgba(24, 97, 242, 0.24);
    background: #fff !important;
  }
  :focus {
    background: #eff0fb;
    border: 1px solid #8994e6;
    color: #3342b8;
  }
`;

export const ButtonsWrapper = styled.div`
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  padding: 24px 20px;
  & > :first-child {
    width: 96px;
    border: 1px solid #8994e6;
    background: #fff;
  }
  & > :last-child {
    width: 116px;
    border: none;
    background: #3848c6;
    > div {
      color: #fff;
    }
  }
`;
