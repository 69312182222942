import { useQuery, UseQueryOptions } from 'react-query';

export type QueryProps<TData> = {
  key: string;
  enabled: boolean;
  method: () => Promise<TData>;
  options?: Omit<UseQueryOptions<TData, unknown, TData, string>, 'queryKey' | 'queryFn'>;
};

export function useQueryBuilder<TData>({ key, enabled, method, options }: QueryProps<TData>) {
  const props = useQuery(key, method, {
    refetchOnWindowFocus: false,
    retry: 1,
    retryDelay: 3000,
    enabled,
    ...options,
  });

  return props;
}
