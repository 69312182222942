import isNil from 'lodash/isNil';
import { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router';

import { useSwitch } from 'hooks';
import { getResourceManager } from 'resources';
import { AppRoutes } from 'routes/constants';
import { IProfile, ProfileStatus } from 'store/slices/personal';

import { ClosedScreen } from './ClosedScreen';
import { SuspendedScreen } from './SuspendedScreen';

type Props<T> = {
  profile: T;
  children: ReactNode;
};

const FrozenScreenView = (props: Props<IProfile>) => {
  const { profile, children } = props;

  const { status_w: status } = profile;

  const navigate = useNavigate();
  const [isSuspendedVisible, { off: hideSuspendedScreen }] = useSwitch(
    ProfileStatus.SUSPENDED === status,
  );

  const handleLogout = useCallback(async () => {
    await getResourceManager().auth.signOut();
    localStorage.clear();
    navigate(AppRoutes.public.signIn);
    window.location.reload();
  }, [navigate]);

  switch (status) {
    case ProfileStatus.CLOSED:
      return <ClosedScreen onLogout={handleLogout} />;
    case ProfileStatus.SUSPENDED: {
      return isSuspendedVisible ? (
        <SuspendedScreen onHide={hideSuspendedScreen} />
      ) : (
        <>{children}</>
      );
    }
    default:
      return <>{children}</>;
  }
};

export const FrozenScreen = (props: Props<IProfile | undefined>) => {
  const { profile, children } = props;

  if (isNil(profile)) {
    return <>{children}</>;
  }

  return <FrozenScreenView profile={profile}>{children}</FrozenScreenView>;
};
