import { ifProp, switchProp, theme, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import { IText } from './types';

export const TextField = styled.div<IText>`
  display: inline-block;
  color: ${theme('colors.natural.900')};

  &.upload-failed {
    color: #ff5c5c;
  }

  &.subtitle {
    display: flex;

    & div {
      color: #2d48ed;
      margin: 0 2px;
    }
  }

  ${switchProp('variant', {
    D1: css`
      font-family: 'Commissioner-Regular';
      font-weight: 600;
      font-size: 85px;
      line-height: 85px;
      letter-spacing: -1.8px;
    `,

    D2: css`
      font-family: 'Commissioner-Regular';
      font-weight: 600;
      font-size: 70px;
      line-height: 80px;
      letter-spacing: -1.8px;
    `,

    D3: css`
      font-family: 'Commissioner-Regular';
      font-weight: 600;
      font-size: 56px;
      line-height: 64px;
      letter-spacing: -1.8px;
    `,

    D4: css`
      font-family: 'Commissioner-Regular';
      font-weight: 600;
      font-size: 44px;
      line-height: 52px;
      letter-spacing: -1.4px;
    `,

    H1: css`
      font-family: 'Commissioner-Regular';
      font-weight: 500;
      font-size: 56px;
      line-height: 64px;
      letter-spacing: -1.8px;
    `,

    H2: css`
      font-family: 'Commissioner-Regular';
      font-weight: 500;
      font-size: 44px;
      line-height: 52px;
      letter-spacing: -1.4px;
    `,

    H3: css`
      font-family: 'Commissioner-Regular';
      font-weight: 500;
      font-size: 32px;
      line-height: 42px;
      letter-spacing: -0.8px;
    `,

    IL_H3: css`
      font-family: 'Commissioner-Regular';
      font-weight: 500;
      font-size: 32px;
      line-height: 42px;
      letter-spacing: -0.8px;

      @media (max-width: 1279px) {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
      }
    `,

    H4: css`
      font-family: 'Commissioner-Regular';
      font-weight: 500;
      font-size: 26px;
      line-height: 32px;
      letter-spacing: -0.8px;
    `,

    H5: css`
      font-family: 'Commissioner-Regular';
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.8px;
    `,

    H6: css`
      font-family: 'Commissioner-Regular';
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.8px;
    `,

    H7: css`
      font-family: 'Commissioner-Regular';
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
    `,
    H8: css`
      font-family: 'Commissioner-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.6px;
    `,

    P1: css`
      font-family: 'Lato-Regular';
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.2px;
    `,

    P2: css`
      font-family: 'Lato-Regular';
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.2px;
    `,

    P3: css`
      font-family: 'Lato-Regular';
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.2px;
    `,

    P4: css`
      font-family: 'Lato-Regular';
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.2px;
    `,

    P5: css`
      font-family: 'Lato-Regular';
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.2px;
    `,

    P6: css`
      font-family: 'Lato-Regular';
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.2px;
    `,

    P7: css`
      font-family: 'Lato-Regular';
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;

      &.expire_time_container {
        font-size: 13px;
      }

      .expire_time {
        color: #000;
        font-weight: 700;
      }

      &.two_factor {
        @media (max-width: 353px) {
          font-size: 14px;
        }
      }
    `,

    IL_P7: css`
      font-family: 'Lato-Regular';
      text-align: center;
      color: #686b78;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;

      @media (max-width: 1279px) {
        font-size: 14px;
        line-height: 20px;
      }
    `,

    IL_P8: css`
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    `,

    UP_H7: css`
      font-family: 'Lato-Regular';
      font-weight: 600;
      text-align: center;
      color: #3848c6;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
    `,

    P8: css`
      font-family: 'Lato-Regular';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
    `,

    P9: css`
      font-family: 'Lato-Regular';
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.2px;
    `,

    P10: css`
      font-family: 'Lato-Regular';
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.2px;
    `,

    P11: css`
      font-family: 'Lato-Regular';
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.2px;
    `,

    P12: css`
      font-family: 'Lato-Regular';
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.2px;
    `,

    P13: css`
      font-family: 'Lato-Regular';
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    `,

    P14: css`
      font-family: 'Lato-Regular';
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;
    `,

    title: css`
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 24px;
      line-height: 100%;
      letter-spacing: -0.2px;
    `,

    description: css`
      font-family: 'Lato-Regular';
      text-align: center;
      color: #686b78;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.2px;
    `,
  })}

  ${switchProp('color', {
    p700: css`
      color: ${theme('colors.primary.700')};
    `,
    p600: css`
      color: ${theme('colors.primary.600')};
    `,
    p500: css`
      color: ${theme('colors.primary.500')};
    `,
    p400: css`
      color: ${theme('colors.primary.400')};
    `,
    p300: css`
      color: ${theme('colors.primary.300')};
    `,
    p200: css`
      color: ${theme('colors.primary.200')};
    `,
    p100: css`
      color: ${theme('colors.primary.100')};
    `,
    n900: css`
      color: ${theme('colors.natural.900')};
    `,
    n800: css`
      color: ${theme('colors.natural.800')};
    `,
    n700: css`
      color: ${theme('colors.natural.700')};
    `,
    n600: css`
      color: ${theme('colors.natural.600')};
    `,
    n500: css`
      color: ${theme('colors.natural.500')};
    `,
    n400: css`
      color: ${theme('colors.natural.400')};
    `,
    n300: css`
      color: ${theme('colors.natural.300')};
    `,
    n200: css`
      color: ${theme('colors.natural.200')};
    `,
    n100: css`
      color: ${theme('colors.natural.100')};
    `,
    n50: css`
      color: ${theme('colors.natural.50')};
    `,
    white: css`
      color: ${theme('colors.natural.white')};
    `,
    aOne600: css`
      color: ${theme('colors.accentOne.600')};
    `,
    a500: css`
      color: ${theme('colors.accentOne.500')};
    `,
    aOne400: css`
      color: ${theme('colors.accentOne.400')};
    `,
    aTwo500: css`
      color: ${theme('colors.acceptTwo.500')};
    `,
    aTwo300: css`
      color: ${theme('colors.acceptTwo.300')};
    `,
    aTwo200: css`
      color: ${theme('colors.acceptTwo.200')};
    `,
    aTwo100: css`
      color: ${theme('colors.acceptTwo.100')};
    `,
    aThree500: css`
      color: ${theme('colors.acceptThree.500')};
    `,
    w500: css`
      color: ${theme('colors.warning.500')};
    `,
    w300: css`
      color: ${theme('colors.warning.300')};
    `,
    w200: css`
      color: ${theme('colors.warning.200')};
    `,
    w100: css`
      color: ${theme('colors.warning.100')};
    `,
    e500: css`
      color: ${theme('colors.error.500')};
    `,
    e300: css`
      color: ${theme('colors.error.300')};
    `,
    e200: css`
      color: ${theme('colors.error.200')};
    `,
    e100: css`
      color: ${theme('colors.error.100')};
    `,
    e50: css`
      color: ${theme('colors.error.50')};
    `,
    s500: css`
      color: ${theme('colors.success.500')};
    `,
    s300: css`
      color: ${theme('colors.success.300')};
    `,
    s200: css`
      color: ${theme('colors.success.200')};
    `,
    s100: css`
      color: ${theme('colors.success.100')};
    `,
  })}
  
${ifProp(
    'lineHeight',
    css`
      line-height: ${prop('lineHeight')};
    `,
  )}

${ifProp(
    'bottom',
    css`
      margin-bottom: ${prop('bottom')}px;
    `,
  )}

${ifProp(
    'top',
    css`
      margin-top: ${prop('top')}px;
    `,
  )}

${ifProp(
    'left',
    css`
      margin-left: ${prop('left')}px;
    `,
  )}

${ifProp(
    'right',
    css`
      margin-right: ${prop('right')}px;
    `,
  )}

${ifProp(
    'margin',
    css`
      margin: ${prop('margin')}px;
    `,
  )}
  
${ifProp(
    'cursor',
    css`
      cursor: ${prop('cursor')};
    `,
  )}
  
${ifProp(
    'ellipsis',
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
  )}
  
${ifProp(
    'width',
    css`
      width: ${prop('width')}px;
    `,
  )}

  ${ifProp(
    'height',
    css`
      height: ${prop('height')}px;
    `,
  )}
  
${ifProp(
    'fullWidth',
    css`
      width: 100%;
    `,
  )}
  
   ${ifProp(
    'bold',
    css`
      font-weight: bolder;
    `,
  )}
  
  ${ifProp(
    'center',
    css`
      text-align: center;
    `,
  )}

  ${ifProp(
    'fw',
    css`
      font-weight: ${prop('fw')};
    `,
  )}

${ifProp(
    'bgc',
    css`
      color: ${prop('bgc')};
    `,
  )}
`;
