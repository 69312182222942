import { useMemo, useState } from 'react';

type SwitchHelpers = Record<'on' | 'off' | 'toggle', () => void>;

export function useSwitch(defaultValue = false): [boolean, SwitchHelpers] {
  const [isOn, setIsOn] = useState(defaultValue);

  const actions = useMemo(
    () => ({
      on: () => setIsOn(true),
      off: () => setIsOn(false),
      toggle: () => setIsOn((v) => !v),
    }),
    [],
  );

  return [isOn, actions];
}
