import { Fetcher } from 'utils';

import { Response } from '../types';

import { ENDPOINTS } from './endpoints';
import { TCheckCurrency } from './types';

export class CurrencyRepo {
  private fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  public async checkCurrency(currency: string) {
    const { data } = await this.fetcher.fetch<unknown, { data: Response<TCheckCurrency> }>({
      url: ENDPOINTS.check,
      method: 'GET',
      params: {
        currency,
      },
    });

    return data?.data;
  }
}
