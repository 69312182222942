import { AxiosResponse } from 'axios';

import { Response } from 'resources/types';
import { Fetcher } from 'utils';

import { ENDPOINTS } from './endpoints';
import { CountryType } from './types';

export class CountryRepo {
  private fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  public async getCountries() {
    const { data } = await this.fetcher.fetch<unknown, Response<AxiosResponse<CountryType[]>>>({
      url: ENDPOINTS.list,
      method: 'GET',
    });

    return data.data;
  }
}
