import { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { envConfig } from 'config/env';
import { useAppProvider } from 'providers/appProvider';
import { AppRoutes } from 'routes/constants';
import { RootState } from 'store';
import { setSteps } from 'store/slices/steps';
import { updateSendMoneyState } from 'store/slices/transaction';
import { sendMoneyStepsSelector } from 'store/slices/transaction/sender/sender.selectors';

export const useSendMoneyStepsContext = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const headerSteps = useSelector((state: RootState) => state?.steps);
  const steps = useSelector(sendMoneyStepsSelector);
  const { pathname } = location;

  const { isPersistDone } = useAppProvider();

  const filteredPathname = useMemo(
    () => (pathname === AppRoutes.main ? AppRoutes.moneySteps.sendMoney : pathname),
    [pathname],
  );

  /**
   * Update current step state and try to open next unsuccess step if it's possible
   */
  const handleNextStep = useCallback(
    (params?: Record<string, string | number | string[] | number[] | unknown>) => {
      const currentStepIndex = steps?.findIndex(
        (step) => step.path.toLowerCase() === filteredPathname.toLowerCase(),
      );

      const nextSteps = steps?.filter((_, index) => currentStepIndex < index);

      const [nextStep, skippedStepBefore] = nextSteps || [];

      const isPhoneVerifyStep =
        nextStep.path.toLowerCase() === AppRoutes.moneySteps.phoneVerification.toLowerCase();
      const step =
        isPhoneVerifyStep && nextStep?.success && skippedStepBefore ? skippedStepBefore : nextStep;

      const newSteps = steps?.map((step, index) => {
        if (
          step.path.toLowerCase() === filteredPathname.toLowerCase() ||
          currentStepIndex === index
        ) {
          return { ...step, success: true };
        }

        if (currentStepIndex === 0) {
          return { ...step, success: false };
        }

        return step;
      });

      dispatch(updateSendMoneyState({ steps: newSteps }));

      if (step) {
        navigate(step.path, { ...(params ? { state: params } : {}) });
      }
    },
    [filteredPathname, steps, navigate, dispatch],
  );

  /**
   * Try to open last success step, if user try to get access to deep unsuccess step
   */
  useEffect(() => {
    const currentStepIndex = steps?.findIndex(
      (step) => step.path.toLowerCase() === filteredPathname.toLowerCase(),
    );

    if (currentStepIndex < 0 || !isPersistDone || envConfig.isDebugStepsEnabled) {
      return () => {};
    }

    const currentStep = steps?.[currentStepIndex];

    const previousStep = steps?.[currentStepIndex - 1];

    if (currentStepIndex > 0 && !currentStep.success && previousStep && !previousStep?.success) {
      const allUnsuccessSteps = steps?.filter((step) => !step.success);

      const [firstUnsuccessStep] = allUnsuccessSteps || [];

      if (firstUnsuccessStep?.path) {
        navigate(firstUnsuccessStep.path);
      }
    }

    return () => {};
  }, [isPersistDone, filteredPathname, steps, navigate]);

  useEffect(() => {
    if (!isPersistDone) {
      return () => {};
    }

    const index = steps?.findIndex(
      (step) => step.path?.toLowerCase() === filteredPathname?.toLowerCase(),
    );

    const isSameStep =
      headerSteps?.totalSteps === steps?.length && index === headerSteps?.stepNumber;

    if (index > -1 && !isSameStep) {
      dispatch(
        setSteps({
          stepNumber: index,
          /**
           * 0 step index show = false, other = true
           */
          show: !(index === 0),
          totalSteps: steps?.length,
          stepDescription: '',
        }),
      );
    }

    return () => {};
  }, [
    isPersistDone,
    filteredPathname,
    steps,
    headerSteps?.stepNumber,
    headerSteps?.totalSteps,
    dispatch,
  ]);

  return { steps, handleNextStep };
};
