import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 75%;
  height: 100%;
  align-content: center;
  margin: auto auto 48px auto;
  padding: 48px 60px 0 48px;
  position: relative;
  @media screen and (max-width: 1599px) {
    width: auto;
  }
  @media screen and (max-width: 1279px) {
    margin-bottom: 48px;
    padding: 24px;
  }
  @media screen and (max-width: 647px) {
    margin: 24px 16px 48px;
    padding: 0;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .add-button {
    padding: 0;
  }
  @media (max-width: 647px) {
    flex-direction: column;
    .add-button {
      margin-left: 0;
    }
  }
`;

export const TextWrapper = styled.div`
  display: flex:
  flex-direction: column;
  gap: 8px;
  @media (max-width: 1279px) {
    &:nth-child(1) {
      & > :first-child {
        font-weight: 500;
        font-size: 26px;
        line-height: 32px;
      }
    }
  }
  @media (max-width: 647px) {
    margin-bottom: 16px;
  }
  
`;

export const PrimaryButton = styled.button<{ width?: string }>`
  width: 156px;
  height: 56px;
  border: none;
  padding: 16px 24px;
  background: #3848c6;
  color: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-family: Commissioner-Regular;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  @media (max-width: 647px) {
    width: ${({ width }) => width};
    height: 56px;
  }
  :hover {
    background: #4858cf;
  }
  :active {
    outline: 4px solid rgba(24, 97, 242, 0.24);
    background: #3848c6 !important;
  }
  :focus {
    background: #3342b8;
  }
  :disabled {
    background: #dde0f8 !important;
  }
  > .cancel-btn {
    border: 1px solid red;
  }
`;

export const TableWrapper = styled.div`
  margin-top: 32px;
  border: 1px solid #f0f0f2;
  border-radius: 8px;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  thead {
    background: #f0f0f2;
  }
  td:nth-child(1) {
    width: 34px;
    padding-right: 0;
  }
  td > svg {
    width: 34px;
    height: auto;
    padding: 0;
  }
  td > img {
    width: 30px;
    position: relative;
    top: 2px;
  }
  td:last-child,
  td:last-child > svg {
    width: 24px;
    text-align: right;
  }
  tr {
    border-top: 1px solid #f0f0f2;
  }
  th,
  td {
    padding: 16px 16px;
    text-align: left;
  }
`;

export const StyledDropdown = styled.div`
  cursor: pointer;
  position: relative;
  > .dropdown-items {
    right: 0;
    position: absolute;
    z-index: 1000;
    box-shadow: 0px 2px 4px rgba(34, 34, 41, 0.2);
    border-radius: 8px;
  }
  > .dropdown-items > .item {
    position: relative;
    // Depend on page animation
    z-index: 99999;
    padding: 0;
  }
  > .dropdown-items > .item > button:first-child {
    :hover {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
  > .dropdown-items > .item > button:last-child {
    :hover {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

export const NewCardSection = styled.div`
  width: 600px;
  margin: 32px auto 0;
  @media (max-width: 647px) {
    width: 100%;
    margin: 0 auto;
  }
`;

export const TextConteiner = styled.div`
  padding: 16px;
`;
export const BoxContent = styled.div`
  width: auto;
  max-width: 700px;
  flex-wrap: wrap;
  display: flex;
  gap: 16px;
  margin: 20px 0;
  * {
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
  @media (max-width: 1279px) {
    flex-direction: column;
  }
`;

export const BoxFillElement = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  .card-number {
    width: 292px;
  }
  .cardholder {
    width: 464px;
    > label > .input-container > input {
      width: 100%;
    }
  }
  @media (max-width: 1279px) {
    width: 100%;
    flex-direction: column;
    .card-number {
      width: 100%;
    }
    .cardholder {
      width: auto;
    }
  }
`;

export const BoxLastElement = styled.div`
  width: 120px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1279px) {
    width: 100%;
  }
`;

export const LineWrapper = styled.div`
  margin: 32px 0 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  @media (max-width: 648px) {
    flex-direction: column;
    gap: 16px;
  }
  > button {
    width: 96px;
    :disabled {
      cursor: default;
    }
    @media (max-width: 648px) {
      width: auto;
    }
  }
  .save-button {
    width: 116px;
    @media (max-width: 648px) {
      width: auto;
    }
  }
`;

export const WrapperNewCard = styled.div``;

export const DateContainer = styled.div`
  width: 156px;
  @media (max-width: 1279px) {
    width: 100%;
`;
