import { CardData } from 'resources/card';

export const filterCardsByCurrency = (sourceCurrency: string, cards?: CardData[]) => {
  return cards?.filter((card) => {
    if (card.type === 'visa' && sourceCurrency) {
      return sourceCurrency.toLowerCase() === card.currency.toLowerCase();
    }

    return true;
  });
};
