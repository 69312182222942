import { IStepFiveDrawData } from 'store/slices/transaction';

import {
  StyledReviewComponentPartContainer,
  StyledReviewComponentPartTitle,
  StyledReviewComponentPartValue,
} from './styles';

export const ReviewComponentPart = ({ data }: { data: IStepFiveDrawData[] }) => (
  <StyledReviewComponentPartContainer>
    {data?.map((item: IStepFiveDrawData, index: number) => (
      <div key={index}>
        <StyledReviewComponentPartTitle>{item?.title}</StyledReviewComponentPartTitle>
        <StyledReviewComponentPartValue>
          {item?.value ? item.value : '_'}
        </StyledReviewComponentPartValue>
      </div>
    ))}
  </StyledReviewComponentPartContainer>
);
