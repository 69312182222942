import { Fetcher } from 'utils';

import { TranslationRepo } from './TranslationRepo';
import { TranslationUpdatePayload } from './types';

export class TranslationService {
  private repo: TranslationRepo;

  constructor(fetcher: Fetcher) {
    this.repo = new TranslationRepo(fetcher);
  }

  public async getTranslatedData(lang: string) {
    const data = await this.repo.getTranslatedData(lang);

    return data;
  }

  public async checkAccess() {
    const data = await this.repo.checkAccess();

    return data;
  }

  public async update(payload: TranslationUpdatePayload) {
    const data = await this.repo.update(payload);

    return data;
  }
}
