import { IImage } from './types';
import { ImageWrapper } from './styles';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

export const Image = (props: IImage): ReactJSXElement => {
  const { image, height, width, imgMaxWidth } = props;
  return (
    <ImageWrapper height={height} width={width}>
      {typeof image === 'string' ? <img style={{ maxWidth: imgMaxWidth }} src={image} /> : image}
    </ImageWrapper>
  );
};
