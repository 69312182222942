import { createSlice } from '@reduxjs/toolkit';

import { Action } from '../../../types';

import { DEFAULT_SEND_MONEY_STEPS } from './constants';
import { IDataStepOne, SendMoneyFields, SendMoneySlice, TransactionStatus } from './sender.types';

const initialState: SendMoneySlice = {
  data: {
    sendMoney: <IDataStepOne>{
      isAgree: false,
      fromAmount: '',
      toAmount: '',
      fee: '',
      debet: '',
      debit: '',
      rate: {
        rate: '',
        to: '',
        date: '',
        from: '',
      },
      delivery: '',
    },
  },
  fromCountry: '',
  toCountry: '',
  receiverEmail: '',
  transactionStatus: TransactionStatus.empty,
  fromCurrency: '',
  toCurrency: '',
  steps: DEFAULT_SEND_MONEY_STEPS,
  id: '',
};

const sendMoneySlice = createSlice({
  name: 'sendMoney',
  initialState: {
    ...initialState,
  },

  reducers: {
    updateSendMoneyData(state, action: Action<IDataStepOne>) {
      return {
        ...state,
        data: {
          ...state.data,
          sendMoney: {
            ...state.data.sendMoney,
            ...action.payload,
          },
        },
      };
    },
    updateSendMoneyState(state, action: Action<SendMoneyFields>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateSendMoneyData, updateSendMoneyState } = sendMoneySlice.actions;

export const sendMoneyReducer = sendMoneySlice.reducer;
