import { yupResolver } from '@hookform/resolvers/yup';
import { FocusEvent, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { TextField, PrimaryButtonComp, PrototypeOfStepPagesForm } from 'components';
import { PrimaryButton } from 'components/PrimaryButtonComp/style';
import { I18n } from 'components/Translation';
import { InputFieldContainer } from 'components/fields';
import { StyledFooterWithButtons } from 'components/styles';
import { CONSTANTS } from 'config';
import { dataNumberSpacesAccess } from 'helpers';
import { useValidationSchema } from 'hooks';
import { useAppProvider, useSendMoneySteps } from 'providers';
import { AppRoutes } from 'routes/constants';
import { getMonitoring } from 'services/monitoring';
import { RootState } from 'store';
import { updateRecipientDetailsDataAction } from 'store/actionCreator';
import { IDataRecipientDetails } from 'store/slices/transaction';

import {
  StyledContainer,
  StyledUpperPart,
  StyledSectionTitle,
  StyledCardInfoUpperPart,
  StyledSectionWithEqualFields,
  StyledRecipientCardInfoLowerPart,
} from '../style';
import { InputContainer, Wrapper } from '../stylesRecipientDetails';

import { getValidationSchema } from './getValidationSchema';
import styles from './receipt.module.scss';

export const RecipientDetails = () => {
  const { loadType } = useAppProvider();
  const { handleNextStep } = useSendMoneySteps();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isError, setIsError] = useState<boolean>(false);

  const recipientDetails = useSelector(
    (state: RootState) => state?.senderRecipient?.data?.recipientDetails,
  );

  const senderCardPan =
    useSelector((state: RootState) => state?.senderCard?.data?.senderDetailsCard?.pan) || '';

  const replacedNumber = senderCardPan?.replace(/ /gi, '');
  const cardNumbers =
    replacedNumber?.indexOf('****') > -1
      ? replacedNumber
      : `${replacedNumber?.slice(0, 6)}****${replacedNumber?.slice(-4)}`;
  const senderCardIncludeNumbers = cardNumbers?.split('****');

  const cardReg = new RegExp(
    `(${senderCardIncludeNumbers?.[0].slice(0, 4)}\\s${senderCardIncludeNumbers?.[0].slice(
      4,
      6,
    )}\\d\\d\\s\\d\\d\\d\\d\\s${senderCardIncludeNumbers?.[1]})`,
  );

  const testRegx = (string: string) => cardReg.test(string);

  const schema = useValidationSchema(getValidationSchema, testRegx);

  const defaultValues = useMemo(
    () => ({
      ...(recipientDetails || {}),
    }),
    [recipientDetails],
  );

  const form = useForm<IDataRecipientDetails>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: { ...defaultValues },
  });

  const { handleSubmit: onSubmit, formState } = form;
  const { isValid } = formState;

  const handleSubmit = async (values: IDataRecipientDetails) => {
    try {
      updateRecipientDetailsDataAction(dispatch, {
        ...values,
      });
      handleNextStep();
    } catch (error) {
      getMonitoring()?.captureException(error);
      setIsError(true);
    }
  };

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={onSubmit(handleSubmit)} noValidate>
          <PrototypeOfStepPagesForm
            smallTitle={CONSTANTS.STEP_PAGES.TITLES.RECIPIENT_CONTACT_DETAILS}
            subtitle={CONSTANTS.STEP_PAGES.SUBTITLES.COMPLETE_RECIPIENT_PERSONAL_DATA}
            description={<I18n tKey="complete_receipt_details_description" />}
            contentClassName={styles['container']}
          >
            <Wrapper>
              <StyledUpperPart isAlignLeftPage>
                <StyledContainer>
                  <StyledSectionTitle>{CONSTANTS.INFO_ABOUT_RECIPIENT}</StyledSectionTitle>
                  <StyledSectionWithEqualFields>
                    <InputContainer className="firstChild">
                      <InputFieldContainer
                        name="firstName"
                        className="first-name"
                        label={<I18n tKey="first_name" />}
                      />
                    </InputContainer>
                    <InputContainer className="secondChild">
                      <InputFieldContainer
                        name="lastName"
                        className="last-name"
                        label={<I18n tKey="last_name" />}
                      />
                    </InputContainer>
                  </StyledSectionWithEqualFields>
                </StyledContainer>
                <StyledContainer>
                  <StyledSectionTitle>
                    <I18n tKey="recipient_card_details" />
                  </StyledSectionTitle>
                  <StyledCardInfoUpperPart>
                    <InputContainer className="firstChild">
                      <InputFieldContainer
                        name="cardNumber"
                        className="card-number"
                        label={<I18n tKey="card_number" />}
                        onValidate={(e: FocusEvent<HTMLInputElement>) =>
                          !!dataNumberSpacesAccess(e, 19)
                        }
                        mask={CONSTANTS.REGEXP.CARD_NUMBER_MASK}
                      />
                    </InputContainer>
                  </StyledCardInfoUpperPart>
                  <StyledRecipientCardInfoLowerPart>
                    <InputContainer className="firstChild">
                      <InputFieldContainer
                        name="cardholderName"
                        className="firstChild cardholder-name"
                        label={<I18n tKey="cardholder_name_label" />}
                      />
                    </InputContainer>
                  </StyledRecipientCardInfoLowerPart>
                </StyledContainer>
                {isError && (
                  <TextField className="error-message" variant="P7">
                    <I18n tKey="recipient_must_be_18" />
                  </TextField>
                )}
                <StyledFooterWithButtons>
                  <PrimaryButton
                    type="button"
                    width="84px"
                    onClick={() => {
                      navigate(AppRoutes.moneySteps.cardVerification);
                    }}
                  >
                    <I18n tKey="back" />
                  </PrimaryButton>
                  <PrimaryButtonComp
                    width="84px"
                    text={<I18n tKey="next" />}
                    valid={isValid}
                    typeOfLoad={loadType}
                  />
                </StyledFooterWithButtons>
              </StyledUpperPart>
            </Wrapper>
          </PrototypeOfStepPagesForm>
        </form>
      </FormProvider>
    </>
  );
};
