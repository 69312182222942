import styled from 'styled-components';

const SocialWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  .line-wrapper {
    gap: 16px;
    * {
      margin: 24px 0 12px;
      padding: 0;
    }
  }
  > button {
    margin: 0;
    padding: 12px;
    background: white;
    border: 1px solid #c7c9d1;
    border-radius: 8px;
    width: 480px;
    height: 48px;
    cursor: pointer;
  }
  @media (max-width: 960px) {
    width: 100%;
    > button {
      @media (max-width: 960px) {
        width: 100%;
      }
    }
  }
`;

const LineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Line = styled.div`
  border: 1px solid #f0f0f2;
  width: 50%;
`;

export { SocialWrapper, LineWrapper, Line };
