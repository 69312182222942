const ERROR_CODES: Record<string, string> = {
  invalid_card: 'error_invalid_card',
  unable_to_send_verification_code: 'error_unable_to_send_verification_code',
};

export const getErrorTranslation = (error: string): string | undefined => {
  const translation = ERROR_CODES?.[error];

  return translation;
};
