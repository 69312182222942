import {
  AuthError,
  AuthResponse,
  OAuthResponse,
  Session,
  UserResponse,
} from '@supabase/supabase-js';

import { Nullable } from 'types/helpers';

export type PasswordSignInProps = {
  email: string;
  password: string;
};

export interface IAuthRepo {
  signOut: () => Promise<{
    error: AuthError | null;
  }>;
  signInWithGoogle: () => Promise<OAuthResponse>;
  signInWithApple: () => Promise<OAuthResponse>;
  signInWithEmail: (email: string) => Promise<AuthResponse>;
  signInWithPassword: (credentials: PasswordSignInProps) => Promise<AuthResponse>;
  getUserData: () => Promise<UserResponse>;
  setSession: (accessToken: string, refreshToken: string) => Promise<AuthResponse>;
  getSession: () => Promise<{
    data: {
      session: Nullable<Session>;
    };
    error: Nullable<AuthError>;
  }>;
}

export enum DataAuthProvider {
  google = 'google',
  apple = 'apple',
}
