import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ifProp, switchProp, theme, prop } from 'styled-tools';

interface IButton {
  color?: string;
  margin?: string;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  padding?: string;
  cursor?: string;
  width?: string;
  height?: string;
  fullWidth?: boolean;
  bold?: boolean;
  center?: boolean;
  bg?: string;
  border?: string;
  radius?: string;
}

const Button = styled.button<IButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme('colors.natural.900')};
  border: none;
  :disabled {
    opacity: 0.3;
  }
  ${switchProp('color', {
    p700: css`
      color: ${theme('colors.primary.700')};
    `,
    p600: css`
      color: ${theme('colors.primary.600')};
    `,
    p500: css`
      color: ${theme('colors.primary.500')};
    `,
    p400: css`
      color: ${theme('colors.primary.400')};
    `,
    p300: css`
      color: ${theme('colors.primary.300')};
    `,
    p200: css`
      color: ${theme('colors.primary.200')};
    `,
    p100: css`
      color: ${theme('colors.primary.100')};
    `,
    n900: css`
      color: ${theme('colors.natural.900')};
    `,
    n800: css`
      color: ${theme('colors.natural.800')};
    `,
    n700: css`
      color: ${theme('colors.natural.700')};
    `,
    n600: css`
      color: ${theme('colors.natural.600')};
    `,
    n500: css`
      color: ${theme('colors.natural.500')};
    `,
    n400: css`
      color: ${theme('colors.natural.400')};
    `,
    n300: css`
      color: ${theme('colors.natural.300')};
    `,
    n200: css`
      color: ${theme('colors.natural.200')};
    `,
    n100: css`
      color: ${theme('colors.natural.100')};
    `,
    n50: css`
      color: ${theme('colors.natural.50')};
    `,
    white: css`
      color: ${theme('colors.natural.white')};
    `,
    aOne600: css`
      color: ${theme('colors.acceptOne.600')};
    `,
    aOne500: css`
      color: ${theme('colors.acceptOne.500')};
    `,
    aOne400: css`
      color: ${theme('colors.acceptOne.400')};
    `,
    aTwo500: css`
      color: ${theme('colors.acceptTwo.500')};
    `,
    aTwo300: css`
      color: ${theme('colors.acceptTwo.300')};
    `,
    aTwo200: css`
      color: ${theme('colors.acceptTwo.200')};
    `,
    aTwo100: css`
      color: ${theme('colors.acceptTwo.100')};
    `,
    aThree500: css`
      color: ${theme('colors.acceptThree.500')};
    `,
    w500: css`
      color: ${theme('colors.warning.500')};
    `,
    w300: css`
      color: ${theme('colors.warning.300')};
    `,
    w200: css`
      color: ${theme('colors.warning.200')};
    `,
    w100: css`
      color: ${theme('colors.warning.100')};
    `,
    e500: css`
      color: ${theme('colors.error.500')};
    `,
    e300: css`
      color: ${theme('colors.error.300')};
    `,
    e200: css`
      color: ${theme('colors.error.200')};
    `,
    e100: css`
      color: ${theme('colors.error.100')};
    `,
    e50: css`
      color: ${theme('colors.error.50')};
    `,
    s500: css`
      color: ${theme('colors.success.500')};
    `,
    s300: css`
      color: ${theme('colors.success.300')};
    `,
    s200: css`
      color: ${theme('colors.success.200')};
    `,
    s100: css`
      color: ${theme('colors.success.100')};
    `,
  })}

  ${ifProp(
    'bottom',
    css`
      margin-bottom: ${prop('bottom')}px;
    `,
  )}

${ifProp(
    'top',
    css`
      margin-top: ${prop('top')}px;
    `,
  )}

${ifProp(
    'left',
    css`
      margin-left: ${prop('left')}px;
    `,
  )}

${ifProp(
    'right',
    css`
      margin-right: ${prop('right')}px;
    `,
  )}

${ifProp(
    'margin',
    css`
      margin: ${prop('margin')}px;
    `,
  )}

${ifProp(
    'padding',
    css`
      padding: ${prop('padding')};
    `,
  )}
  
${ifProp(
    'cursor',
    css`
      cursor: ${prop('cursor')};
    `,
  )}
  
${ifProp(
    'width',
    css`
      width: ${prop('width')}px;
    `,
  )}

  ${ifProp(
    'height',
    css`
      height: ${prop('height')}px;
    `,
  )}
  
${ifProp(
    'fullWidth',
    css`
      width: 100%;
    `,
  )}

${ifProp(
    'border',
    css`
      border: ${prop('border')};
    `,
  )}

  ${ifProp(
    'bg',
    css`
      background: ${prop('bg')};
    `,
  )}

  ${ifProp(
    'radius',
    css`
      border-radius: ${prop('radius')};
    `,
  )}
`;

const PrimaryButton = styled.button<{ width?: string }>`
  width: ${({ width }) => width};
  border: none;
  padding: 16px 24px;
  background: #3848c6;
  color: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  font-family: Commissioner-Regular;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background: ${theme('colors.primary.400')};
  }
  :active {
    outline: 4px solid rgba(24, 97, 242, 0.24);
    background: ${theme('colors.primary.500')} !important;
  }
  :focus {
    background: ${theme('colors.primary.600')};
  }
  :disabled {
    background: ${theme('colors.primary.100')} !important;
    cursor: default;
    outline: none !important;
  }
`;

const SecondaryButton = styled(PrimaryButton)`
  border: 1px solid #8994e6;
  background: transparent;
  color: #4858cf;
  :hover {
    border: 1px solid #abb3ed;
    color: #4858cf;
  }
  :focus {
    border: 4px solid rgba(24, 97, 242, 0.24);
  }
  :active {
    border: 1px solid #4858cf;
  }
`;

const TertiaryButton = styled(PrimaryButton)`
  border: 2px solid #fff;
  padding: 10px 24px;
  :hover {
    border: 2px solid #dde0f8;
  }
`;

const TextButton = styled.button<{ isStepPage?: boolean }>`
  color: ${({ isStepPage }) => (isStepPage ? '#2D48ED' : '#222229')};
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  :hover {
    color: #2d48ed;
  }
  :active {
    color: #000000;
  }
`;

const StyledLink = styled(Link)<{ color?: string }>`
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: ${({ color }) => (color ? color : '#fff')};
`;

const StyledLinkText = styled.div`
  display: inline-block;
  margin-left: 7px;
  :hover:not(:focus) {
    > div {
      color: black;
    }
  }
  :active {
    outline: 4px solid rgba(24, 97, 242, 0.24);
    border-radius: 4px;

    > div {
      color: black;
    }
  }
  :focus {
    outline: none !important;
    > div {
      color: #293fcb !important;
    }
  }
  :disabled {
    > div {
      color: #c7c9d1;
    }
  }
`;

const StyledLinkBlack = styled.div`
  margin-right: 24px;
  > div {
    color: #222229;
    :hover {
      color: #2d48ed;
    }
    :active {
      outline: 4px solid rgba(24, 97, 242, 0.24);
      border-radius: 4px;
      color: #2d48ed;
    }
    :focus {
      color: #000 !important;
      outline: none !important;
    }
    :disabled {
      color: #c7c9d1;
    }
  }
`;

const CheckboxContent = styled.div`
  display: flex;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  input {
    position: absolute;
    display: none;
    left: 0.5px;
  }

  label {
    margin-left: 20px;
  }

  img {
    position: absolute;
    z-index: 10;
    top: 8px;
    left: 5px;
  }
  label .check-text {
    cursor: pointer;
    margin-left: 12px;
  }
  input + label:before {
    content: '';
    position: absolute;
    left: 0;
    margin-top: 2px;
    width: 18px;
    height: 18px;
    border: 1px solid #c7c9d1;
    border-radius: 4px;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input + label:hover::before {
    border: 2px solid #4f66f5;
    cursor: pointer;
  }
  input + label:active::before {
    border: 2px solid #4f66f5;
    outline: 4px solid #c8d9fc;
  }
  input + label:active::before {
    border: 2px solid #293fcb;
  }
  input + label:disabled::before {
    background-color: #e3e5eb;
  }
  input:checked + label::before {
    background-color: #2d48ed;
    border: 1px solid #2d48ed;
    z-index: 2;
  }
  input:checked + label:hover::before {
    border: 1px solid #4f66f5;
    background-color: #4f66f5;
  }
  input:checked + label:disabled::before {
    background-color: #e3e5eb;
  }
`;

export {
  StyledLink,
  TextButton,
  TertiaryButton,
  SecondaryButton,
  PrimaryButton,
  Button,
  StyledLinkText,
  StyledLinkBlack,
  CheckboxContent,
};
