import { useEffect, useState } from 'react';

import { TextField, CardTypeComponent, ConfirmationPopup } from 'components';
import { I18n } from 'components/Translation';
import { CONSTANTS } from 'config';
import { toastAlert, translate } from 'helpers';
import { useTranslation } from 'providers';
import { getResourceManager } from 'resources';
import { getMonitoring } from 'services/monitoring';
import { ICards, ICardsTypes } from 'types/cards';

import { DropdownWrapper } from './DropdownWrapper';
import { TextConteiner } from './styles';
import {
  Container,
  Wrapper,
  Box,
  CardType,
  CardNumber,
  ExpDate,
  Status,
  DropDownSection,
} from './styles-mobile-table';
import { IData } from './types';

export const MobileTable = ({ data, getCards }: IData) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteApproved, setIsDeleteApproved] = useState(false);
  const [cardDigits, setCardDigits] = useState('');
  const [cardType, setCardType] = useState<string>('');
  const [deletedCardId, setDeletedCardId] = useState('');

  const datass = [
    {
      value: '1',
      label: `${translate(t, 'dropdown_send_money')}`,
    },
    {
      value: '2',
      label: `${translate(t, 'dropdown_make_default')}`,
    },
    {
      value: '3',
      label: `${translate(t, 'dropdown_delete')}`,
    },
  ];

  const dataForDefault = [
    {
      value: '1',
      label: `${translate(t, 'dropdown_send_money')}`,
    },
    {
      value: '3',
      label: `${translate(t, 'dropdown_delete')}`,
    },
  ];

  const handleClose = () => {
    setIsModalOpen(false);
    setIsDeleteApproved(false);
  };

  const handleSubmit = () => {
    setIsDeleteApproved(true);
  };

  useEffect(() => {
    if (isDeleteApproved && deletedCardId) {
      getResourceManager()
        .card.delete(deletedCardId)
        .then((res) => {
          if (getCards) {
            getCards();
          }
          return res;
        })
        .catch((err) => {
          getMonitoring()?.captureException(err);
          toastAlert(
            'error',
            err?.response?.data?.message ?? translate(t, 'error_something_went_wrong'),
          );
        })
        .finally(() => {
          setIsDeleteApproved(false);
          setIsModalOpen(false);
          setDeletedCardId('');
        });
    }
  }, [isDeleteApproved, deletedCardId]);

  return (
    <Wrapper>
      {isModalOpen && (
        <ConfirmationPopup
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          cardDigits={cardDigits}
          cardType={cardType}
        />
      )}
      {data?.length ? (
        data?.map((data: ICards, index: number) => {
          return (
            <Container key={data.id}>
              <Box>
                <CardType>
                  <CardTypeComponent type={data?.type} />
                </CardType>
                <CardNumber>
                  <TextField variant="P14" color="n900">
                    <I18n tKey="card_info" />
                  </TextField>
                  <TextField variant="P7" color="n800">
                    {CONSTANTS.CARD_TYPES[data?.type as keyof ICardsTypes]}{' '}
                    <I18n tKey="ending_in" /> {data?.pan?.substring(data?.pan?.length - 4)}
                  </TextField>
                </CardNumber>
                <ExpDate>
                  <TextField variant="P14" color="n900">
                    <I18n tKey="expiration_date" />
                  </TextField>
                  <TextField variant="P7" color="n800">
                    {String(data?.exp_month < 10 ? `0${data?.exp_month}` : data?.exp_month)}/
                    {String(data?.exp_year).slice(-2)}
                  </TextField>
                </ExpDate>
                {data?.isDefault && (
                  <Status>
                    <TextField variant="P14" color="n900">
                      <I18n tKey="status" />
                    </TextField>
                    <TextField variant="P7" color="n800">
                      {data?.isDefault && <I18n tKey="default" />}
                    </TextField>
                  </Status>
                )}
              </Box>
              <DropDownSection>
                <DropdownWrapper
                  data={data}
                  id={data?.id}
                  index={index}
                  datas={data?.isDefault ? dataForDefault : datass}
                  getCards={getCards}
                  setIsModalOpen={setIsModalOpen}
                  setCardDigits={setCardDigits}
                  setDeletedCardId={setDeletedCardId}
                  setCardType={setCardType}
                />
              </DropDownSection>
            </Container>
          );
        })
      ) : (
        <TextConteiner>
          <TextField variant="P7" color="">
            <I18n tKey="you_havent_cards_local" />{' '}
          </TextField>
        </TextConteiner>
      )}
    </Wrapper>
  );
};
