import * as yup from 'yup';

import { translate } from 'helpers';
import { TranslationDataType } from 'resources/translation';

export const getValidationConstructor = (
  t: TranslationDataType,
  regexp: RegExp,
  tKey: string,
  required = true,
) => {
  let validation = yup.string().matches(regexp, `${translate(t, tKey)}`);

  if (required) {
    validation = validation
      .required(`${translate(t, 'required_field')}`)
      .typeError(`${translate(t, 'must_be_string')}`);
  }

  return validation;
};
