import styled from 'styled-components';

export const ComplaintsPolicyWrapper = styled.div`
  display: flex;
  margin: 48px auto;
  max-width: 840px;
  min-height: 100vh;

  > div {
    h1 {
      font-family: 'Commissioner';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 42px;
      letter-spacing: -0.8px;
      color: #222229;
      margin-bottom: 8px;
    }

    h2 {
      font-family: 'Commissioner';
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 32px;
      letter-spacing: -0.8px;
      color: #222229;
      margin-bottom: 8px;
    }

    h3 {
      font-family: 'Commissioner';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      letter-spacing: -0.8px;
      color: #222229;
      margin-bottom: 8px;
    }

    p {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
      color: #686b78;

      a {
        text-decoration: none;
      }
    }

    ul {
      margin-bottom: 8px;

      li {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #686b78;
      }
    }
  }
`;
