import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { QuestionIcon } from 'assets/images';
import { MessageBox } from 'components/MessageBox';
import { useOnClickOutside, useSwitch } from 'hooks';

import style from './infoTips.module.scss';

type Props = {
  children: ReactNode;
  message: string | TrustedHTML;
  isPreLine?: boolean;
};

export const InfoTips = ({ children, message, isPreLine }: Props) => {
  const [popHeight, setPopHeight] = useState<number>(0);
  const [popUpTopPosition, setPopUpTopPosition] = useState<number>(0);
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const [isOpen, { toggle, off }] = useSwitch();
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

  useOnClickOutside(ref, () => off());

  const popupHeight = (height: number) => {
    setPopHeight(height);
  };

  useEffect(() => {
    setPopUpTopPosition(Number(ref?.current?.getBoundingClientRect().top) - Number(popHeight) - 16);
  }, [scrollPosition, popHeight]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const boxClasses = useMemo(() => {
    const prelineClass = isPreLine ? style['tips-preline'] : '';

    const containerClass =
      popUpTopPosition < 0 ? style['tips_message_rotate'] : style['tips_message'];

    return `${prelineClass || ''} ${containerClass || ''}`;
  }, [isPreLine, popUpTopPosition]);

  return (
    <div className={style['container']}>
      {children}
      <div
        ref={ref}
        onClick={() => toggle()}
        className={`${style['tips_popup']} ${style['popup-message']}`}
      >
        {isOpen && <MessageBox getHeight={popupHeight} text={message} className={boxClasses} />}
        <div className={style['icon-box']}>
          <QuestionIcon />
        </div>
      </div>
    </div>
  );
};
