import { createSelector } from '@reduxjs/toolkit';

import { rootSelector } from '../../../rootSelector';

const senderDetailsState = createSelector(rootSelector, (state) => state.senderDetails);

export const senderDetailsSelector = createSelector(
  senderDetailsState,
  (state) => state.data.senderDetailsData,
);
