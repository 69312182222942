import * as yup from 'yup';

import { translate } from 'helpers';
import { TranslationDataType } from 'resources/translation';
import { getValidations } from 'validation';

export const getValidationSchema = (t: TranslationDataType) => {
  const { letters, phone } = getValidations(t);

  return yup.object().shape({
    firstName: letters.engRequired,
    lastName: letters.engRequired,
    country: yup
      .string()
      .required(`${translate(t, 'required_field')}`)
      .typeError(`${translate(t, 'required_field')}`),
    city: letters.engWithSpacesRequired,
    street: letters.engAndDigitsWithSimpleSignsRequired,
    zip: letters.engAndDigitsRequired,
    email: yup
      .string()
      .email(`${translate(t, 'enter_correct_email')}`)
      .required(`${translate(t, 'required_field')}`),
    phone: phone.standart,
    day: yup.string().required(`${translate(t, 'required_field')}`),
    month: yup.string().required(`${translate(t, 'required_field')}`),
    year: yup.string().required(`${translate(t, 'required_field')}`),
  });
};
