import { createSlice } from '@reduxjs/toolkit';

import { Action } from '../../../types';

import { ISenderDetailsCardData } from './cardDetails.types';

const senderCardSlice = createSlice({
  name: 'senderCard',
  initialState: {
    data: {
      senderDetailsCard: <ISenderDetailsCardData>{
        isNewCard: false,
        purpose_payment: '',
        source_founds: '',
        pan: '',
        expDate: '',
        isSave: false,
        dataId: '',
        defaultCardId: '',
        cardHolderName: '',
      },
    },
  },

  reducers: {
    updateSenderDetailsCardData(state, action: Action<ISenderDetailsCardData>) {
      return {
        ...state,
        data: {
          ...state.data,
          senderDetailsCard: {
            ...state.data.senderDetailsCard,
            ...action.payload,
          },
        },
      };
    },
  },
});

export const { updateSenderDetailsCardData } = senderCardSlice.actions;

export const senderCardReducer = senderCardSlice.reducer;
