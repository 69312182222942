import styled, { css } from 'styled-components';

export const StyledDropdownMenu = styled.div<{ isTextDropdown: boolean }>`
  padding: 8px 0;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(34, 34, 41, 0.1);
  border-radius: 8px;
  position: absolute;
  top: 40px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  > .item {
    width: 184px;
    padding: 0 16px !important;
  }
  ${({ isTextDropdown }) =>
    isTextDropdown
      ? css`
          top: 33px;
          width: 184px;
        `
      : css`
          left: 0;
          width: 164px;
        `}
`;

export const StyledDropdownItem = styled.button`
  display: flex;
  align-items: center;
  padding: 6px 16px;
  font-size: 16px;
  border: none;
  height: 32px;
  width: 160px;
  text-align: left;
  background: transparent;
  cursor: pointer;
  .delete {
    color: red !important;
  }
  > svg {
    margin-right: 16px;
  }
  :hover {
    background: #f4f4f6;
    > svg path {
      fill: #2d48ed;
    }
  }
`;
