import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'react-use';

import { getDaysInMonth } from 'helpers/dayCounter';
import { userInfoLoadedSelector, userInfoSelector } from 'store/selectors';

import { getDataFromDate } from '../utils';

export const usePersonal = (props?: { country?: string; defaultValue?: any }) => {
  const [state, setState] = useSetState<{
    yearNumber?: string;
    monthNumber?: number;
    amountOfDays: number;
  }>({
    amountOfDays: 31,
  });

  const profile = useSelector(userInfoSelector);
  const isProfileLoaded = useSelector(userInfoLoadedSelector);

  const { defaultValue = {}, country = '' } = props || {};

  const defaultValues = useMemo(
    () => ({
      firstName: profile?.first_name || defaultValue?.firstName || '',
      lastName: profile?.last_name || defaultValue?.lastName || '',
      country: profile?.country || country || defaultValue?.country || '',
      city: profile?.city || defaultValue?.city || '',
      street: profile?.street || defaultValue?.street || '',
      zip: profile?.zip || defaultValue?.zip || '',
      phone: profile?.phone || defaultValue?.phone || '',
      email: profile?.email || defaultValue?.email || '',
      day:
        getDataFromDate(profile?.date_of_birth, 2, defaultValue?.day as string) ||
        defaultValue?.day ||
        '',
      month:
        getDataFromDate(profile?.date_of_birth, 1, defaultValue?.month as string) ||
        defaultValue?.month ||
        '',
      year:
        getDataFromDate(profile?.date_of_birth, 0, defaultValue?.year as string) ||
        defaultValue?.year ||
        '',
    }),
    [profile, defaultValue, country],
  );

  useEffect(() => {
    if (state.yearNumber && state.monthNumber) {
      setState({ amountOfDays: getDaysInMonth(+state.yearNumber, state.monthNumber) });
    }
  }, [state.yearNumber, state.monthNumber, setState]);

  const values = useMemo(
    () => ({
      defaultValues,
      profile,
      isProfileLoaded,
      state,
      setState,
    }),
    [profile, defaultValues, isProfileLoaded, state, setState],
  );

  return values;
};
