import { Response } from 'resources/types';
import { Fetcher } from 'utils';

import { ENDPOINTS } from './endpoints';
import { CardDataResponse, CheckCardRequest, CheckCardResponse } from './types';

export class CardRepo {
  private fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  public async getCardsData() {
    const { data } = await this.fetcher.fetch<unknown, { data: Response<CardDataResponse[]> }>({
      url: ENDPOINTS.getCards,
      method: 'GET',
    });

    return data?.data;
  }

  public async delete(id: string): Promise<boolean> {
    const { data } = await this.fetcher.fetch<{ id: string }, { data: Response<unknown> }>({
      url: `${ENDPOINTS.delete}`,
      method: 'POST',
      data: { id },
    });

    return data?.status;
  }

  public async check(cardId: string): Promise<boolean> {
    const { data } = await this.fetcher.fetch<
      CheckCardRequest,
      { data: Response<CheckCardResponse> }
    >({
      url: `${ENDPOINTS.check}`,
      method: 'POST',
      data: { cardId },
    });

    return data?.data.isValid;
  }
}
