import * as yup from 'yup';

import { checkIsUnionPay, translate } from 'helpers';
import { TranslationDataType } from 'resources/translation';
import { getValidations } from 'validation';

export const getValidationSchema = (t: TranslationDataType) => {
  const { card, date, number } = getValidations(t, {
    card: {
      fn: checkIsUnionPay,
      translation: 'supportedCardsSource',
    },
  });

  return yup.object().shape({
    isNewCard: yup.boolean(),
    cardNumber: yup.string().when('isNewCard', {
      is: true,
      then: card.standart,
    }),
    cardHolderName: yup.string().when('isNewCard', {
      is: true,
      then: yup
        .string()
        .matches(/^/, `${translate(t, 'required_field')}`)
        .required(`${translate(t, 'required_field')}`),
    }),
    cvv: yup.string().when('isNewCard', {
      is: true,
      then: number.standart
        .min(3, `${translate(t, 'must_contain_only_3_digits')}`)
        .max(3, `${translate(t, 'must_contain_only_3_digits')}`),
    }),
    expDate: yup.string().when('isNewCard', {
      is: true,
      then: date.standart,
    }),
    purpose_payment: yup.string().required(`${translate(t, 'required_field')}`),
    source_founds: yup.string().required(`${translate(t, 'required_field')}`),
  });
};
