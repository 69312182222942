import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { TextField } from 'components';
import { I18n } from 'components/Translation';
import { useAppProvider, useAuth } from 'providers';
import { userInfoLoadingSelector } from 'store/selectors';
import { IProfile, IUserInfo } from 'store/slices/personal';

import { PersonalForm } from './PersonalForm';
import { PersonalPasswordForm } from './PersonalPasswordForm';
import { usePersonal } from './hooks';
import { Wrapper, TitleWrapper } from './styles';

export const Personal = () => {
  const { isProfileLoaded, profile } = usePersonal();
  const { setLoader, isPersistDone } = useAppProvider();
  const { refetchProfileData, refetchProfileSettings } = useAuth();

  const [isSetPassword, setIsSetPassword] = useState<boolean>(false);
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false);

  const isProfilePasswordSet =
    !!(profile as IProfile)?.passwordset || !!(profile as IUserInfo)?.passwordSet;
  const isProfileLoading = useSelector(userInfoLoadingSelector);

  const isSetPasswordAvailable = !isSetPassword && !isProfilePasswordSet;

  const onCancel = () => {
    setIsChangePassword(false);
    setIsSetPassword(false);
  };

  useEffect(() => {
    if (typeof isProfileLoading !== 'undefined') {
      setLoader(isProfileLoading);
    }
  }, [setLoader, isProfileLoading]);

  if (!(isProfileLoaded && !!profile && isPersistDone)) {
    return null;
  }

  return (
    <Wrapper>
      <TitleWrapper>
        <TextField variant="H3" color="n900">
          <I18n tKey="title_personal_info" />
        </TextField>
        <br />
        <TextField variant="P7" color="n700">
          <I18n tKey="subtitle_personal_info" />
        </TextField>
      </TitleWrapper>

      <PersonalForm
        isSetPassword={isSetPassword}
        refetchProfileData={refetchProfileData}
        refetchProfileSettings={refetchProfileSettings}
        setIsChangePassword={setIsChangePassword}
        setIsSetPassword={setIsSetPassword}
      />

      <PersonalPasswordForm
        isSetPasswordAvailable={isSetPasswordAvailable}
        isChangePassword={isChangePassword}
        isSetPassword={isSetPassword}
        isProfilePasswordSet={isProfilePasswordSet}
        refetchProfileData={refetchProfileData}
        refetchProfileSettings={refetchProfileSettings}
        setIsChangePassword={setIsChangePassword}
        setIsSetPassword={setIsSetPassword}
        onCancel={onCancel}
      />
    </Wrapper>
  );
};
