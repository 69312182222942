import { Fetcher } from 'utils';

import { CardModel } from './CardModel';
import { CardRepo } from './CardRepo';

export class CardService {
  private cardRepo: CardRepo;

  private model: CardModel = new CardModel();

  constructor(fetcher: Fetcher) {
    this.cardRepo = new CardRepo(fetcher);
  }

  public async getCardsData() {
    const cards = await this.cardRepo.getCardsData();

    const parsedCards = cards?.map(this.model.getUiCardModelFromResponse);

    return parsedCards;
  }

  public async delete(id: string): Promise<boolean> {
    const status = await this.cardRepo.delete(id);

    return status;
  }

  public async check(cardId: string): Promise<boolean> {
    const isValid = await this.cardRepo.check(cardId);

    return isValid;
  }
}
