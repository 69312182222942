import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Swap } from 'assets/images/swap.svg';
import { CountrySelect } from 'components/CountrySelect';
import { InputField } from 'components/InputField';
import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';
import currencies from 'components/countriesCurrency/currency.json';
import { dataEntryAccess } from 'helpers';
import { useCountries } from 'providers/countriesProvider';
import { useCheckCurrency } from 'queries/currency';
import { useProfileLocation } from 'queries/profile';
import { userInfoSelector } from 'store/selectors';
import { updateSendMoneyData, updateSendMoneyState } from 'store/slices/transaction';
import {
  sendMoneyFullSelector,
  sendMoneyTransactionSelector,
} from 'store/slices/transaction/sender/sender.selectors';
import { IAmount, ICurrency } from 'types/exchange';

import styles from './exchange.module.scss';
import { IExchangeComponent } from './types';

type ObjectKey = keyof typeof currencies;

export const ExchangeComponent = ({
  children,
  getData,
  setAmount,
  rateInfo,
  limitPass,
  isRepeat,
  defaultProperties,
  setCurrencies,
  setCountryFrom,
  setCountryTo,
}: IExchangeComponent) => {
  const dispatch = useDispatch();
  const { sendingCountries, receivingCountries, countries } = useCountries();

  const transaction = useSelector(sendMoneyTransactionSelector);
  const {
    fromCountry: countryFrom,
    fromCurrency: currencyFrom,
    toCountry: countryTo,
  } = useSelector(sendMoneyFullSelector);

  const profile = useSelector(userInfoSelector);

  const [fromAmount, setFromAmount] = useState<string>(setAmount.from);
  const [toAmount, setToAmount] = useState<string>(setAmount.to);
  const [rate, setRate] = useState<IAmount>({
    from: '',
    to: '',
    rate: '',
  });

  const { defaultFromCountry, defaultToCountry, defaultFromCurrency, defaultToCurrency } =
    defaultProperties;
  const [fromCountry, setFromCountry] = useState<string>(defaultFromCountry || '');
  const [toCountry, setToCountry] = useState<string>(defaultToCountry || '');

  const [fromCurrency, setFromCurrency] = useState<string>(defaultFromCurrency || '');
  const [toCurrency, setToCurrency] = useState<string>(defaultToCurrency || '');

  const [currentCountry, setCurrentCountry] = useState<string>('');
  const [currentRateFrom, setCurrentRateFrom] = useState<string>('');
  const userCountry = profile?.country;

  const queryEnabled = profile && !!profile.id && !fromCountry;

  const handleUpdateToCountry = useCallback(
    (value: string) => {
      setCountryTo(value);
      setToCountry(value);
      value && setToCurrency((currencies as ICurrency)[value as ObjectKey]);

      dispatch(updateSendMoneyState({ toCountry: value }));
    },
    [dispatch, setCountryTo],
  );

  const handleUpdateFromCountry = useCallback(
    (value: string) => {
      setFromCountry(value);
      setCountryFrom(value);
      setCurrentRateFrom((currencies as ICurrency)[value as ObjectKey]);

      value && setFromCurrency((currencies as ICurrency)[value as ObjectKey]);
    },
    [setCountryFrom],
  );

  const { handleGetCurrency } = useCheckCurrency({
    enabled: queryEnabled && !!profile?.country,
    country: profile?.country || '',
    options: {
      onSuccess: () => {
        handleUpdateFromCountry(profile?.country ?? countryFrom);
        handleUpdateToCountry(countryTo);
        setFromAmount(transaction?.fromAmount);
        getData.getIsFromAmount(true);
      },
    },
  });

  useProfileLocation({
    enabled: queryEnabled && !profile?.country,
    options: {
      onSuccess: ({ country }) => {
        setCurrentCountry(country);
        handleGetCurrency(country);
      },
    },
  });

  useEffect(() => {
    if (!isRepeat) {
      dispatch(
        updateSendMoneyState({
          fromCurrency: '',
        }),
      );
    }
  }, [dispatch, isRepeat]);

  useEffect(() => {
    if (currentCountry || profile.country) {
      handleUpdateFromCountry(currentCountry || profile?.country || '');
    }
  }, [currentCountry, profile.country, handleUpdateFromCountry]);

  useEffect(() => {
    if (rateInfo?.from || rateInfo?.to) {
      setRate({
        from: rateInfo?.from as string,
        to: rateInfo?.to as string,
        rate: rateInfo?.rate as string,
      });
    } else {
      setRate((rate) => ({
        ...rate,
        from: currentRateFrom as string,
      }));
    }
  }, [rateInfo, currentRateFrom]);

  useEffect(() => {
    getData.fromAmount(fromAmount);
    getData.toAmount(toAmount);
  }, [getData, fromAmount, toAmount]);

  useEffect(() => {
    setCurrencies(fromCurrency, toCurrency);
  }, [setCurrencies, fromCurrency, toCurrency]);

  const { isSourceCountryDisabled, isProfileCountryAvailable } = useMemo(() => {
    if (!userCountry) {
      return { isSourceCountryDisabled: false, isProfileCountryAvailable: false };
    }

    const isProfileCountryAvailable = !!countries?.find(
      (country) => country.iso2.toLowerCase() === userCountry.toLowerCase(),
    );

    return { isProfileCountryAvailable, isSourceCountryDisabled: true };
  }, [userCountry, countries]);

  return (
    <>
      <div className={styles['title']}>
        <TextField variant="H3">
          <I18n tKey="lets_send_money" />
        </TextField>
        <TextField variant="P7" className={styles['subtitle']}>
          {' '}
          <I18n tKey="send_money_description" />
        </TextField>
      </div>
      <TextField variant="H6">
        <I18n tKey="transaction_details" />
      </TextField>
      <div className={styles['row']}>
        <div className={styles['field']}>
          <CountrySelect
            title={<I18n tKey="sender_country" />}
            error={false}
            current={<I18n tKey="choose_country" />}
            onChange={(value) => {
              dispatch(
                updateSendMoneyState({
                  fromCurrency: '',
                }),
              );
              if (typeof value === 'string') {
                handleGetCurrency(value);
              }
              handleUpdateFromCountry(value);
            }}
            countries={sendingCountries}
            value={isProfileCountryAvailable ? userCountry : fromCountry}
            disabled={isSourceCountryDisabled && isProfileCountryAvailable}
          />
        </div>
        <div className={styles['input_container']}>
          <InputField
            text={<I18n tKey="you_send" />}
            currnency={currencyFrom}
            placeholder={setAmount.from ? setAmount.from : '0.00'}
            value={fromAmount}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const amount = e.target.value;

              const isValidAccess = dataEntryAccess(e);

              const matched = amount.match(/^\d+\.[0-9]{0,2}$/);
              const [matchedValue] = matched || [''];

              const isLastDot = matchedValue?.[matchedValue?.length - 1] === '.';

              if (matchedValue) {
                setFromAmount(matchedValue);
                dispatch(updateSendMoneyData({ fromAmount: matchedValue }));
              }

              if (isValidAccess && !isLastDot) {
                setToAmount('');
                setFromAmount(amount);
                getData?.getIsFromAmount(true);
                dispatch(updateSendMoneyData({ fromAmount: matchedValue }));
              }
            }}
          />
        </div>
      </div>

      <div className={styles['swap']}>
        <Swap />
        <TextField variant="P13" className={styles['rate']}>
          {toCountry && (
            <>
              <I18n tKey="exchange_rate" />
              {': '}1 {rate.from} = {rate.rate} {rate.to}
            </>
          )}
        </TextField>
      </div>

      <div className={styles['row']}>
        <div className={styles['field']}>
          <CountrySelect
            title={<I18n tKey="recipient_country" />}
            error={false}
            current={<I18n tKey="choose_country" />}
            onChange={handleUpdateToCountry}
            value={toCountry}
            countries={receivingCountries}
          />
        </div>
        <div className={styles['input_container']}>
          <InputField
            text={<I18n tKey="recipient_receives" />}
            currnency={rate.to}
            placeholder={setAmount.to ? setAmount.to : '0.00'}
            value={toAmount}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const amount = e.target.value;

              const isValidAccess = dataEntryAccess(e);

              const matched = amount.match(/^\d+\.[0-9]{0,2}$/);
              const [matchedValue] = matched || [''];

              const isLastDot = matchedValue?.[matchedValue?.length - 1] === '.';

              if (matchedValue) {
                setToAmount(e.target.value);
              }

              if (isValidAccess && !isLastDot) {
                setFromAmount('');
                setToAmount(e.target.value);
                getData?.getIsFromAmount(false);
              }
            }}
          />
        </div>
      </div>
      {limitPass === false && (
        <TextField variant="P7" color="e500">
          {' '}
          <I18n tKey="exceeded_amount_limit_local" />{' '}
        </TextField>
      )}
      {children}
    </>
  );
};
