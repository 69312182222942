import { TranslationDataType } from 'resources/translation';

import { getCardValidation } from './cardValidation';
import { getDateValidation } from './dateValidation';
import { getLettersValidation } from './lettersValidation';
import { getNumberValidation } from './numberValidation';
import { getPhoneValidation } from './phoneValidation';
import { ValidationOptions } from './types';

export const getValidations = (t: TranslationDataType, options?: ValidationOptions) => ({
  letters: getLettersValidation(t),
  phone: getPhoneValidation(t),
  card: getCardValidation(t, options?.card),
  date: getDateValidation(t),
  number: getNumberValidation(t),
});
