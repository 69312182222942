import styled from 'styled-components';

const StyledMainTitle = styled.div`
  padding-top: 48px;
  text-align: center;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 72px;
  line-height: 72px;
  letter-spacing: -0.03em;
  color: #ffffff;
`;

const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid #f0f0f2;
`;

export { StyledMainTitle, Line };
