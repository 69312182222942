import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { ProfileApi } from 'apiListURL';
import { ReactComponent as Arrow } from 'assets/images/arrow.svg';
import { TextField } from 'components/TextField';
import { CONSTANTS } from 'config';
import { useOnClickOutside } from 'hooks';
import { getResourceManager } from 'resources';
import { AppRoutes } from 'routes/constants';
import {
  clearRecipientDetailsAction,
  clearSenderDetailsAction,
  clearSenderDetailsCardAction,
  clearSendMoneyAction,
  clearUserInfoAction,
} from 'store/actionCreator';
import { updateSendMoneyState } from 'store/slices/transaction';

import {
  StyledDropdown,
  StyledDropdownButton,
  StyledArrow,
  StyledDropdownMenu,
  StyledDropdownItem,
} from './styles';
import { IData, IProperties, IItem } from './types';

export const Dropdown = ({
  isTextDropdown = false,
  data,
  isShowIcon = true,
  title,
  id,
  getCards = () => {},
}: IProperties) => {
  const [show, setShow] = useState(false);
  const [value] = useState<IData>();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const inputField = useRef() as React.MutableRefObject<HTMLInputElement>;
  useOnClickOutside(inputField, () => setShow(false));

  const handleSignOut = async () => {
    clearSendMoneyAction(dispatch);
    clearSenderDetailsAction(dispatch);
    clearSenderDetailsCardAction(dispatch);
    clearRecipientDetailsAction(dispatch);
    clearUserInfoAction(dispatch);
    dispatch(updateSendMoneyState({ toCountry: '', fromCountry: '' }));
    await getResourceManager().auth.signOut();
    localStorage.clear();
    navigate(AppRoutes.public.signIn);
  };

  const handleClick = (item: IItem) => {
    if (item.label === 'Delete' && id) {
      getResourceManager()
        .card.delete(id)
        .then(() => {
          getCards();
        });
    } else if (item.label === 'Make Default') {
      ProfileApi.makeDefaultCard(id).then(() => {
        getCards();
      });
    } else {
      !(item.label === CONSTANTS.SINGOUT) ? navigate(item.value) : handleSignOut();
    }
  };

  useEffect(() => {
    !isShowIcon && setShow((prev) => !prev);
  }, [isShowIcon]);

  useEffect(() => {
    setShow(false);
  }, [location]);

  return (
    <StyledDropdown ref={inputField}>
      {isShowIcon && (
        <StyledDropdownButton
          className="dropdown-icon"
          onClick={() => setShow((prev) => !prev)}
          isTextDropdown={isTextDropdown}
        >
          {value ? (
            <TextField variant="P8" fw="600">
              {' '}
              {value}{' '}
            </TextField>
          ) : (
            <TextField variant="H7">{title}</TextField>
          )}
          <StyledArrow show={show}>
            {' '}
            <Arrow />{' '}
          </StyledArrow>
        </StyledDropdownButton>
      )}
      {show && (
        <StyledDropdownMenu className="dropdown-items" isTextDropdown={isTextDropdown}>
          {data?.map((item: IData, index: number) => (
            <StyledDropdownItem className="item" key={index} onClick={() => handleClick(item)}>
              {item.icon}
              <TextField variant="P9" color="n900">
                {' '}
                {item.label}{' '}
              </TextField>
            </StyledDropdownItem>
          ))}
        </StyledDropdownMenu>
      )}
    </StyledDropdown>
  );
};
