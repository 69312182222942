import { createSlice } from '@reduxjs/toolkit';

import { Action } from '../../../types';

import { ISenderDetailsData } from './details.types';

const senderDetailsSlice = createSlice({
  name: 'senderDetails',
  initialState: {
    data: {
      senderDetailsData: <ISenderDetailsData>{
        firstName: '',
        lastName: '',
        country: '',
        city: '',
        street: '',
        zip: '',
        phone: '',
        email: '',
        day: '',
        month: '',
        year: '',
        date_of_birth: '',
      },
    },
  },

  reducers: {
    updateSenderDetailsData(state, action: Action<ISenderDetailsData>) {
      return {
        ...state,
        data: {
          ...state.data,
          senderDetailsData: {
            ...state.data.senderDetailsData,
            ...action.payload,
          },
        },
      };
    },
    setSenderBirthDate(state, action: { type: string; payload: string }) {
      const day = action.payload.split('-')[2];
      const month = action.payload.split('-')[1];
      const year = action.payload.split('-')[0];

      return {
        ...state,
        data: {
          ...state.data,
          senderDetailsData: {
            ...state.data.senderDetailsData,
            day,
            month,
            year,
          },
        },
      };
    },
    setDateOfBirth(state, action: { type: string; payload?: string }) {
      const { year, month, day } = state.data.senderDetailsData;
      const dateOfBirth = action?.payload ?? `${year}-${month}-${day}`;

      return {
        ...state,
        data: {
          ...state.data,
          senderDetailsData: {
            ...state.data.senderDetailsData,
            date_of_birth: dateOfBirth,
          },
        },
      };
    },
  },
});

export const { updateSenderDetailsData, setSenderBirthDate, setDateOfBirth } =
  senderDetailsSlice.actions;

export const senderDetailsReducer = senderDetailsSlice.reducer;
