import { axiosInstance } from 'apiListURL/axiosInstance';
import { Header, Footer, Limit } from 'components';
import { envConfig } from 'config/env';
import { getResourceManager } from 'resources';

import { LimitForMobileWrapper, Wrapper, LimitForMobile } from './style';
import { IDefaultLayoutProps } from './types';

const DefaultLayout = ({ isAuth, children }: IDefaultLayoutProps) => {
  const lang = localStorage.getItem('language');

  /**
   * @deprecated
   */
  axiosInstance.interceptors.request.use(
    function (config) {
      const tokenObject = localStorage.getItem(envConfig.provider.supabaseApp);
      const token = tokenObject ? JSON.parse(tokenObject).access_token : null;

      config.headers = {
        'Content-Type': 'application/json',
        Authorization: token,
        lang: lang || 'en',
      };
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  /**
   * @deprecated
   */
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (error.response.status === 401) {
        await getResourceManager().auth.signOut();
        localStorage.clear();
        window.location.reload();
      }
      return Promise.reject(error);
    },
  );

  return (
    <Wrapper>
      <Header auth={isAuth} />
      {isAuth && (
        <LimitForMobileWrapper>
          <LimitForMobile>
            <Limit />
          </LimitForMobile>
        </LimitForMobileWrapper>
      )}
      {children}
      {isAuth && <Footer />}
    </Wrapper>
  );
};

export default DefaultLayout;
