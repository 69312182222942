import styled, { css } from 'styled-components';
import { IText } from '../StepPreview/types';
import { switchProp } from 'styled-tools';

export const MarginDiv = styled.div<IText>`
  ${switchProp('variant', {
    M46: css`
      margin-bottom: 46px;
    `,

    M16: css`
      margin-bottom: 16px;
    `,

    M32: css`
      margin-bottom: 32px;
    `,

    M24: css`
      margin-bottom: 24px;
    `,
    M20: css`
      margin-bottom: 20px;
    `,
  })}
`;

export const IdConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 600px;
  margin: 47px auto 68px auto;

  @media (min-height: 1030px) {
    margin-bottom: auto;
  }

  .emptyFile {
    background: #dde0f8;
  }

  @media (max-width: 647px) {
    width: 328px;
    margin: 24px auto 68px;
  }
`;

export const UnderLine = styled.div`
  width: 100%;
  height: 0;
  border: 1px solid #e3e5eb;
`;

export const ContinueButton = styled.div<{ isPreparing?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 56px;
  background: ${(props) => (props.isPreparing ? '#DDE0F8' : '#3848C6')};
  border-radius: 6px;
  color: #ffffff;
  letter-spacing: -0.2px;
`;

export const UploadedFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;
