import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 600px;
  height: 100%;
  margin: 48px auto;
  @media screen and (max-width: 648px), {
    width: auto;
    margin: 24px 16px 0;
  }
  .error-message {
    font-size: 12px;
    margin-top: 8px;
  }
`;

export const TitleWrapper = styled.div`
  > :first-child {
    padding-bottom: 8px;
  }
`;

export const InfoWrapper = styled.div`
  margin-top: 32px;
  > :first-child {
    padding-bottom: 16px;
  }
`;

export const InputFields = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const RowContent = styled.div`
  display: flex;
  gap: 16px;
  @media screen and (max-width: 648px) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  .first-name,
  .last-name,
  .country,
  .city,
  .street,
  .email,
  .phone {
    width: 292px;
    height: 72px;
    border-radius: 8px;
  }
  .first-name label .input-container {
    position: absolute;
    top: 35px;
  }
  .last-name label .input-container {
    position: absolute;
    top: 35px;
  }
  .street {
    width: 464px;
  }
  .current-password {
    margin-top: 16px;
  }

  .city label .input-container {
    position: absolute;
    top: 35px;
  }

  .street label .input-container {
    position: absolute;
    top: 35px;
  }

  .zip-code label .input-container {
    position: absolute;
    margin-top: 27px;
  }

  .phone label .input-container {
    position: absolute;
    top: 35px;
  }

  .current-password label .input-container {
    position: absolute;
    top: 35px;
  }

  .current-pass-label {
    position: absolute;
    margin-top: 15px;
  }

  .password label .input-container {
    position: absolute;
    top: 35px;
  }

  .passwordConfirmation label .input-container {
    position: absolute;
    top: 35px;
  }

  .email > label > .input-container > input {
    width: 100%;
  }

  > .label {
    pointer-events: none;
    position: absolute;
    top: 24px;
    left: 18px;
    transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    box-sizing: border-box;
    .on-focus {
      -webkit-transition: all 0.1s linear;
      -moz-transition: all 0.1s linear;
      position: relative;
      font-size: 13px;
      color: #4c4e57;
      top: -18px;
    }
  }

  @media (max-width: 648px) {
    flex-direction: column;
    .first-name,
    .last-name,
    .country,
    .street,
    .city,
    .email,
    .phone,
    .password {
      width: auto;
    }
  }
`;

export const PrimaryButton = styled.button<{ width?: string }>`
  width: 100%;
  margin-top: 24px;
  height: 56px;
  padding: 16px 24px;
  background: #3848c6;
  border: 1px solid #8994e6;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-family: Commissioner-Regular;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  @media (max-width: 647px) {
    width: ${({ width }) => width};
    height: 56px;
  }
  :disabled {
    background: #dde0f8 !important;
    cursor: default;
  }
  :hover {
    background: #4858cf;
  }
  :active {
    outline: 4px solid rgba(24, 97, 242, 0.24);
    background: #3848c6 !important;
  }
  :focus {
    background: #3342b8;
  }
`;
export const SecondaryButton = styled.button<{ width?: string }>`
  width: 100%;
  margin-top: 24px;
  height: 56px;
  padding: 16px 24px;
  background: #fff;
  border: 1px solid #8994e6;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-family: Commissioner-Regular;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  @media (max-width: 647px) {
    width: ${({ width }) => width};
    height: 56px;
  }
  :hover {
    color: #4858cf;
    border: 1px solid #3848c6;
  }
  :active {
    box-shadow: 0 0 0 1px #3848c6;
    outline: 5px ridge rgba(24, 97, 242, 0.24);
    background: #fff !important;
  }
  :focus {
    background: #eff0fb;
    border: 1px solid #8994e6;
    color: #3342b8;
  }
  :disabled {
    border: 1px solid #dde0f8;
    color: #abb3ed;
    cursor: default;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  padding: 20px 0;
  border-top: 1px solid #f0f0f2;
  button {
    white-space: nowrap;
  }
  > :first-child {
    margin: 0;
  }
  > :last-child {
    margin: 0;
    border: none;
  }
  @media screen and (max-width: 648px), {
    width: 100%;
  }
`;

export const SetPasswordWrapper = styled.div`
  margin-top: 16px;
`;

export const ChangePasswordWrapper = styled.div`
  margin-top: 16px;
  > .change-pass {
    > :first-child {
      height: auto;
    }
    gap: 0;
  }
`;

export const ButtonBox = styled.div`
  margin-top: 24px;
  .set-password,
  .cancel-btn {
    height: 56px;
    * {
      color: #3848c6;
    }
    :hover,
    :focus,
    :disabled {
      background: none !important;
    }
    :active {
      outline: none;
      background: none !important;
    }

    border: 1px solid #8994e6;
    border-radius: 6px;
    background: white;
  }
`;

export const IconBox = styled.div``;
