import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router';

import { ProfileApi } from 'apiListURL';
import toastClose from 'assets/icons/toastClose.svg';
import Susy from 'assets/images/susy.png';
import { TextField, EmailForm, StyledLink, SocialAuth, PrimaryButtonComp } from 'components';
import { StyledLinkText } from 'components/PrimaryButtonComp/style';
import { SimpleLink } from 'components/StyledLink/style';
import { I18n } from 'components/Translation';
import { CONSTANTS } from 'config';
import { translate } from 'helpers';
import { ImageWrapper, ToastWrapper } from 'helpers/style';
import AuthLayout from 'layout/auth_layout';
import { useAppProvider, useAuth, useTranslation } from 'providers';
import { getResourceManager } from 'resources';
import { AppRoutes, ExternalRoutes } from 'routes/constants';

import { Line } from '../styles';

import style from './style.module.scss';
import {
  Title,
  Wrapper,
  LinkWrapper,
  ButtonWrapper,
  MainContainer,
  UnderLinkWrapper,
} from './styles';

export const SignUp = (): ReactJSXElement => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { email: definedEmail, signInError } = location?.state || {};

  const [email, setEmail] = useState<string>(definedEmail || '');
  const [valid, setValid] = useState<boolean>(false);
  const [flashMessage, setFlashMessage] = useState<string | undefined>(signInError);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { setLoader, loadType, setLoadType } = useAppProvider();
  const { t } = useTranslation();
  const signUpContinueButton = useRef<HTMLButtonElement | null>(null);

  const handleSignUp = async () => {
    setLoader(true);
    setLoadType('Sign Up');
    ProfileApi.checkUser({
      email,
    })
      .then(async (data: any) => {
        if (data?.data?.userExists) {
          throw new Error('Error');
        } else {
          const { error } = await getResourceManager().auth.signInWithEmail(email);

          !error?.message &&
            navigate(AppRoutes.public.success, {
              state: { email, userExists: data?.data?.userExists },
            });
          error?.message && setErrorMessage(error?.message);
        }
      })
      .catch(() => {
        setErrorMessage(`${translate(t, 'error_email_already_exist_local')}`);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const keyPressHandler = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      signUpContinueButton?.current?.click?.();
    }
  };

  useEffect(() => {
    document.addEventListener('keypress', keyPressHandler);

    return () => {
      document.removeEventListener('keypress', keyPressHandler);
    };
  }, []);

  if (isAuth) {
    return <Navigate to={AppRoutes.main} />;
  }

  return (
    <AuthLayout img={Susy}>
      <Wrapper>
        <div className={style['form_wrapper']}>
          {flashMessage && (
            <ToastWrapper
              toastType="error"
              animate
              fixedWidth={false}
              className={style['toast_wrapper']}
            >
              <TextField variant="P7">{flashMessage}</TextField>
              <ImageWrapper
                className={style['image_wrapper']}
                onClick={() => setFlashMessage(undefined)}
              >
                <img src={toastClose} alt="close icon" />
              </ImageWrapper>
            </ToastWrapper>
          )}
          <MainContainer>
            <Title>
              <TextField className="text header" variant="H3" fw="500">
                <I18n tKey="get_started_with_remitiva" />
              </TextField>
              <TextField className="text" variant="P9" color="n700">
                <I18n tKey="get_started_description_local" />
              </TextField>
            </Title>
            <EmailForm
              initialValues={{
                email: definedEmail || '',
              }}
              handleData={(isValid, emailValue) => {
                setValid(!!isValid);
                setEmail(emailValue);
              }}
            />
            {!!errorMessage && (
              <TextField className="error-message" variant="P7">
                {errorMessage}
              </TextField>
            )}
            <LinkWrapper>
              <TextField variant="P7" color="n700">
                <I18n tKey="have_an_account" />
              </TextField>
              <StyledLink url={AppRoutes.public.signIn}>
                <StyledLinkText>
                  <TextField variant="P7" color="a500">
                    <I18n tKey="sign_in" />
                  </TextField>
                </StyledLinkText>
              </StyledLink>
            </LinkWrapper>
            <SocialAuth type="sign_up" />

            <UnderLinkWrapper>
              <TextField as="p" variant="P7" color="n700">
                <I18n tKey="acceptOurPolicies" />{' '}
                <SimpleLink target="_blank" to={ExternalRoutes.termsOfUse}>
                  <TextField as="span" variant="P7" color="a500">
                    <I18n tKey="terms_of_use" />
                  </TextField>
                </SimpleLink>{' '}
                <I18n tKey="and" />{' '}
                <SimpleLink target="_blank" to={ExternalRoutes.privacyPolicy}>
                  <TextField as="span" variant="P7" color="a500">
                    <I18n tKey="privacy_policy" />
                  </TextField>
                </SimpleLink>
              </TextField>
            </UnderLinkWrapper>
          </MainContainer>
        </div>
        <ButtonWrapper>
          <Line />
          <PrimaryButtonComp
            ref={signUpContinueButton}
            width={CONSTANTS.HUNDREED_PERCENT}
            text={<I18n tKey="continue" />}
            click={() => handleSignUp()}
            valid={valid}
            typeOfLoad={loadType}
          />
        </ButtonWrapper>
      </Wrapper>
    </AuthLayout>
  );
};
