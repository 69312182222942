import moment from 'moment';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from 'components/PrimaryButtonComp/style';
import { I18n } from 'components/Translation';
import { translate } from 'helpers';
import { useTranslation } from 'providers';
import { AppRoutes } from 'routes/constants';
import { setSteps } from 'store/slices/steps';
import { updateSendMoneyState, TransactionStatus } from 'store/slices/transaction';
import { sendMoneyTransactionStatusSelector } from 'store/slices/transaction/sender/sender.selectors';

import { StyledContent } from '../styles';

import {
  StyledSectionWithGreenBackground,
  StyledSuccessIcon,
  StyledTitle,
  StyledP,
  StyledDateP,
  StyledText,
  StyledPage,
  Wrapper,
  Content,
  ButtonBox,
} from './style';

export const TransferSuccess = () => {
  const { t } = useTranslation();
  const transactionStatus = useSelector(sendMoneyTransactionStatusSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const monthData = [
    translate(t, 'january'),
    translate(t, 'february'),
    translate(t, 'march'),
    translate(t, 'april'),
    translate(t, 'may'),
    translate(t, 'june'),
    translate(t, 'july'),
    translate(t, 'august'),
    translate(t, 'september'),
    translate(t, 'october'),
    translate(t, 'november'),
    translate(t, 'december'),
  ];

  const tomorrowMoment = moment().clone().add(1, 'days');

  const deliveryDay = tomorrowMoment.get('date');
  const deliveryMonth = tomorrowMoment.get('month');

  const transformedDeliveryDate =
    deliveryDay === 1
      ? `${deliveryDay}${translate(t, 'date_st')}`
      : deliveryDay === 2
      ? `${deliveryDay}${translate(t, 'date_nd')}`
      : deliveryDay === 3
      ? `${deliveryDay}${translate(t, 'date_rd')}`
      : deliveryDay < 10
      ? `${deliveryDay}${translate(t, 'date_th')}`
      : `${deliveryDay}${translate(t, 'date_th')}`;

  useEffect(() => {
    // TODO: улучшить кусок, плохой сайд эффект
    !(transactionStatus === TransactionStatus.success)
      ? navigate(AppRoutes.moneySteps.sendMoney)
      : dispatch(updateSendMoneyState({ transactionStatus: TransactionStatus.finish }));
  }, []);

  useEffect(() => {
    dispatch(
      setSteps({
        stepNumber: 0,
        show: false,
        totalSteps: 3,
        stepDescription: '',
      }),
    );
  }, []);

  return (
    <Wrapper>
      <Content>
        <StyledPage>
          <StyledSuccessIcon />
          <StyledContent>
            <StyledTitle>
              <I18n tKey="transfer_success" />
            </StyledTitle>
            <StyledSectionWithGreenBackground>
              <StyledP>
                <I18n tKey="estimated_delivery_local" />
              </StyledP>
              <StyledDateP>
                <I18n tKey="estimated_delivery_instantly" />
              </StyledDateP>
              <StyledP>
                <I18n tKey="estimated_delivery_not_later" />
              </StyledP>
              <StyledDateP>
                {monthData[deliveryMonth]} {transformedDeliveryDate}
              </StyledDateP>
            </StyledSectionWithGreenBackground>
            <StyledText>
              <I18n tKey="transfer_success_message_local" />
            </StyledText>
            <ButtonBox>
              <PrimaryButton
                onClick={() => {
                  navigate(AppRoutes.moneySteps.sendMoney);
                }}
              >
                <I18n tKey="send_another_recipient" />
              </PrimaryButton>
            </ButtonBox>
          </StyledContent>
        </StyledPage>
      </Content>
    </Wrapper>
  );
};
