import { SupabaseClient } from '@supabase/supabase-js';

import { supabase } from './supabaseClient';

export class SupabaseDataProvider {
  readonly supabase: SupabaseClient<any, 'public', any>;

  constructor() {
    this.supabase = supabase;
  }

  get manager() {
    return this.supabase;
  }

  get auth() {
    return this.supabase.auth;
  }
}
