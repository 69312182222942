import * as yup from 'yup';

import { translate } from 'helpers';
import { TranslationDataType } from 'resources/translation';
import { getValidations } from 'validation';

export const getValidationProfileSchema = (t: TranslationDataType) => {
  const { letters, phone } = getValidations(t);

  return yup.object().shape({
    firstName: letters.engRequired,
    lastName: letters.engRequired,
    country: yup
      .string()
      .required(`${translate(t, 'required_field')}`)
      .typeError(`${translate(t, 'required_field')}`),
    city: letters.engWithSpacesRequired,
    street: letters.engAndDigitsWithSimpleSignsRequired,
    zip: letters.engAndDigitsRequired,
    email: yup
      .string()
      .email(`${translate(t, 'must_contain_eng_letters_digits')}`)
      .required(`${translate(t, 'required_field')}`),
    phone: phone.standart,
    day: yup.string().required(`${translate(t, 'required_field')}`),
    month: yup.string().required(`${translate(t, 'required_field')}`),
    year: yup.string().required(`${translate(t, 'required_field')}`),
  });
};

export const getValidationProfilePasswordSchema = (
  t: TranslationDataType,
  passwordMode = false,
  passwordChangeMode = false,
) => {
  return yup.object().shape({
    ...(passwordChangeMode
      ? {
          currentPassword: yup
            .string()
            .min(8, `${translate(t, 'minimum_characters')}`)
            .matches(/[a-zA-Z]/, `${translate(t, 'uppercase_or_lowercase')}`)
            .matches(/[0-9]/, `${translate(t, 'at_least_one_digit')}`)
            .matches(
              /[!"#$%&'()*+,-.:;<=>?@[\]^_`\/{|}~]/,
              `${translate(t, 'at_least_one_symbol')}`,
            )
            .matches(/^[\S]*$/, `${translate(t, 'at_least_one_symbol')}`)
            .required(`${translate(t, 'required_field')}`),
        }
      : {}),
    password:
      passwordMode || passwordChangeMode
        ? yup
            .string()
            .min(8, `${translate(t, 'minimum_characters')}`)
            .matches(/[a-zA-Z]/, `${translate(t, 'uppercase_or_lowercase')}`)
            .matches(/[0-9]/, `${translate(t, 'at_least_one_digit')}`)
            .matches(
              /[!"#$%&'()*+,-.:;<=>?@[\]^_`\/{|}~]/,
              `${translate(t, 'at_least_one_symbol')}`,
            )
            .matches(/^[\S]*$/, `${translate(t, 'at_least_one_symbol')}`)
            .required(`${translate(t, 'required_field')}`)
        : yup
            .string()
            .min(8, `${translate(t, 'minimum_characters')}`)
            .matches(/[a-zA-Z]/, `${translate(t, 'uppercase_or_lowercase')}`)
            .matches(/[0-9]/, `${translate(t, 'at_least_one_digit')}`)
            .matches(
              /[!"#$%&'()*+,-.:;<=>?@[\]^_`\/{|}~]/,
              `${translate(t, 'at_least_one_symbol')}`,
            )
            .matches(/^[\S]*$/, `${translate(t, 'at_least_one_symbol')}`),
    passwordConfirmation:
      passwordMode || passwordChangeMode
        ? yup
            .string()
            .oneOf([yup.ref('password')], `${translate(t, 'no_match')}`)
            .required(`${translate(t, 'required_field')}`)
        : yup.string().oneOf([yup.ref('password')], `${translate(t, 'no_match')}`),
  });
};
