import { useEffect, useState } from 'react';
import { ComplaintsPolicyWrapper } from './style';
import { PagesApi } from 'apiListURL/pages';

export const ComplaintsPolicy = () => {
  const [page, setPage] = useState();

  useEffect(() => {
    PagesApi.getComplaintsPolicy().then((res) => {
      setPage(res?.data?.data?.description);
    });
  }, []);

  return (
    <ComplaintsPolicyWrapper>
      {
        // @ts-ignore
        <div className="content" dangerouslySetInnerHTML={{ __html: page }} />
      }
    </ComplaintsPolicyWrapper>
  );
};
