import * as Sentry from '@sentry/react';

import { envConfig } from 'config/env';

export const getMonitoring = () => {
  if (!envConfig.moniroting.dsn) return undefined;

  return Sentry;
};

export const initMonitoring = () => {
  if (!envConfig.moniroting.dsn) return;

  Sentry.init({
    dsn: envConfig.moniroting.dsn,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [window.location.host],
      }),
      new Sentry.Replay(),
    ],
    initialScope: {
      tags: { app: 'client-frontend' },
    },
    environment: envConfig.moniroting.environment,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    /**
     * This sets the sample rate at 10%. You may want to change it to 100%
     * while in development and then sample at a lower rate in production.
     */
    replaysSessionSampleRate: 0.1,
    /**
     * If you're not already sampling the entire session,
     * change the sample rate to 100% when sampling sessions where errors occur.
     */
    replaysOnErrorSampleRate: 1.0,
  });
};
