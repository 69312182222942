import { axiosInstance } from './axiosInstance';

export const PagesApi = {
  getPrivacyPolicy: async () => {
    return axiosInstance.get('page/get/privacy_policy');
  },
  getTermsOfUse: async () => {
    return axiosInstance.get('page/get/terms_of_use');
  },
  getComplaintsPolicy: async () => {
    return axiosInstance.get('page/get/terms_of_use');
  },
};
