import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { theme } from 'config';
import { ToastContainer } from 'containers/Toast';
import { InitedContainer } from 'containers/initedContainer';
import { AppProvider, AuthProvider, SendMoneyStepsProvider } from 'providers';
import { CountriesProvider } from 'providers/countriesProvider';
import { TranslationProvider } from 'providers/translationProvider/provider';
import { initResources } from 'resources/provider';
import Router from 'router';
import { initAnalytics } from 'services/analytics';
import { initMonitoring } from 'services/monitoring';

import ErrorBoundary from './ErrorBoundary/ErrorBoundary';

import './App.css';

initResources();
initAnalytics();
initMonitoring();

const queryClient = new QueryClient();

function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <div className="App">
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <AuthProvider>
                  <SendMoneyStepsProvider>
                    <TranslationProvider defaultLang="en">
                      <CountriesProvider>
                        <InitedContainer>
                          <Router />
                        </InitedContainer>
                      </CountriesProvider>
                    </TranslationProvider>
                  </SendMoneyStepsProvider>
                </AuthProvider>
              </BrowserRouter>
            </ThemeProvider>
            <ToastContainer />
          </div>
        </AppProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
