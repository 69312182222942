import { useState } from 'react';

import fileUpload from 'assets/icons/fileUpload.svg';
import { Icon } from 'components/Icon';
import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';
import { toastAlert, translate } from 'helpers';
import { useTranslation } from 'providers';

import { FilesDragAndDrop } from './FilesDragAndDrop';
import {
  DescriptionWrapper,
  FileCard,
  MarginDiv,
  UploadButton,
  UploadCower,
  UploadWrapper,
} from './styles';
import { propsType } from './types';

export const FileUploader = ({ onFileChange, uploadedFile }: propsType) => {
  const { t } = useTranslation();
  const [isValidFile, setIsValidFile] = useState(true);

  const handleClick = () => {
    toastAlert('error', translate(t, 'remove_existing_file'));
  };

  return (
    <UploadWrapper uploadedFile={!!uploadedFile}>
      {uploadedFile && <UploadCower onClick={handleClick} />}
      <FilesDragAndDrop onFileChange={onFileChange} setIsValidFile={setIsValidFile}>
        <FileCard className={!isValidFile ? 'upload-failed' : ''}>
          <Icon customWidth="48px" customHeight="48px" img={fileUpload} />
          <MarginDiv variant="M24" />
          <TextField variant="H6">
            <I18n tKey="drag_and_drop_files" />
          </TextField>
          <MarginDiv variant="M8" />
          <DescriptionWrapper>
            <TextField className={!isValidFile ? 'upload-failed' : ''} variant="description">
              <I18n tKey="acceptable_file_format_local" />
            </TextField>
          </DescriptionWrapper>
          <MarginDiv variant="M16" />
          <TextField variant="IL_P7">
            <I18n tKey="or" />
          </TextField>
          <MarginDiv variant="M16" />
          <UploadButton>
            <TextField variant="UP_H7">
              <I18n tKey="browse_files" />
            </TextField>
          </UploadButton>
        </FileCard>
        {!isValidFile && (
          <TextField className={!isValidFile ? 'upload-failed' : ''} variant="IL_P7">
            <I18n tKey="incorrect_file_format" />
          </TextField>
        )}
      </FilesDragAndDrop>
    </UploadWrapper>
  );
};
