import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Arrow } from 'assets/images/arrow.svg';
import { TextField } from 'components/TextField';
import { CONSTANTS } from 'config';
import { useOnClickOutside } from 'hooks';
import { useTranslation } from 'providers';
import { RootState } from 'store';
import { changeLanguage } from 'store/slices/language';

import {
  StyledDropdown,
  StyledDropdownButton,
  StyledArrow,
  StyledDropdownMenu,
  StyledDropdownItem,
  StyledFlag,
  Container,
} from './styles';
import { ICurrentLang, IProps } from './types';

export const LangDropdown = ({
  isTextDropdown = false,
  isDropdownInFooter,
  isLeftSide,
}: IProps) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [value, setValue] = useState('English');
  const [flag, setFlag] = useState(CONSTANTS.LANGUAGES[0].iso2);
  const { language } = useSelector((state: RootState) => state?.language);
  const { handleUpdateLang } = useTranslation();

  const selectItem = (item: ICurrentLang) => {
    setValue(item.label);
    setFlag(item.iso2);
    setShow(false);
    handleUpdateLang(item.lang);
    dispatch(changeLanguage({ language: item.lang }));
  };

  const inputField = useRef() as React.MutableRefObject<HTMLInputElement>;
  useOnClickOutside(inputField, () => setShow(false));

  useEffect(() => {
    const chooseLanguage = CONSTANTS.LANGUAGES.filter((elem) => elem.lang === language);
    setFlag(chooseLanguage?.[0].iso2);
    setValue(chooseLanguage?.[0].label);
    localStorage.setItem('language', chooseLanguage?.[0].lang);
  }, [language]);

  return (
    <StyledDropdown ref={inputField}>
      <StyledDropdownButton
        onClick={() => setShow((prev) => !prev)}
        isTextDropdown={isTextDropdown}
        isDropdownFooter={isDropdownInFooter}
        isLeftSide={isLeftSide}
      >
        {!isLeftSide && (
          <StyledFlag>
            <img alt="Flag" src={`/countries/${flag.toLowerCase()}.svg`} />
          </StyledFlag>
        )}
        <TextField variant="P8" fw="600">
          {value}
        </TextField>
        <StyledArrow show={isDropdownInFooter ? show : !show}>
          <Arrow />
        </StyledArrow>
      </StyledDropdownButton>
      {show && (
        <StyledDropdownMenu
          isTextDropdown={isTextDropdown}
          isDropdownInFooter={isDropdownInFooter}
          isLeftSide={isLeftSide}
        >
          {CONSTANTS.LANGUAGES.map((item) => (
            <Container key={item.iso2}>
              <StyledFlag>
                <img alt="Flag" src={`/countries/${item.iso2.toLowerCase()}.svg`} />
              </StyledFlag>
              <StyledDropdownItem key={item.iso2} onClick={() => selectItem(item)}>
                {item.label}
              </StyledDropdownItem>
            </Container>
          ))}
        </StyledDropdownMenu>
      )}
    </StyledDropdown>
  );
};
