import { UnionPay, Master, Visa } from 'assets/images';
import { Image } from 'components/Image';
import { LangDropdown } from 'components/LangDropdown';
import { StyledLinkBlack } from 'components/PrimaryButtonComp/style';
import { StyledLink } from 'components/StyledLink';
import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';
import { ExternalRoutes } from 'routes/constants';

import {
  Wrapper,
  Container,
  TopSection,
  BottomSection,
  Line,
  Policysection,
  TextWrapper,
  PaymentSection,
  PaymentsWrapper,
  LangBox,
} from './styles';

const isLangAvailable = false;

export const Footer = () => (
  <Wrapper>
    <Container>
      <TopSection>
        <Policysection>
          <StyledLink isExternal url={ExternalRoutes.termsOfUse}>
            <StyledLinkBlack>
              <TextField variant="P11">
                <I18n tKey="terms_of_use" />
              </TextField>
            </StyledLinkBlack>
          </StyledLink>
          <StyledLink isExternal url={ExternalRoutes.privacyPolicy}>
            <StyledLinkBlack>
              <TextField variant="P11">
                <I18n tKey="privacy_policy" />
              </TextField>
            </StyledLinkBlack>
          </StyledLink>
        </Policysection>
        {isLangAvailable && (
          <LangBox>
            <LangDropdown isDropdownInFooter isLeftSide={false} />
          </LangBox>
        )}
      </TopSection>
      <Line />
      <BottomSection>
        <PaymentSection>
          <TextField className="accepted-text" variant="P11" color="n700">
            <I18n tKey="accepted_payment_card_types" />
          </TextField>
          <PaymentsWrapper>
            <Image image={<Visa />} />
            <Image image={<Master />} />
            <Image image={<UnionPay />} />
          </PaymentsWrapper>
        </PaymentSection>
        <TextWrapper className="footer-center-text">
          <TextField variant="P11" color="n700">
            <I18n tKey="footer_address_line_1" />
          </TextField>
          <TextField variant="P11" color="n700">
            <I18n tKey="footer_address_line_2" />
          </TextField>
          <TextField variant="P11" color="n700">
            <I18n tKey="footer_address_line_3" />
          </TextField>
          <TextField variant="P11" color="n700">
            <I18n tKey="footer_address_line_4" />
          </TextField>
        </TextWrapper>
        <TextWrapper>
          <TextField variant="P11" color="n700">
            <I18n tKey="fm_finance_ltd" />
          </TextField>
          <TextField variant="P11" color="n700">
            <I18n tKey="registration_number" />
          </TextField>
          <TextField variant="P11" color="n700">
            <I18n tKey="auth_payment_institution" />
          </TextField>
          <TextField variant="P11" color="n700">
            <I18n tKey="uk_fca_registration_number" />
          </TextField>
          <TextField variant="P11" color="n700">
            <I18n tKey="bic_code" />
          </TextField>
        </TextWrapper>
      </BottomSection>
    </Container>
  </Wrapper>
);
