import styled from 'styled-components';

export const PopUpWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(34, 34, 41, 0.5);
  left: 0;
  top: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 440px;
  background: white;
  opacity: 1;
  backdrop-filter: blur(2px);
  border-radius: 8px;
  @media (max-width: 647px) {
    width: auto;
    margin: 0 8px;
  }
`;

export const Title = styled.div`
  display: flex;
  position: relative;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e7eb;
  > .close-icon {
    cursor: pointer;
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
  gap: 16px;
  border-bottom: 1px solid #e5e7eb;
  .email > label > .input-container > input {
    width: 100%;
    margin-top: 8px;
  }
  .text-area {
    resize: none;
    border: 1px solid #c7c9d1;
    border-radius: 8px;
    padding: 10px 16px;
    color: #4c4e57 !important;
    background: transparent;
    font-family: 'Lato-Regular';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
  > .checkbox {
    display: flex;
    align-items: center;
    > .checkbox-container label {
      margin: 0 15px;
      img {
        top: 6px;
      }
    }
    > .checkbox-container label:before {
      margin-top: 3px;
    }
  }
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: left;
  text-align: left;
  justify-content: flex-start;
  align-content: left;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 20px;
  & > :first-child {
    width: 96px;
    border: 1px solid #8994e6;
    background: #fff;
  }
  & > :last-child {
    width: 116px;
    border: none;
    background: #3848c6;
    > div {
      color: #fff;
    }
  }
`;

export const PrimaryButton = styled.button<{ width?: string }>`
  height: 56px;
  padding: 16px 24px;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-family: Commissioner-Regular;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  @media (max-width: 647px) {
    width: ${({ width }) => width};
    height: 56px;
  }
  :disabled {
    background: #dde0f8 !important;
  }
  :hover {
    background: #4858cf;
  }
  :active {
    outline: 4px solid rgba(24, 97, 242, 0.24);
    background: #3848c6 !important;
  }
  :focus {
    background: #3342b8;
  }
`;

export const SecondaryButton = styled.button<{ width?: string }>`
  height: 56px;
  display: flex;
  align-items: center;
  align-content: center;
  border: 1px solid red;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-family: Commissioner-Regular;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  .cancel-text {
    margin: 0 auto;
  }
  @media (max-width: 647px) {
    width: ${({ width }) => width};
    height: 56px;
  }
  :hover {
    color: #4858cf;
    border: 1px solid #3848c6;
  }
  :disabled {
    border: 1px solid #dde0f8;
    color: #abb3ed;
  }
  :active {
    box-shadow: 0 0 0 1px #3848c6;
    outline: 5px ridge rgba(24, 97, 242, 0.24);
    background: #fff !important;
  }
  :focus {
    background: #eff0fb;
    border: 1px solid #8994e6;
    color: #3342b8;
  }
`;

export const StyledFlex = styled.div`
  display: flex;
`;
