import { IDataProvider } from 'services';

import { QuestionRepo } from './QuestionRepo';
import { AddQuestion, Question } from './types';

export class QuestionService {
  private questionRepo: QuestionRepo;

  constructor(provider: IDataProvider) {
    this.questionRepo = new QuestionRepo(provider);
  }

  public async addQuestion(payload: Question): Promise<AddQuestion> {
    return this.questionRepo.addQuestion(payload);
  }
}
