import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 600px;
  @media screen and (max-width: 648px) {
    width: 100%;
  }
  .error-message {
    margin-bottom: 20px;
    color: #ff5c5c;
    font-size: 13px;
  }
`;

export const InputContainer = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}

  .first-name label .input-container {
    position: absolute;
    top: 35px;
  }
  .last-name label .input-container {
    position: absolute;
    top: 35px;
  }
  .day label .input-container {
    position: absolute;
    top: 35px;
  }
  .city-pos label .input-container {
    position: absolute;
    top: 35px;
  }
  .street label .input-container {
    position: absolute;
    top: 35px;
  }
  .zip label .input-container {
    position: absolute;
    top: 35px;
  }
  .cardholder-name label .input-container {
    width: 100%;
    position: absolute;
    top: 35px;
  }

  > .label {
    pointer-events: none;
    position: absolute;
    top: 24px;
    left: 18px;
    transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    box-sizing: border-box;
    .on-focus {
      -webkit-transition: all 0.1s linear;
      -moz-transition: all 0.1s linear;
      position: relative;
      font-size: 13px;
      color: #4c4e57;
      top: -18px;
    }
  }
  > .error-message {
    font-size: 12px;
    margin-top: 8px;
    width: auto;
  }
  flex-wrap: nowrap;
  > .first-name,
  .country,
  .city,
  .street,
  .card-number,
  .email {
    width: 464px;
    zip-code: 120px;
    @media screen and (max-width: 648px) {
      width: auto;
    }

    &.five_step_email {
      width: 100%;
    }
  }

  .zip {
    width: 120px;
    @media screen and (max-width: 648px) {
      width: auto;
    }
  }
  .card-number {
    width: 292px;
    @media screen and (max-width: 648px) {
      width: auto;
    }
  }
  .exp_date,
  .date {
    width: 156px;
    > div {
      > div {
        padding: 0;
        min-width: 0;
        flex-grow: 0;
      }
    }
  }
  @media screen and (max-width: 648px) {
    flex-direction: column;
  }
`;

export const BirthBox = styled.div`
  display: flex;
  margin-bottom: 24px;
  gap: 16px;
  .day {
    width: 120px;
    height: 72px;
  }
  .month,
  .year {
    width: 224px;
    height: 72px;
  }
  @media (max-width: 647px) {
    flex-direction: column;
    .day {
      width: 100%;
    }
    .month,
    .year {
      width: 100%;
    }
  }
`;
