import styled, { css } from 'styled-components';

export const StyledDropdown = styled.div`
  position: relative;
`;

export const StyledDropdownButton = styled.button<{
  isTextDropdown: boolean;
  isDropdownFooter?: boolean;
  isLeftSide?: boolean;
}>`
  font-family: Commissioner-Regular;
  font-size: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  border: ${({ isDropdownFooter }) => (isDropdownFooter ? 'unset' : 'none')};
  box-sizing: border-box;
  ${({ isTextDropdown, isDropdownFooter, isLeftSide }) =>
    isTextDropdown
      ? css`
          font-weight: 600;
          color: #fff;
          border-radius: 11px;
          padding: 2px 0;
          background: transparent;
          :hover {
            color: #dde0f8;
          }
          :focus {
            border-color: rgba(255, 255, 255, 0.24);
          }
        `
      : css`
          width: 102px;
          height: 40px;
          font-weight: 400;
          color: #222229;
          border-radius: ${isDropdownFooter ? 'unset' : '6px'};
          padding: ${isLeftSide ? '8px 16px' : ''};
          background: ${isDropdownFooter ? 'transparent' : '#fff'};
          :hover {
            color: #3848c6;
            svg path {
              fill: #3848c6;
            }
            div {
              color: #3848c6 !important;
            }
          }
          :focus {
            border-color: rgba(255, 255, 255, 0.24);
            outline: 4px solid #6874d4;
            border-radius: 4px;
            div {
              color: #3848c6 !important;
            }
            svg path {
              fill: #3848c6;
            }
          }
          svg path {
            fill: #222229;
          }
        `}
`;

export const StyledArrow = styled.div<{ show?: boolean }>`
  transform: ${({ show }) => (show ? 0 : 'rotate(180deg)')};
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
`;

export const StyledDropdownMenu = styled.div<{
  isTextDropdown: boolean;
  isDropdownInFooter?: boolean;
  isLeftSide?: boolean;
}>`
  width: 280px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(34, 34, 41, 0.1);
  border-radius: 8px;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  ${({ isTextDropdown }) =>
    isTextDropdown
      ? css`
          top: 33px;
          left: -16px;
        `
      : css`
          width: 280px;
          top: ${(isDropdownInFooter) => !isDropdownInFooter && '40px'};
          bottom: ${(isDropdownInFooter) => isDropdownInFooter && '40px'};
        `}
  ${({ isLeftSide }) =>
    isLeftSide
      ? css`
          left: 0;
          margin-bottom: 4px;
        `
      : css`
          left: -180px;
          @media (max-width: 647px) {
            left: 0;
          }
        `}
  > :first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  > :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const StyledDropdownItem = styled.button`
  font-family: Lato-Regular;
  font-size: 16px;
  color: #222229;
  border: none;
  width: 160px;
  text-align: left;
  background: transparent;
  cursor: pointer;
  position: relative;
  bottom: 1px;
`;

export const Container = styled.div`
  height: 40px;
  display: flex;
  padding: 0 16px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  :hover {
    background: #f4f4f6;
  }
`;

export const StyledFlag = styled.div`
  display: flex;
  img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    object-fit: cover;
  }
`;
