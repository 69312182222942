import { FocusEvent } from 'react';

import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';

import { StyledErrorText, StyledFlag, StyledLabel, StyledTitle } from '../styles';

import { StyledSelect, StyledFlex, StyledSpan, styles } from './styles';
import { ISelector, IElement, CountrySelectOption } from './types';

export const CountrySelect = ({
  title = '',
  error = false,
  className = '',
  onChange = () => {},
  current = '',
  value = '',
  disabled = false,
  onBlur = () => {},
  isIso = true,
  countries = [],
}: ISelector) => {
  const data = countries.map((element) => {
    return {
      value: isIso ? element.iso2 : element.name,
      name: element.name,
      iso2: element.iso2,
      iso3: element.iso3,
      label: (
        <StyledFlex key={element.iso3}>
          <StyledFlag>
            <img
              alt={`${element.name || element.iso3} Flag`}
              src={`https://static.remitiva.com/countries/${element.iso2.toLowerCase()}.svg`}
            />
          </StyledFlag>
          <StyledSpan disable={disabled}>{element.name}</StyledSpan>
        </StyledFlex>
      ),
    };
  });

  const customFilter = (option: any, searchText: any) => {
    if (
      option.data?.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data?.iso2.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data?.iso3.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <StyledLabel error={error} onChange={onChange} className={className} isDisabled={disabled}>
        <StyledTitle as="div" marginBottom="0" marginTop="-4px">
          <TextField variant="P7">{!title ? <I18n tKey="sender_country" /> : title}</TextField>
        </StyledTitle>
        <StyledSelect
          isDisabled={disabled}
          options={data}
          onBlur={(e: FocusEvent<HTMLInputElement>) => onBlur(e)}
          onChange={(option: CountrySelectOption | unknown) =>
            onChange ? onChange((option as CountrySelectOption).value) : {}
          }
          placeholder={current ?? <I18n tKey="choose_country" />}
          aria-labelledby="aria-label"
          styles={styles}
          filterOption={customFilter}
          value={data?.filter((el: IElement) => el.value === value)}
        />
      </StyledLabel>
      {error && (
        <StyledErrorText className="country_select">
          <I18n tKey="required_field" />
        </StyledErrorText>
      )}
    </div>
  );
};
