import { CardTypeComponent } from 'components/CardTypeComponent';
import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';
import { CARD_TYPE_ENDING } from 'config/constants';

import {
  Card,
  Button,
  CardWrapper,
  FlexContainer,
  ButtonsWrapper,
  ModalBackground,
  CardTypeWrapper,
} from './style';
import { propsType } from './types';

type CardType = 'mastercard' | 'visa' | 'union_pay';

export const ConfirmationPopup = ({
  handleClose,
  handleSubmit,
  cardDigits,
  cardType,
}: propsType) => (
  <>
    <ModalBackground />
    <CardWrapper>
      <Card>
        <div>
          <TextField variant="H6" margin="33px 20px 16px 20">
            <I18n tKey="delete_confirmation" />
          </TextField>
        </div>
        <FlexContainer>
          <CardTypeWrapper>
            <CardTypeComponent type={cardType} />
          </CardTypeWrapper>
          <TextField variant="P8" bgc="#4C4E57">
            <I18n tKey={CARD_TYPE_ENDING[cardType as CardType]} /> {cardDigits}
          </TextField>
        </FlexContainer>
        <ButtonsWrapper>
          <Button className="cancel-btn" onClick={handleClose}>
            <TextField className="cancel-text" variant="H7" color="p500">
              <I18n tKey="cancel" />
            </TextField>
          </Button>

          <Button className="delete-btn" onClick={handleSubmit}>
            <TextField className="cancel-text" variant="H7" color="p500">
              <I18n tKey="dropdown_delete" />
            </TextField>
          </Button>
        </ButtonsWrapper>
      </Card>
    </CardWrapper>
  </>
);
