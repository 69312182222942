export interface IUserInfo {
  id: string;
  first_name: string;
  last_name: string;
  country: string;
  city: string;
  street: string;
  zip: string;
  phone: string;
  firstedit: boolean;
  passwordSet: boolean | string;
  code: null | string;
  email: string;
  date_of_birth: string;
  status_w: ProfileStatus | null;
  createdAt?: string | null;
}

export enum ProfileStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  BLOCKED = 'BLOCKED',
  SUSPENDED = 'SUSPENDED',
  CLOSED = 'CLOSED',
}

export interface ISuccess {
  state: {
    email: string;
  };
}

export interface IProfile {
  email?: string;
  city?: string;
  country?: string;
  first_name?: string;
  firstedit: boolean;
  id: string;
  last_name?: string;
  passwordset: boolean;
  phone?: string;
  street?: string;
  zip?: string;
  day?: string;
  month?: string;
  year?: string;
  code: null | string;
  date_of_birth?: string;
  status_w?: ProfileStatus | null;
  createdAt?: string | null;
}
export interface IProfileData {
  email?: string;
  city?: string;
  country?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  street?: string;
  zip?: string;
  day?: string;
  month?: string;
  year?: string;
  // dateOfBirth?: string;
}

export interface IPromiseProfile {
  message: string;
  profile: {
    city: string;
    code: number;
    country: string;
    email: string;
    first_name: string;
    firstedit: boolean;
    id: string;
    last_name: string;
    passwordSet: boolean;
    phone: string;
    street: string;
    zip: string;
  };
  status: boolean;
}

export interface IProfileCheck {
  email?: string;
}
