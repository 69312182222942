import isNil from 'lodash/isNil';
import { useMemo, useState, useCallback } from 'react';

import { defaultT } from 'config';
import { useTranslationQuery, useTranslationAccessQuery } from 'queries/translation';
import { translation } from 'utils/Translation';

export const useTranslationContext = (defaultLang: string) => {
  const [lang, setLang] = useState(defaultLang);
  const [isModalOpen, setModalVisibility] = useState(false);
  const [isEditVisibility, setEditVisibility] = useState(false);
  const [currentKey, setKey] = useState('');

  const { data, isFetched, isLoading, refetch } = useTranslationQuery({
    lang,
    options: {
      onSuccess: (data) => {
        translation.setRemoteTranslations(data);
      },
    },
  });

  const { data: isAllowedData } = useTranslationAccessQuery();

  const { isAllowed } = isAllowedData || {};

  const handleUpdateLang = (lang: string) => {
    setLang(lang);
  };

  const finalTranslation = useMemo(() => data || defaultT, [data]);

  const handleModal = useCallback(
    (key?: string) => {
      if (key && isAllowed) {
        setKey(key);
        setModalVisibility(true);

        return;
      }

      setKey('');
      setModalVisibility(false);
    },
    [isAllowed],
  );

  const handleToggleEdit = useCallback(() => setEditVisibility((state) => !state), []);

  const handleRefetch = useCallback(async () => refetch(), [refetch]);

  return {
    lang,
    t: finalTranslation,
    isModalOpen,
    currentKey,
    isEditVisibility,
    isTranslationManagementAllowed: isAllowed,
    isTranslationLoaded: isFetched && !isLoading && !isNil(data),
    handleUpdateLang,
    toggleModal: handleModal,
    handleToggleEdit,
    handleRefetch,
  };
};
