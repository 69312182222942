import { useRef, useState } from 'react';

import { DropFileInput, DropFileInputWrapper } from './style';
import { propTypes } from './types';

const maxFileSizeByte = 25 * 1024 * 1024;
const acceptedFileTypes = ['jpg', 'jpeg', 'png', 'tiff', 'heic'];

export const FilesDragAndDrop = ({ children, onFileChange, setIsValidFile }: propTypes) => {
  const wrapperRef = useRef(null);
  const [, setFileList] = useState([]);

  // @ts-ignore
  const onDragEnter = () => wrapperRef.current.classList.add('dragover');
  // @ts-ignore
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
  // @ts-ignore
  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const defineFileType = (acceptedFileTypes: string[], fileName: string) => {
    const fileNameToArray = fileName.split('.');
    const fileExtension = fileNameToArray[fileNameToArray.length - 1];
    return acceptedFileTypes.some((fileType) => fileType === fileExtension);
  };

  const onFileDrop = (e: any) => {
    const newFile = e.target.files[0];

    if (newFile.size > maxFileSizeByte) {
      setIsValidFile(false);
    } else {
      if (defineFileType(acceptedFileTypes, newFile.name.toLowerCase())) {
        setIsValidFile(true);
        setFileList(newFile);
        onFileChange(newFile);
      } else {
        setIsValidFile(false);
      }
    }
  };

  return (
    <DropFileInputWrapper
      ref={wrapperRef}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      {children}
      <DropFileInput className="file-input" type="file" value="" onChange={onFileDrop} />
    </DropFileInputWrapper>
  );
};
