import { createContext } from 'react';
import { QueryObserverResult } from 'react-query';

interface TranslationContextProps {
  lang: string;
  currentKey: string;
  isModalOpen: boolean;
  isEditVisibility: boolean;
  isTranslationManagementAllowed?: boolean;
  isTranslationLoaded?: boolean;
  t: Record<string, string>;
  handleUpdateLang: (lang: string) => void;
  toggleModal: (key?: string) => void;
  handleToggleEdit: (key?: string) => void;
  handleRefetch: () => Promise<QueryObserverResult<Record<string, string>, unknown>>;
}

export const TranslationContext = createContext<TranslationContextProps>({
  lang: 'en',
  currentKey: '',
  isModalOpen: false,
  isEditVisibility: false,
  isTranslationManagementAllowed: false,
  isTranslationLoaded: false,
  t: {},
  handleUpdateLang: () => {},
  toggleModal: () => {},
  handleToggleEdit: () => {},
  handleRefetch: () => Promise.resolve(undefined as any),
});
