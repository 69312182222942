import { useEffect, useMemo, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { VerificationApi } from 'apiListURL/verification';
import { Icon, TextField } from 'components';
import { I18n } from 'components/Translation';
import { toastAlert } from 'helpers';
import nextStep from 'helpers/nextStepDefine';
import { AppRoutes } from 'routes/constants';
import { getMonitoring } from 'services/monitoring';
import { setSteps } from 'store/slices/steps';

import {
  StepPreviewComponent,
  MarginDiv,
  IncreaseMonthlyLimitWrapper,
  ConfirmationButton,
} from './styles';
import { propsType } from './types';

export const StepPreview = ({
  image,
  title,
  description,
  buttonName,
  routeTo = '',
  subDescription = '',
  stepCount = 0,
  stepDescription = '',
}: propsType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const isStandAlone = useMemo(() => {
    const standAloneParam = new URLSearchParams(location.search).get('is_stand_alone');
    return standAloneParam === 'true';
  }, [location.search]);
  const routeToFinal = isStandAlone ? AppRoutes.private.increaseMonthlyLimitThankYou : routeTo;

  const [isPreparing, setIsPreparing] = useState(false);

  const checkVerification = () => {
    setIsPreparing(true);
    const checkVerificationStatus = async () => VerificationApi.getVerificationStatus();

    checkVerificationStatus()
      .then((res) => {
        if (!res?.data?.data?.id) {
          VerificationApi.postVerificationStatus()
            .then(() => {
              navigate(routeToFinal);
            })
            .catch(() => {
              toastAlert('error', 'Something went wrong. Please try later.');
            });
        } else {
          setIsPreparing(false);
          routeToFinal ? navigate(routeToFinal) : navigate(nextStep(res?.data?.data));
        }
      })
      .catch(() => {
        toastAlert('error', 'Something went wrong. Please try later.');
      });
  };

  const identityVerification = () => {
    setIsPreparing(true);
    VerificationApi.postIdentityVerification()
      .then((res) => {
        window.location.replace(res?.data?.data?.redirect_url);
      })
      .catch((err) => {
        getMonitoring()?.captureException(err);
        toastAlert('error', 'Something went wrong. Please try later.');
      })
      .finally(() => {
        setIsPreparing(false);
      });
  };

  const clickHandler = () => {
    if (location.pathname.includes(AppRoutes.private.increaseMonthlyLimitWithZsPassed)) {
      if (!isPreparing) {
        identityVerification();
      }
      return;
    }
    if (location.pathname === AppRoutes.private.increaseMonthlyLimit) {
      checkVerification();
    } else {
      navigate(routeToFinal);
    }
  };

  useEffect(() => {
    dispatch(
      setSteps({
        stepNumber: stepCount,
        show: location.pathname.includes(AppRoutes.private.increaseMonthlyLimitWithZsPassed),
        totalSteps: 3,
        stepDescription,
      }),
    );
  }, []);

  return (
    <StepPreviewComponent>
      <IncreaseMonthlyLimitWrapper>
        <Icon customWidth="64px" img={image} />
        <MarginDiv variant="M46" />
        <TextField variant="IL_H3">
          <I18n tKey={title} />
        </TextField>
        {description && (
          <TextField variant="IL_P7">
            <I18n tKey={description} />
          </TextField>
        )}
        {subDescription && (
          <TextField variant="IL_P7">
            <I18n tKey={subDescription} />
          </TextField>
        )}
        <MarginDiv variant="M32" />
        <ConfirmationButton isPreparing={isPreparing} onClick={clickHandler}>
          <TextField variant="IL_P8" className="increase_limits" style={{ color: '#FFFFFF' }}>
            {!isPreparing ? (
              <I18n tKey={isStandAlone ? 'finish' : buttonName} />
            ) : (
              <Oval
                height={24}
                width={100}
                color="#3848C6"
                wrapperStyle={{}}
                wrapperClass="spinner"
                visible
                ariaLabel="oval-loading"
                secondaryColor="#ABB3ED"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            )}
          </TextField>
        </ConfirmationButton>
      </IncreaseMonthlyLimitWrapper>
    </StepPreviewComponent>
  );
};
