import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from 'components/PrimaryButtonComp/style';
import { I18n } from 'components/Translation';
import { AppRoutes } from 'routes/constants';
import { setSteps } from 'store/slices/steps';
import { updateSendMoneyState, TransactionStatus } from 'store/slices/transaction';
import { sendMoneyTransactionStatusSelector } from 'store/slices/transaction/sender/sender.selectors';

import { StyledContent } from '../styles';

import {
  StyledSectionWithRedBackground,
  StyledDeniedIcon,
  StyledTitle,
  StyledP,
  StyledText,
  Wrapper,
  StyledPage,
  Content,
} from './style';
import { PropsType } from './types';

export const TransferDenied = ({ errorType }: PropsType) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transactionStatus = useSelector(sendMoneyTransactionStatusSelector);

  useEffect(() => {
    !(transactionStatus === TransactionStatus.denied)
      ? navigate(AppRoutes.moneySteps.sendMoney)
      : dispatch(updateSendMoneyState({ transactionStatus: TransactionStatus.finish }));
  }, []);

  useEffect(() => {
    dispatch(
      setSteps({
        stepNumber: 0,
        show: false,
        totalSteps: 3,
        stepDescription: '',
      }),
    );
  }, []);

  return (
    <Wrapper>
      <Content>
        <StyledPage>
          <StyledDeniedIcon />
          <StyledContent>
            <StyledTitle>
              <I18n tKey="transfer_has_been_denied" />
            </StyledTitle>
            <StyledSectionWithRedBackground>
              <StyledP>
                {errorType === 1 ? (
                  <I18n tKey="transfer_declined_message" />
                ) : (
                  <I18n tKey="transfer_denied_message" />
                )}
              </StyledP>
            </StyledSectionWithRedBackground>
            <StyledText>
              <I18n tKey="transfer_denied_second_message" />
            </StyledText>
            <PrimaryButton
              onClick={() => {
                navigate(AppRoutes.moneySteps.sendMoney);
              }}
            >
              <I18n tKey="send_another_recipient" />
            </PrimaryButton>
          </StyledContent>
        </StyledPage>
      </Content>
    </Wrapper>
  );
};
