import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';

import { ProfileApi } from 'apiListURL';
import Password from 'assets/images/password.png';
import { EmailForm, PrimaryButtonComp, TextField } from 'components';
import { I18n } from 'components/Translation';
import { CONSTANTS } from 'config';
import { translate, toastAlert } from 'helpers';
import AuthLayout from 'layout/auth_layout';
import { useAppProvider, useTranslation } from 'providers';
import { AppRoutes } from 'routes/constants';
import { getMonitoring } from 'services/monitoring';

import { Line } from '../styles';

import { Wrapper, ButtonWrapper, MainContainer } from './styles';

export const ResetPassword = (): ReactJSXElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchEmail = useMemo(
    () => new URLSearchParams(location.search).get('email'),
    [location.search],
  );

  const [email, setEmail] = useState<string>(searchEmail || '');
  const [valid, setValid] = useState(false);
  const { setLoader, loadType, setLoadType } = useAppProvider();
  const { t } = useTranslation();
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleSubmit = async () => {
    setLoader(true);
    setLoadType('Reset Password');

    navigate(AppRoutes.public.success, { state: { email, passwordReset: true } });
    ProfileApi.setResetEmail({
      email,
    })
      .then(async () => {
        navigate(AppRoutes.public.success, { state: { email, passwordReset: true } });
      })
      .catch((e) => {
        getMonitoring()?.captureException(e);
        toastAlert(
          'error',
          e.response?.data?.message || translate(t, 'error_something_went_wrong'),
        );
      })
      .finally(() => {
        setLoader(false);
        setValid(false);
      });
  };

  const handleUserKeyPress = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submitButtonRef?.current?.click?.();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', (e: KeyboardEvent) => handleUserKeyPress(e));

    return () => {
      window.removeEventListener('keydown', (e: KeyboardEvent) => handleUserKeyPress(e));
    };
  }, []);

  return (
    <>
      <AuthLayout img={Password} imgMaxWidth="80%">
        <Wrapper>
          <MainContainer>
            <TextField variant="H3" bottom="8">
              <I18n tKey="password_recovery" />
            </TextField>
            <TextField className="subtitle" variant="P7" bottom="24">
              <I18n tKey="enter_your_email_for_reset_link_local" />
            </TextField>
            <EmailForm
              className="input-field"
              initialValues={{
                email: searchEmail || '',
              }}
              handleData={(err: boolean, emailValue: string) => {
                setValid(!!err);
                setEmail(emailValue);
              }}
            />
          </MainContainer>
          <ButtonWrapper>
            <Line />
            <PrimaryButtonComp
              ref={submitButtonRef}
              width={CONSTANTS.HUNDREED_PERCENT}
              text={<I18n tKey="continue" />}
              valid={valid}
              click={() => handleSubmit()}
              typeOfLoad={loadType}
            />
          </ButtonWrapper>
        </Wrapper>
      </AuthLayout>
    </>
  );
};
