import { useQueryBuilder } from 'hooks/useQueryBuilder';
import { getResourceManager } from 'resources';

import { CountryQueryProps } from './types';

export const useCountriesData = (props?: CountryQueryProps) => {
  const { options } = props || {};

  return useQueryBuilder({
    key: 'countries',
    method: async () => getResourceManager().country.getCountries(),
    enabled: true,
    options,
  });
};
