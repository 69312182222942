import { useEffect, useState } from 'react';

import checkImg from 'assets/images/check.svg';
import { StyledLinkText } from 'components/PrimaryButtonComp/style';
import { StyledLink } from 'components/StyledLink';
import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';
import { ExternalRoutes } from 'routes/constants';

import { Checkbox, CheckboxContent } from './styles';
import { IProps } from './types';

export const AgreeCheckbox = ({ getIsCheck, isTermsOnly = false }: IProps) => {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    getIsCheck(checked);
  }, [checked, getIsCheck]);

  return (
    <Checkbox>
      <CheckboxContent>
        <input
          onChange={(e) => {
            e.stopPropagation();
            setChecked(e.target.checked);
          }}
          type="checkbox"
          id="agree1"
          name="privacyPolicy"
          value="agree"
        />
        <label htmlFor="agree1">
          <img src={checkImg} alt="check" />
          <TextField className="check-text bordering" variant="P7" color="n900">
            <I18n tKey="i_agree_with" />
          </TextField>
        </label>
        <StyledLink isExternal url={ExternalRoutes.termsOfUse}>
          <StyledLinkText>
            <TextField variant="P7" color="a500">
              <I18n tKey="terms_of_use" />
            </TextField>
          </StyledLinkText>
        </StyledLink>
        {!isTermsOnly && (
          <StyledLinkText>
            <TextField variant="P7" color="n900">
              <I18n tKey="and" />
            </TextField>
          </StyledLinkText>
        )}
      </CheckboxContent>
      {!isTermsOnly && (
        <StyledLink isExternal url={ExternalRoutes.privacyPolicy}>
          <StyledLinkText>
            <TextField variant="P7" color="a500">
              <I18n tKey="privacy_policy" />
            </TextField>
          </StyledLinkText>
        </StyledLink>
      )}
    </Checkbox>
  );
};
