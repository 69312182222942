import styled from 'styled-components';

import { StyledSectionTitle } from '../style';

export const SectionTitle = styled(StyledSectionTitle)`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
`;

export const SectionHead = styled.div`
  margin-bottom: 16px;
`;
