import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import Hero from 'assets/images/hero.png';
import SuccessIcon from 'assets/images/successIcon.png';
import { TextField } from 'components';
import { PrimaryButton } from 'components/PrimaryButtonComp/style';
import { I18n } from 'components/Translation';
import AuthLayout from 'layout/auth_layout';
import { AppRoutes } from 'routes/constants';
import { ISuccess } from 'types/singIn';

import { SuccesWrapper, SuccessMessage, TextWrapper } from './styles';

export const SuccessPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation() as ISuccess;

  if (!state) {
    return <Navigate to={AppRoutes.main} />;
  }

  const { userExists, email, passwordReset } = state || {};

  return (
    <AuthLayout img={Hero}>
      <SuccesWrapper>
        <SuccessMessage>
          <img src={SuccessIcon} alt="Success" />
          <TextField variant="H3" className="titleText">
            <I18n
              tKey={
                userExists
                  ? 'title_success_magic_link_local'
                  : 'link_sent_message_first_thank_you_local'
              }
            />
          </TextField>
          <TextWrapper>
            {passwordReset ? (
              <>
                <TextField className="text" variant="P7">
                  <I18n tKey="password_recovery_short_local" />
                </TextField>
                <TextField className="text" variant="P7">
                  <I18n tKey="password_recovery_short_continue_local" /> <b>{email}.</b>
                </TextField>
              </>
            ) : (
              <>
                {userExists ? (
                  <>
                    <TextField className="text" variant="P7">
                      <I18n tKey="link_sent_message_local" /> <b>{email}.</b>
                    </TextField>
                    <TextField className="text" variant="P7">
                      <I18n tKey="click_on_your_link_local" />
                    </TextField>
                  </>
                ) : (
                  <>
                    <TextField className="text" variant="P7">
                      <I18n tKey="link_sent_message_first_local" /> <b>{email}</b>
                    </TextField>
                    <TextField className="text" variant="P7">
                      <I18n tKey="link_sent_message_first_continue_local" />
                    </TextField>
                  </>
                )}
              </>
            )}
          </TextWrapper>
          <PrimaryButton className="button" onClick={() => navigate(AppRoutes.main)}>
            <TextField variant="P7" fw="700" color="white">
              <I18n tKey="change_email" />
            </TextField>
          </PrimaryButton>
        </SuccessMessage>
      </SuccesWrapper>
    </AuthLayout>
  );
};
