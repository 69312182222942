import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { envConfig } from 'config/env';
import { toastAlert, translate } from 'helpers';
import updateToken from 'layout/updateToken';
import { useTranslation } from 'providers/translationProvider';
import { useProfileQuery, useProfileSettingsQuery } from 'queries/profile';
import { AppRoutes } from 'routes/constants';
import { getMonitoring } from 'services/monitoring';

import { TOKEN_UPDATE_INTERVAL } from './constants';

const getAuthValue = () => localStorage.getItem(envConfig.provider.supabaseApp);

/**
 * We need debounced container because component has many effects
 */
const debouncedToastAlert = debounce(toastAlert, 1000);
const debouncedMonitoring = debounce(
  (...args: any[]) => getMonitoring()?.captureException(args),
  1000,
);

export const useAuthContext = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isStep, setIsStep] = useState<boolean>(false);
  const [isRecovery, setIsRecovery] = useState<boolean>(false);
  const [isPreventRedirect, setIsPreventRedirect] = useState<boolean>(true);
  const [isAuth, setIsAuth] = useState<boolean>(!!getAuthValue());

  const {
    data: settings,
    refetch: refetchSettings,
    isLoading: isSettingsLoading,
    isFetched: isSettingsFetched,
  } = useProfileSettingsQuery({
    enabled: isAuth || location.pathname === AppRoutes.private.transferSuccess,
  });

  const {
    data,
    refetch,
    isLoading: isProfileLoading,
    isFetched: isProfileFetched,
  } = useProfileQuery({
    enabled: isAuth,
  });

  useEffect(() => {
    setIsStep(location.pathname === AppRoutes.moneySteps.senderDetails);
    window.scrollTo(0, 0);

    setIsAuth(!!getAuthValue());

    const searchParams = new URLSearchParams(location.search || location.hash);

    if (searchParams.get('type') === 'recovery') {
      setIsRecovery(true);
      setIsPreventRedirect(true);
    } else {
      setIsPreventRedirect(false);
    }
  }, [location, isAuth]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search || location.hash);

    if (searchParams.get('error') !== null) {
      debouncedToastAlert('error', translate(t, 'error_something_went_wrong'));

      debouncedMonitoring(
        searchParams.get('error_description') || translate(t, 'error_something_went_wrong'),
      );
    }
  }, [t, location]);

  useEffect(() => {
    if (isRecovery && isAuth) {
      setIsRecovery(false);
      setIsPreventRedirect(false);
      navigate(AppRoutes.private.passwordRecover);
    }
  }, [isRecovery, isAuth, navigate]);

  /**
   * force refresh the token every TOKEN_UPDATE_INTERVAL
   */
  useEffect(() => {
    updateToken();

    const timerId = setInterval(() => updateToken(), TOKEN_UPDATE_INTERVAL);

    // clean up setInterval
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    if (data) {
      getMonitoring()?.setUser({
        user: {
          id: data.id,
          email: data?.email || '',
        },
      });
    }
  }, [data]);

  return {
    isAuth,
    settings,
    isStep,
    profile: data,
    isLoading: isProfileLoading || isSettingsLoading || !isProfileFetched || !isSettingsFetched,
    isProfileLoading: isProfileLoading || !isProfileFetched,
    isSettingsLoading: isSettingsLoading || !isSettingsFetched,
    isPreventPrivateRedirect: isRecovery || isPreventRedirect,
    refetchProfileData: () => refetch(),
    refetchProfileSettings: () => refetchSettings(),
  };
};
