import styled from 'styled-components';

const Wrapper = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div:nth-child(3) {
    width: 100%;
  }
  @media (max-width: 960px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export { Wrapper, ButtonWrapper };
