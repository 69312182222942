import { createContext, FC, ReactNode, useContext } from 'react';

import { TSendMoneyStep } from 'store/slices/transaction/sender';

import { useSendMoneyStepsContext } from './useSendMoneyStepsContext';

interface SendMoneyStepsContext {
  steps: TSendMoneyStep[];
  handleNextStep: (
    params?: Record<string, string | number | string[] | number[] | unknown>,
  ) => void;
}

const Context = createContext<SendMoneyStepsContext>({
  steps: [],
  handleNextStep: () => {},
});

export const SendMoneyStepsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const state = useSendMoneyStepsContext();

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

/**
 * @return {object}
 */
export const useSendMoneySteps = () => {
  const context = useContext(Context);

  if (!context) throw new Error('useSendMoneySteps must be used within a SendMoneyStepsProvider');

  return context;
};
