import { ForwardedRef, forwardRef } from 'react';
import { Oval } from 'react-loader-spinner';

import { TextField } from 'components/TextField';
import { useAppProvider } from 'providers';

import { PrimaryButton } from './style';
import { IPrim } from './types';

export const PrimaryButtonComp = forwardRef(
  (props: IPrim, ref?: ForwardedRef<HTMLButtonElement> | null) => {
    const {
      width,
      text,
      click,
      onEnter = () => {},
      valid,
      type = 'submit',
      className,
      typeOfLoad,
      id,
    } = props;

    const { loader, loadType } = useAppProvider();

    return (
      <PrimaryButton
        className={className}
        disabled={loader || !valid}
        width={width}
        onClick={click}
        onKeyDown={onEnter}
        type={type}
        id={id}
        ref={ref}
      >
        <TextField variant="H7" color="white">
          {loader && loadType === typeOfLoad ? (
            <Oval
              height={24}
              width={24}
              color="#3848C6"
              wrapperStyle={{}}
              wrapperClass="spinner"
              visible
              ariaLabel="oval-loading"
              secondaryColor="#ABB3ED"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            text
          )}
        </TextField>
      </PrimaryButton>
    );
  },
);

PrimaryButtonComp.displayName = 'PrimaryButtonComp';
