import { useMemo } from 'react';

import { useCountriesData } from 'queries/country';

export const useCountriesContext = () => {
  const { data, isLoading } = useCountriesData();

  const sendingCountries = useMemo(() => data?.filter((country) => !!country.isSending), [data]);
  const receivingCountries = useMemo(
    () => data?.filter((country) => !!country.isReceiving),
    [data],
  );

  return { countries: data, sendingCountries, receivingCountries, isCountryListLoading: isLoading };
};
