import { FocusEvent, ReactNode, useState } from 'react';
import { Controller, FieldValues, Noop, RegisterOptions, useFormContext } from 'react-hook-form';

import { InputField } from 'components/InputField';
import { TextField } from 'components/TextField';
import { useRegister } from 'hooks';

import style from './fields.module.scss';

type Props = {
  name: string;
  options?: RegisterOptions<FieldValues, string> | undefined;
  placeholder?: string;
  label?: ReactNode;
  text?: ReactNode;
  disabled?: boolean;
  checkFields?: boolean;
  type?: string;
  className?: string;
  hint?: ReactNode;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: (value: string) => void;
  onValidate?: (e: FocusEvent<HTMLInputElement>) => boolean;
  value?: string | number;
  isFocus?: boolean;
  mask?: (string | RegExp)[];
};

export const InputFieldContainer = (props: Props) => {
  const { watch, register, formState } = useFormContext();
  const {
    placeholder,
    className,
    type = 'text',
    label,
    isFocus = false,
    checkFields,
    disabled: preventDisabled,
    value,
    onChange,
    onValidate,
    onFocus,
    onBlur,
    mask,
    text,
    name,
    hint,
    options = {},
  } = props;

  const [isFocused, setFocus] = useState<boolean>(isFocus);

  const { errors, touchedFields } = formState;

  const field = useRegister({
    ...register(name, options),
    errors,
    touchedFields,
    withRef: false,
  });

  const { disabled } = field;
  /**
   * Это свойство нужно оборачивать в отслеживаемое
   * _
   * Если так не делать, то из-за вложенности компонентов может случится такое,
   * что formState.isDirty не будет изменяться по мере изменений в полях ввода
   */
  const watchedValue = watch(field.name);
  const finalValue = value ?? watchedValue;

  const handleFocus = () => {
    onFocus?.();

    setFocus(true);
  };

  const handleBlur = (defaultOnBlur: Noop) => (e: FocusEvent<HTMLInputElement, Element>) => {
    onBlur?.(e.target.value);
    defaultOnBlur?.();

    setFocus(false);
  };

  return (
    <Controller
      {...field}
      render={({ field: { onChange: defaultOnChange, onBlur: defaultOnBlur } }) => (
        <>
          <InputField
            // ref={ref as RefCallBack | undefined}
            name={field.name ?? ''}
            type={type}
            className={className}
            value={finalValue}
            onChange={(e: FocusEvent<HTMLInputElement>) => {
              const { value } = e.target ?? {};
              let valid = true;

              if (typeof onValidate !== 'undefined') {
                valid = onValidate?.(e);
              }

              if (valid) {
                onChange?.(value);
                defaultOnChange(e);
              }
            }}
            onFocus={handleFocus}
            onBlur={handleBlur(defaultOnBlur)}
            placeholder={placeholder}
            disabled={preventDisabled || disabled}
            error={
              (field.error && (field.isTouched || checkFields)) || (!watchedValue && checkFields)
            }
            errorMessage={field.helperText}
            text={text}
            hint={hint}
            dateMask={mask}
          />
          {label && (
            <label className={style.label}>
              <TextField
                className={!!watchedValue || isFocus || isFocused ? style['on-focus'] : ''}
                variant="P7"
              >
                {label}
              </TextField>
            </label>
          )}
        </>
      )}
    />
  );
};

InputFieldContainer.displayName = 'InputFieldContainer';
