import styled from 'styled-components';

const ModalBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #222229;
  opacity: 0.5;
  z-index: 1;
`;

const CardWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 440px;
  height: 232px;
  background-color: #ffffff;
  border-radius: 8px;
  z-index: 2;

  @media (max-width: 406px) {
    height: 266px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 91%;
  padding: 50px 0 0 0;
  & > :first-child {
    width: 96px;
    border: 1px solid #8994e6;
    background: #fff;
  }
  & > :last-child {
    width: 96px;
    border: none;
    background: #3848c6;
    > div {
      color: #fff;
    }
  }
`;

const Button = styled.button`
  height: 56px;
  width: 96px;
  display: flex;
  align-items: center;
  align-content: center;
  border: 1px solid red;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-family: Commissioner-Regular;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  .cancel-text {
    margin: 0 auto;
  }
  @media (max-width: 647px) {
    height: 56px;
  }
  :hover {
    color: #4858cf;
    opacity: 0.9;
    border: 1px solid #3848c6;
  }
`;

const CardTypeWrapper = styled.div`
  > img {
    margin-right: 15px;
  }
`;

export {
  Card,
  CardWrapper,
  FlexContainer,
  ButtonsWrapper,
  ModalBackground,
  Button,
  CardTypeWrapper,
};
