import { useMutation } from 'react-query';

import { translate } from 'helpers';
import { useTranslation } from 'providers';
import { getResourceManager } from 'resources';

import { Props } from './types';

export const usePhoneCheckMutation = (props?: Props) => {
  const { t } = useTranslation();
  const { onSuccess, onError, onSettled, definedPhone } = props || {};

  const handlePhoneIsEmpty = useMutation(
    async (phone: string) => {
      /**
       * If user already have phone number, we don't need to check this phone
       */
      if (definedPhone) {
        return false;
      }

      if (!phone) {
        throw new Error(translate(t, 'error_something_went_wrong'));
      }

      const isUsed = await getResourceManager().user.profile.checkPhoneIsEmpty(phone);

      if (isUsed) {
        throw new Error(translate(t, 'error_phone_already_use'));
      }

      return isUsed;
    },
    {
      onSuccess,
      onError,
      onSettled,
    },
  );

  return { handlePhoneIsEmpty };
};
