import { Link } from 'react-router-dom';

import { LinkWrapper } from './style';
import { ILink } from './types';

export const StyledLink = (props: ILink) => {
  const { url, children, isExternal, isMenuItem = false } = props;
  return (
    <LinkWrapper>
      {isExternal ? (
        <a
          href={url}
          target="_blank"
          style={{ width: isMenuItem ? '100%' : 'auto' }}
          rel="noreferrer"
        >
          {children}
        </a>
      ) : (
        <Link to={url} style={{ width: isMenuItem ? '100%' : 'auto' }}>
          {children}
        </Link>
      )}
    </LinkWrapper>
  );
};
