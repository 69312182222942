import { connection } from 'config';
import { SupabaseDataProvider } from 'services';
import { Fetcher } from 'utils';

import { AuthService } from './auth';
import { CardService } from './card';
import { CountryService } from './countries';
import { CurrencyService } from './currency';
import { OptionService } from './option';
import { QuestionService } from './question';
import { TransactionService } from './transaction';
import { TranslationService } from './translation';
import { ProfileService, UserSettingsService } from './user';

type ResourceManager = {
  auth: AuthService;
  question: QuestionService;
  translation: TranslationService;
  user: {
    profile: ProfileService;
    settings: UserSettingsService;
  };
  card: CardService;
  transaction: TransactionService;
  currency: CurrencyService;
  options: OptionService;
  country: CountryService;
};

let __manager: ResourceManager | undefined;

export const initResources = () => {
  const provider = new SupabaseDataProvider();
  const auth = new AuthService(provider);
  const handleLogout = auth.signOut.bind(auth);
  const fetcher = new Fetcher(connection.baseURL, handleLogout);
  const profile = new ProfileService(fetcher);
  const settings = new UserSettingsService(fetcher);
  const card = new CardService(fetcher);
  const question = new QuestionService(provider);
  const transaction = new TransactionService(fetcher);
  const currency = new CurrencyService(fetcher);
  const options = new OptionService(fetcher);
  const translation = new TranslationService(fetcher);
  const country = new CountryService(fetcher);

  __manager = {
    auth,
    question,
    user: {
      profile,
      settings,
    },
    card,
    transaction,
    currency,
    options,
    translation,
    country,
  };
};

export const getResourceManager = () => {
  if (!__manager) {
    throw new Error('Resource manager was not initialized!');
  }

  return __manager;
};
