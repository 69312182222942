export enum LoadingStatus {
  INIT = 'INIT',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export const loadingInitialData: LoadingData | undefined = {
  isLoaded: false,
  isLoading: false,
  isError: false,
  status: LoadingStatus.INIT,
};

export type LoadingData = {
  isLoaded: boolean;
  isLoading: boolean;
  isError: boolean;
  status: LoadingStatus;
};
