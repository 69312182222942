import { ChangeEvent, useState } from 'react';

import { InputField } from 'components/InputField';
import { PrimaryButtonComp } from 'components/PrimaryButtonComp';
import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';
import { CONSTANTS } from 'config';
import { dataEntryAccess, translate } from 'helpers';
import { IErrorData } from 'pages/steps/PhoneVerification/types';
import { useAppProvider, useTranslation } from 'providers';
import { getResourceManager } from 'resources';

import {
  PopUpWrapper,
  Wrapper,
  UnderLine,
  InputContainer,
  InputFields,
  SecondaryButton,
  ButtonsWrapper,
} from './style';

interface PropsTypes {
  setIsOpenPopup: (value: boolean) => void;
  errorMessage: IErrorData;
  setErrorMessage: (value: IErrorData) => void;
  onSubmit: () => void;
}

const CODE_LENGTH = 4;

export const TwoFactorAuthentication = ({
  setIsOpenPopup,
  errorMessage,
  setErrorMessage,
  onSubmit,
}: PropsTypes) => {
  const { setLoader, loadType, setLoadType } = useAppProvider();
  const { t } = useTranslation();

  const [inputCode, setInputCode] = useState<string>('');

  const handleCancel = () => {
    setIsOpenPopup(false);
    setInputCode('');
  };

  const handleSubmit = () => {
    setLoader(true);
    setLoadType(CONSTANTS.PHONE);
    getResourceManager()
      .user.profile.codeVerify({
        code: inputCode,
      })
      .then(() => {
        onSubmit?.();
        setIsOpenPopup(false);
        setLoader(false);
      })
      .catch(() => {
        setErrorMessage({
          isError: true,
          message: translate(t, 'wrong_verification_code'),
        });
      })
      .finally(() => setLoader(false));
  };

  return (
    <PopUpWrapper>
      <Wrapper>
        <TextField variant="H8" bottom="20" top="20" left="20" right="20">
          <I18n tKey="enter_your_code" />
        </TextField>

        <UnderLine />

        <TextField variant="P7" bottom="0" top="20" left="20" right="20" className="two_factor">
          <I18n tKey="code_sent" />
        </TextField>

        <TextField variant="P7" color="e500" left="20">
          {errorMessage.message}
        </TextField>

        <InputContainer>
          <InputFields>
            <InputField
              text={<I18n tKey="enter_code" />}
              value={inputCode}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dataEntryAccess(e, 4) && setInputCode(e.target.value);
              }}
              className="input-code"
            />
          </InputFields>
        </InputContainer>

        <UnderLine />

        <ButtonsWrapper>
          <SecondaryButton
            type="button"
            className="cancel-btn"
            width={CONSTANTS.HUNDREED_PERCENT}
            onClick={handleCancel}
          >
            <TextField className="cancel-text" variant="H7" color="p500">
              <I18n tKey="cancel" />
            </TextField>
          </SecondaryButton>
          <PrimaryButtonComp
            width=""
            type="button"
            text={<I18n tKey="submit" />}
            click={handleSubmit}
            valid={inputCode.length === CODE_LENGTH && !errorMessage?.isError}
            typeOfLoad={loadType}
          />
        </ButtonsWrapper>
      </Wrapper>
    </PopUpWrapper>
  );
};
