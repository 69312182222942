import { createSelector } from '@reduxjs/toolkit';

import { rootSelector } from '../../rootSelector';

const state = createSelector(rootSelector, (state) => state.cards);

export const formDataSelector = createSelector(state, ({ isAutoSubmit, formData, isHidden }) => ({
  isAutoSubmit,
  formData,
  isHidden,
}));
