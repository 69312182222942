import { useRef } from 'react';

import { TextField } from 'components/TextField';

import { MessageContainer, Arrow, Box } from './styles';
import { IProps } from './types';

export const MessageBox = ({ text, className, getHeight }: IProps) => {
  const myRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  myRef.current && getHeight?.(myRef?.current?.clientHeight);
  return (
    <Box ref={myRef} className={className}>
      <MessageContainer className="container">
        <TextField className="text-container" variant="P14">
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </TextField>
      </MessageContainer>
      <Arrow className="arrow" />
    </Box>
  );
};
