import { useMutation } from 'react-query';

import { getResourceManager } from 'resources';
import { TranslationUpdatePayload } from 'resources/translation';

export const useTranslationUpdate = () => {
  const update = useMutation((payload: TranslationUpdatePayload) => {
    return getResourceManager().translation.update(payload);
  });

  return update;
};
