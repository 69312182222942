import { useMutation } from 'react-query';

import { translate } from 'helpers';
import { useTranslation } from 'providers';
import { getResourceManager } from 'resources';

import { Props } from './types';

export const usePhoneVerifyMutation = (props?: Props) => {
  const { t } = useTranslation();
  const { onSuccess, onError, onSettled } = props || {};

  const handlePhoneVerify = useMutation(
    async (phone: string) => {
      if (!phone) {
        throw new Error(translate(t, 'error_something_went_wrong'));
      }

      return getResourceManager().user.profile.phoneVerify({
        phone,
      });
    },
    {
      onSuccess,
      onError,
      onSettled,
    },
  );

  return { handlePhoneVerify };
};
