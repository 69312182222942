import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import { TextField, PrimaryButtonComp } from 'components';
import { InfoTips } from 'components/InfoTips';
import { I18n } from 'components/Translation';
import { InputFieldContainer } from 'components/fields';
import { CONSTANTS } from 'config';
import { translate, toastAlert } from 'helpers';
import { useAppProvider, useTranslation } from 'providers';
import { getResourceManager } from 'resources';
import { AppRoutes } from 'routes/constants';
import { getMonitoring } from 'services/monitoring';

import { Line } from '../styles';

import {
  Wrapper,
  Content,
  ButtonWrapper,
  TitleBox,
  InputsContainer,
  InputContainer,
  Container,
} from './styles';
import { RecoverFormProps } from './types';

export const PasswordRecover = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { setLoader, loadType, setLoadType } = useAppProvider();

  const validationsSchema = yup.object().shape({
    password: yup
      .string()
      .matches(CONSTANTS.REGEXP.PASSWORD, translate(t, 'password_validation_error'))
      .typeError('Must be string'),
    passwordConfirmation: yup.string().oneOf([yup.ref('password')], `${translate(t, 'no_match')}`),
  });

  const form = useForm<RecoverFormProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationsSchema),
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  const { handleSubmit: onSubmit, formState } = form;
  const { isValid } = formState;

  const handleSubmit = async (values: RecoverFormProps) => {
    setLoader(true);
    setLoadType('Save');
    getResourceManager()
      .user.profile.resetPassword({
        password: values.password,
        password_confirmation: values.passwordConfirmation,
      })
      .then(async () => {
        toastAlert('success', translate(t, 'password_successfully_recovered'));
        navigate(AppRoutes.private.personal);
      })
      .catch((e) => {
        getMonitoring()?.captureException(e);

        const isEqualError =
          (e.response?.data?.message || e?.message || '').indexOf(
            'password should be different from the old',
          ) > -1;

        const errorMessage = isEqualError
          ? translate(t, 'password_equality_error')
          : translate(t, 'error_something_went_wrong');

        toastAlert('error', errorMessage);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={onSubmit(handleSubmit)} noValidate>
          <Wrapper>
            <Content>
              <InputsContainer>
                <TitleBox>
                  <TextField variant="H3">
                    <I18n tKey="password_recovery" />
                  </TextField>
                  <TextField className="subtitle" variant="P7">
                    <I18n tKey="enter_your_new_password" />
                  </TextField>
                </TitleBox>

                <InputContainer>
                  <InputFieldContainer
                    name="password"
                    type="password"
                    className="password"
                    label={<I18n tKey="new_password" />}
                  />
                </InputContainer>
                <InputContainer>
                  <InputFieldContainer
                    name="passwordConfirmation"
                    type="password"
                    className="passwordConfirmation"
                    label={<I18n tKey="confirm_password" />}
                  />
                </InputContainer>
                <Container>
                  <InfoTips message={translate(t, 'password_hint_message_1')} isPreLine>
                    <TextField variant="P13" color="n800">
                      <I18n tKey="password_hint" />
                    </TextField>
                  </InfoTips>
                </Container>
              </InputsContainer>

              <ButtonWrapper>
                <Line />
                <PrimaryButtonComp
                  className="button"
                  width={CONSTANTS.HUNDREED_PERCENT}
                  text={<I18n tKey="save" />}
                  valid={isValid}
                  typeOfLoad={loadType}
                />
              </ButtonWrapper>
            </Content>
          </Wrapper>
        </form>
      </FormProvider>
    </>
  );
};
