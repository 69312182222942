import { AxiosResponse } from 'axios';

import { Response } from 'resources/types';
import { Fetcher } from 'utils';

import { ENDPOINTS } from './endpoints';
import { TPurposePayment } from './types';

export class OptionRepo {
  private fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  public async getPurposes() {
    const { data } = await this.fetcher.fetch<unknown, Response<AxiosResponse<TPurposePayment[]>>>({
      url: ENDPOINTS.purposes,
      method: 'GET',
    });

    return data.data;
  }

  public async getFounds() {
    const { data } = await this.fetcher.fetch<unknown, Response<AxiosResponse<TPurposePayment[]>>>({
      url: ENDPOINTS.founds,
      method: 'GET',
    });

    return data.data;
  }
}
