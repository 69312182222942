import { envConfig } from './env';

export const connection = {
  baseURL: `${envConfig.fmpayServiceBaseUrl}/api/`,
  /**
   * @deprecated you must create endpoints file inside resource service
   */
  routes: {
    exchange: 'exchange',
    setCard: 'card/authorize',
    updateProfile: 'user/update-profile',
    checkUser: 'user/check-user',
  },
};
