import { ReactNode } from 'react';
import { Controller, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';

import { RandomSelector } from 'components/RandomSelector';
import { ISelector } from 'components/RandomSelector/types';
import { useRegister } from 'hooks';

type Props = {
  name: string;
  options?: RegisterOptions<FieldValues, string> | undefined;
  placeholder?: string;
  label?: ReactNode;
  preventDisabled?: boolean;
  checkFields?: boolean;
  disabled?: boolean;
  type?: string;
  className?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  data?: ISelector['data'];
  value?: string | number;
  isFocus?: boolean;
};

export const RandomSelectorFieldContainer = (props: Props) => {
  const { watch, register, formState } = useFormContext();
  const {
    placeholder,
    className,
    type = 'number',
    label,
    checkFields,
    disabled: preventDisabled,
    value,
    onChange,
    onBlur,
    data,
    name,
    options = {},
  } = props;

  const { errors, touchedFields } = formState;

  const field = useRegister({
    ...register(name, options),
    errors,
    touchedFields,
    withRef: false,
  });

  const { disabled } = field;
  /**
   * Это свойство нужно оборачивать в отслеживаемое
   * _
   * Если так не делать, то из-за вложенности компонентов может случится такое,
   * что formState.isDirty не будет изменяться по мере изменений в полях ввода
   */
  const watchedValue = watch(field.name);
  const finalValue = value ?? watchedValue;

  return (
    <Controller
      {...field}
      render={({ field: { onChange: defaultOnChange, onBlur: defaultOnBlur } }) => (
        <>
          <RandomSelector
            name={field.name}
            className={className}
            title={label}
            data={data}
            value={finalValue || ''}
            inputType={type}
            onChange={(value: string) => {
              defaultOnChange(value);
              onChange?.(value);
            }}
            onBlur={() => {
              defaultOnBlur();
              onBlur?.();
            }}
            disabled={preventDisabled || disabled}
            error={(field.error && field.isTouched) || (!watchedValue && checkFields)}
            placeholder={placeholder}
          />
        </>
      )}
    />
  );
};

RandomSelectorFieldContainer.displayName = 'RandomSelectorFieldContainer';
