import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Close } from 'assets/images';
import { Portal } from 'components/Modal';
import { Content, PopUpWrapper, Title } from 'components/PopUp/styles';
import { PrimaryButtonComp } from 'components/PrimaryButtonComp/PrimaryButtonComp';
import { PrimaryButton } from 'components/PrimaryButtonComp/style';
import { TextField } from 'components/TextField';
import { InputFieldContainer } from 'components/fields';
import { toastAlert, translate } from 'helpers';
import { InputContainer } from 'pages/Personal/styles';
import { useTranslation } from 'providers/translationProvider/useTranslation';
import { useTranslationUpdate } from 'queries/translation';
import { translation } from 'utils/Translation';

import classes from './translation.module.scss';

export const TranslationPopup = () => {
  const { isModalOpen, currentKey, toggleModal, t, handleRefetch } = useTranslation();
  const handleUpdateTranslation = useTranslationUpdate();

  const translationProps = useMemo(
    () => translation.getTranslationsPerKey(currentKey),
    [currentKey],
  );

  const form = useForm<{ value: string }>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: { value: '' },
  });

  const { formState, handleSubmit: onSubmit, reset } = form;
  const { isDirty } = formState;

  const handleCancel = useCallback(() => {
    toggleModal();
    reset();
  }, [reset, toggleModal]);

  useEffect(() => {
    if (!isModalOpen) {
      handleCancel();
    }
  }, [isModalOpen, handleCancel]);

  const handleSubmit = useCallback(
    async (values: { value: string }) => {
      const { value } = values;

      const response = await handleUpdateTranslation.mutateAsync({ value, key: currentKey });

      if (response?.id) {
        toastAlert('success', translate(t, 'translation_successfully_updated'));
        handleRefetch?.();
        handleCancel();

        return;
      }

      toastAlert('error', translate(t, 'error_something_went_wrong_try_later'));
    },
    [currentKey, handleCancel, handleRefetch, handleUpdateTranslation, t],
  );

  return (
    <>
      {isModalOpen && (
        <Portal>
          <PopUpWrapper>
            <Content>
              <Title>
                <TextField variant="H6" color="n900">
                  {translate(t, 'edit_locale')}
                </TextField>
                <Close className="close-icon" onClick={() => toggleModal()} />
              </Title>
              <div className={classes['body']}>
                <div className={classes['row']}>
                  <div className={classes['field']}>
                    <TextField variant="P7">{translate(t, 'current_key')}:</TextField>
                  </div>
                  <div className={classes['field']}>
                    <TextField variant="P11">{currentKey}</TextField>
                  </div>
                </div>
                <div className={classes['row']}>
                  <div className={classes['field']}>
                    <TextField variant="P7">{translate(t, 'local_key_value')}:</TextField>
                  </div>
                  <div className={classes['field']}>
                    <TextField variant="P11">{translationProps.local}</TextField>
                  </div>
                </div>
                {translationProps.localInterpolations && (
                  <div className={classes['row']}>
                    <div className={classes['field']}>
                      <TextField variant="P11">{translate(t, 'interpolations')}:</TextField>
                    </div>
                    <div className={classes['field']}>
                      <TextField variant="P11">
                        {translationProps.localInterpolations?.join(', ')}
                      </TextField>
                    </div>
                  </div>
                )}
                <div className={classes['row']}>
                  <div className={classes['field']}>
                    <TextField variant="P7">{translate(t, 'remote_key_value')}:</TextField>
                  </div>
                  <div className={classes['field']}>
                    <TextField variant="P11">{translationProps.remote}</TextField>
                  </div>
                </div>
                {translationProps.remoteInterpolations && (
                  <div className={classes['row']}>
                    <div className={classes['field']}>
                      <TextField variant="P11">{translate(t, 'interpolations')}:</TextField>
                    </div>
                    <div className={classes['field']}>
                      <TextField variant="P11">
                        {translationProps.remoteInterpolations?.join(', ')}
                      </TextField>
                    </div>
                  </div>
                )}

                <div className={classes['form_title']}>
                  <TextField variant="P7">{translate(t, 'update_locale_form_title')}</TextField>
                </div>
                <FormProvider {...form}>
                  <form onSubmit={onSubmit(handleSubmit)} noValidate className={classes['form']}>
                    <InputContainer>
                      <InputFieldContainer
                        name="value"
                        type="text"
                        label={translate(t, 'remote_key_value')}
                      />
                    </InputContainer>

                    <div className={classes['buttons']}>
                      <PrimaryButton width="84px" onClick={handleCancel}>
                        {translate(t, 'cancel')}
                      </PrimaryButton>
                      <PrimaryButtonComp
                        type="submit"
                        text={translate(t, 'save_changes')}
                        valid={isDirty}
                        // TODO: добавить load состояние
                      />
                    </div>
                  </form>
                </FormProvider>
              </div>
            </Content>
          </PopUpWrapper>
        </Portal>
      )}
    </>
  );
};
