/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { CardData, CardDataResponse } from './types';

export class CardModel {
  public getUiCardModelFromResponse(card: CardDataResponse): CardData {
    const { created_at, is_default, is_saved, user_id, ...otherProps } = card;

    return {
      createdAt: created_at,
      isDefault: is_default,
      isSaved: is_saved,
      userId: user_id,
      ...otherProps,
    };
  }
}
