import styled, { css } from 'styled-components';
import { IText } from '../../pages/StepPreview/types';
import { switchProp } from 'styled-tools';

interface UploadedFile {
  uploadedFile: boolean;
}

export const FileCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 600px;
  height: 334px;
  background: #ffffff;
  border: 1px dashed #a3a5af;
  border-radius: 8px;

  &.upload-failed {
    background-color: #fff7f7;
    border-color: #ff3333;
    color: #ff5c5c;

    .upload-failed {
      color: #ff5c5c;
    }
  }

  @media (max-width: 647px) {
    width: 328px;
    height: 354px;
  }
`;

export const MarginDiv = styled.div<IText>`
  ${switchProp('variant', {
    M24: css`
      margin-bottom: 24px;
    `,

    M8: css`
      margin-bottom: 8px;
    `,
    M16: css`
      margin-bottom: 16px;
    `,
  })}
`;

export const DescriptionWrapper = styled.div`
  width: 350px;

  @media (max-width: 647px) {
    width: 296px;
  }
`;

export const UploadButton = styled.button`
  width: 120px;
  height: 40px;
  border: 1px solid #8994e6;
  border-radius: 6px;
  background-color: transparent;
  font-size: 16px;
  line-height: 24px;
  color: #3848c6;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.2px;
  cursor: pointer;
`;

export const UploadWrapper = styled.div<UploadedFile>`
  position: relative;
  color: ${({ uploadedFile }) => uploadedFile && '#afafaf'};

  > div {
    > div {
      > div {
        color: ${({ uploadedFile }) => uploadedFile && '#afafaf'};

        > div {
          color: ${({ uploadedFile }) => uploadedFile && '#afafaf'};
        }
      }

      > button {
        border: ${({ uploadedFile }) => uploadedFile && '1px solid #afafaf'};

        > div {
          color: ${({ uploadedFile }) => uploadedFile && '#afafaf'};
        }
      }
    }
  }
`;

export const UploadCower = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1;
  border-radius: 5px;
`;
