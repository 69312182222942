import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store';

import { Icon, HiddenCheckbox, StyledCheckbox, CheckboxContainer } from './styles';
import { IRound } from './types';

export const RoundCheckbox = ({
  className,
  active,
  setIndex,
  handleActiveIndex,
  cardId,
}: IRound) => {
  const [checked, setChecked] = useState<boolean>(false);
  const chengeChecked = (isDefault: boolean) => setChecked(!isDefault);

  const selectedCardId = useSelector(
    (state: RootState) => state?.senderCard?.data?.senderDetailsCard?.dataId,
  );
  useEffect(() => {
    selectedCardId === cardId ? setChecked(false) : setChecked(true);
  }, [active, cardId, selectedCardId]);
  return (
    <CheckboxContainer
      onClick={() => {
        chengeChecked(checked);
        handleActiveIndex(setIndex);
      }}
      className={className}
    >
      <HiddenCheckbox defaultChecked={checked} />
      <StyledCheckbox checked={selectedCardId === cardId}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};
