import { AppRoutes } from 'routes/constants';

import { TSendMoneyStep } from './sender.types';

export const DEFAULT_SEND_MONEY_STEPS: TSendMoneyStep[] = [
  {
    path: AppRoutes.moneySteps.sendMoney,
  },
  {
    path: AppRoutes.moneySteps.senderDetails,
  },
  {
    path: AppRoutes.moneySteps.phoneVerification,
  },
  {
    path: AppRoutes.moneySteps.cardVerification,
  },
  {
    path: AppRoutes.moneySteps.recipientDetails,
  },
  {
    path: AppRoutes.moneySteps.approveTransaction,
  },
];
