import styled from 'styled-components';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  * {
    width: 100%;
  }
`;

const StyledInputContainer = styled.div<{
  width?: string;
  isDisabled?: boolean;
}>`
  width: ${({ width }) => width};
  height: 72px;
  background-color: ${({ isDisabled }) => isDisabled && '#F0F0F2'};
  border-radius: 8px;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: 0px;
  > * {
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }
  }
`;

const ErrorBox = styled.div`
  max-width: fit-content;
`;

const StyledInput = styled.input<{ error?: boolean }>`
  border: none;
  color: #222229;
  font-size: 16px;
  background: transparent;
  width: calc(100% - 32px);
  font-family: 'Lato-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222229;
  :-webkit-autofill {
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }
  }
  ::placeholder {
    color: ${({ error }) => (error ? '#FF5C5C' : '#A3A5AF')};
  }

  :-ms-input-placeholder {
    color: ${({ error }) => (error ? '#FF5C5C' : '#A3A5AF')};
  }

  ::-ms-input-placeholder {
    color: ${({ error }) => (error ? '#FF5C5C' : '#A3A5AF')};
  }
  :focus {
    outline: none;
  }
  :disabled {
    color: #6e6f78;
    -webkit-text-fill-color: #6e6f78;
    opacity: 1;
  }
  :-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
`;

const StyledCurrency = styled.p`
  text-align: end;
  margin-top: 11px;
  align-items: center;
  color: #222229;
`;

export {
  StyledInputContainer,
  InputContainer,
  StyledInput,
  StyledCurrency,
  InputWrapper,
  ErrorBox,
};
