import { connection } from 'config';
import { IPostCardData, IOrderId } from 'pages/steps/type/transaction';
import { IExchangeData } from 'types/exchange';

import { axiosInstance } from './axiosInstance';

export const TransactionApi = {
  getExchange: async (data: IExchangeData) => {
    return axiosInstance.post(`${connection.routes.exchange}`, data);
  },
  setCardURL: async (data: IPostCardData) => {
    return axiosInstance.post('card/authorize', data);
  },
  setOrderIdURL: async (id: IOrderId) => {
    return axiosInstance.post('card/authorize/complete', id);
  },
};
