import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const LinkWrapper = styled.div`
  > a {
    text-decoration: none;
  }
`;

export const SimpleLink = styled(Link)`
  text-decoration: none;
`;
