import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import IdentityCheck from 'assets/icons/IdentityCheck.svg';
import IncreasePage from 'assets/icons/IncreasePage.svg';
import ThankYouIcon from 'assets/icons/ThankYouIcon.svg';
import { PageAnimation, TransferDenied, TransferSuccess } from 'components';
import DefaultLayout from 'layout/defaultLayout';
import {
  SignUp,
  SignIn,
  ResetPassword,
  ComplaintsPolicy,
  ApproveTransaction,
  DocumentConfirmation,
  MyCards,
  PasswordRecover,
  Personal,
  PhoneVerification,
  RecipientDetailsContainer,
  SenderDetailsContainer,
  SendMoney,
  StepPreview,
  SuccessPage,
  TransactionHistory,
} from 'pages';
import { CardDetails } from 'pages/steps';
import { useAuth } from 'providers';
import { PrivateRoute } from 'routes';
import { AppRoutes, RoutesNameByPath } from 'routes/constants';
import { getAnalytics } from 'services/analytics';
import { DEFAULT_PAGE_ENTRANCE } from 'services/analytics/actions';

function Router() {
  const { isAuth, isStep } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location || {};

    if (pathname) {
      const pageName = RoutesNameByPath?.[pathname];

      const payload = {
        ...DEFAULT_PAGE_ENTRANCE,
        label: pageName || pathname,
      };

      getAnalytics().event(payload);
    }
  }, [location]);

  return (
    <>
      <div>
        <DefaultLayout isAuth={isAuth} isStep={isStep}>
          <AnimatePresence>
            <Routes location={location} key={location.pathname}>
              <Route
                path={AppRoutes.moneySteps.sendMoney}
                element={
                  <PageAnimation extra>
                    <PrivateRoute>
                      <SendMoney />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.private.myCards}
                element={
                  <PrivateRoute>
                    <PageAnimation>
                      <MyCards />
                    </PageAnimation>
                  </PrivateRoute>
                }
              />
              <Route
                path={AppRoutes.private.transferSuccess}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <TransferSuccess />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.private.passwordRecover}
                element={
                  <PageAnimation>
                    <PasswordRecover />
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.private.transferDenied}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <TransferDenied errorType={2} />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.private.transferDeclined}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <TransferDenied errorType={1} />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.private.history}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <TransactionHistory />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.private.personal}
                element={
                  <PageAnimation extra>
                    <PrivateRoute>
                      <Personal />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.moneySteps.senderDetails}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <SenderDetailsContainer />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.moneySteps.cardVerification}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <CardDetails />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.moneySteps.phoneVerification}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <PhoneVerification />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.moneySteps.recipientDetails}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <RecipientDetailsContainer />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.moneySteps.approveTransaction}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <ApproveTransaction />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.private.increaseMonthlyLimitWithIdAndRecheckId}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <DocumentConfirmation
                        typeId="1"
                        stepCount={1}
                        stepDescription="personal_data"
                        title="id_confirmation"
                        description="id_upload_identity_notification"
                      />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.private.increaseMonthlyLimitWithId}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <DocumentConfirmation
                        typeId="1"
                        stepCount={1}
                        stepDescription="personal_data"
                        title="id_confirmation"
                        description="id_upload_identity_notification"
                        routeTo={AppRoutes.private.increaseMonthlyLimitWithAddress}
                      />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.private.increaseMonthlyLimitWithAddressAndRecheckId}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <DocumentConfirmation
                        typeId="2"
                        stepCount={2}
                        stepDescription="proof_of_address"
                        title="address_verification"
                        description="id_upload_documents_notification"
                      />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.private.increaseMonthlyLimitWithAddress}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <DocumentConfirmation
                        typeId="2"
                        stepCount={2}
                        stepDescription="proof_of_address"
                        title="address_verification"
                        description="id_upload_documents_notification"
                        routeTo={AppRoutes.private.increaseMonthlyLimitWithZsPassed}
                      />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              {/*
              <Route
                path={AppRoutes.private.increaseMonthlyLimitWithSofPassedAndRecheckId}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <DocumentConfirmation
                        typeId="3"
                        stepCount={3}
                        stepDescription="source_of_funds"
                        title="source_of_funds"
                        description="upload_any_documents"
                      />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.private.increaseMonthlyLimitWithSofPassed}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <DocumentConfirmation
                        typeId="3"
                        stepCount={3}
                        stepDescription="source_of_funds"
                        title="source_of_funds"
                        description="upload_any_documents"
                        route_to={AppRoutes.private.increaseMonthlyLimitWithZsPassed}
                      />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              */}
              <Route
                path={AppRoutes.private.increaseMonthlyLimitWithZsPassedAndRecheckId}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <StepPreview
                        stepCount={3}
                        stepDescription="selfie_check_local"
                        withStepHeader
                        image={IdentityCheck}
                        title="identity_check_local"
                        buttonName="take_selfie"
                      />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.private.increaseMonthlyLimitWithZsPassed}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <StepPreview
                        stepCount={3}
                        stepDescription="selfie_check_local"
                        withStepHeader
                        image={IdentityCheck}
                        title="identity_check_local"
                        buttonName="take_selfie"
                        routeTo={AppRoutes.main}
                      />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.private.increaseMonthlyLimitThankYou}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <StepPreview
                        image={ThankYouIcon}
                        title="thank_you"
                        description="provide_the_necessary_information"
                        subDescription="verification_process_time"
                        buttonName="close"
                        routeTo={AppRoutes.main}
                      />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path={AppRoutes.private.increaseMonthlyLimit}
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <StepPreview
                        image={IncreasePage}
                        title="increase_your_monthly_limit"
                        description="please_take_5_minutes_to_enter_your_personal_data"
                        buttonName="verify_now"
                        routeTo={AppRoutes.private.increaseMonthlyLimitWithId}
                      />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route
                path="/go-to-profile"
                element={
                  <PageAnimation>
                    <PrivateRoute>
                      <StepPreview
                        image={IncreasePage}
                        title="go_to_profile_title"
                        description="go_to_profile_description"
                        buttonName="go_to_profile_button"
                        routeTo={AppRoutes.private.personal}
                      />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
              <Route path={AppRoutes.public.signIn} element={<SignIn />} />
              <Route path={AppRoutes.public.signUp} element={<SignUp />} />
              <Route path={AppRoutes.public.forgetPass} element={<ResetPassword />} />
              <Route path={AppRoutes.public.success} element={<SuccessPage />} />
              {/* TODO: privacy, terms, policy need to animated routes (but without auth) */}
              <Route path={AppRoutes.public.complaintsPolicy} element={<ComplaintsPolicy />} />
              <Route
                path="/*"
                element={
                  <PageAnimation extra>
                    <PrivateRoute>
                      <SendMoney />
                    </PrivateRoute>
                  </PageAnimation>
                }
              />
            </Routes>
          </AnimatePresence>
        </DefaultLayout>
      </div>
    </>
  );
}

export default Router;
