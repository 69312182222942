import { StepPagesPropsType } from './types';
import { StyledPage, StyledContent } from '../styles';
import { StyledTitle, StyledSubtitle, StyledSmallTilte } from './styles';

export const PrototypeOfStepPages = (props: StepPagesPropsType) => {
  const { title, smallTitle, subtitle, children, isAlignLeftPage, className } = props;
  return (
    <>
      <StyledPage className={className}>
        <StyledContent isAlignLeftPage={isAlignLeftPage}>
          {title ? (
            <StyledTitle isAlignLeftPage={isAlignLeftPage}>{title}</StyledTitle>
          ) : (
            <StyledSmallTilte>{smallTitle}</StyledSmallTilte>
          )}
          <StyledSubtitle isAlignLeftPage={isAlignLeftPage}>{subtitle}</StyledSubtitle>
          {children}
        </StyledContent>
      </StyledPage>
    </>
  );
};
