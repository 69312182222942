import { TUserSettings } from 'types/settings';
import { Fetcher } from 'utils';

import { ENDPOINTS } from './endpoints';

export class UserSettingsRepo {
  private fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  public async getSettings() {
    const { data } = await this.fetcher.fetch<null, { data: { data: TUserSettings } }>({
      url: `${ENDPOINTS.settings}`,
      method: 'GET',
    });

    return data?.data;
  }
}
