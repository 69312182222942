import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Logo, Burger, ProfileIcon, SupportIcon, LogoutIcon } from 'assets/images';
import { Dropdown } from 'components/Dropdown';
import { StepHeader } from 'components/StepHeader';
import { StyledLink } from 'components/StyledLink';
import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';
import { translate } from 'helpers';
import { useOnClickOutside } from 'hooks';
import { useTranslation } from 'providers';
import { AppRoutes, ExternalLinks } from 'routes/constants';
import { RootState } from 'store';
import { userInfoLoadedSelector, userInfoSelector } from 'store/selectors';
import { setIsPersonalInfoFilled } from 'store/slices/personal/personalInfo.slice';

import { Limit } from './Limit';
import { HeaderStyles } from './styles';
import { IHeaderProps } from './types';

export const Header = (props: IHeaderProps) => {
  const { auth } = props;

  const { t } = useTranslation();
  const steps = useSelector((state: RootState) => state?.steps);
  const location = useLocation();
  const dispatch = useDispatch();
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const inputField = useRef() as React.MutableRefObject<HTMLInputElement>;
  const profile = useSelector(userInfoSelector);
  const isProfileLoaded = useSelector(userInfoLoadedSelector);

  useOnClickOutside(inputField, () => setIsOpenMenu(false));

  const data = useMemo(
    () => [
      {
        value: AppRoutes.private.personal,
        label: translate(t, 'personal_info'),
        icon: <ProfileIcon className="is-hover" />,
      },
      {
        value: AppRoutes.private.history,
        label: translate(t, 'history'),
        icon: <SupportIcon className="is-hover" />,
      },
      {
        value: AppRoutes.public.signIn,
        label: translate(t, 'logout'),
        icon: <LogoutIcon className="is-hover" />,
      },
    ],
    [t],
  );

  const links = useMemo(
    () => [
      {
        url: AppRoutes.moneySteps.sendMoney,
        text: translate(t, 'send_money'),
      },
      {
        url: AppRoutes.private.history,
        text: translate(t, 'history'),
      },
      {
        url: AppRoutes.private.myCards,
        text: translate(t, 'cards'),
      },
      {
        url: ExternalLinks.support,
        isExternal: true,
        text: translate(t, 'support'),
      },
    ],
    [t],
  );

  const reload = (): void => {
    window.location.reload();
  };

  useEffect(() => {
    if (
      auth &&
      isProfileLoaded &&
      location.pathname !== '/' &&
      location.pathname !== AppRoutes.moneySteps.sendMoney
    ) {
      if (profile?.firstedit) {
        dispatch(setIsPersonalInfoFilled(false));
      } else {
        dispatch(setIsPersonalInfoFilled(true));
      }
    }
  }, [auth, isProfileLoaded, profile?.firstedit, location.pathname, dispatch]);

  useEffect(() => {
    setIsOpenMenu(false);
  }, [location.pathname]);

  return (
    <>
      {auth && !steps.show ? (
        <HeaderStyles.StyledHeader auth pt>
          <HeaderStyles.StyledLeftPart pt>
            <HeaderStyles.Burger ref={inputField}>
              <Burger onClick={() => setIsOpenMenu((prev: boolean) => !prev)} />
              {isOpenMenu && (
                <HeaderStyles.Menu>
                  {links.map((link) => (
                    <StyledLink
                      key={link.url}
                      url={link.url}
                      isExternal={link.isExternal}
                      isMenuItem
                    >
                      <HeaderStyles.MenuItem>
                        <TextField variant="H7">{link.text}</TextField>
                      </HeaderStyles.MenuItem>
                    </StyledLink>
                  ))}
                </HeaderStyles.Menu>
              )}
            </HeaderStyles.Burger>
            <HeaderStyles.LogoWrapper>
              <StyledLink url={AppRoutes.moneySteps.sendMoney}>
                <Logo />
              </StyledLink>
            </HeaderStyles.LogoWrapper>
            {links.map((link) => (
              <HeaderStyles.LinkWrapper key={link.url} active={location.pathname === link.url}>
                <StyledLink url={link.url} isExternal={link.isExternal}>
                  <TextField variant="H7">{link.text}</TextField>
                </StyledLink>
              </HeaderStyles.LinkWrapper>
            ))}
          </HeaderStyles.StyledLeftPart>
          <HeaderStyles.StyledRightPart>
            <Limit />
            <Dropdown selected="1" data={data} title={<I18n tKey="profile" />} />
          </HeaderStyles.StyledRightPart>
        </HeaderStyles.StyledHeader>
      ) : auth && steps.show ? (
        <StepHeader
          width={((steps.stepNumber as number) * 100) / steps.totalSteps}
          step={steps.stepNumber}
          totalSteps={`${steps.totalSteps}`}
          stepDescription={steps.stepDescription}
        />
      ) : (
        <HeaderStyles.StyledHeader className="login">
          <HeaderStyles.LinkWrapper onClick={reload}>
            <StyledLink url={AppRoutes.main}>
              <Logo />
            </StyledLink>
          </HeaderStyles.LinkWrapper>
          <HeaderStyles.BackButton onClick={reload}>
            <StyledLink url={AppRoutes.main}>
              <TextField variant="H7" color="natural['900']">
                <I18n tKey="back_to_home" />
              </TextField>
            </StyledLink>
          </HeaderStyles.BackButton>
        </HeaderStyles.StyledHeader>
      )}
    </>
  );
};
