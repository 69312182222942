import { IHistoryGet } from 'types/types';

import { axiosInstance } from './axiosInstance';
import { historyNormalization } from './normalization';

export const PaymentHistoryApi = {
  getPaymentHistory: async (data: IHistoryGet) => {
    return axiosInstance.get(`transactions/history${historyNormalization(data)}`);
  },
};
