import { useQueryBuilder } from 'hooks/useQueryBuilder';
import { getResourceManager } from 'resources';

export const useFoundsOptions = () => {
  return useQueryBuilder({
    key: 'founds-options',
    method: async () => getResourceManager().options.getFounds(),
    enabled: true,
  });
};
