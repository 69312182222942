import styled, { keyframes } from 'styled-components';
import { ReactComponent as Spinner } from '../../assets/images/Spinner.svg';
import { StyledSpinnerProps } from './types';

const spin = keyframes`
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    25% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    75% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
`;

const sizes: Record<string, number> = {
  lg: 24,
  sm: 12,
};

export const StyledSpinner = styled(Spinner)<StyledSpinnerProps>`
  width: ${(props) => sizes[props.variant || 'lg']}px;
  height: ${(props) => sizes[props.variant || 'lg']}px;
  -webkit-animation: ${spin} 2s infinite linear;
  animation-duration: 0.8s;
`;
