import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { FocusEvent, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import infoIcon from 'assets/images/infoIcon.png';
import { StyledLink, PrimaryButtonComp, PrototypeOfStepPagesForm } from 'components';
import { Button, PrimaryButton } from 'components/PrimaryButtonComp/style';
import {
  ButtonWrapper,
  StyledFooterWithButtonsOfFormPage,
} from 'components/PrototypeOfStepPages/styles';
import { StyledP, StyledSpan } from 'components/StepHeader/style';
import { StyledBackground, StyledInfoIcon } from 'components/TransactionInfo/style';
import { I18n } from 'components/Translation';
import {
  InputFieldContainer,
  RandomSelectorFieldContainer,
  CountrySelectFieldContainer,
} from 'components/fields';
import { CONSTANTS, getErrorTranslation } from 'config';
import { MONTHS_AS_NUMBER, MONTHS } from 'config/months';
import { phoneNumberEntryAccess, toastAlert, translate } from 'helpers';
import { dayData } from 'helpers/datData';
import { YEAR_OPTIONS } from 'helpers/years';
import { useValidationSchema } from 'hooks';
import { usePersonal, usePersonalPhone } from 'pages/Personal/hooks';
import { getDataFromDate } from 'pages/Personal/utils';
import { useAppProvider, useAuth, useSendMoneySteps, useTranslation } from 'providers';
import { useCountries } from 'providers/countriesProvider';
import { usePhoneCheckMutation, usePhoneVerifyMutation } from 'queries/profile/mutation';
import { AppRoutes } from 'routes/constants';
import { getMonitoring } from 'services/monitoring';
import {
  updateSenderDetailsData,
  setDateOfBirth,
  ISenderDetailsData,
} from 'store/slices/transaction';
import { senderDetailsSelector } from 'store/slices/transaction/details/details.selectors';
import { fromCountrySelector } from 'store/slices/transaction/sender/sender.selectors';

import { monthData } from '../RecipientDetails/monthData';
import {
  StyledUpperPart,
  StyledContainer,
  StyledSectionTitle,
  StyledStepPagesLowerPart,
  StyledSectionWithEqualFields,
  StyledSectionWithNotEqualFields,
} from '../style';
import { InputContainer } from '../styledSenderDetails';
import { BirthBox } from '../stylesRecipientDetails';

import { getValidationSchema } from './getValidationSchema';
import styles from './sender.module.scss';

type ObjectKey = keyof typeof MONTHS_AS_NUMBER;

export const SenderDetails = () => {
  const { settings } = useAuth();
  const { sendingCountries } = useCountries();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [checkFields, setCheckFields] = useState<boolean>(false);
  const { setLoader, loadType, setLoadType } = useAppProvider();
  const { t } = useTranslation();
  const senderCountry = useSelector(fromCountrySelector);
  const senderDetailsData = useSelector(senderDetailsSelector);

  const schema = useValidationSchema(getValidationSchema);
  const { handleNextStep } = useSendMoneySteps();

  const { profile, defaultValues, state, setState } = usePersonal({
    country: senderCountry,
    defaultValue: senderDetailsData,
  });

  const handleNextPage = (params: Record<string, unknown>) => {
    handleNextStep(params);
    setLoader(false);
  };

  const form = useForm<ISenderDetailsData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValues,
    },
  });

  const { setValue, watch, handleSubmit, setError, clearErrors } = form;

  const { handlePhoneVerify } = usePhoneVerifyMutation({
    onError: (e) => {
      getMonitoring()?.captureException(e);
      const defaultResponseError = e instanceof AxiosError ? e?.response?.data?.message : '';
      const responseError = getErrorTranslation(defaultResponseError) || defaultResponseError;
      const baseError = getErrorTranslation(e.message || '');
      const errorMessage = baseError || e.message || translate(t, 'error_something_went_wrong');

      toastAlert(
        'error',
        responseError ? translate(t, responseError) : responseError || errorMessage,
      );
    },
    onSuccess: (isVerified) => {
      if (isVerified) {
        handleNextPage({ phoneVerified: 1 });

        return;
      }

      toastAlert('error', translate(t, 'error_something_went_wrong'));
    },
    onSettled: () => {
      setLoader(false);
    },
  });

  const { handlePhoneIsEmpty } = usePhoneCheckMutation({
    definedPhone: profile.phone,
    onError: () => {
      setLoader(false);
      setError('phone', {
        type: 'CUSTOM',
        message: translate(t, 'error_phone_already_use'),
      });
    },
  });

  const handleBack = () => {
    navigate(AppRoutes.moneySteps.sendMoney);
  };

  const onSubmit = async (values: ISenderDetailsData) => {
    setLoader(true);
    setLoadType('Step Two');
    let monthNumber: string;
    if (Number(values?.month)) {
      monthNumber = values?.month as string;
    } else {
      monthNumber = `0${MONTHS_AS_NUMBER?.[values?.month?.toLowerCase() as ObjectKey]}`.slice(-2);
    }

    dispatch(
      updateSenderDetailsData({
        firstName: values?.firstName,
        lastName: values?.lastName,
        country: values?.country,
        city: values?.city,
        street: values?.street,
        zip: values?.zip,
        phone: values?.phone,
        email: values?.email,
        day: values?.day,
        month: values?.month,
        year: values?.year,
        date_of_birth: `${values?.year}-${monthNumber}-${values?.day}`,
      }),
    );

    await handlePhoneIsEmpty.mutateAsync(values?.phone || '');
    await handlePhoneVerify.mutateAsync(values?.phone || '');
  };

  useEffect(() => {
    setState({
      monthNumber:
        MONTHS_AS_NUMBER?.[
          (
            getDataFromDate(profile?.date_of_birth, 1, senderDetailsData?.month as string) ||
            senderDetailsData?.month
          )?.toLowerCase() as ObjectKey
        ],
      yearNumber:
        getDataFromDate(profile?.date_of_birth, 0, senderDetailsData?.year as string) ||
        senderDetailsData?.year,
    });
  }, [profile, senderDetailsData, setState]);

  const [firstNameValue, lastNameValue, dayValue, monthValue, yearValue, country, phone] = watch([
    'firstName',
    'lastName',
    'day',
    'month',
    'year',
    'country',
    'phone',
  ]);

  const onPhoneUpdate = useCallback((phone: string) => setValue('phone', phone), [setValue]);

  usePersonalPhone({ country, phone, onUpdate: onPhoneUpdate });

  useEffect(() => {
    const { amountOfDays = 0 } = state;

    if (dayValue && +dayValue > amountOfDays) {
      setValue('day', '');
    }
  }, [dayValue, state, setValue]);

  useEffect(() => {
    clearErrors('phone');
  }, [phone, clearErrors]);

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <PrototypeOfStepPagesForm
            smallTitle={<I18n tKey="senders_info" />}
            subtitle={<I18n tKey="complete_personal_details" />}
            description={<I18n tKey="complete_personal_details_description" />}
            contentClassName={styles['container']}
          >
            <StyledStepPagesLowerPart>
              <StyledUpperPart isAlignLeftPage>
                <StyledContainer>
                  <StyledSectionTitle>
                    <I18n tKey="info_about_you" />
                  </StyledSectionTitle>
                  <StyledSectionWithEqualFields marginBottom="24px">
                    <InputContainer className="firstChild">
                      <InputFieldContainer
                        name="firstName"
                        className="first_name"
                        disabled={!!profile?.first_name}
                        value={firstNameValue?.replace(CONSTANTS.REGEXP.NON_SPACES, '')}
                        checkFields={checkFields}
                        onBlur={(value: string) => {
                          dispatch(updateSenderDetailsData({ firstName: value }));
                        }}
                        label={<I18n tKey="first_name" />}
                      />
                    </InputContainer>
                    <InputContainer className="firstChild">
                      <InputFieldContainer
                        name="lastName"
                        className="last_name"
                        disabled={!!profile?.last_name}
                        value={lastNameValue?.replace(CONSTANTS.REGEXP.NON_SPACES, '')}
                        onBlur={(value: string) => {
                          dispatch(updateSenderDetailsData({ lastName: value }));
                        }}
                        checkFields={checkFields}
                        label={<I18n tKey="last_name" />}
                      />
                    </InputContainer>
                  </StyledSectionWithEqualFields>
                  <StyledSectionTitle>
                    <I18n tKey="your_date_of_birth" />
                  </StyledSectionTitle>
                  <BirthBox className="birth">
                    <InputContainer>
                      <RandomSelectorFieldContainer
                        name="day"
                        className="day"
                        label={<I18n tKey="day" />}
                        data={dayData(state.amountOfDays)}
                        value={dayValue || ''}
                        type="number"
                        onChange={(value: string) => {
                          dispatch(updateSenderDetailsData({ day: value }));
                          dispatch(setDateOfBirth());
                        }}
                        disabled={!!profile?.date_of_birth}
                        checkFields={checkFields}
                        placeholder={translate(t, 'select')}
                      />
                    </InputContainer>
                    <RandomSelectorFieldContainer
                      name="month"
                      className="month"
                      label={<I18n tKey="month" />}
                      data={monthData(t)}
                      value={
                        profile?.date_of_birth
                          ? // @ts-ignore
                            translate(t, MONTHS[profile?.date_of_birth?.split('-')?.[1]])
                          : monthValue
                      }
                      type="string"
                      onChange={(value: string) => {
                        dispatch(updateSenderDetailsData({ month: value }));
                        dispatch(setDateOfBirth());
                      }}
                      disabled={!!profile?.date_of_birth}
                      checkFields={checkFields}
                    />
                    <RandomSelectorFieldContainer
                      name="year"
                      className="year"
                      label={<I18n tKey="year" />}
                      data={YEAR_OPTIONS}
                      value={yearValue}
                      type="number"
                      onChange={(value: string) => {
                        dispatch(updateSenderDetailsData({ year: value }));
                        dispatch(setDateOfBirth());
                      }}
                      disabled={!!profile?.date_of_birth}
                      checkFields={checkFields}
                    />
                  </BirthBox>
                </StyledContainer>

                <StyledContainer>
                  <StyledSectionTitle>
                    <I18n tKey="your_contact_address" />
                  </StyledSectionTitle>
                  <StyledSectionWithEqualFields>
                    <div className="firstChild">
                      <CountrySelectFieldContainer
                        name="country"
                        label={<I18n tKey="your_country_residence" />}
                        checkFields={checkFields}
                        disabled={!!senderCountry}
                        countries={sendingCountries}
                      />
                    </div>
                    <InputContainer className="secondChild">
                      <InputFieldContainer
                        name="city"
                        className="city"
                        onBlur={(value: string) => {
                          dispatch(updateSenderDetailsData({ city: value }));
                        }}
                        checkFields={checkFields}
                        disabled={!!profile?.city}
                        label={<I18n tKey="your_city" />}
                      />
                    </InputContainer>
                  </StyledSectionWithEqualFields>
                  <StyledSectionWithNotEqualFields marginBottom="24px">
                    <InputContainer className="streetWrapper">
                      <InputFieldContainer
                        name="street"
                        className="street"
                        onBlur={(value: string) => {
                          dispatch(updateSenderDetailsData({ street: value }));
                        }}
                        checkFields={checkFields}
                        disabled={!!profile?.street}
                        label={<I18n tKey="your_full_address" />}
                      />
                    </InputContainer>
                    <InputContainer className="secondChild">
                      <InputFieldContainer
                        name="zip"
                        className="zip"
                        onBlur={(value: string) => {
                          dispatch(updateSenderDetailsData({ zip: value }));
                        }}
                        checkFields={checkFields}
                        disabled={!!profile?.zip}
                        label={<I18n tKey="zip_code" />}
                      />
                    </InputContainer>
                  </StyledSectionWithNotEqualFields>
                </StyledContainer>

                <StyledContainer>
                  <StyledSectionTitle>
                    <I18n tKey="your_contact_details" />
                  </StyledSectionTitle>
                  <StyledSectionWithEqualFields>
                    <InputContainer className="firstChild">
                      <InputFieldContainer
                        name="email"
                        className="email"
                        disabled={!!profile?.email}
                        checkFields={checkFields}
                        label={<I18n tKey="email" />}
                      />
                    </InputContainer>
                    <InputContainer className="secondChild">
                      <InputFieldContainer
                        name="phone"
                        className="phone"
                        /**
                         * Здесь кастомная логика валидации, пока не убрал в onBlur из-за этого
                         */
                        onChange={(value: string) => {
                          dispatch(updateSenderDetailsData({ phone: value }));
                        }}
                        onValidate={(e: FocusEvent<HTMLInputElement>) =>
                          !!phoneNumberEntryAccess(e)
                        }
                        hint={
                          !profile?.phone ? <I18n tKey="phone_make_verified_message" /> : undefined
                        }
                        checkFields={checkFields}
                        disabled={!!profile?.phone}
                        label={<I18n tKey="phone" />}
                      />
                    </InputContainer>
                  </StyledSectionWithEqualFields>
                </StyledContainer>
              </StyledUpperPart>
              {!profile?.status_w && (
                <StyledBackground>
                  <StyledInfoIcon>
                    <img src={infoIcon} alt="Info" />
                  </StyledInfoIcon>
                  <StyledP margin="18px 0 0">
                    <StyledSpan margin="0">
                      <I18n tKey="upgrade_your_monthly_limit_local" />{' '}
                      {settings?.settings.upgradeLimit} {settings?.settings.defaultCurrency}?
                    </StyledSpan>
                  </StyledP>
                  <StyledP margin="0">
                    <I18n tKey="verify_text_local" />
                  </StyledP>
                  <StyledLink
                    url={
                      !!profile.first_name && !!profile.last_name
                        ? AppRoutes.private.increaseMonthlyLimit
                        : AppRoutes.private.goToProfile
                    }
                  >
                    <ButtonWrapper margin="0">
                      <Button>
                        <I18n tKey="verify_now" />
                      </Button>
                    </ButtonWrapper>
                  </StyledLink>
                </StyledBackground>
              )}
              <StyledFooterWithButtonsOfFormPage>
                <PrimaryButton width="84px" onClick={handleBack}>
                  <I18n tKey="back" />
                </PrimaryButton>
                <PrimaryButtonComp
                  width="84px"
                  text={<I18n tKey="next" />}
                  valid
                  typeOfLoad={loadType}
                  click={() => {
                    setCheckFields(true);
                  }}
                />
              </StyledFooterWithButtonsOfFormPage>
            </StyledStepPagesLowerPart>
          </PrototypeOfStepPagesForm>
        </form>
      </FormProvider>
    </>
  );
};
