import { useDispatch } from 'react-redux';

import { toastAlert } from 'helpers';
import { useQueryBuilder } from 'hooks/useQueryBuilder';
import { getResourceManager } from 'resources/provider';
import { getMonitoring } from 'services/monitoring';
import { LoadingStatus } from 'store/loading';
import { setUserInfo, setUserLoading } from 'store/slices/personal/personalInfo.slice';
import { setVerificationStatus } from 'store/slices/verification';

import { ProfileQuery } from './types';

export const useProfileQuery = (props: ProfileQuery) => {
  const dispatch = useDispatch();

  const getProfile = async () => {
    try {
      dispatch(setUserLoading(LoadingStatus.LOADING));

      const { data: profile, status } = await getResourceManager().user.profile.getProfile();

      dispatch(setVerificationStatus(profile?.status_w || null));
      dispatch(setUserLoading(LoadingStatus.LOADED));

      if (status && profile) {
        dispatch(setUserInfo(profile));

        dispatch(setUserLoading(LoadingStatus.SUCCESS));
        return profile;
      }

      throw new Error();
    } catch (error) {
      dispatch(setUserLoading(LoadingStatus.FAIL));
      throw error;
    }
  };

  const response = useQueryBuilder({
    key: 'user-profile',
    method: getProfile,
    enabled: props.enabled,
    options: {
      onError: (err: any) => {
        dispatch(setUserLoading(LoadingStatus.FAIL));
        getMonitoring()?.captureException(err);
        toastAlert('error', err.message);
      },
      ...props?.options,
    },
  });

  return response;
};
