export const CONSTANTS = {
  LANGUAGES: [
    {
      label: 'English',
      iso2: 'US',
      lang: 'en',
    },
    {
      label: 'French',
      iso2: 'FR',
      lang: 'fr',
    },
    {
      label: 'Spanish',
      iso2: 'ES',
      lang: 'es',
    },
    {
      label: 'German',
      iso2: 'DE',
      lang: 'de',
    },
    {
      label: 'Ukrainian',
      iso2: 'UA',
      lang: 'ua',
    },
    {
      label: 'Polish',
      iso2: 'PL',
      lang: 'pl',
    },
  ],
  HUNDREED_PERCENT: '100%',
  BACK_HOME: 'Back to Home',
  HOME: 'Home',
  PAYBLE: 'Payble',
  FORMAT: 'io',
  STEP: 'Step',
  STEP_CONTINUE: 'Personal Identification',
  SEND: 'You send',
  SEND_NOW: 'Send now',
  RECEIVE: 'They receive',
  RECIPIENT_RECEIVES: 'Recipient receives',
  TRANSACTION_FEE: 'Our transfer fee',
  EXCHANGE_RATE: 'Exchange rate',
  AMOUNT_TO_BE_DEBITED: 'Amount to be debited from your card',
  THE_RECIPIENT_RECIEVES: 'The recipient recieves',
  INFO_ABOUT_YOU: 'Information about you',
  INFO_ABOUT_PAYMENT: 'Information about your payment',
  INFO_ABOUT_CARD: 'Your payment card information',
  INFO_ABOUT_ADDRESS: 'Your contact address',
  SELECT_CARD: 'Select your payment card',
  CONTACT_DETAIL: 'Your contact details',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  SURNAME: 'Smith',
  INFO_ABOUT_RECIPIENT: 'Information about recipient',
  DATE_OF_BIRTH: 'Date of birth',
  CONTACT_INFO: 'Contact Information',
  RECIPIENT_CARD_INFO: 'Recipient payment card details',
  YOUR_COUNTRY: 'Your country',
  YOUR_CITY: 'Your city',
  YOUR_STREET: 'Your street',
  YOUR_NAME: 'Your name',
  YOUR_CARD: 'Your card',
  ZIP_CODE: 'Zip code',
  RECIPIENT_COUNTRY: 'Recipient country',
  RECIPIENT_NAME: 'Recipient name',
  RECIPIENT_CARD_NUMBER: "Recipient's payment card number",
  SEND_AMOUNT: 'Amount to send',
  RECIEVE_AMOUNT: 'Amount to receive',
  OUR_FEE: 'Our fee',
  TOTAL_PAY_TODAY: 'Total to pay today',
  NOTIFY_RECIPIENT: 'Notify recipient',
  NEW_PASSWORD: 'New password',
  CONFIRM_PASSWORD: 'Confirm password',
  CURRENT_PASSWORD: 'Current password',
  PERSONAL: 'Personal info',
  SUPPORT: 'Support',
  SINGOUT: 'Logout',
  VERIFICATION_TIME_ZONE: 120,
  SAVE: 'Save',

  SUCCESS_SIGNIN_PAGE: {
    LOGIN: 'Login',
    TRANSFERSIGNUP: 'Sign up here',
    SIGNIN: 'Sign in',
    SIGNUP: 'Sign up',
  },

  SUCCESS_SIGN_PAGE: {
    SUCCESS_MAGIC_LINK: 'Your magic link has been sent',
    NEED_HELP_MESSAGE: 'Need help with a recent transaction?',
    CONTACT_SUPPORT_TEAM: 'Contact customer support team',
    LINK_SENT_MESSAGE: 'Link was sent to the email',
    CLICK_ON_YOUR_LINK: 'To enter your personal account, click on the link in the letter.',
  },

  ASK_QUESTION: 'Ask a question',
  PRIVACY_POLICY: 'Privacy Policy',
  TERMS_OF_USE: 'Terms of Use',
  TRANSFER_HAS_BEEN_SENT: 'Your transfer has been sent',
  ESTIMATED_DELIVERY: 'Estimated delivery',
  NOTE_ABOUT_PAYMENT:
    'Please note that a debit charge will appear on your card statement within the next few days.',
  TRANSFER_HAS_BEEN_DENIED: 'Your transfer has been declined',
  DENIED_TRANSFER_MESSAGE:
    "Your card was declined or recipient's card issuer doesn't allow the transaction",
  PAYMENT_WASNT_CHANGE: 'You can try again to send your payment using a different payment card.',
  CARDHOLDER_NAME: 'Name on card',
  CITY: 'City',
  STREET: 'Street',
  CVV: 'CVV',
  PHONE: 'Phone',
  EMAIL: 'Email',

  STEP_PAGES: {
    TITLES: {
      GET_STARTED: 'Get started',
      ENTER_CODE: 'Enter your code',
      LETS_SEND_MONEY: "Let's send money",
      SENDERS_PAYMENT_INFO: "Sender's Payment Information",
      RECIPIENT_CONTACT_DETAILS: "Recipient's Contact Details",
      ALMOST_DONE: 'Almost done!',
    },
    SUBTITLES: {
      ENTER_PHONE_NUMBER: 'Enter your phone number, we will send a code to identify you',
      ENTER_EMAIL: 'Enter your email, we will send a code to identify you',
      MESSAGE_SEND:
        'A message with a code was sent to the number +420 234 567 890. Please enter the code in the field below.',
      ENTER_AMOUNT:
        'Enter the amount you want to send or the amount you want your recipient to recieve.',
      COMPLETE_PERSONAL_DETAILS: 'Please, complete your personal details.',
      COMPLETE_RECIPIENT_PERSONAL_DATA: 'Please, complete your recipient’s personal details.',
      REVIEW_AND_APROVE: 'Please review and approve transaction',
    },
    NO_EMAIL: "Don't have an email?",
    USE_EMAIL: "Let's use email instead",
    NO_PHONE: "Don't have a phone number?",
    USE_PHONE: 'Identify yourself by phone number',
  },

  INPUT: {
    TYPES: {
      STRING: 'string',
      NUMBER: 'number',
    },
    TITLES: {
      EMAIL_TITLE: 'Enter your email',
      PHONE_TITLE: 'Enter your phone number',
      PASSWORD_TITLE: 'Enther your password',
    },
  },

  BUTTON: {
    CONTINUE: 'Continue',
    CANCEL: 'Cancel',
    CHANGE_PASSWORD: 'Change password',
    SAVE_CHANGES: 'Save changes',
    SET_PASSWORD: 'Set Password',
    CHANGE_EMAIL: 'Change Email',
    BACK: 'Back',
    NEXT: 'Next',
    SEND: 'Send',
  },

  CARD_TYPES: {
    union_pay: 'UnionPay',
    mastercard: 'Mastercard',
    visa: 'Visa',
  },

  PASSWORD_HINT_MESSAGE_1: `
  <p>Password should contain:</p>
  <p>Minimum 8 characters</p>
  <p>Uppercase or lowercase letters:</p>
  <p>A-Z / a-z</p>
  <p>At least one digit: 0-9</p>
  <p>At least one special symbol: !,@,#, etc.</p>
`,

  REGEXP: {
    EMAIL: /^[A-Z0-9._+-=/!#$%^&*?:]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!~%*#?&])[A-Za-z\d@$!%*~#?&]{8,}$/,
    NON_SPACES: /\s+/g,
    EXP_DATE_MASK: [/[0-9]/, /\d/, '/', /\d/, /\d/],
    CARD_NUMBER_MASK: [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
  },
};

interface CardTypes {
  mastercard: string;
  visa: string;
  union_pay: string;
}

export const CARD_TYPE_ENDING: CardTypes = {
  mastercard: 'master_card_pay_ending',
  visa: 'visa_pay_ending',
  union_pay: 'union_pay_ending',
};
