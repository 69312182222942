import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledStepHeader = styled.div`
  background: #fff;
  width: 1005;
  padding: 30px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1299px) {
    padding: 24px;
  }
  @media (max-width: 647px) {
    padding: 18px 16px;
  }
`;

const StyledProgressBar = styled.div<{ width?: number }>`
  width: 100%;
  height: 1px;
  display: flex;
  background-color: #f0f0f2;
  align-items: center;
  div {
    height: 3px;
    width: ${({ width }) => `${width}%`};
    background-color: #00baff;
    transition: width ease 0.5s;
  }
`;

const StyledLeftPart = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.2px;
`;

const StyledTitle = styled.h3`
  font-family: Poppins !important;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.2px;
  color: #222229;
  margin-top: 3px;
  margin-right: 32px;
  span {
    color: #00baff;
  }
`;

const StyledP = styled.div<{ margin?: string }>`
  font-family: 'Lato-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #4c4e57;
  margin: ${({ margin }) => (margin ? margin : '0px 8px')};

  b {
    color: #222229;
  }

  .step-color {
    color: #4c4e57;
  }
`;

const WrapperD = styled.div`
  display: inline-block;
  @media (max-width: 648px) {
    display: none;
  }
`;

const WrapperM = styled.span`
  display: none;
  @media (max-width: 648px) {
    display: flex;
  }
`;

const StyledSpan = styled.span<{ margin?: string }>`
  font-size: 16px;
  font-weight: bold;
  color: #222229;
  margin: ${({ margin }) => margin ?? '0 0 0 5px'};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-family: Commissioner;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.2px;
  color: #222229;
`;

export {
  StyledStepHeader,
  StyledProgressBar,
  StyledLeftPart,
  StyledTitle,
  StyledP,
  WrapperD,
  WrapperM,
  StyledSpan,
  StyledLink,
};
