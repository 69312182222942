import { Formik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';

import { InputField } from 'components/InputField';
import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';
import { CONSTANTS } from 'config';

import { InputContainer } from './styles';
import { IProps } from './types';

export const EmailForm = ({
  className = 'email',
  titleName = '',
  handleData,
  passwordInput,
  initialValues,
}: IProps) => {
  const regexp = CONSTANTS.REGEXP.EMAIL;
  const hasInitial = !!initialValues?.email || !!initialValues?.password;
  const [isOnFocus, setIsOnFocus] = useState(!!initialValues?.email || !!initialValues?.password);

  const validationsSchema = yup.object().shape({
    email: yup.string().email('Enter correct email'),
    password: yup.string().min(8),
  });

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        ...initialValues,
      }}
      validateOnBlur
      validateOnMount={hasInitial}
      onSubmit={() => {}}
      validationSchema={validationsSchema}
      validate={(values) => {
        let isValid = false;
        if (passwordInput) {
          isValid = values.password.length >= 6;
        } else {
          isValid = !!values.email.match(regexp);
        }
        handleData(isValid, values.email, values.password);
      }}
    >
      {({ values, handleChange }) => {
        return passwordInput ? (
          <InputContainer>
            <InputField
              type="password"
              className={className}
              value={values.password}
              name="password"
              onChange={handleChange}
              onFocus={() => !values.password && setIsOnFocus((prev) => !prev)}
              onBlur={() => !values.password && setIsOnFocus((prev) => !prev)}
            />
            <label className={`label ${isOnFocus ? 'on-focus' : ''}`}>
              <I18n tKey="enter_your_password" />
            </label>
          </InputContainer>
        ) : (
          <InputContainer>
            <InputField
              type="email"
              className={className}
              value={values.email}
              name="email"
              onChange={handleChange}
              onFocus={() => !values.email && setIsOnFocus((prev) => !prev)}
              onBlur={() => !values.email && setIsOnFocus((prev) => !prev)}
            />
            <label className={`label ${isOnFocus ? 'on-focus' : ''}`}>
              <TextField className={`${isOnFocus ? 'on-focus' : ''}`} variant="P7">
                {!titleName ? <I18n tKey="email_input" /> : titleName}
              </TextField>
            </label>
          </InputContainer>
        );
      }}
    </Formik>
  );
};
