import { ISupportCatalog, IFAQDataBase } from 'types/types';

import { axiosInstance } from './axiosInstance';

export const QuestionCatalogsApi = {
  getQuestionCatalogs: async (): Promise<ISupportCatalog> => {
    return axiosInstance.get('options/data/question_type');
  },
  getFAQ: async (): Promise<IFAQDataBase> => {
    return axiosInstance.get('support/get-faq?lang=en');
  },
};
