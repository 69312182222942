import { LoadingStatus } from './state';

const getLoadingData = (status: LoadingStatus) => {
  switch (status) {
    case LoadingStatus.INIT:
      return {
        isLoaded: false,
        isLoading: false,
        isError: false,
        status,
      };
    case LoadingStatus.LOADED:
      return {
        isLoaded: true,
        isLoading: false,
        isError: false,
        status,
      };
    case LoadingStatus.LOADING:
      return {
        isLoaded: false,
        isLoading: true,
        isError: false,
        status,
      };
    case LoadingStatus.SUCCESS:
      return {
        isLoaded: true,
        isLoading: false,
        isError: false,
        status,
      };
    case LoadingStatus.FAIL:
      return {
        isLoaded: true,
        isLoading: false,
        isError: true,
        status,
      };
    default:
      return {
        isLoaded: false,
        isLoading: false,
        isError: false,
        status,
      };
  }
};

export function updateLoadingProvider<State>(
  state: State,
  action: { type: string; payload: LoadingStatus },
) {
  return {
    ...state,
    loadingData: getLoadingData(action.payload),
  };
}
