export interface IDataStepOne {
  isAgree: boolean;
  fromAmount: string;
  toAmount: string;
  fee: string;
  /**
   * @deprecated
   */
  debet: string;
  debit?: string;
  rate: {
    rate?: string;
    to?: string;
    date?: string;
    from?: string;
  };
  delivery: string;
}

export enum TransactionStatus {
  success = 'transfer-success',
  finish = 'transfer-finish',
  denied = 'transfer-denied',
  empty = '',
}

export type SendMoneyFields = {
  fromCountry: string;
  toCountry: string;
  receiverEmail: string;
  transactionStatus: TransactionStatus;
  fromCurrency: string;
  toCurrency: string;
  steps: TSendMoneyStep[];
  id: string;
};

export type SendMoneySlice = {
  data: {
    sendMoney: IDataStepOne;
  };
} & SendMoneyFields;

export type TSendMoneyStep = {
  path: string;
  success?: boolean;
};
