const {
  REACT_APP_SUPABASE_URL: supabaseUrl = '',
  REACT_APP_SUPABASE_ANON_KEY: supabaseAnonKey = '',
  REACT_APP_SUPABASE_APP: supabaseApp = '',
  REACT_APP_FMPAY_SERVICE_BASE_URL: fmpayServiceBaseUrl = '',
  REACT_APP_ENABLE_REDUX_LOGGER: enableReduxLogger = '0',
  REACT_APP_ENABLE_DEBUG_STEPS: enableDebugSteps = '0',
  REACT_APP_ENABLE_GA_DEBUG: enableGaDebug = '0',
  REACT_APP_GA_TAG: gaTag,
  REACT_APP_SENTRY_DSN: sentryDsn,
  REACT_APP_SENTRY_ENVIRONMENT: sentryEnvironment = 'production',
} = process.env;

export const envConfig = {
  provider: {
    supabaseUrl,
    supabaseAnonKey,
    supabaseApp,
  },
  analytics: {
    key: gaTag,
  },
  moniroting: {
    dsn: sentryDsn,
    environment: sentryEnvironment,
  },
  fmpayServiceBaseUrl,
  isReduxLoggerEnabled: enableReduxLogger === '1',
  isDebugStepsEnabled: enableDebugSteps === '1',
  isDebugGaEnabled: enableGaDebug === '1',
};
