import { Fetcher } from 'utils';

import { UserSettingsRepo } from './UserSettingsRepo';

export class UserSettingsService {
  private userSettingsRepo: UserSettingsRepo;

  constructor(fetcher: Fetcher) {
    this.userSettingsRepo = new UserSettingsRepo(fetcher);
  }

  public async getSettings() {
    const data = await this.userSettingsRepo.getSettings();

    return data;
  }
}
