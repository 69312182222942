import { AuthResponse, OAuthResponse, UserResponse } from '@supabase/supabase-js';

import { IDataProvider } from 'services';

import { DataAuthProvider, IAuthRepo, PasswordSignInProps } from './types';

export class AuthRepo implements IAuthRepo {
  private provider: IDataProvider;

  constructor(provider: IDataProvider) {
    this.provider = provider;
  }

  public async signOut() {
    return this.provider.auth.signOut();
  }

  public async signInWithGoogle(): Promise<OAuthResponse> {
    return this.provider.auth.signInWithOAuth({
      provider: DataAuthProvider.google,
      options: {
        redirectTo: window.location.href,
      },
    });
  }

  public async signInWithApple(): Promise<OAuthResponse> {
    return this.provider.auth.signInWithOAuth({
      provider: DataAuthProvider.apple,
      options: {
        redirectTo: window.location.href,
      },
    });
  }

  public async signInWithEmail(email: string): Promise<AuthResponse> {
    return this.provider.auth.signInWithOtp({ email });
  }

  public async getUserData(): Promise<UserResponse> {
    return this.provider.auth.getUser();
  }

  public async signInWithPassword(props: PasswordSignInProps): Promise<AuthResponse> {
    return this.provider.auth.signInWithPassword(props);
  }

  public async setSession(accessToken: string, refreshToken: string): Promise<AuthResponse> {
    return this.provider.auth.setSession({
      access_token: accessToken,
      refresh_token: refreshToken,
    });
  }

  public async refreshSession(refreshToken: string): Promise<AuthResponse> {
    return this.provider.auth.refreshSession({
      refresh_token: refreshToken,
    });
  }

  public async getSession() {
    return this.provider.auth.getSession();
  }
}
