import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 40px 15%;
  background: #f8f8fa;
  @media (max-width: 1600px) {
    padding: 40px 5% 40px;
  }
  @media (max-width: 1279px) {
    height: auto;
    padding: 33px 24px 32px;
  }
  @media (max-width: 647px) {
    height: auto;
    padding: 33px 24px 32px;
  }
`;

const Container = styled.div`
  max-width: 1160px;
  margin: 0 auto;
  @media (max-width: 1279px) {
    margin: 0 auto;
  }
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  @media (max-width: 647px) {
    flex-direction: column-reverse;
    gap: 32px;
  }
`;

const BottomSection = styled.div`
  width: 100%;
  display: flex;
  margin-top: 32px;
  .footer-center-text {
    margin: 0 140px 0 323px;
  }
  @media (max-width: 1279px) {
    flex-direction: column;
    justify-content: unset;
    .footer-center-text {
      margin: 32px 0 16px;
    }
  }
  @media (max-width: 647px) {
    margin-top: 24px;
  }
`;

const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid #f0f0f2;
`;

const Policysection = styled.div`
  display: flex;
  align-items: center;
  * {
    line-height: 20px;
    height: 20px;
  }
  a {
    margin-right: 24px;
  }
  @media (max-width: 647px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  * {
    line-height: 20px;
  }
  max-width: 260px;
`;

const PaymentSection = styled.div`
  display: flex;
  flex-direction: column;
  .accepted-text {
    line-height: 20px;
  }
  :first-child {
    white-space: nowrap;
  }
`;

const PaymentsWrapper = styled.div`
  display: flex;
  gap: 13px;
  align-items: center;
  margin-top: 16px;
  > div {
    width: unset;
    justify-content: unset;
  }
  @media (max-width: 1279px) {
    margin-bottom: 0;
  }
`;

const LangBox = styled.div`
  * button {
    height: 24px;
  }
`;

export {
  Wrapper,
  Container,
  TopSection,
  BottomSection,
  Line,
  Policysection,
  TextWrapper,
  PaymentSection,
  PaymentsWrapper,
  LangBox,
};
