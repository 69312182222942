import { io, Socket } from 'socket.io-client';

import { envConfig } from 'config/env';

const URL = envConfig.fmpayServiceBaseUrl;

const updateSocketAuth = () => {
  const tokenObject = localStorage.getItem(envConfig.provider.supabaseApp);
  const token = tokenObject ? JSON.parse(tokenObject).access_token : null;

  return { token };
};

class SocketProvider {
  private provider: Socket<any, any> | undefined = undefined;

  private tryToGetSocket() {
    if (this.provider) {
      return undefined;
    }

    const socket = io(URL || '', {
      autoConnect: false,
      auth: {
        token: '1',
      },
    });

    const { emit } = socket;

    /**
     * We need to upgrade token and reconnect every emit
     */
    (socket as any).emit = (...props: any) => {
      const { token } = updateSocketAuth();

      (socket?.auth as { [key: string]: any }).token = token;

      this.provider?.disconnect();
      this.provider?.connect();

      emit.apply(socket, props);
    };

    this.provider = socket;

    return this.provider;
  }

  get socket() {
    this.tryToGetSocket();

    return this.provider;
  }
}

export const socketProvider = new SocketProvider();
