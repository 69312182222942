import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 647px) {
    width: auto;
    margin: 0 16px;
  }
`;

const Content = styled.div`
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 48px auto 20px;
  @media (max-width: 647px) {
    width: 100%;
    margin: 24px 16px 20px;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  align-content: center;
  .subtitle {
    color: #686b78;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  gap: 24px;
  > * {
    gap: 0;
  }

  @media (max-width: 647px) {
    width: 100%;
    padding: 0;
  }
`;
export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 72px;

  .password label .input-container {
    position: absolute;
    top: 35px;
  }

  .passwordConfirmation label .input-container {
    position: absolute;
    top: 35px;
  }

  > .label {
    pointer-events: none;
    position: absolute;
    top: 24px;
    left: 18px;
    transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    box-sizing: border-box;
    .on-focus {
      -webkit-transition: all 0.1s linear;
      -moz-transition: all 0.1s linear;
      position: relative;
      font-size: 13px;
      color: #4c4e57;
      top: -18px;
    }
  }

  @media (max-width: 648px) {
    flex-direction: column;
    .password {
      width: auto;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  .pop-up .message-box {
    top: -130px;
    .arrow {
      rotate: 0deg;
    }
  }
  width: 100%;
`;

export const PopUpMessage = styled.div`
  margin-left: 4px;

  > :first-child {
    position: absolute;
  }
  > :last-child {
    position: absolute;
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  align-items: center;
  .button {
    margin-top: 20px;
  }
`;

export { Wrapper, ButtonWrapper, TitleBox, InputsContainer, Content };
