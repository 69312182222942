import { useEffect } from 'react';

import { useCountries } from 'providers/countriesProvider';

type Props = {
  onUpdate: (phone: string) => void;
  country?: string;
  phone?: string;
};

export const usePersonalPhone = ({ country, phone, onUpdate }: Props) => {
  const { sendingCountries } = useCountries();

  useEffect(() => {
    const currentCountry = sendingCountries?.find((countryItem) => countryItem.iso2 === country);

    if (currentCountry) {
      const phoneCode = currentCountry?.phoneCode;
      const phoneStartedValue = `+${phoneCode}`;

      if (
        phoneCode &&
        (!phone ||
          phone?.length < phoneStartedValue.length ||
          phone.slice(0, phoneStartedValue.length) !== phoneStartedValue ||
          phone.trim() === phoneStartedValue.trim())
      ) {
        const isOnlyDigits = phone?.match(/^\d$/);
        onUpdate(isOnlyDigits ? phoneStartedValue + phone : phoneStartedValue);
      }
    }
  }, [country, phone, sendingCountries, onUpdate]);
};
