import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';

import { TextWrapper, VerificationStatusBody, VerificationStatusWrapper } from './style';
import { propsType } from './types';

export const VerificationStatus = ({ status, icon }: propsType) => (
  <VerificationStatusWrapper className={status.toLowerCase()}>
    <VerificationStatusBody>
      <TextWrapper>
        <TextField variant="P13" color="n900">
          <I18n tKey={status.toLowerCase()} />
        </TextField>
      </TextWrapper>
      {status.toLowerCase() === 'verified' && <img src={icon} alt="Status icon" />}
    </VerificationStatusBody>
  </VerificationStatusWrapper>
);
