import styled from 'styled-components';

export const Wrapper = styled.div`
  display: none;
  @media screen and (max-width: 1279px) {
    display: block;
    margin-top: 24px;
    border: 1px solid #c7c9d1;
    border-radius: 8px;
    > :last-child {
      border: none;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  height: auto;
  border-bottom: 1px solid #c7c9d1;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const CardType = styled.div`
  > img {
    width: 45px;
  }
`;
export const CardNumber = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ExpDate = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Status = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DropDownSection = styled.div``;
