import { createSelector } from '@reduxjs/toolkit';

import { LoadingStatus } from '../../loading';
import { rootSelector } from '../../rootSelector';

const personalState = createSelector(rootSelector, (state) => state.personalInfo);
export const userInfoSelector = createSelector(personalState, (state) => state.userInfo);

export const userInfoLoadedSelector = createSelector(
  personalState,
  (state) => state.loadingData?.isLoaded,
);

export const userInfoLoadingSelector = createSelector(
  personalState,
  (state) => state.loadingData?.isLoading,
);

export const userInfoSuccessSelector = createSelector(
  personalState,
  (state) => state.loadingData?.status === LoadingStatus.SUCCESS,
);
