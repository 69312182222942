import { useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Portal } from 'components/Modal';
import { formDataSelector } from 'store/slices/cards/cards.selectors';

const FRAME_ID = 'threedMethodFrame';
const FORM_ID = 'threedMethodForm';

export const IframeSecure3d = () => {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const secure3dFormData = useSelector(formDataSelector);

  const { formData, isHidden, isAutoSubmit } = secure3dFormData;

  useLayoutEffect(() => {
    const ifrm = ref?.current;

    ifrm?.contentWindow?.document?.open();
    if (formData) {
      ifrm?.contentWindow?.document?.write(formData);
      if (isAutoSubmit) {
        const innerForm = ifrm?.contentWindow?.document?.getElementById(FORM_ID) as HTMLFormElement;
        innerForm?.submit();
      }
    } else {
      ifrm?.contentWindow?.document?.write('<span>No formData</span>');
    }
    ifrm?.contentWindow?.document?.close();
  }, [formData, isAutoSubmit]);

  if (!formData) {
    return null;
  }

  return (
    <Portal>
      <iframe
        name="threedMethodFrame"
        id={FRAME_ID}
        style={
          isHidden
            ? { display: 'none' }
            : {
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                background: '#fff',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
              }
        }
        ref={ref}
        title="secure3d"
      />
    </Portal>
  );
};
