import { useEffect, useState } from 'react';

import { persistor } from 'store';

/**
 * If Persist is empty, after this time we force it
 */
const PERSIST_FOLLBACK_TIMEOUT = 100;
/**
 * State about persist redux service. Need to use with AppProvider context
 */
export const useGetPersistAvailable = () => {
  const [isPersistDone, setPersistDone] = useState(false);

  useEffect(() => {
    persistor.subscribe(() => setPersistDone(true));

    setTimeout(() => {
      setPersistDone((state) => {
        if (!state) {
          return true;
        }

        return state;
      });
    }, PERSIST_FOLLBACK_TIMEOUT);
  }, []);

  return isPersistDone;
};
