import { CONSTANTS } from 'config';
import { setPartialCardsState } from 'store/slices/cards';
import { setUserInfo } from 'store/slices/personal/personalInfo.slice';
import {
  IDataRecipientDetails,
  ISenderDetailsCardData,
  updateRecipientDetailsData,
  updateSenderDetailsCardData,
  updateSenderDetailsData,
  updateSendMoneyData,
  updateSendMoneyState,
} from 'store/slices/transaction';

import { DEFAULT_SEND_MONEY_STEPS } from '../slices/transaction/sender/constants';
import { store } from '../store';

export const clearSendMoneyAction = (dispatch: typeof store.dispatch) => {
  dispatch(
    updateSendMoneyData({
      fromAmount: '',
      toAmount: '',
      fee: '',
      debet: '',
      debit: '',
      rate: {
        rate: '',
        to: '',
        date: '',
        from: '',
      },
      delivery: '',
    }),
  );
  dispatch(
    updateSendMoneyState({
      fromCountry: '',
      toCountry: '',
      fromCurrency: '',
      toCurrency: '',
      steps: DEFAULT_SEND_MONEY_STEPS,
      id: '',
    }),
  );
};

export const clearSenderDetailsAction = (dispatch: typeof store.dispatch) => {
  return dispatch(
    updateSenderDetailsData({
      firstName: '',
      lastName: '',
      country: '',
      city: '',
      street: '',
      zip: '',
      phone: '',
      email: '',
      day: '',
      month: '',
      year: '',
      date_of_birth: '',
    }),
  );
};

export const clearSenderDetailsCardAction = (dispatch: typeof store.dispatch) => {
  return dispatch(
    updateSenderDetailsCardData({
      isNewCard: false,
      purpose_payment: '',
      source_founds: '',
      pan: '',
      expDate: '',
      isSave: false,
      dataId: '',
      defaultCardId: '',
      cardHolderName: '',
    }),
  );
};

export const clearRecipientDetailsAction = (dispatch: typeof store.dispatch) => {
  dispatch(
    updateRecipientDetailsData({
      firstName: '',
      lastName: '',
      cardNumber: '',
      cardholderName: '',
    }),
  );
};

export const clearUserInfoAction = (dispatch: typeof store.dispatch) => {
  dispatch(
    setUserInfo({
      id: '',
      first_name: '',
      last_name: '',
      country: '',
      city: '',
      street: '',
      zip: '',
      phone: '',
      firstedit: false,
      passwordSet: false,
      code: null,
      email: '',
      date_of_birth: '',
    }),
  );
};

export const updateRecipientDetailsDataAction = (
  dispatch: typeof store.dispatch,
  values: IDataRecipientDetails,
) => {
  dispatch(
    updateRecipientDetailsData({
      firstName: values?.firstName,
      lastName: values?.lastName,
      cardNumber: values?.cardNumber.replace(CONSTANTS.REGEXP.NON_SPACES, ''),
      cardholderName: values?.cardholderName,
    }),
  );
};

export const updateSenderDetailsCardDataAction = (
  dispatch: typeof store.dispatch,
  values: ISenderDetailsCardData,
  selectedCardId: string,
) => {
  dispatch(
    updateSenderDetailsCardData({
      isNewCard: values?.isNewCard,
      defaultCardId: values?.defaultCardId,
      purpose_payment: values?.purpose_payment,
      source_founds: values?.source_founds,
      pan:
        selectedCardId?.replace(CONSTANTS.REGEXP.NON_SPACES, '') ||
        values?.pan?.replace(CONSTANTS.REGEXP.NON_SPACES, ''),
      dataId: selectedCardId || '',
      isSave: values?.isSave,
      expDate: values?.expDate,
      cardHolderName: values?.cardHolderName,
    }),
  );
};

export const resetFormState = (dispatch: typeof store.dispatch) => {
  dispatch(
    setPartialCardsState({ formData: undefined, isHidden: undefined, isAutoSubmit: undefined }),
  );
};
