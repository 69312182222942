import styled from 'styled-components';
import Select from 'react-select';

export const StyledSelect = styled(Select)`
  .menu::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  .menu::-webkit-scrollbar-thumb {
    background: #d0d2d9;
  }
`;

export const SelecWrapper = styled.label<{ error: boolean }>`
  // Depend on page animation
  z-index: 99999;
  position: relative;
  border: ${({ error }) => (error ? '1px solid #FF5C5C' : '1px solid #C7C9D1')};
  background: ${({ error }) => (error ? '#FFF7F7' : 'transparent')};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8.5px 16px;
  color: #9294a0;
  font-family: Lato-Regular;
  font-weight: 400;
  display: block;
  height: 72px;
  :hover {
    border-color: #a3a5af;
  }
  :focus-within {
    border: 2px solid #2d48ed;
  }
`;

export const styles = {
  dropdownIndicator: (styles: any, state: any) => {
    return {
      ...styles,
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      color: '#222229',
      border: 'none',
      marginTop: '-20px',
      padding: 0,
    };
  },
  control: (styles: any) => {
    return {
      ...styles,
      width: '100%',
      border: 'none',
      position: 'relative',
      boxShadow: 'none',
      marginBottom: '-3px',
      ':focus': {
        border: 'none',
        outline: 'none',
      },
    };
  },
  container: (styles: any) => {
    return {
      ...styles,
      ':focus': {
        border: 'none',
        outline: 'none',
        marginBottom: '-5px',
      },
    };
  },
  valueContainer: (styles: any) => {
    return {
      ...styles,
      padding: '0px',
      marginRight: '20px',
    };
  },
  indicatorSeparator: (styles: any) => {
    return {
      ...styles,
      display: 'none',
    };
  },
  menu: (styles: any) => {
    return {
      ...styles,
      maxHeight: 333,
      width: 'calc(100% + 32px)',
      marginLeft: '-16px',
    };
  },
  option: (styles: any, { isSelected }: any) => {
    return {
      ...styles,
      color: '#333333',
      '&:hover': {
        background: '#F4F4F6 !important',
      },
      background: isSelected && '#F4F4F6 !important',
      div: {
        background: isSelected && '#F4F4F6 !important',
      },
      '&:active': {
        background: '#F4F4F6 !important',
        div: {
          background: '#F4F4F6 !important',
        },
      },
    };
  },
  placeholder: (styles: any) => {
    return {
      ...styles,
      color: '#222229',
    };
  },
};
