import { IYearsData } from 'pages/steps/type/transaction';

const getYearsData = () => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 100;
  const years = [];

  for (let i = currentYear - 18; i >= startYear; i--) {
    years.push({
      value: String(i),
      title: String(i),
    });
  }

  return years;
};

export const YEAR_OPTIONS: IYearsData[] = getYearsData();
