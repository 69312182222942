import { usePersonal } from 'pages/Personal/hooks';
import { useAppProvider } from 'providers';

import { RecipientDetails } from './RecipientDetails';

export const RecipientDetailsContainer = () => {
  const { isProfileLoaded, profile } = usePersonal();
  const { isPersistDone } = useAppProvider();

  if (isPersistDone && isProfileLoaded && !!profile) {
    return <RecipientDetails />;
  }

  return null;
};
