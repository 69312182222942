import ReactGA from 'react-ga4';

import { envConfig } from 'config/env';

let __ga: typeof ReactGA | undefined;

export const initAnalytics = () => {
  if (envConfig.analytics.key) {
    ReactGA.initialize(envConfig.analytics.key, {
      gaOptions: {
        userId: 'Not-Logged',
      },
    });
    __ga = ReactGA;
  }
};

export const getAnalytics = () => {
  if (!__ga) {
    // eslint-disable-next-line no-console
    console.error('Analytics was not initialized!');

    return {
      event: () => {},
    };
  }

  return __ga;
};
