import { Fetcher } from 'utils';

import { OptionRepo } from './OptionRepo';

export class OptionService {
  private repo: OptionRepo;

  constructor(fetcher: Fetcher) {
    this.repo = new OptionRepo(fetcher);
  }

  public async getPurposes() {
    const data = await this.repo.getPurposes();

    return data;
  }

  public async getFounds() {
    const data = await this.repo.getFounds();

    return data;
  }
}
