import { createSlice } from '@reduxjs/toolkit';

const paymentHistorySlice = createSlice({
  name: 'paymentHistory',
  initialState: {
    paymentHistoryList: [],
  },

  reducers: {
    setPaymentHistoryList(state, { payload }) {
      return {
        ...state,
        paymentHistoryList: payload,
      };
    },
  },
});
export const { setPaymentHistoryList } = paymentHistorySlice.actions;
export const paymentHistoryReducer = paymentHistorySlice.reducer;
