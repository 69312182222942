import { Fetcher } from 'utils';

import { CountryRepo } from './CountryRepo';

export class CountryService {
  private repo: CountryRepo;

  constructor(fetcher: Fetcher) {
    this.repo = new CountryRepo(fetcher);
  }

  public async getCountries() {
    const data = await this.repo.getCountries();

    return data;
  }
}
