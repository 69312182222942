import { createSlice } from '@reduxjs/toolkit';

import { loadingInitialData, updateLoadingProvider } from '../../loading';
import { Action } from '../../types';

import { IProfile, IUserInfo } from './personalInfo.types';

const personalInfoSlice = createSlice({
  name: 'personalInfo',
  initialState: {
    loadingData: loadingInitialData,
    userInfo: <IUserInfo | IProfile>{
      id: '',
      first_name: '',
      last_name: '',
      country: '',
      city: '',
      street: '',
      zip: '',
      phone: '',
      firstedit: false,
      passwordSet: false,
      code: null,
      email: '',
      date_of_birth: '',
    },
    isPersonalInfoFilled: false,
  },
  reducers: {
    setUserLoading: updateLoadingProvider,

    setUserInfo: (state, action: Action<IUserInfo | IProfile | undefined>) => {
      if (!action.payload) return state;

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.payload,
        },
      };
    },

    setIsPersonalInfoFilled(state, { payload }) {
      return {
        ...state,
        isPersonalInfoFilled: payload,
      };
    },
  },
});

export const { setUserInfo, setIsPersonalInfoFilled, setUserLoading } = personalInfoSlice.actions;
export const personalInfoReducer = personalInfoSlice.reducer;
