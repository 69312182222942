import styled from 'styled-components';

const StyledWithBackgroundFlex = styled.div`
  margin: 24px 0 16px;
  display: flex;
  flex-direction: column;
  background: #ebfaff;
  border: 1px solid #ade9ff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px 24px;
`;

const StyledBackground = styled(StyledWithBackgroundFlex)`
  width: 100%;
  flex-direction: column;
  padding: 26px 26px 24px;
  align-items: flex-start;
  margin: 0 0 16px;
  @media screen and (max-width: 1279px) {
    margin-bottom: 24px;
  }
`;

const StyledContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  margin-top: 18px;
  > :first-child {
    margin-right: 32px;
  }
`;

const StyledFlexItem = styled.div`
  text-align: center;
  span {
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #4c4e57;
  }
  p {
    margin-top: 7px;
  }
`;

const StyledColumnItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  margin-bottom: 16px;
`;

const FlexSection = styled.div`
  display: flex;
  margin-top: 16px;
`;

const StyledFlexItemHeir = styled(StyledFlexItem)`
  text-align: left;
  margin-top: 16px;
  p {
    font-weight: bold;
    font-size: 20px;
    margin: 0;
  }
`;

const StyledInfoIcon = styled.div`
  width: 32px;
  height: 32px;
  image {
    width: 100%;
    height: 100%;
  }
`;

const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid #f0f0f2;
  margin-bottom: 20px;
`;

const CheckboxContainer = styled.div`
  margin: 24px 0;
`;

export {
  StyledWithBackgroundFlex,
  StyledBackground,
  StyledContent,
  StyledFlexItem,
  StyledFlexItemHeir,
  StyledInfoIcon,
  StyledColumnItem,
  FlexSection,
  CheckboxContainer,
  Line,
};
