import { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react';

import { useGetPersistAvailable } from 'hooks';

interface LoaderContext {
  isPersistDone: boolean;
  loader: boolean;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  loadType: string;
  setLoadType: React.Dispatch<React.SetStateAction<string>>;
}

const Context = createContext<LoaderContext>({
  loader: false,
  isPersistDone: false,
  loadType: '',
  setLoader: () => {},
  setLoadType: () => {},
});

export const AppProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [loadType, setLoadType] = useState<string>('');
  const isPersistDone = useGetPersistAvailable();

  const state = useMemo(
    () => ({ loader, setLoader, loadType, setLoadType, isPersistDone }),
    [loader, setLoader, loadType, setLoadType, isPersistDone],
  );

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

/**
 * @return {object}
 */
export const useAppProvider = () => {
  const context = useContext(Context);

  if (!context) throw new Error('useAppProvider must be used within a AppProvider');

  return context;
};
