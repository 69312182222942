import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<{ checked?: boolean }>`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${(props) => (props?.checked ? 'blue' : '#fff')};
  border-radius: 50px;
  border: ${(props) => (!props?.checked ? '1px solid black' : '1px solid blue')};
  transition: all 150ms;
  box-sizing: border-box;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${(props) => (props?.checked ? 'visible' : 'hidden')};
  }
`;
