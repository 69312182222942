import { createSlice } from '@reduxjs/toolkit';

import { Action } from '../../types';

import { CardsStateFields } from './cards.types';

const cardsSlice = createSlice({
  name: 'cards',
  initialState: <CardsStateFields>{
    formData: '',
  },

  reducers: {
    setPartialCardsState(state, action: Action<CardsStateFields>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});
export const { setPartialCardsState } = cardsSlice.actions;
export const cardsSliceReducer = cardsSlice.reducer;
