import { useMemo, useState } from 'react';

import spinner from 'assets/images/Spinner.svg';
import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';

import { UploadedFileNameWrapper, FileName, DeleteWrapper, SpinnerWrapper } from './style';
import { propsType } from './types';

export const UploadedFileName = ({
  name,
  handleDelete,
  uploadingFileName = null,
  isLoading,
}: propsType) => {
  const [isDelete, setIsDelete] = useState(false);

  const loading = useMemo(
    () => (typeof isLoading === 'boolean' ? isLoading : isDelete),
    [isLoading, isDelete],
  );

  const deleteClick = () => {
    handleDelete();
    setIsDelete(true);
  };

  return (
    <UploadedFileNameWrapper>
      <FileName>{name}</FileName>
      <DeleteWrapper onClick={deleteClick} disabled={loading}>
        <TextField variant="UP_H7">
          {!loading && !uploadingFileName ? (
            <I18n tKey="dropdown_delete" />
          ) : (
            <SpinnerWrapper>
              <img src={spinner} alt="spinner" />
            </SpinnerWrapper>
          )}
        </TextField>
      </DeleteWrapper>
    </UploadedFileNameWrapper>
  );
};
