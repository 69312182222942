import styled from 'styled-components';

const Wrapper = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  > :nth-child(2) {
    width: 100%;
  }
  @media (max-width: 960px) {
    width: 100%;
  }
  @media (max-width: 647px) {
    padding: 0 16px;
  }
`;

const MainContainer = styled.div`
  width: 480px;
  > .input {
    margin-bottom: 26px;
  }
  > .error-message {
    padding: 0 6px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #ff5c5c;
  }
  @media (max-width: 647px) {
    width: 100%;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  margin-bottom: 24px;
  > .header {
    @media (max-width: 1279px) {
      font-weight: 500;
      font-size: 26px;
      line-height: 32px;
    }
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const UnderLinkWrapper = styled(LinkWrapper)`
  margin-top: 24px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  > * {
    width: 600px;
    @media (max-width: 647px) {
      width: 100%;
    }
  }
  > button {
    margin: 20px 0;
    padding: 16px 24px;
    background: #3848c6;
    border-radius: 6px;
    @media (max-width: 647px) {
      padding: 16px;
    }
  }
`;

export { Wrapper, MainContainer, Title, LinkWrapper, ButtonWrapper, UnderLinkWrapper };
