export const dayData = (amountOfDays: number) => {
  const dayData = [];

  for (let day = 1; day <= amountOfDays; day++) {
    dayData.push({
      value: `0${day}`.slice(-2),
      title: `0${day}`.slice(-2),
    });
  }

  return dayData;
};
