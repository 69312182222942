import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { PaymentHistoryApi } from 'apiListURL/history';
import { RepeatIcon, Vector } from 'assets/images';
import { TextField, StyledLink, Pagination, MessageBox, Loader } from 'components';
import { InfoTips } from 'components/InfoTips';
import { StyledLinkText } from 'components/PrimaryButtonComp/style';
import { I18n } from 'components/Translation';
import { CONSTANTS } from 'config/constants';
import { translate, toastAlert } from 'helpers';
import { useAuth, useTranslation } from 'providers';
import { getResourceManager } from 'resources';
import { AppRoutes } from 'routes/constants';
import { getMonitoring } from 'services/monitoring';
import { RootState } from 'store';
import { setPaymentHistoryList } from 'store/slices/paymentHistory';
import { ProfileStatus } from 'store/slices/personal';
import {
  updateRecipientDetailsData,
  updateSenderDetailsCardData,
  updateSendMoneyState,
  updateSendMoneyData,
} from 'store/slices/transaction';
import { ICardsTypes } from 'types/cards';
import { IHistoryGet } from 'types/types';

import style from './popupStyle.module.scss';
import {
  PrimaryButton,
  Wrapper,
  TotalOrdering,
  Block,
  LinkWrapper,
  StyledBackground,
  PaymentHistoryTitle,
  TableWrapper,
  Table,
  MonthData,
  ActionStyles,
  Title,
  ActionTitles,
  PaginationWrapper,
  VectorWrapper,
  IconBox,
} from './styles';
import { ITransactionData } from './types';

interface IScreenWidth {
  innerWidth: number;
}

enum Status {
  approved = 'accepted',
  rejected = 'rejected',
  pending = 'pending',
}

export const TransactionHistory = () => {
  const { t } = useTranslation();

  const titleRef = useRef<HTMLDivElement | null>(null);

  const [, setIsLoadingData] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState<IScreenWidth>(getWindowSize());
  const [textWidth, setTextWidth] = useState<number>(0);
  const isPersonalInfoFilled = useSelector(
    (state: RootState) => state?.personalInfo?.isPersonalInfoFilled,
  );
  const { firstedit: isFirstEdit, email } = useSelector(
    (state: RootState) => state.personalInfo.userInfo,
  );
  const verification = useSelector(
    (state: RootState) => state?.verification?.verificationInfo?.verificationStatus,
  );
  const [query, setQuery] = useState<IHistoryGet>({
    page: 0,
    size: 10,
    ascending: false,
    sortBy: 'createdAt',
  });
  const navigate = useNavigate();
  const { settings, profile, isLoading } = useAuth();

  const month_names_short = [
    `${translate(t, 'month_jan')}`,
    `${translate(t, 'month_feb')}`,
    `${translate(t, 'month_mar')}`,
    `${translate(t, 'month_apr')}`,
    `${translate(t, 'month_may')}`,
    `${translate(t, 'month_jun')}`,
    `${translate(t, 'month_jul')}`,
    `${translate(t, 'month_aug')}`,
    `${translate(t, 'month_sep')}`,
    `${translate(t, 'month_oct')}`,
    `${translate(t, 'month_nov')}`,
    `${translate(t, 'month_dec')}`,
  ];
  const [isActive, setIsActive] = useState<string>('');
  const [isRotate, setIsRotate] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [drawingList, setDrawingList] = useState<ITransactionData[]>([]);

  const { settings: insertedSettings, alreadyTransferred } = settings || {};
  const {
    defaultCurrency = 'EUR',
    upgradeLimit = 15000,
    startLimit = 1000,
    upgradeMonthlyLimit = 3000,
  } = insertedSettings || {};
  const hasSettings = !!defaultCurrency && !!upgradeLimit;
  const isVerifiedProfile = profile?.status_w === ProfileStatus.VERIFIED;

  const dispatch = useDispatch();
  const message = hasSettings
    ? `${translate(t, 'if_you_pass_verification_new', {
        limit: `${upgradeLimit ?? '-'} ${defaultCurrency ?? ''}`,
      })}`
    : '';
  const paginate = (pageNumber: number) => {
    const data = { ...query };
    data.page = pageNumber - 1;
    setQuery(data);
    getHistory(data);
  };

  const paymentHistoryList = useSelector(
    (state: RootState) => state?.paymentHistory?.paymentHistoryList,
  );

  const getHistory = (data: IHistoryGet) => {
    PaymentHistoryApi.getPaymentHistory(data)
      .then((res) => {
        setTotalCount(res?.data?.count);
        dispatch(setPaymentHistoryList(res?.data?.data));
        setDrawingList(res?.data?.data);
      })
      .then(() => window.scrollTo(0, 0))
      .catch(() => toastAlert('error'));
  };

  const sortHistory = (name: string) => {
    setIsRotate('');
    const data = { ...query };
    if (data.sortBy !== name) {
      data.ascending = true;
      data.sortBy = name;
    } else {
      data.ascending = !data?.ascending;
    }
    setQuery(data);
    if (isActive === name && isRotate !== name) {
      setIsRotate(name);
    }
    setIsActive(name);
    PaymentHistoryApi.getPaymentHistory(data)
      .then((res) => {
        setTotalCount(res?.data?.count);
        dispatch(setPaymentHistoryList(res?.data?.data));
        setDrawingList(res?.data?.data);
      })
      .catch(() => toastAlert('error'));
  };

  const alreadyTransferredFinal = {
    transfersInThisMonth: alreadyTransferred?.amountForOneLastMonth ?? '0.00',
    /**
     * total amount in 6 month period
     */
    totalAmountOfTransfers: alreadyTransferred?.amountForSixLastMonth ?? '0.00',
  };

  const [repeatId, setRepeatId] = useState<number>(0);

  const repeatHandler = (id: number) => {
    setRepeatId(id);
    setIsLoadingData(true);
    getResourceManager()
      .transaction.repeatTransaction(id)
      .then((data) => {
        const { cardId, sendMoney, senderDetails, recipientDetails } = data;
        const { receiverData } = recipientDetails || {};
        const { senderData, sourceFounds, purposePayment } = senderDetails || {};
        const { fromAmount, fromCurrency, toCurrency } = sendMoney || {};

        dispatch(
          updateSenderDetailsCardData({
            source_founds: sourceFounds,
            purpose_payment: purposePayment,
            dataId: cardId,
            defaultCardId: cardId,
          }),
        );
        dispatch(updateRecipientDetailsData(receiverData));
        dispatch(
          updateSendMoneyState({
            fromCountry: senderData.country,
            toCountry: receiverData?.country,
            receiverEmail: data?.notifyEmail,
            fromCurrency,
            toCurrency,
          }),
        );
        dispatch(
          updateSendMoneyData({
            fromAmount: `${Math.round(fromAmount)}`,
          }),
        );
        navigate(AppRoutes.moneySteps.sendMoney, { state: { isRepeat: true } });
      })
      .catch((err) => {
        getMonitoring()?.captureException(err);
        toastAlert('error', err.message);
      })
      .finally(() => {
        setRepeatId(0);
        setIsLoadingData(false);
      });
  };

  const showDate = (data: ITransactionData) => {
    return `${moment(data?.createdAt).format('D')} ${
      month_names_short[Number(moment(data?.createdAt).format('MM')) - 1]
    }, ${moment(data?.createdAt).format('yyyy')}`;
  };

  useEffect(() => {
    getHistory(query);
  }, []);

  function getWindowSize() {
    const { innerWidth } = window;
    return { innerWidth };
  }

  useEffect(() => {
    const title = titleRef?.current;

    if (title?.clientWidth) {
      setTextWidth(title.clientWidth + 1);
    }

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [settings]);

  const isEmailIdAvailable = textWidth < windowSize.innerWidth - 32;

  return (
    <Wrapper>
      <Title>
        <TextField className="title-email" variant="H3" color="n900">
          <div
            ref={isEmailIdAvailable ? titleRef : undefined}
            id={isEmailIdAvailable ? 'email-id' : 'gradient-title'}
          >
            {email}
          </div>
        </TextField>
        {isFirstEdit ? (
          <TextField className="subtitle" variant="P7" color="n700">
            {' '}
            <I18n tKey="please_provide_local" />{' '}
            <StyledLink url={AppRoutes.private.personal}>
              <TextField variant="P7" color="n700">
                <I18n tKey="text_personal_information" />{' '}
              </TextField>
            </StyledLink>
            <I18n tKey="this_takes_couple_minutes_local" />
          </TextField>
        ) : (
          <TextField className="subtitle" variant="P7" color="n700">
            <I18n tKey="your_transfers_stored_here" />
          </TextField>
        )}
      </Title>

      <StyledBackground verification={verification ? 'verified' : 'notVerified'}>
        <TotalOrdering verification={verification ? 'verified' : 'notVerified'}>
          <MonthData>
            {!isVerifiedProfile ? (
              <Block>
                <InfoTips message={message}>
                  <TextField variant="P13" color="n800">
                    <I18n tKey="current_limit_history" />
                  </TextField>
                </InfoTips>
                <TextField variant="P4" fw="800" color="n900">
                  {isLoading ? (
                    <Loader variant="sm" />
                  ) : (
                    <>{hasSettings ? `${startLimit} ${defaultCurrency}` : null}</>
                  )}
                </TextField>
              </Block>
            ) : (
              <Block>
                <InfoTips
                  message={`${translate(t, 'you_can_send_local', {
                    limit: `${upgradeLimit} ${defaultCurrency}`,
                  })} ${translate(
                    t,
                    'month_limit_local',
                  )} ${upgradeMonthlyLimit} ${defaultCurrency}`}
                >
                  <TextField variant="P13" color="n800">
                    <I18n tKey="current_limit_history" />
                  </TextField>
                </InfoTips>
                <TextField variant="P4" fw="800" color="n900">
                  {isLoading ? (
                    <Loader variant="sm" />
                  ) : (
                    <>{hasSettings ? `${upgradeLimit} ${defaultCurrency}` : null}</>
                  )}
                </TextField>
              </Block>
            )}
            <Block>
              <TextField variant="P13" color="n800">
                <I18n tKey="month_limit_local" />
              </TextField>
              <TextField variant="P4" fw="800" color="n900">
                {isLoading ? (
                  <Loader variant="sm" />
                ) : (
                  <>
                    {hasSettings &&
                      `${isVerifiedProfile ? upgradeMonthlyLimit : startLimit} ${defaultCurrency}`}
                  </>
                )}
              </TextField>
            </Block>
            <Block>
              <TextField variant="P13" className={style.break} color="n800">
                <I18n tKey="transfer_in_this_month_local" />
              </TextField>
              <TextField variant="P4" fw="800" color="n900">
                {isLoading ? (
                  <Loader variant="sm" />
                ) : (
                  <>
                    {hasSettings &&
                      `${alreadyTransferredFinal?.transfersInThisMonth} ${defaultCurrency}`}
                  </>
                )}
              </TextField>
            </Block>
            <Block>
              <TextField className={style.break} variant="P13" color="n800">
                <I18n tKey="total_amount_of_transfers_in_six_month_period" />
              </TextField>
              <TextField variant="P4" fw="800" color="n900">
                {isLoading ? (
                  <Loader variant="sm" />
                ) : (
                  <>
                    {alreadyTransferredFinal.totalAmountOfTransfers} {defaultCurrency}
                  </>
                )}
              </TextField>
            </Block>
          </MonthData>

          {!verification ||
            (verification === ProfileStatus.ACTIVE && (
              <LinkWrapper>
                <StyledLink
                  url={
                    isPersonalInfoFilled
                      ? AppRoutes.private.increaseMonthlyLimit
                      : AppRoutes.private.goToProfile
                  }
                >
                  <StyledLinkText>
                    <TextField variant="H7" color="a500">
                      {hasSettings && (
                        <>
                          <I18n tKey="upgrade_limit_to_local" /> {upgradeLimit} {defaultCurrency}{' '}
                          <I18n tKey="over_six_month_period_local" />
                        </>
                      )}
                    </TextField>
                  </StyledLinkText>
                </StyledLink>
              </LinkWrapper>
            ))}

          <PrimaryButton
            onClick={() => {
              navigate(AppRoutes.moneySteps.sendMoney);
            }}
            width={CONSTANTS.HUNDREED_PERCENT}
          >
            <TextField variant="P8" color="white">
              <I18n tKey="send_money" />
            </TextField>
          </PrimaryButton>
        </TotalOrdering>
      </StyledBackground>

      <PaymentHistoryTitle>
        <TextField variant="H4" color="n900">
          <I18n tKey="payment_history" />
        </TextField>
      </PaymentHistoryTitle>
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <th className="date-hide" onClick={() => sortHistory('createdAt')}>
                <TextField variant="P14" color="n900">
                  <I18n tKey="date" />
                  <VectorWrapper
                    active={isActive === 'createdAt'}
                    revers={isRotate === 'createdAt'}
                  >
                    <Vector />
                  </VectorWrapper>
                </TextField>
              </th>
              <th>
                <TextField variant="P14" color="n900">
                  <I18n tKey="status" />
                </TextField>
              </th>
              <th onClick={() => sortHistory('recipient')}>
                <TextField variant="P14" color="n900">
                  <I18n tKey="recipient" />
                  <VectorWrapper
                    active={isActive === 'recipient'}
                    revers={isRotate === 'recipient'}
                  >
                    <Vector />
                  </VectorWrapper>
                </TextField>
              </th>
              <th>
                <TextField variant="P14" color="n900">
                  <I18n tKey="payment_method" />
                </TextField>
              </th>
              <th className="amount-title">
                <TextField variant="P14" color="n900">
                  <I18n tKey="amount" />
                </TextField>
              </th>
              <th>
                <TextField variant="P14" color="n900">
                  <I18n tKey="currency" />
                </TextField>
              </th>
              <th>
                <TextField variant="P14" color="n900">
                  <I18n tKey="actions" />
                </TextField>
              </th>
            </tr>
          </thead>
          <tbody>
            {paymentHistoryList?.length ? (
              drawingList?.map((data: ITransactionData) => (
                <tr key={data?.id} className={data?.status === Status.rejected ? 'rejected' : ''}>
                  <td>
                    <TextField variant="P7" color="n800">
                      {showDate(data)}
                    </TextField>
                  </td>
                  <td>
                    {data?.status === Status.approved && (
                      <TextField className="accepted" variant="P7" color="n800">
                        <I18n tKey="approved" />
                      </TextField>
                    )}
                    {data?.status === Status.rejected && (
                      <TextField variant="P7" color="n800">
                        <I18n tKey="rejected" />
                      </TextField>
                    )}
                    {data?.status === Status.pending && (
                      <TextField variant="P7" color="n800">
                        <I18n tKey="pending" />
                      </TextField>
                    )}
                  </td>

                  <td>
                    <TextField variant="P7" color="n800">
                      {data?.receiverName}
                    </TextField>
                  </td>
                  <td>
                    <TextField variant="P7" color="n800">
                      {`${CONSTANTS.CARD_TYPES[data?.card?.type as keyof ICardsTypes]} `}
                      <I18n tKey="ending_in" />{' '}
                      {data?.card?.pan?.substring(data?.card?.pan?.length - 4)}
                    </TextField>
                  </td>
                  <td className="amount">
                    <TextField variant="P7" color="n900">
                      {data?.debit && `${data?.debit}`}
                    </TextField>
                  </td>
                  <td>
                    <TextField variant="P7" color="n900">
                      {data?.fromAmount && `${data?.fromCurrency}`}
                    </TextField>
                  </td>
                  <td className="actions">
                    <ActionStyles>
                      {data?.status === Status.approved && (
                        <ActionTitles
                          className={style.repeat_wrapper}
                          onClick={() => repeatHandler(data?.id)}
                        >
                          <TextField variant="P8" color="a500" className={style.repeat}>
                            <I18n tKey="repeat" />
                          </TextField>
                          <IconBox
                            key={data?.id}
                            className={data?.id === repeatId ? style.loading : style.notLoading}
                          >
                            <RepeatIcon />
                          </IconBox>
                          <MessageBox className="message" text="Repeat" />
                        </ActionTitles>
                      )}
                    </ActionStyles>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="empty-data-message">
                <TextField as="td" className="message" variant="P7" color="n800">
                  <I18n tKey="you_havent_sent_money" />
                </TextField>
              </tr>
            )}
          </tbody>
        </Table>
      </TableWrapper>
      <PaginationWrapper>
        <TextField variant="P9" color="n700">
          <I18n tKey="showing" />{' '}
          <TextField variant="P9" color="n900">
            {query.size * query.page + 1}
          </TextField>{' '}
          <I18n tKey="to" />{' '}
          <TextField variant="P9" color="n900">
            {query.size + (query.page * query.size + 1) > totalCount
              ? totalCount
              : query.size * (query.page + 1)}
          </TextField>{' '}
          <I18n tKey="of" />{' '}
          <TextField variant="P9" color="n900">
            {totalCount}
          </TextField>{' '}
          <I18n tKey="payments" />
        </TextField>
        {query.size <= totalCount && (
          <Pagination
            currentPage={query.page + 1}
            postsPerPage={query.size}
            totalPosts={totalCount}
            paginate={paginate}
            totalPages={totalCount / query.size}
          />
        )}
      </PaginationWrapper>
    </Wrapper>
  );
};
