import debounce from 'lodash/debounce';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { NavigateOptions, To, useLocation, useNavigate } from 'react-router';

import { useAuth } from 'providers';

import { AppRoutes } from './constants';

type PrivateRouteProps = {
  redirectTo?: string;
  children: ReactNode;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  redirectTo = AppRoutes.public.signIn,
}) => {
  const { isAuth, isPreventPrivateRedirect } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const debouncedNavigate = debounce(
    (to: To, options?: NavigateOptions) => navigate(to, options),
    300,
  );
  /**
   * При подгрузке стейта после успешной авторизации еще нет информации об авторизации
   */
  const hasAccessTokenHash = useMemo(() => location.hash?.indexOf('access_token') > -1, [location]);

  useEffect(() => {
    if (!isAuth && !isPreventPrivateRedirect) {
      debouncedNavigate(redirectTo, { state: { from: location } });
    }
  }, [isPreventPrivateRedirect, location, isAuth, redirectTo, debouncedNavigate]);

  /**
   * TODO: Удалить после того, как удасться решить баг с авторизацией
   */
  console.info('User auth state: ', isAuth);

  if (isAuth) {
    return <>{children}</>;
  }

  if (hasAccessTokenHash) {
    return null;
  }

  return null;
};

export { PrivateRoute };
