import toast, { ToastType } from 'react-hot-toast';

import toastClose from 'assets/icons/toastClose.svg';
import { TextField } from 'components/TextField';

import { ImageWrapper, ToastWrapper } from './style';

export const toastAlert = (toastType: ToastType, message?: string) => {
  const userMessage = !window.navigator.onLine ? 'No internet connection' : message;

  toast.custom(
    (t) => (
      <ToastWrapper toastType={toastType} animate={t.visible}>
        <TextField variant="P7">{userMessage}</TextField>
        <ImageWrapper onClick={() => toast.dismiss(t.id)}>
          <img src={toastClose} alt="close icon" />
        </ImageWrapper>
      </ToastWrapper>
    ),
    {
      position: 'top-center',
      duration: 900000,
    },
  );
};
