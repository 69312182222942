import styled from 'styled-components';

export const StyledDiv = styled.div<{ padding?: string }>`
  overflow-y: hidden;
  padding: ${({ padding }) => padding ?? ''};
`;

export const StyledContainer = styled.div<{ margin?: string }>`
  width: 100%;
  margin: ${({ margin }) => margin ?? ''};
  .error-message {
    margin-bottom: 3px;
    padding: 0 4px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #ff5c5c;
  }
`;

export const StyledMessageUnderInput = styled.p`
  margin-top: 16px;
  margin-bottom: 24px;
  color: #686b78;
  font-family: Lato-Regular;
  font-size: 13px;
  button {
    font-family: Lato-Regular;
    font-size: 13px;
    color: #2d48ed;
    font-weight: 700;
    margin-left: 8px;
    text-decoration: none;
    border: none;
    background: transparent;
    cursor: pointer;
  }
  @media screen and (max-width: 647px) {
    display: flex;
    flex-direction: column;
    button {
      margin-bottom: 8px;
      margin-left: 0;
    }
  }
`;

export const StyledSectionWithEqualFields = styled.div<{
  marginBottom?: string;
}>`
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '24px'};
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  .firstChild {
    width: 50%;
  }
  .secondChild {
    width: 50%;
  }
  .onlyChild {
    width: 100%;
  }
  .cardholder {
    width: 464px;
  }
  .cvv-number {
    > label {
      width: 120px;
    }
    + .input-error {
      width: 120px;
    }
    > * {
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
  @media screen and (max-width: 647px) {
    flex-direction: column;
    .firstChild {
      width: 100% !important;
      margin-right: 0;
    }
    .secondChild,
    .onlyChild {
      width: 100% !important;
    }
    .cardholder {
      width: 100%;
    }
  }
`;

export const CardType = styled.div`
  > img {
    width: 35px;
    margin: auto;
    position: relative;
    top: 2px;
  }
  > svg {
    width: 35px;
  }
`;

export const StyledSectionWithNotEqualFields = styled(StyledSectionWithEqualFields)<{
  jc?: string;
}>`
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '16px'};
  display: flex;
  justify-content: ${({ jc }) => jc ?? 'space-between'};
  width: 100%;
  gap: 16px;
  .street {
    width: 100% !important;
  }
  .streetWrapper {
    width: 50%;
    @media screen and (max-width: 648px) {
      width: auto;
    }
  }
  .zip-code {
    width: 50%;
    > label > .input-container > input {
      width: auto;
    }
  }
  .card-number {
    width: 294px;
    @media screen and (max-width: 647px) {
      width: auto;
    }
    > * {
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
  @media screen and (max-width: 647px) {
    flex-direction: column;
    .street,
    .zip-code {
      width: 100%;
    }
  }
`;

export const StyledSectionWithHorizontalFields = styled(StyledSectionWithEqualFields)`
  .set-cursor {
    * {
      cursor: pointer;
    }
  }
  flex-direction: column;
  .firstChild,
  .secondChild {
    width: 100%;
  }
  @media screen and (max-width: 647px) {
    flex-direction: column;
    .firstChild,
    .secondChild {
      width: 100%;
    }
  }
`;

export const StyledCardInfoUpperPart = styled.div`
  margin-bottom: 25px;
  width: 100%;
  display: flex;
  gap: 16px;
  @media screen and (max-width: 647px) {
    flex-direction: column;
    width: 100%;
    .firstChild {
      width: 100%;
      margin-bottom: 16px;
      margin-right: 0;
    }
    .secondChild {
      width: 100%;
    }
  }
`;

export const StyledUpperPart = styled.div<{ isAlignLeftPage?: boolean }>`
  width: 100%;
  padding: ${({ isAlignLeftPage }) => (isAlignLeftPage ? 0 : '0 60px')};
  display: flex;
  flex-direction: column;
  align-items: ${({ isAlignLeftPage }) => (isAlignLeftPage ? 'flex-start' : 'center')};
  box-sizing: border-box;
  @media screen and (max-width: 647px) {
    padding: 0;
  }
`;

export const StyledCardInfoLowerPart = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .firstChild {
    width: 80%;
    margin-right: 16px;
  }
  .secondChild {
    width: 20%;
  }
  @media screen and (max-width: 647px) {
    flex-direction: column;
    .firstChild {
      width: 100%;
      margin-bottom: 16px;
      margin-right: 0;
    }
    .secondChild {
      width: 100%;
    }
  }
`;

export const StyledRecipientCardInfoLowerPart = styled.div`
  width: 100%;
  margin-bottom: 24px;
  @media screen and (max-width: 647px) {
    width: 100%;
  }
`;

export const StyledSectionTitle = styled.h3`
  font-family: Commissioner-Regular;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.8px;
  color: #222229;
  margin-bottom: 16px;
`;

export const StyledReviewPage = styled.div`
  width: 100%;
`;

export const StyledStepPagesLowerPart = styled.div`
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 647px) {
    width: auto;
  }
`;

export const Table = styled.table`
  width: 100%;
  border: 1px solid #f0f0f2;
  border-radius: 8px;

  tr {
    border-top: 1px solid #f0f0f2;
    .first-row {
      display: flex;
      gap: 16px;
      align-items: center;
      align-content: center;
    }
    .second-row {
      text-align: right;
    }
  }
  td {
    padding: 16px 16px;
    text-align: left;
    align-items: center;
    align-content: center;
  }
`;

export const IconBox = styled.div`
  > svg {
    width: 34px;
    padding: 0;
    text-align: center;
  }
`;
