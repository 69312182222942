export const defaultT: Record<string, string> = {
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  about: 'About',
  add_new_payment_card: 'Add new payment card',
  add_new_card: 'Add a new card',
  accepted: 'Accepted',
  accepted_payment_card_types: 'Accepted payment card types',
  actions: 'Actions',
  address: 'Address',
  all_transfers_stored_here: 'All data on your transfers is stored here',
  amount: 'Amount',
  recipient_recieve: 'Recipients receives',
  amount_send: 'Amount to send',
  amount_to_be_debited: 'Amount to be debited',
  almost_done: 'Almost done',
  and: 'and',
  approved: 'Approved',
  ask_question: 'Ask a question',
  auth_payment_institution: 'Authorised Payment Institution',
  back: 'Back',
  back_to_home: 'Back to home',
  bic_code: 'BIC: FAENGB22',
  cancel: 'Cancel',
  card_successfully_saved: 'Your credit card has been successfully saved',
  edit_locale: 'Edit translation',
  card_successfully_saved_local: 'Your payment card has been successfully saved',
  card_unavailable: 'Selected card is not valid anymore',
  card_info: 'Card Info',
  cards: 'Cards',
  card_number: 'Card number',
  cardholder_name_label: 'Name on card',
  change_password: 'Change password',
  change_email: 'Change email',
  choose_country: 'Choose country',
  city: 'City',
  click_on_your_link_local:
    'To access your Remitiva account, please click on the link provided in the email.',
  close_new_card: 'Close new card',
  code_expires_in: 'Code expires in',
  code_sent: 'A text message containing a code has been sent to your registered phone number',
  complaints_policy: 'Complaints Policy',
  complete_personal_details: 'Please, complete your personal details',
  complete_personal_details_description:
    'We collect personal information to comply with legal and anti-money laundering regulations, ensuring safe and secure transactions.',
  complete_receipt_details_description:
    'We collect personal information to comply with legal and anti-money laundering regulations, ensuring safe and secure transactions. You must have obtained the consent of the recipient for us to process their personal data.',
  complete_personal_payment_details_local: 'Please complete your payment details',
  confirm_password: 'Confirm password',
  contact_info: 'Contact Information',
  contact_support_team: 'Contact customer support team',
  continue: 'Continue',
  contacts: 'Contacts',
  country: 'Country',
  currency: 'Currency',
  current_password: 'Current password',
  cvv: 'CVV',
  data_successfully_saved_local: 'Your data has been successfully saved',
  password_successfully_recovered: 'Your new password has been successfully saved',
  date: 'Date',
  date_of_birth: 'Date of birth',
  your_date_of_birth: 'Your date of birth',
  date_nd: 'nd',
  date_rd: 'rd',
  date_st: 'st',
  date_th: 'th',
  day: 'Day',
  debited_from_your_card: 'Amount to be debited from your card',
  default: 'Default',
  describe_your_issue: 'Describe your issue',
  didnt_receive_code: "Didn't receive the confirmation code?",
  dont_have_account: "Don't have an account?",
  download_pdf: 'Download PDF',
  dropdown_send_money: 'Send Money',
  dropdown_edit_card: 'Edit Card',
  dropdown_make_default: 'Make Default',
  dropdown_delete: 'Delete',
  email: 'Email',
  email_input: 'Enter your email',
  ending_in: 'ending in',
  enter_code: ' Enter code',
  enter_code_message: 'Test mode: Enter code 1111',
  enter_correct_email: 'Enter correct email',
  enter_your_card_number: 'Enter your card number',
  enter_your_code: 'Enter your code',
  enter_your_email_for_reset_link_local:
    'Please enter your email registered on Remitiva and we will send you a password reset link.',
  enter_your_password: 'Enter your password',
  enter_your_new_password: 'Enter your new password',
  enter_your_phone_number: 'Enter your phone number',
  error_email_already_exist: 'This email already exists. Please Sign in',
  error_email_already_exist_local:
    'The account associated with this email already exists. Please Sign in',
  error_something_went_wrong: 'Something went wrong',
  error_phone_already_use: 'This phone number is already in use on another account',
  password_equality_error: 'New password cannot be the same as current password',
  error_something_went_wrong_try_later: 'Something went wrong, please try again later',
  error_invalid_card: 'Your card has been declined',
  error_unable_to_send_verification_code: 'Unable to send verification code',
  estimated_delivery: 'Estimated delivery',
  estimated_delivery_local: 'Estimated delivery',
  estimated_delivery_instantly: 'Instantly',
  estimated_delivery_not_later: 'Not later than',
  exceeded_amount_limit_local: 'Account limit exceeded',
  exchange_rate: 'Exchange rate',
  expiration_date: 'Expiration date',
  faq: 'FAQ',
  first_name: 'First name',
  fm_finance_ltd: 'FM FINANCE LTD',
  forgot_password: 'Forgot password?',
  footer_address_line_1: 'Suite 15-16 Pure Offices',
  footer_address_line_2: 'Hatherley Lane, Cheltenham Office Park',
  footer_address_line_3: 'GL51 6SH Cheltenham',
  footer_address_line_4: 'United Kingdom',
  footer_sentence_bottom:
    'FM FINANCE LTD Registration number: 11415496 Authorised Payment Institution UK FCA registration number: 815137 BIC: FAENGB22',
  footer_sentence_top:
    'Suite 15-16 Pure Offices Hatherley Lane, Cheltenham Office Park GL51 6SH Cheltenham United Kingdom',
  get_started: 'Get started',
  get_started_description:
    'Please enter your email and we will send you the link to finalise the sign up process',
  get_started_description_local: 'Enter your email, we will send you a magic link to login',
  get_started_with_paybl: 'Get started',
  get_started_with_remitiva: 'Get started with Remitiva',
  have_an_account: 'Already have an account?',
  acceptOurPolicies: 'By registering, you accept our',
  history: 'History',
  home: 'Home',
  how_it_works: 'How it works',
  i_agree_with: 'I agree with',
  // This value get from database
  if_you_pass_verification:
    'Once your verification is successfully completed, we will increase your limit up to',
  if_you_pass_verification_new:
    'Once your verification is successfully completed, we will increase your limit up to {limit}',
  increase_limit_time: 'over the 6-months period.',
  info_about_you: 'Information about you',
  info_about_your_payment: 'Information about your payment',
  invalid_date: 'Invalid date',
  invalid_day: 'Invalid day',
  invalid_email: 'Invalid email',
  last_name: 'Last name',
  lets_send_money: "Let's send money",
  link_sent_message_local: 'The link has been sent to the email address',
  link_sent_message_first_thank_you_local: 'Thank you for choosing Remitiva',
  link_sent_message_first_local: 'The link has been sent to email',
  link_sent_message_first_continue_local:
    'By clicking on the link you will be confirming your email address and completing the sign-up process.',
  password_recovery_short_local:
    'You have requested to recover the password for your Remitiva account',
  password_recovery_short_continue_local: 'The link to reset your password has been sent to email',
  login: 'Login',
  logout: 'Logout',
  money_will_be_available: 'Money will be available to',
  month: 'Month',
  month_limit_local: 'Monthly limit',
  month_limit_header: 'Sending limits',
  current_limit_history: 'Current limit',
  month_jan: 'Jan',
  month_feb: 'Feb',
  month_mar: 'Mar',
  month_apr: 'Apr',
  month_may: 'May',
  month_jun: 'Jun',
  month_jul: 'Jul',
  month_aug: 'Aug',
  month_sep: 'Sep',
  month_oct: 'Oct',
  month_nov: 'Nov',
  month_dec: 'Dec',
  must_be_string: 'Must be string',
  must_contain_only_3_digits: 'This field must contain only 3 digits',
  must_contain_only_16_digits: 'This field must contain only 16 digits',
  sender_receiving_same_local: 'Sending and receiving cards cannot be the same.',
  must_contain_only_digits: 'Only digits',
  must_contain_upper_lower_eng_letters:
    'This field must contain only upper and lower English letters',
  must_contain_eng_letters_digits: 'Only English letters and digits',
  need_help_message: 'Need help with a recent transaction?',
  new_payment_card: 'New payment card',
  next: 'Next',
  new_password: 'New password',
  no_match: 'No match',
  minimum_characters: 'Requires minimum 8 characters',
  uppercase_or_lowercase: 'Requires uppercase or lowercase letters',
  at_least_one_digit: 'Requires at least one digit: 0-9',
  at_least_one_symbol: 'Requires at least one special symbol: !,@,#, etc.',
  notify_recipient: 'Notify recipient',
  of: 'of',
  or: 'or',
  or_upper_case: 'OR',
  our_fee: 'Our fee',
  our_transfer_fee: 'Our transfer fee',
  page: 'page',
  password: 'Password',
  password_recovery: 'Password recovery',
  password_validation_error: 'Please enter valid password',
  payment_history: 'Payment History',
  payment_method: 'Payment method',
  payments: 'payments',
  per_month: 'per month',
  over_six_month_period_local: 'over the 6\u2011month period',
  personal_identification_1: 'Sender details',
  personal_identification_2: 'Phone verification',
  personal_identification_3: 'Sender payment details',
  personal_identification_4: 'Recipient details',
  personal_identification_5: 'Confirm transaction',
  personal_info: 'Personal info',
  phone: 'Phone',
  phone_validation: 'Must start with plus and contain only digits, without spaces',
  please_provide_local: 'Please provide your',
  please_select: 'Please select',
  select: 'Select',
  prev: 'Prev',
  privacy_policy: 'Privacy Policy',
  profile: 'Profile',
  purpose_of_payment: 'Purpose of payment',
  recipient: 'Recipient',
  recipient_card_details: 'Recipient payment card details',
  recipient_country: 'Recipient country',
  recipient_email: 'Recipient email',
  recipient_must_be_18: 'Recipient must be at least 18 years of age.',
  recipient_name: 'Recipient name',
  recipient_receives: 'Recipient receives',
  recipient_card_number_label: 'Recipient card number',
  registration_number: 'Registration number: 11415496',
  rejected: 'Rejected',
  repeat: 'Repeat',
  required_field: 'Required field',
  reset_password_title: 'Reset password',
  review_appove_subtitle: 'Please review and approve transaction.',
  save: 'Save',
  save_card: 'Save card',
  save_changes: 'Save changes',
  select_payment_card: 'Select payment card',
  send: 'Send',
  send_another_recipient: 'Send more to another recipient',
  send_code_again: 'Send code again',
  send_money: 'Send money',
  send_money_description:
    'Enter the amount you want to send or the amount you want your recipient to receive.',
  sender_country: 'Sender country',
  senders_info: "Sender's information",
  senders_payment_info: "Sender's payment information",
  set_password: 'Set password',
  showing: 'Showing',
  sign_in: 'Sign in',
  sign_up: 'Sign up',
  sign_up_here: 'Sign up here',
  sign_with_apple: 'Sign up with Apple',
  sign_with_google: 'Sign up with Google',
  source_of_funds: 'Source of funds',
  status: 'Status',
  step: 'Step',
  recipient_full_address: 'Full address',
  subtitle_my_cards: 'Here you can find all your saved cards',
  subtitle_personal_info: 'Your personal data to be used in transfers',
  subtitle_support: 'Below we have provided answers to the most frequently asked questions.',
  support: 'Support',
  terms_of_use: 'Terms of Use',
  text_personal_information: 'personal information.',
  the_recipient_receives: 'The recipient receives',
  this_takes_couple_minutes_local: 'It only takes a couple of minutes.',
  title_my_cards: 'My cards',
  title_personal_info: 'Personal info',
  title_support: 'How can we help you?',
  title_success_magic_link_local: 'The magic link has been sent.',
  to: 'to',
  total_amount_of_transfers: 'Total amount of transfers',
  total_amount_of_transfers_in_six_month_period: 'Transfers in the\nlast 180 days',
  you_can_send_local: 'You can send {limit} over the 6\u2011month-period.',
  total_pay_today: 'Total to pay today',
  transaction_details: 'Transaction details',
  transfer_fee: 'Our transfer fee',
  transfer_denied_message:
    "The recipient's card has been declined. We will refund your money back to your card.",
  transfer_declined_message: 'We were unable to process your card. Your card has been declined.',
  transfer_denied_second_message:
    'You can try again to send your payment using a different payment card.',
  transfer_has_been_denied: 'Your transfer has been declined',
  transfer_in_this_month: 'Transfers in this month',
  transfer_in_this_month_local: 'Transfers in the\nlast 30 days',
  transfer_success: 'Your transfer has been sent',
  transfer_success_message_local:
    'Please note that a debit charge will appear on your card statement within the next few days',
  types: 'Types',
  uk_fca_registration_number: 'UK FCA registration number: 815137',
  upgrade_limit_to_local: 'Upgrade your sending limit to',
  upgrade_your_monthly_limit_local: 'Do you want to upgrade your limit to',
  verify_now: 'Verify now',
  verify_text_local: 'Complete a simple verification. This will take no more than 5 minutes.',
  will_redirected_another_page: 'After clicking on the send button, you will be redirected to the',
  with_apple: 'with Apple',
  sign_up_with_apple: 'Continue with Apple',
  sign_in_with_apple: 'Continue with Apple',
  with_google: 'with Google',
  sign_up_with_google: 'Continue with Google',
  sign_in_with_google: 'Continue with Google',
  within_minutes_might_take_day_local:
    ' within minutes, but in some cases it might take up to 2 business days.',
  after_clicking_redirect_info:
    'After clicking the SEND button, you will be redirected to complete a',
  after_clicking_redirect_info_continue:
    'Page process, which is a mandatory security procedure to safeguard your online transactions.',
  security_alert_text:
    'Do you know and trust this payee? If you have any doubts or uncertainties, we advice you not to proceed with the payment as we may not be able to help you to get your money back. Remember, fraudsters can impersonate others, and we will never ask you to make a payment.',
  without_spaces: 'Without spaces',
  year: 'Year',
  you_havent_cards_local: 'You have no saved cards yet',
  you_havent_sent_money: "You haven't sent money yet.",
  you_send: 'You send',
  your_card: 'Your card',
  your_city: 'Your city',
  your_contact_address: 'Your contact address',
  your_contact_details: 'Your contact details',
  phone_make_verified_message: 'This number will be verified with a text message',
  your_country: 'Your country',
  your_country_residence: 'Your country of residence',
  your_message_successfully_sent: 'Your message has successfully sent!',
  your_name: 'Your name',
  your_payment_card_info: 'Your payment card information',
  your_full_address: 'Your full address',
  your_transfers_stored_here: 'All data on your transfers is stored here',
  zip_code: 'Zip code',
  password_hint: 'Password hint',
  notification_title: 'Password should contain:',
  password_hint_message_1: `Password should contain:
    Minimum 8 characters
    Uppercase or lowercase letters:
    A-Z / a-z
    At least one digit: 0-9
    At least one special symbol: !,@,#, etc.`,
  delete_confirmation: 'Are you sure you want to delete the card?',
  union_pay_ending: 'UnionPay ending in ',
  visa_pay_ending: 'Visa ending in',
  master_card_pay_ending: 'Master card ending in',
  increase_limits: 'Increase limit',
  increase_your_monthly_limit:
    'Do you want to upgrade your sending limit to 15,000 EUR over a six-month period?',
  please_take_5_minutes_to_enter_your_personal_data:
    'Complete a simple verification, which will take no more than 5 minutes.',
  id_confirmation: 'ID verification',
  id_upload_identity_notification:
    'Please upload an identity document: National ID, Passport, or Driving License.',
  drag_and_drop_files: 'Drag and drop your files here',
  acceptable_file_format_local: `
  Acceptable file formats are: jpg, jpeg, png, tiff and heic.
  File size must not exceed 25MB.`,
  browse_files: 'Browse files',
  uploaded_files: 'Uploaded files',
  identity_check_local: 'Liveness check',
  required_documents: 'Please have any of your Identification documents handy',
  required_documents_type: '(Passport, ID card, Driving license)',
  take_selfie: 'Take a selfie and finish',
  thank_you: 'Thank you!',
  provide_the_necessary_information:
    'Thank you for taking the time to provide the necessary documents.',
  verification_process_time:
    'Please note that our verification process may take up to 48 hours. Once the verification is complete, we will increase the limit accordingly.',
  close: 'Close',
  address_verification: 'Address verification',
  id_upload_documents_notification:
    'Please upload one of these documents to confirm your residential address: Utility bill, Bank statement, Driving license, Residence permit, Government letter. The documents should be issued within the last three months.',
  incorrect_file_format: 'Incorrect file format or file size',
  upload_any_documents:
    'Please upload one of these documents to confirm your source of funds: Bank statement, Payslip, Tax return, Investment statement.',
  verified: 'Verified',
  blocked: 'Blocked',
  pending: 'Pending',
  active: 'Active',
  closed: 'Closed',
  suspended: 'Suspended',
  personal_data: 'Personal data',
  proof_of_address: 'Proof of address',
  selfie_check_local: 'Selfie',
  go_to_profile_title: 'Please provide your personal details',
  go_to_profile_description: 'Please go to profile section and provide your personal details.',
  go_to_profile_button: 'Go to profile',
  preparing: 'Preparing ...',
  loading: 'Loading...',
  submit: 'Submit',
  finish: 'Finish',
  remove_existing_file: 'Please remove the existing file and then upload a new one!',
  wrong_verification_code: 'You have entered wrong verification code',
  email_doesnt_exist_local:
    'There is no existing account associated with this email, please press Continue to sign up',
  required_password_credentials: 'Enter required credentials for the password',
  supportedCardsSource: 'Only Visa, Mastercard or UnionPay supported',
  supportedCardsTarget: 'Only Visa or Mastercard supported',
  supportedUnionPayCard: 'Only cards issued outside of China are accepted',
  current_key: 'Current key',
  local_key_value: 'Local translation value',
  remote_key_value: 'Remote translation value',
  interpolations: 'Value interpolations',
  update_locale_form_title: 'Update remote translation value by key',
  show_edit_buttons: 'Show edit',
  hide_edit_buttons: 'Hide edit',
  translation_successfully_updated: 'Translation successfully updated',
  closed_profile_alert_message: 'Your account has been officially closed',
  suspended_profile_alert_message: 'Your account has been suspended from sending money transfers',
  suspended_ok: 'Ok',
};
