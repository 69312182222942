import { translate } from 'helpers';
import { TranslationDataType } from 'resources/translation';

export const monthData = (t: TranslationDataType) => [
  {
    value: `${translate(t, 'january')}`,
    title: `${translate(t, 'january')}`,
  },
  {
    value: `${translate(t, 'february')}`,
    title: `${translate(t, 'february')}`,
  },
  {
    value: `${translate(t, 'march')}`,
    title: `${translate(t, 'march')}`,
  },
  {
    value: `${translate(t, 'april')}`,
    title: `${translate(t, 'april')}`,
  },
  {
    value: `${translate(t, 'may')}`,
    title: `${translate(t, 'may')}`,
  },
  {
    value: `${translate(t, 'june')}`,
    title: `${translate(t, 'june')}`,
  },
  {
    value: `${translate(t, 'july')}`,
    title: `${translate(t, 'july')}`,
  },
  {
    value: `${translate(t, 'august')}`,
    title: `${translate(t, 'august')}`,
  },
  {
    value: `${translate(t, 'september')}`,
    title: `${translate(t, 'september')}`,
  },
  {
    value: `${translate(t, 'october')}`,
    title: `${translate(t, 'october')}`,
  },
  {
    value: `${translate(t, 'november')}`,
    title: `${translate(t, 'november')}`,
  },
  {
    value: `${translate(t, 'december')}`,
    title: `${translate(t, 'december')}`,
  },
];
