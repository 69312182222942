import { IAllStepsData } from 'pages/steps/type/transaction';
import { Response } from 'resources/types';
import { Fetcher } from 'utils';

import { ENDPOINTS } from './endpoints';
import { IResponse, TRepeatTransaction } from './types';

export class TransactionRepo {
  private fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  public async process(payload: IAllStepsData) {
    const { data } = await this.fetcher.fetch<IAllStepsData, IResponse>({
      url: ENDPOINTS.create,
      method: 'POST',
      data: payload,
    });

    return data;
  }

  public async repeatTransaction(id: number) {
    const { data } = await this.fetcher.fetch<null, { data: Response<TRepeatTransaction> }>({
      url: `${ENDPOINTS.repeat}/${id}`,
      method: 'GET',
    });

    return data.data;
  }
}
