import styled from 'styled-components';

export const UploadedFileNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  background: #eff0fb;
  border-radius: 8px;
`;

export const FileName = styled.div`
  margin: 0 0 0 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DeleteWrapper = styled.button`
  margin: 0 24px 0 0;
  border: 0;
  background: transparent;

  &:not(:disabled) {
    cursor: pointer;
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  animation: spinner 4s infinite;

  @keyframes spinner {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
