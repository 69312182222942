import { useCallback, useEffect } from 'react';

import { socketProvider } from 'services/socket';

export function useWebsocketConnect<T>(eventName: string, handler?: (event: T) => void) {
  const { socket } = socketProvider;

  useEffect(() => {
    if (!socket?.active) {
      socket?.connect();
    }

    if (handler) {
      socket?.on(eventName, handler);
    }

    return () => {
      socket?.off(eventName);
    };
  }, [eventName, socket, handler]);

  const emit = useCallback(
    (name?: string, payload?: Record<string, unknown>) => {
      socket?.emit(name || eventName, {
        ...(payload || {}),
      });
    },
    [eventName, socket],
  );

  return {
    emit,
  };
}
