import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  height: calc(100% - 68px);
  @media (max-width: 1600px) {
    flex-direction: column;
    justify-content: space-between;
  }
`;

const ChildrenWrapper = styled.div`
  width: calc(100% - 540px);
  display: flex;
  justify-content: center;
  margin-top: 48px;
  @media (max-width: 1600px) {
    width: 100%;
  }
  @media (max-width: 1279px) {
    margin-top: 24px;
  }
  @media (max-width: 647px) {
    margin-top: 23px;
  }
`;

const ImageSection = styled.div(
  ({ theme }) => css`
    width: 540px;
    height: 100%;
    min-height: calc(100vh-68px);
    position: relative;
    background: ${theme.colors.primary['500']};
    overflow: hidden;
    > :nth-child(1) {
      justify-content: flex-start;
      > img {
        margin-top: 64px;
        width: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
      }
    }
    @media (max-width: 1600px) {
      width: 100%;
      min-height: unset;
      height: unset;
      > :nth-child(1) {
        display: none;
      }
    }
  `,
);

const Privacysection = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 1rem;
  left: 56px;
  gap: 32px;
  @media (max-width: 1600px) {
    position: relative;
    height: 92px;
    bottom: 0;
    left: 0;
    padding: 40px 24px 32px;
    justify-content: space-between;
  }
  @media (max-width: 647px) {
    height: auto;
    padding: 16px;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-right: 24px;
  }
  @media (max-width: 647px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }
`;

export { Wrapper, ChildrenWrapper, ImageSection, Privacysection, Links };
