import { useQueryBuilder } from 'hooks/useQueryBuilder';
import { getResourceManager } from 'resources';

import { LocationQuery } from './types';

export const useProfileLocation = ({ enabled, options }: LocationQuery) => {
  return useQueryBuilder({
    key: 'profile-location',
    method: async () => getResourceManager().user.profile.getCurrentLocation(),
    options,
    enabled,
  });
};
