import styled from 'styled-components';

export const Checkbox = styled.div`
  display: flex;
  align-items: left;
  text-align: left;
  justify-content: flex-start;
  align-content: left;
  @media (max-width: 400px) {
    flex-direction: column;
    > :last-child {
      margin-left: 16px;
    }
  }
`;

export const CheckboxContent = styled.div`
  display: flex;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  input {
    position: absolute;
    display: none;
    left: 0.5px;
  }

  label {
    margin-left: 25px;
  }

  img {
    position: absolute;
    top: 8px;
    left: 5px;
  }
  label .check-text {
    cursor: pointer;
  }
  input + label:before {
    content: '';
    position: absolute;
    left: 0;
    margin-top: 2px;
    width: 18px;
    height: 18px;
    border: 1px solid #c7c9d1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input + label:hover::before {
    border: 2px solid #4f66f5;
    cursor: pointer;
  }
  input + label:active::before {
    border: 2px solid #4f66f5;
    outline: 4px solid #c8d9fc;
  }
  input + label:active::before {
    border: 2px solid #293fcb;
  }
  input + label:disabled::before {
    background-color: #e3e5eb;
  }
  input:checked + label::before {
    background-color: #2d48ed;
    border: 1px solid #2d48ed;
  }
  input:checked + label:hover::before {
    border: 1px solid #4f66f5;
    background-color: #4f66f5;
  }
  input:checked + label:disabled::before {
    background-color: #e3e5eb;
  }
`;
