import styled from 'styled-components';
import { theme } from 'styled-tools';

import { StyledFooterWithButtons } from '../styles';

export const StyledTitle = styled.h1<{ isAlignLeftPage?: boolean }>`
  font-family: Commissioner-Regular;
  color: #222229;
  font-weight: 500;
  align-items: ${({ isAlignLeftPage }) => (isAlignLeftPage ? 'flex-start' : 'center')};
  font-size: 44px;
  padding: 48px 0 8px;
  @media (max-width: 1299px) {
    padding: 24px 0 8px;
  }
`;

export const StyledSmallTilte = styled(StyledTitle)`
  font-size: 32px;
  @media (max-width: 1279px) {
    font-size: 26px;
  }
`;

export const StyledSubtitle = styled.p<{ isAlignLeftPage?: boolean; isMarginBottom?: boolean }>`
  font-family: Lato-Regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: ${({ isAlignLeftPage }) => (isAlignLeftPage ? 'left' : 'center')};
  letter-spacing: -0.2px;
  color: #686b78;
  margin-bottom: ${({ isMarginBottom = true }) => (isMarginBottom ? '32px' : 0)};
`;

export const StyledDescription = styled(StyledSubtitle)`
  font-size: 15px;
  color: ${theme('colors.neutral.500')};
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  .cone,
  .cube,
  .sphere {
    position: fixed;
  }
  .cone {
    left: 199px;
    bottom: 224px;
  }
  .cube {
    left: 144px;
    top: 124px;
  }
`;

export const StyledFooter = styled.div`
  z-index: 10;
  margin-bottom: 32px;
  margin-left: 40px;
  width: fit-content;
  @media screen and (max-width: 1279px) {
    display: flex;
    align-items: center;
    margin: 40px 24px 32px;
  }
  @media screen and (max-width: 730px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media screen and (max-width: 647px) {
    flex-direction: column-reverse;
    margin: 32px 24px;
  }
`;

export const StyledFooterNav = styled.div`
  display: flex;
  margin-top: 32px;
  @media screen and (max-width: 1279px) {
    margin-top: 0;
    margin-left: 32px;
  }
  @media screen and (max-width: 730px) {
    margin-left: 0;
    margin-top: 32px;
  }
  @media screen and (max-width: 647px) {
    flex-direction: column;
    margin-bottom: 40px;
    margin-top: 0;
  }
`;

export const StyledLink = styled.a`
  font-family: Lato-Regular;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  margin-right: 24px;
  text-decoration: none;
  @media screen and (max-width: 647px) {
    :not(:last-of-type) {
      margin-bottom: 24px;
    }
  }
`;

export const StyledFooterWithButtonsOfFormPage = styled(StyledFooterWithButtons)`
  @media screen and (max-width: 1279px) {
    button {
      :first-of-type {
        margin-right: 16px;
      }
    }
  }
`;

export const StyledReviewComponentPartContainer = styled.div`
  width: 100%;
  border-top: 1px solid #dcdee3;
  font-family: Lato-Regular;
  padding: 24px 0;
`;

export const StyledReviewComponentPartTitle = styled.p`
  font-family: 'Lato-Regular';
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #4c4e57;
  margin-top: 4px;
`;

export const StyledReviewComponentPartValue = styled.div`
  margin-top: 4px;
  font-family: 'Lato-Regular';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  letter-spacing: -0.2px;
  @media (max-width: 1299px) {
    justify-content: space-between;
  }
  color: #222229;
`;

export const ButtonWrapper = styled.div<{ margin?: string }>`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 24px;

  > button {
    font-family: 'Commissioner-Regular';
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    background: none;

    display: flex;
    align-items: center;
    letter-spacing: -0.2px;

    color: #2d48ed;
    cursor: pointer;

    :disabled {
      cursor: default;
    }
  }
  .card {
    border: 1px solid red;
  }
`;
