import { Box } from '@mui/material';

import { StyledPage, StyledContent } from '../styles';

import { StyledDescription, StyledSmallTilte, StyledSubtitle, StyledTitle } from './styles';
import { StepPagesFormPropsType } from './types';

export const PrototypeOfStepPagesForm = (props: StepPagesFormPropsType) => {
  const {
    title,
    smallTitle,
    subtitle,
    children,
    description,
    className,
    contentClassName,
    onKeyDown,
  } = props;

  return (
    <StyledPage className={className} onKeyDown={onKeyDown}>
      <StyledContent isAlignLeftPage className={contentClassName}>
        {title ? (
          <StyledTitle isAlignLeftPage>{title}</StyledTitle>
        ) : (
          <StyledSmallTilte>{smallTitle}</StyledSmallTilte>
        )}
        <Box mb={description ? '8px' : 0}>
          <StyledSubtitle isAlignLeftPage isMarginBottom={!description}>
            {subtitle}
          </StyledSubtitle>
        </Box>
        {description && <StyledDescription isAlignLeftPage>{description}</StyledDescription>}
        {children}
      </StyledContent>
    </StyledPage>
  );
};
