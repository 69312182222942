import { TranslationDataType } from 'resources/translation';

import { getValidationConstructor } from './validationConstructor';

export const getLettersValidation = (t: TranslationDataType) => ({
  anySymbolsRequired: getValidationConstructor(
    t,
    /^[a-zA-Z0-9\s\S]*$/,
    'must_contain_upper_lower_eng_letters',
  ),
  engWithSpacesRequired: getValidationConstructor(
    t,
    /^[a-zA-Z\s]*$/,
    'must_contain_upper_lower_eng_letters',
  ),
  engRequired: getValidationConstructor(t, /^[a-zA-Z]*$/, 'must_contain_upper_lower_eng_letters'),
  engAndDigitsRequired: getValidationConstructor(
    t,
    /^[a-zA-Z0-9\s]*$/,
    'must_contain_eng_letters_digits',
  ),
  engAndDigitsWithSimpleSignsRequired: getValidationConstructor(
    t,
    /^[a-zA-Z0-9\s.,()-]*$/,
    'must_contain_eng_letters_digits',
  ),
});
