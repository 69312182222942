import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { TransactionApi } from 'apiListURL';
import { ExchangeComponent, TransactionInfoHome } from 'components';
import { useSendMoneySteps } from 'providers';
import {
  clearRecipientDetailsAction,
  clearSenderDetailsAction,
  clearSenderDetailsCardAction,
} from 'store/actionCreator';
import { setIsPersonalInfoFilled } from 'store/slices/personal';
import {
  updateSendMoneyState,
  updateSendMoneyData,
  TransactionStatus,
} from 'store/slices/transaction';
import { sendMoneyFullSelector } from 'store/slices/transaction/sender/sender.selectors';
import { IExchange, IExchangeContent, IExchangeData } from 'types/exchange';

import styles from './money.module.scss';

export const SendMoney = () => {
  const dispatch = useDispatch();
  const { handleNextStep } = useSendMoneySteps();
  const location = useLocation();
  const { isRepeat = false } = location.state || {};
  const sendMoneyState = useSelector(sendMoneyFullSelector);

  const {
    data,
    fromCountry,
    toCountry,
    fromCurrency: dataFromCurrency,
    toCurrency: dataToCurrency,
  } = sendMoneyState;
  const { fromAmount: defaultFromAmount } = data.sendMoney;

  const defaultProperties = useMemo(() => {
    if (isRepeat) {
      return {
        defaultFrom: Number(defaultFromAmount).toString() || '',
        defaultFromCountry: fromCountry || '',
        defaultToCountry: toCountry || '',
        defaultFromCurrency: dataFromCurrency || '',
        defaultToCurrency: dataToCurrency || '',
      };
    }

    return {
      defaultFrom: '',
      defaultFromCountry: '',
      defaultToCountry: '',
      defaultFromCurrency: '',
      defaultToCurrency: '',
    };
  }, [isRepeat, dataFromCurrency, dataToCurrency, defaultFromAmount, fromCountry, toCountry]);

  const {
    defaultFrom,
    defaultFromCountry,
    defaultToCountry,
    defaultFromCurrency,
    defaultToCurrency,
  } = defaultProperties;

  const [to, setTo] = useState<string>('');
  const [rateInfo, setRateInfo] = useState<IExchange>();
  const [from, setFrom] = useState<string>(defaultFrom);
  const [toAmount, setToAmount] = useState<string>('0');
  const [countryTo, setToCountry] = useState<string>(defaultToCountry);
  const [toCurrency, setToCurrency] = useState<string>(defaultToCurrency || '');
  const [contentInfo, setContentInfo] = useState<IExchangeContent>();
  const [fromAmount, setFromAmount] = useState<string>(defaultFrom || '0');
  const [countryFrom, setFromCountry] = useState<string>(defaultFromCountry);
  const [fromCurrency, setFromCurrency] = useState<string>(defaultFromCurrency || '');
  const [isFromAmount, setIsFromAmount] = useState<boolean>(isRepeat);
  const [showTransactionInfo, setShowTransactionInfo] = useState<boolean>(false);
  const [exchangeResult, setExchangeResult] = useState<string>('');

  const handleData = (isAgree?: boolean) => {
    if (!isRepeat) {
      clearSenderDetailsAction(dispatch);
      clearSenderDetailsCardAction(dispatch);
      clearRecipientDetailsAction(dispatch);
    }
    dispatch(setIsPersonalInfoFilled(true));

    dispatch(
      updateSendMoneyData({
        ...contentInfo,
        isAgree,
      }),
    );
    dispatch(
      updateSendMoneyState({
        fromCountry: countryFrom,
        toCountry: countryTo,
      }),
    );
    handleNextStep();
  };

  const handleDataObject = {
    fromAmount: (el: string) => {
      return setFromAmount(el);
    },
    toAmount: (el: string) => setToAmount(el),
    getIsFromAmount: (el: boolean) => setIsFromAmount(el),
  };

  const amountsData = {
    from,
    to,
  };

  const getExchange = async () => {
    const data: IExchangeData = {
      fromAmount: Number(fromAmount),
      toAmount: Number(toAmount),
      from: fromCurrency,
      to: toCurrency,
      selectedFrom: isFromAmount,
    };
    const res = await TransactionApi.getExchange(data);
    setFrom(res?.data?.fromAmount);
    setTo(res?.data?.toAmount);
    setRateInfo(res?.data?.rate);
    setContentInfo(res.data);
    setExchangeResult(res?.data?.fromAmount);
  };

  useEffect(() => {
    toCurrency && Number(to) && setShowTransactionInfo(true);
  }, [from, to, toCurrency]);

  useEffect(() => {
    const timeout =
      fromCurrency &&
      toCurrency &&
      setTimeout(() => {
        getExchange();
      }, 700);

    return () => {
      clearTimeout(timeout);
    };
  }, [fromAmount, toAmount, fromCurrency, toCurrency]);

  useEffect(() => {
    dispatch(updateSendMoneyState({ transactionStatus: TransactionStatus.empty }));
  }, []);

  useEffect(() => {
    dispatch(updateSendMoneyData({ fromAmount: exchangeResult }));
  }, [exchangeResult]);

  return (
    <div className={styles['page']}>
      <div className={styles['container']}>
        <ExchangeComponent
          getData={handleDataObject}
          setAmount={amountsData}
          rateInfo={rateInfo}
          limitPass={contentInfo?.limitPass}
          setCountryFrom={setFromCountry}
          setCountryTo={setToCountry}
          isRepeat={isRepeat}
          defaultProperties={defaultProperties}
          setCurrencies={(fromC, toC) => {
            setFromCurrency(fromC);
            setToCurrency(toC);
          }}
        >
          <TransactionInfoHome
            handleDispatchData={handleData}
            showInfo={showTransactionInfo}
            data={contentInfo}
          />
        </ExchangeComponent>
      </div>
    </div>
  );
};
