import { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { TextField, InputField, PrimaryButtonComp } from 'components';
import { PrimaryButton } from 'components/PrimaryButtonComp/style';
import { I18n } from 'components/Translation';
import { CONSTANTS } from 'config/constants';
import { dataEntryAccess, translate } from 'helpers';
import { Line } from 'pages/styles';
import { useAppProvider, useSendMoneySteps, useTranslation } from 'providers';
import { usePhoneCheckMutation, usePhoneVerifyMutation } from 'queries/profile/mutation';
import { getResourceManager } from 'resources';
import { AppRoutes } from 'routes/constants';
import { getMonitoring } from 'services/monitoring';
import { RootState } from 'store';
import { userInfoSelector } from 'store/selectors';

import {
  Wrapper,
  TitleWrapper,
  InputContainer,
  InputFields,
  ButtonsWrapper,
  RepeatRequest,
} from './styles';
import { ITimer, IErrorData } from './types';

export const PhoneVerification = () => {
  const navigate = useNavigate();
  const { setLoader, loadType, setLoadType } = useAppProvider();
  const { handleNextStep } = useSendMoneySteps();
  const location = useLocation();

  const { t } = useTranslation();
  const phoneNumber = useSelector(
    (state: RootState) => state?.senderDetails?.data?.senderDetailsData?.phone,
  );
  const profile = useSelector(userInfoSelector);

  const [counter, setCounter] = useState<number>(CONSTANTS.VERIFICATION_TIME_ZONE);
  const [inputCode, setInputCode] = useState<string>('');
  const [timer, setTimer] = useState<ITimer>({
    minutes: Math.floor(counter / 60),
    seconds: counter % 60,
  });

  const [errorMessage, setErrorMessage] = useState<IErrorData>({
    isError: false,
    message: '',
  });
  const [isAlreadyUsed, setIsAleadyUsed] = useState(false);

  const { handlePhoneVerify } = usePhoneVerifyMutation();

  const { handlePhoneIsEmpty } = usePhoneCheckMutation({
    definedPhone: profile.phone,
    onSuccess: () => {
      setCounter(CONSTANTS.VERIFICATION_TIME_ZONE);
      setErrorMessage({
        isError: false,
        message: '',
      });

      handlePhoneVerify.mutateAsync(phoneNumber).catch((e) => {
        getMonitoring()?.captureException(e);
        setErrorMessage({
          isError: true,
          message: e.message,
        });
      });
    },
    onError: () => {
      setIsAleadyUsed(true);

      setErrorMessage({
        isError: true,
        message: translate(t, 'error_phone_already_use'),
      });
    },
  });

  const setMobileCode = async () => {
    if (!phoneNumber) return;

    await handlePhoneIsEmpty.mutateAsync(phoneNumber);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!(counter !== 0 && inputCode.length === 4 && !isAlreadyUsed)) {
      return;
    }

    setLoader(true);
    setLoadType('Phone');
    getResourceManager()
      .user.profile.codeVerify({
        code: inputCode,
      })
      .then(() => {
        handleNextStep();
        setLoader(false);
      })
      .catch(() => {
        setErrorMessage({
          isError: true,
          message: translate(t, 'wrong_verification_code'),
        });
      })
      .finally(() => setLoader(false));
  };

  const handleBackBtn = () => {
    navigate(AppRoutes.moneySteps.senderDetails);
  };

  useEffect(() => {
    if (location?.state?.phoneVerified) {
      navigate(location.pathname, {});
    } else {
      setMobileCode();
    }
  }, [phoneNumber]);

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter((prev) => prev - 1), 1000);
    }

    setTimer({
      minutes: Math.floor(counter / 60),
      seconds: counter % 60,
    });
    return () => clearTimeout(timer);
  }, [counter]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Wrapper>
        <TitleWrapper>
          <TextField variant="H3" color="n900">
            <I18n tKey="enter_your_code" />
          </TextField>
          <br />
          <TextField variant="P7" color="n700">
            <I18n tKey="code_sent" />
          </TextField>
          <br />
          <TextField variant="P7" color="n700">
            <I18n tKey="enter_code_message" />
          </TextField>
        </TitleWrapper>
        <InputContainer>
          <InputFields>
            <InputField
              text={<I18n tKey="enter_code" />}
              value={inputCode}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dataEntryAccess(e, 4) && setInputCode(e.target.value);
              }}
              className="input-code"
              disabled={!counter || isAlreadyUsed}
            />
          </InputFields>

          {counter ? (
            <TextField className="expire_time_container" variant="P7" color="n700">
              <I18n tKey="code_expires_in" />{' '}
              <span className="expire_time">
                {timer.minutes < 10 ? `0${timer.minutes}` : timer.minutes}:
                {timer.seconds < 10 ? `0${timer.seconds}` : timer.seconds}
              </span>
            </TextField>
          ) : (
            <RepeatRequest>
              <TextField variant="P7" color="n700">
                <I18n tKey="didnt_receive_code" />
              </TextField>
              <TextField className="send-again" onClick={setMobileCode} variant="P7" color="a500">
                <I18n tKey="send_code_again" />
              </TextField>
            </RepeatRequest>
          )}
        </InputContainer>

        <InputContainer>
          <TextField variant="P7" color="e500">
            {errorMessage.message}
          </TextField>
        </InputContainer>
        <Line className="line" />
        <ButtonsWrapper>
          <PrimaryButton type="button" onClick={handleBackBtn} width={CONSTANTS.HUNDREED_PERCENT}>
            <I18n tKey="back" />
          </PrimaryButton>
          <PrimaryButtonComp
            width="84px"
            typeOfLoad={loadType}
            valid={counter !== 0 && inputCode.length === 4 && !isAlreadyUsed}
            text={translate(t, 'next')}
          />
        </ButtonsWrapper>
      </Wrapper>
    </form>
  );
};
