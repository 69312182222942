import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { useWebsocketConnect } from 'hooks';
import {
  CardAuthFailure,
  CardAuthFinish,
  CardAuthStart,
  CardFormStart,
} from 'resources/card/types';
import { WS_EVENTS } from 'resources/wsEvents';
import { userInfoSelector } from 'store/selectors';

export const useCardFlow = ({
  onFail,
  onFormStart,
  onFormFinish,
  onAuthFinish,
}: {
  onFail: (data: CardAuthFailure) => void;
  onFormStart: (data: CardFormStart) => void;
  onFormFinish: () => void;
  onAuthFinish: (data: CardAuthFinish) => void;
}) => {
  const [authId, setAuthId] = useState<string | undefined>();
  const profile = useSelector(userInfoSelector);
  const { emit: emitCardStart } = useWebsocketConnect(WS_EVENTS.card.CARD_AUTH_START);

  useWebsocketConnect(WS_EVENTS.card.CARD_AUTH_ABORT, (data: CardAuthFailure) => {
    onFail?.(data);
  });

  // TODO: Открытие формы, инструкции к ней
  useWebsocketConnect(WS_EVENTS.card.CARD_AUTH_FORM_START, (data: CardFormStart) => {
    onFormStart?.(data);
  });

  useWebsocketConnect(WS_EVENTS.card.CARD_AUTH_FINISH, (data: CardAuthFinish) => {
    if (authId === data.authId) {
      onAuthFinish?.(data);
    }
  });

  useWebsocketConnect(WS_EVENTS.card.CARD_AUTH_FORM_FINISH, (data: { authId: string }) => {
    if (authId === data.authId) {
      onFormFinish?.();
    }
  });

  const handleStartAuth = useCallback(
    (payload: CardAuthStart, authId) => {
      if (authId) {
        setAuthId(authId);

        const { language, userAgent } = window?.navigator || {};
        const { availHeight, availWidth } = window?.screen || {};
        const clientInfo = {
          language,
          userAgent,
          screenHeight: `${availHeight}`,
          screenWidth: `${availWidth}`,
          timeZone: `${new Date().getTimezoneOffset()}`,
        };

        emitCardStart(WS_EVENTS.card.CARD_AUTH_START, {
          clientId: profile?.id,
          authId,
          clientInfo,
          body: payload,
        });
      }
    },
    [profile?.id, emitCardStart],
  );

  return {
    handleStartAuth,
  };
};
