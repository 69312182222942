import styled from 'styled-components';
import { ReactComponent as SuccessIcon } from 'assets/images/SuccessIcon.svg';
import { ReactComponent as DeniedIcon } from 'assets/images/deniedIcon.svg';

const Wrapper = styled.div`
  margin: 0 auto;
  height: 100%;
  @media (max-width: 600px) {
    width: auto;
  }
`;

const Content = styled.div`
  padding-bottom: 64px;
  display: flex;
  justify-content: space-around;
  margin: 50px 60px 0;
  text-align: center;
  @media (max-width: 1279px) {
    width: auto;
    margin: 50px auto 0;
  }
`;

const StyledPage = styled.div`
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat;
  @media screen and (max-width: 1279px) {
    margin: 0 16px;
  }
  @media screen and (max-width: 660px) {
    width: auto;
  }
`;

const StyledSuccessIcon = styled(SuccessIcon)`
  width: 28px;
  height: 28px;
  margin-bottom: 18px;
`;

const StyledDeniedIcon = styled(DeniedIcon)`
  width: 28px;
  height: 28px;
  margin-bottom: 18px;
`;

const StyledTitle = styled.h1`
  font-family: Commissioner-Regular;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  letter-spacing: -0.8px;
  color: #222229;
  @media (max-width: 600px) {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
  }
`;

const StyledSectionWithGreenBackground = styled.div`
  background: #f0faf4;
  border: 1px solid #c2ebd2;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  padding: 24px;
  margin: 24px 0;
`;

const StyledSectionWithRedBackground = styled(StyledSectionWithGreenBackground)`
  background: #ffebeb;
  border: 1px solid #ffc2c2;
`;

const StyledP = styled.p`
  font-family: Lato-Regular;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #4c4e57;
  @media (max-width: 600px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;

const StyledDateP = styled.p`
  font-family: Lato-Regular;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #222229;
`;

const StyledText = styled.p`
  font-family: Lato-Regular;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #686b78;
  margin-bottom: 24px;
`;

const StyledLowerPart = styled.div`
  width: max-content;
  display: flex;
  padding: 39px 0;
  @media screen and (max-width: 647px) {
    flex-direction: column;
  }
`;

const StyledLink = styled.a<{ marginRight?: number }>`
  font-family: Lato-Regular;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #222229;
  margin-right: ${({ marginRight }) => `${marginRight ? marginRight : 0}px`};
  @media screen and (max-width: 647px) {
    margin-right: 0 !important;
  }
`;

const ButtonBox = styled.div``;

export {
  StyledSuccessIcon,
  StyledDeniedIcon,
  StyledTitle,
  StyledSectionWithGreenBackground,
  StyledSectionWithRedBackground,
  StyledP,
  StyledDateP,
  StyledText,
  StyledLowerPart,
  StyledLink,
  Wrapper,
  Content,
  StyledPage,
  ButtonBox,
};
