import { Response } from 'resources/types';
import { Fetcher } from 'utils';

import { ProfileRepo } from './ProfileRepo';
import {
  CodeRequestParams,
  PhoneRequestParams,
  ProfileResponse,
  TProfileDataRequest,
  TProfilePasswordRequest,
  TResetNewPassword,
} from './types';

export class ProfileService {
  private profileRepo: ProfileRepo;

  constructor(fetcher: Fetcher) {
    this.profileRepo = new ProfileRepo(fetcher);
  }

  public async getProfile(): Promise<Response<ProfileResponse>> {
    return this.profileRepo.getProfile();
  }

  public async phoneVerify(body: PhoneRequestParams): Promise<boolean> {
    const status = await this.profileRepo.phoneVerify(body);

    return status;
  }

  public async checkPhoneIsEmpty(phone: string): Promise<boolean> {
    const status = await this.profileRepo.checkPhoneIsEmpty(phone);

    return status;
  }

  public async codeVerify(body: CodeRequestParams): Promise<boolean> {
    const status = await this.profileRepo.codeVerify(body);

    return status;
  }

  public async getCurrentLocation() {
    const data = await this.profileRepo.getCurrentLocation();

    return data;
  }

  public async updateProfileData(payload: TProfileDataRequest) {
    try {
      const data = await this.profileRepo.updateProfileData(payload);

      return data;
    } catch (error: any) {
      const { response } = error;
      const { status, data } = response;

      if (status === 400) {
        throw new Error(data?.message || error?.message);
      }

      throw error;
    }
  }

  public async updateProfilePassword(payload: TProfilePasswordRequest) {
    try {
      const data = await this.profileRepo.updateProfilePassword(payload);

      return data;
    } catch (error: any) {
      const { response } = error;
      const { status, data } = response;

      if (status === 400) {
        throw new Error(data?.message || error?.message);
      }

      throw error;
    }
  }

  public async resetPassword(payload: TResetNewPassword): Promise<boolean> {
    try {
      const data = await this.profileRepo.resetPassword(payload);

      return data;
    } catch (error: any) {
      const { response } = error;
      const { status, data } = response;

      if (status === 400) {
        throw new Error(data?.message || error?.message);
      }

      throw error;
    }
  }
}
