import styled from 'styled-components';

export const VerificationStatusWrapper = styled.div`
  display: inline-block;
  background: #e0f5e9;
  border-radius: 8px;

  &.pending {
    background: yellow;
  }

  &.limited {
    background: red;
  }
`;

export const VerificationStatusBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 6px 1px 4px;

  > img {
    margin-left: 6px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
