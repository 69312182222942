import { defaultT } from 'config';
import { TranslationDataType } from 'resources/translation';

export const translate = (
  t: TranslationDataType,
  field: string,
  keyValues?: Record<string, string>,
) => {
  const fieldValue = t?.[field] ? t?.[field] : defaultT?.[field];
  let finalValue = fieldValue;

  if (keyValues) {
    finalValue = Object.entries(keyValues).reduce(
      (label, [key, value]) => label?.replaceAll(`{${key}}`, value),
      fieldValue,
    );
  }

  return finalValue;
};
