import { createSlice } from '@reduxjs/toolkit';

import { Action } from '../../../types';

import { IDataRecipientDetails } from './recipientDetails.types';

const senderRecipientSlice = createSlice({
  name: 'senderRecipient',
  initialState: {
    data: {
      recipientDetails: <IDataRecipientDetails>{
        firstName: '',
        lastName: '',
        cardNumber: '',
        cardholderName: '',
      },
    },
  },

  reducers: {
    updateRecipientDetailsData(state, action: Action<IDataRecipientDetails>) {
      return {
        ...state,
        data: {
          ...state.data,
          recipientDetails: {
            ...state.data.recipientDetails,
            ...action.payload,
          },
        },
      };
    },
  },
});

export const { updateRecipientDetailsData } = senderRecipientSlice.actions;

export const senderRecipientReducer = senderRecipientSlice.reducer;
