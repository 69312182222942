import { useQueryBuilder } from 'hooks/useQueryBuilder';
import { getResourceManager } from 'resources';

import { TranslationsAccessQuery } from './types';

export const useTranslationAccessQuery = (props?: TranslationsAccessQuery) => {
  const key = 'translation-access';

  return useQueryBuilder({
    key,
    method: async () => getResourceManager().translation.checkAccess(),
    enabled: true,
    ...props,
  });
};
