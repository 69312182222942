import { IDataProvider } from 'services';

import { AuthRepo } from './AuthRepo';
import { PasswordSignInProps } from './types';

export class AuthService {
  private authRepo: AuthRepo;

  constructor(provider: IDataProvider) {
    this.authRepo = new AuthRepo(provider);
  }

  public async signOut() {
    return this.authRepo.signOut();
  }

  public async signInWithGoogle() {
    return this.authRepo.signInWithGoogle();
  }

  public async signInWithApple() {
    return this.authRepo.signInWithApple();
  }

  public async signInWithEmail(email: string) {
    return this.authRepo.signInWithEmail(email);
  }

  public async signInWithPassword(credentials: PasswordSignInProps) {
    return this.authRepo.signInWithPassword(credentials);
  }

  public async getUserData() {
    return this.authRepo.getUserData();
  }

  public async setSession(accessToken: string, refreshToken: string) {
    return this.authRepo.setSession(accessToken, refreshToken);
  }

  public async refreshSession(refreshToken: string) {
    return this.authRepo.refreshSession(refreshToken);
  }

  public async getSession() {
    return this.authRepo.getSession();
  }
}
