import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { TextField, CardTypeComponent, ConfirmationPopup } from 'components';
import { I18n } from 'components/Translation';
import { CONSTANTS } from 'config/constants';
import { translate, toastAlert } from 'helpers';
import { useTranslation } from 'providers';
import { getResourceManager } from 'resources';
import { CardData } from 'resources/card';
import { AppRoutes } from 'routes/constants';
import { getMonitoring } from 'services/monitoring';
import { userInfoSelector } from 'store/selectors';
import { ProfileStatus } from 'store/slices/personal';
import { updateSenderDetailsCardData } from 'store/slices/transaction';
import { ICards, ICardsTypes } from 'types/cards';

import { DropdownWrapper } from './DropdownWrapper';
import { MobileTable } from './MobileTable';
import { NewCardForm } from './NewCardForm';
import {
  TableWrapper,
  Table,
  Wrapper,
  TitleWrapper,
  TextWrapper,
  PrimaryButton,
  TextConteiner,
} from './styles';

const isAvailableNewCard = false;

const MyCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteApproved, setIsDeleteApproved] = useState(false);
  const [cardDigits, setCardDigits] = useState('');
  const [cardType, setCardType] = useState<string>('');
  const [deletedCardId, setDeletedCardId] = useState('');
  const [cardsData, setCardsData] = useState<CardData[]>([]);
  const [isAddingCard, setIsAddingCard] = useState<boolean>(false);
  const { t } = useTranslation();

  const profile = useSelector(userInfoSelector);

  const datass = [
    {
      value: '1',
      label: `${translate(t, 'dropdown_send_money')}`,
    },
    {
      value: '2',
      label: `${translate(t, 'dropdown_make_default')}`,
    },
    {
      value: '3',
      label: `${translate(t, 'dropdown_delete')}`,
    },
  ];

  const dataForDefault = [
    {
      value: '1',
      label: `${translate(t, 'dropdown_send_money')}`,
    },
    {
      value: '3',
      label: `${translate(t, 'dropdown_delete')}`,
    },
  ];

  const getCards = () => {
    getResourceManager()
      .card.getCardsData()
      .then((data) => {
        setCardsData(data);

        if (data.length > 0) {
          data?.forEach((elem) => {
            if (elem.isDefault) {
              dispatch(updateSenderDetailsCardData({ dataId: elem?.id, defaultCardId: elem?.id }));
            }
          });
        } else {
          dispatch(updateSenderDetailsCardData({ dataId: '', defaultCardId: '' }));
        }
      });
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setIsDeleteApproved(false);
  };

  const handleSubmit = () => {
    setIsDeleteApproved(true);
  };

  useEffect(() => {
    if (isDeleteApproved && deletedCardId) {
      getResourceManager()
        .card.delete(deletedCardId)
        .then((res) => {
          getCards();
          return res;
        })
        .catch((err) => {
          getMonitoring()?.captureException(err);
          toastAlert(
            'error',
            err?.response?.data?.message ?? translate(t, 'error_something_went_wrong'),
          );
        })
        .finally(() => {
          setIsDeleteApproved(false);
          setIsModalOpen(false);
          setDeletedCardId('');
        });
    }
  }, [isDeleteApproved, deletedCardId]);

  const handleToggleCardAdding = () => {
    profile?.first_name && profile?.last_name && profile?.country
      ? setIsAddingCard((prev) => !prev)
      : navigate(AppRoutes.moneySteps.sendMoney);
  };

  useEffect(() => {
    getCards();
  }, []);

  return (
    <Wrapper>
      {isModalOpen && (
        <ConfirmationPopup
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          cardDigits={cardDigits}
          cardType={cardType}
        />
      )}
      <TitleWrapper>
        <TextWrapper>
          <TextField variant="H3" color="n900">
            <I18n tKey="title_my_cards" />
          </TextField>
          <br />
          <TextField variant="P7" color="n700">
            <I18n tKey="subtitle_my_cards" />
          </TextField>
        </TextWrapper>
        {isAvailableNewCard && !isAddingCard && profile.status_w !== ProfileStatus.BLOCKED && (
          <PrimaryButton
            disabled
            className="add-button"
            onClick={handleToggleCardAdding}
            width={CONSTANTS.HUNDREED_PERCENT}
          >
            <I18n tKey="add_new_card" />
            <TextField variant="P8" color="white" />
          </PrimaryButton>
        )}
      </TitleWrapper>
      {isAvailableNewCard && isAddingCard && (
        <NewCardForm onCardListFetch={getCards} onToggleCardAdding={handleToggleCardAdding} />
      )}

      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <th colSpan={2}>
                <TextField variant="P14" color="n900">
                  <I18n tKey="card_info" />
                </TextField>
              </th>
              <th>
                <TextField variant="P14" color="n900">
                  <I18n tKey="expiration_date" />
                </TextField>
              </th>
              <th>
                <TextField variant="P14" color="n900">
                  <I18n tKey="status" />
                </TextField>
              </th>
              <th>
                <TextField variant="P14" color="n900">
                  <I18n tKey="actions" />
                </TextField>
              </th>
            </tr>
          </thead>
          <tbody>
            {cardsData?.length ? (
              cardsData?.map((data: ICards, index: number) => {
                return (
                  <tr key={data.id}>
                    <td>
                      <CardTypeComponent type={data?.type} />
                    </td>
                    <td>
                      <TextField variant="P7" color="n800">
                        {CONSTANTS.CARD_TYPES[data?.type as keyof ICardsTypes]}{' '}
                        <I18n tKey="ending_in" /> {data?.pan?.substring(data?.pan?.length - 4)}
                      </TextField>
                    </td>
                    <td>
                      <TextField variant="P7" color="n800">
                        {String(data?.exp_month < 10 ? `0${data?.exp_month}` : data?.exp_month)}/
                        {String(data?.exp_year).slice(-2)}
                      </TextField>
                    </td>
                    <td>
                      <TextField variant="P7" color="n800">
                        {data?.isDefault && <I18n tKey="default" />}
                      </TextField>
                    </td>
                    <td>
                      <DropdownWrapper
                        data={data}
                        id={data?.id}
                        index={index}
                        datas={data?.isDefault ? dataForDefault : datass}
                        getCards={getCards}
                        setIsModalOpen={setIsModalOpen}
                        setCardDigits={setCardDigits}
                        setCardType={setCardType}
                        setDeletedCardId={setDeletedCardId}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <TextConteiner>
                <TextField variant="P7" color="">
                  <I18n tKey="you_havent_cards_local" />{' '}
                </TextField>
              </TextConteiner>
            )}
          </tbody>
        </Table>
      </TableWrapper>
      <MobileTable data={cardsData} getCards={getCards} />
    </Wrapper>
  );
};

export default MyCard;
