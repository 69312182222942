import { createSelector } from '@reduxjs/toolkit';

import { rootSelector } from '../../../rootSelector';

const senderState = createSelector(rootSelector, (state) => state.sendMoney);

export const sendMoneyStepsSelector = createSelector(senderState, (state) => state.steps);
export const fromCountrySelector = createSelector(senderState, (state) => state.fromCountry);
export const sendMoneyAuthIdSelector = createSelector(senderState, (state) => state.id);
export const sendMoneySourceCurrencySelector = createSelector(
  senderState,
  (state) => state.fromCurrency,
);
export const sendMoneyTransactionStatusSelector = createSelector(
  senderState,
  (state) => state.transactionStatus,
);
export const sendMoneyFullSelector = createSelector(senderState, (state) => state);
export const sendMoneyTransactionSelector = createSelector(
  senderState,
  (state) => state.data.sendMoney,
);
