import { AxiosResponse } from 'axios';

import { Response } from 'resources/types';
import { Fetcher } from 'utils';

import { ENDPOINTS } from './endpoints';
import {
  TranslationAccess,
  TranslationDataType,
  TranslationUpdatePayload,
  TranslationUpdateResponse,
} from './types';

export class TranslationRepo {
  private fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  public async getTranslatedData(lang: string) {
    const { data } = await this.fetcher.fetch<
      unknown,
      Response<AxiosResponse<TranslationDataType>>
    >({
      url: ENDPOINTS.translations,
      method: 'GET',
      params: {
        lang,
      },
    });

    return data.data;
  }

  public async checkAccess() {
    const { data } = await this.fetcher.fetch<unknown, Response<AxiosResponse<TranslationAccess>>>({
      url: ENDPOINTS.checkAccess,
      method: 'GET',
    });

    return data.data;
  }

  public async update(payload: TranslationUpdatePayload) {
    const { data } = await this.fetcher.fetch<
      TranslationUpdatePayload,
      Response<AxiosResponse<TranslationUpdateResponse>>
    >({
      url: ENDPOINTS.update,
      method: 'POST',
      data: payload,
    });

    return data.data;
  }
}
