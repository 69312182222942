import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 1160px;
  height: 100%;
  align-content: center;
  margin: 48px auto 70px;
  position: relative;
  @media screen and (max-width: 1599px) {
    width: -webkit-fill-available;
    width: -moz-available;
    padding: 0 60px;
  }
  @media screen and (max-width: 1279px) {
    margin-top: 0;
    padding: 24px;
  }
  @media screen and (max-width: 647px) {
    width: auto;
    margin: 24px 16px;
    padding: 0;
  }
`;

export const IconBox = styled.div`
  @media (max-width: 1279px) {
    svg path {
      fill: #2d48ed;
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 8px;
  position: relative;
  .title-email {
    height: 42px;
    width: auto;
    #email-id {
      position: absolute;
      white-space: nowrap;
    }
    @media (max-width: 1279px) {
      height: 32px;
      font-size: 26px;
    }
  }
  #gradient-title {
    background: #222229;
    background: linear-gradient(to right, #222229 60%, #fff 100%);
    background: -moz-linear-gradient(right, #fff 1%, #222229 40%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 647px) {
    padding: 0;
    width: auto;
    &:nth-child(1) {
      & > .title-email:first-child {
        font-weight: 500;
        font-size: 26px;
        line-height: 32px;
      }
    }
  }
`;

export const StyledBackground = styled.div<{
  verification: 'verified' | 'notVerified';
}>`
  width: 100%;
  background: #ebfaff;
  border: 1px solid #ade9ff;
  box-sizing: border-box;
  border-radius: 8px;
  flex-direction: column;
  padding: 24px;
  margin: 32px 0 48px;
  @media screen and (max-width: 1279px) {
    width: auto;
    margin: 24px 0 32px;
    padding: 16px 16px;
  }
  @media screen and (max-width: 647px) {
    margin: 24px 0 32px;
    padding: 0;
  }
`;

export const TotalOrdering = styled.div<{
  verification: 'verified' | 'notVerified';
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1279px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 647px) {
    justify-content: flex-start;
    flex-direction: column;
    padding: 16px;
  }
`;

export const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  min-width: 160px;
  justify-content: flex-end;

  @media (max-width: 1279px) {
    margin-bottom: 16px;
    justify-content: flex-start;
  }
`;
export const Container = styled.div`
  display: flex;
`;

export const PopUpMessage = styled.div`
  margin-left: 4px;

  > :first-child {
    position: absolute;
  }
  > :last-child {
    position: absolute;
    cursor: pointer;
  }
`;

export const MonthData = styled.div`
  justify-content: space-between;
  display: flex;
  text-align: left;
  @media (max-width: 1279px) {
    width: 100%;
    justify-content: flex-start;
  }
  @media (max-width: 767px) {
    margin-bottom: 16px;
    flex-direction: column;
  }
`;

export const LinkWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  margin-right: 32px;
  margin-left: 32px;

  @media (max-width: 1279px) {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 10px;

    > div {
      > a {
        > div {
          margin-left: 0;
        }
      }
    }
  }
`;

export const PrimaryButton = styled.button<{ width?: string }>`
  width: 136px;
  border: none;
  padding: 16px 24px;
  background: #3848c6;
  color: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-family: Commissioner-Regular;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  @media (max-width: 1279px) {
    width: ${({ width }) => width};
  }
  @media (max-width: 647px) {
    height: 56px;
  }
  :hover {
    background: #4858cf;
  }
  :active {
    outline: 4px solid rgba(24, 97, 242, 0.24);
    background: #3848c6 !important;
  }
  :focus {
    background: #3342b8;
  }
  :disabled {
    background: #dde0f8 !important;
  }
`;

export const PaymentHistoryTitle = styled.div`
  margin-bottom: 32px;
  @media screen and (max-width: 1279px) {
    margin-bottom: 24px;
  }
`;

export const TableWrapper = styled.div`
  margin: 0 0 24px;
  border: 1px solid #f0f0f2;
  border-radius: 8px;
  overflow: hidden;
  @media (max-width: 1279px) {
    width: 100%;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: 16px !important;
  thead {
    background: #f0f0f2;
  }
  tbody tr {
    border: 1px solid #f0f0f2;
  }
  th,
  td:not(.message) {
    padding: 16px 16px;
    text-align: left;
  }
  .rejected > td > div {
    color: #9294a0;
  }
  .accepted {
    color: #46c277;
  }
  .amount,
  .amount-title {
    text-align: left;
  }
  .empty-data-message {
    > .message {
      margin: 16px;
    }
    border: none;
  }
  @media only screen and (max-width: 1279px) {
    .amount,
    .amount-title {
      text-align: left;
    }
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }
    thead tr {
      margin: 0;
      padding: 0;
      opacity: 0;
      height: 0;
      border: none;
      .date-hide {
        display: block;
        border: 1px solid red;
      }
    }
    tbody tr {
      padding-top: 16px;
      border: 1px solid #f0f0f2;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    tr td:not(.message) {
      display: flex;
      flex-direction: column;
      border: none;
      padding: 0 16px;
      line-height: 26px;
    }
    .actions {
      padding: 8px 16px 16px;
    }
    .empty-data-message {
      padding-top: 0;
      border: none;
    }
    td:not(.message) {
      &:before {
        line-height: 18px;
        color: #222229;
        font-size: 13px;
      }
      &:nth-of-type(1):before {
        content: 'Date';
      }
      &:nth-of-type(2):before {
        content: 'Status';
      }
      &:nth-of-type(3):before {
        content: 'Recipient';
      }
      &:nth-of-type(4):before {
        content: 'Payment method';
      }
      &:nth-of-type(5):before {
        content: 'Amount';
      }
      &:nth-of-type(6):before {
        content: 'Currency';
      }
    }
  }
`;

export const ActionStyles = styled.div`
  margin: none;
  width: 48px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  @media screen and (max-width: 1279px) {
    position: relative;
    width: auto;
    justify-content: flex-start;
  }
`;

export const ActionTitles = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  align-content: center;
  cursor: pointer;
  .message {
    margin-left: 23px;
    > .arrow {
      left: -77px;
    }
  }
  & > :first-child {
    display: none;
  }
  @media screen and (min-width: 1280px) {
    :hover {
      cursor: pointer;
      svg path {
        fill: #2d48ed;
      }
      .message {
        display: inline;
      }
    }
  }

  > .message {
    position: absolute;
    left: -27px;
    top: 20px;
    display: none;

    > .container > .text-container {
      white-space: nowrap;
    }
    > :first-child {
      width: auto;
      height: auto;
      padding: 6px 12px 8px;
    }
    > :last-child {
      rotate: 225deg;
      top: -45px;
      right: 31px;
    }
    @media screen and (max-width: 1279px) {
      display: none;
    }
  }
  > .m-download {
    > :first-child {
      position: absolute;
      left: -26px;
    }
    > :last-child {
      rotate: 225deg;
      top: -45px;
      right: -43px;
    }
  }
  @media screen and (max-width: 1279px) {
    & > :first-child {
      display: inline;
    }
    & > svg > path {
      fill: #2d48ed;
    }
  }
`;

export const HoverMessage = styled.div`
  > :last-child {
    display: none;
  }
`;

export const PaginationWrapper = styled.div`
  margin: 32px 0 48px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1279px) {
    margin-top: 24px;
  }
  @media (max-width: 647px) {
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const VectorWrapper = styled.span<{ active: boolean; revers: boolean }>`
  margin-left: 10px;
  svg {
    path {
      fill: ${({ active }) => active && '#2D48ED'};
    }
    transform: rotate(${({ revers }) => (revers ? '180deg' : '0deg')});
  }
`;
