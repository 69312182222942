import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import checkImg from 'assets/images/check.svg';
import infoIcon from 'assets/images/infoIcon.png';
import {
  TextField,
  InputField,
  PrimaryButtonComp,
  ReviewComponentPart,
  PrototypeOfStepPages,
} from 'components';
import { CheckboxContent, PrimaryButton } from 'components/PrimaryButtonComp/style';
import { StyledP } from 'components/StepHeader/style';
import { StyledBackground, StyledInfoIcon } from 'components/TransactionInfo/style';
import { I18n } from 'components/Translation';
import { StyledFooterWithButtons } from 'components/styles';
import { CONSTANTS } from 'config';
import { translate } from 'helpers';
import { useSwitch } from 'hooks';
import { useAppProvider, useAuth, useTranslation } from 'providers';
import { getResourceManager } from 'resources';
import { AppRoutes } from 'routes/constants';
import { RootState } from 'store';
import {
  clearSendMoneyAction,
  clearSenderDetailsAction,
  clearRecipientDetailsAction,
  clearSenderDetailsCardAction,
} from 'store/actionCreator';
import { setIsPersonalInfoFilled } from 'store/slices/personal/personalInfo.slice';
import {
  IStepFiveDrawData,
  TransactionStatus,
  updateSendMoneyState,
} from 'store/slices/transaction';
import { sendMoneyFullSelector } from 'store/slices/transaction/sender/sender.selectors';

import { StyledContainer, StyledReviewPage } from '../style';
import { InputContainer } from '../stylesRecipientDetails';

import { SectionHead, SectionTitle } from './styles';
import { useCountries } from 'providers/countriesProvider';

export const ApproveTransaction = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sendingCountries, receivingCountries } = useCountries();

  const [email, setEmail] = useState<string>('');
  const [valid, setValid] = useState<boolean>(true);
  const [isRecipient, { toggle }] = useSwitch(false);
  const [isFocused, setIsFocused] = useState<string>('');
  const { refetchProfileData, refetchProfileSettings } = useAuth();

  const { t } = useTranslation();
  const transactionSendMoney = useSelector(sendMoneyFullSelector);
  const transactionDetailsCard = useSelector((state: RootState) => state?.senderCard);
  const transactionDetailsData = useSelector((state: RootState) => state?.senderDetails);
  const transactionRecipientDetails = useSelector((state: RootState) => state?.senderRecipient);

  const { recipientDetails } = transactionRecipientDetails.data || {};
  const { toCountry, receiverEmail, transactionStatus } = transactionSendMoney || {};

  const recipientPayload = {
    cardNumber: recipientDetails.cardNumber || '',
    cardholderName: recipientDetails.cardholderName || '',
    firstName: recipientDetails.firstName || '',
    lastName: recipientDetails.lastName || '',
    country: toCountry,
  };

  const transactionData = {
    data: {
      ...transactionSendMoney.data,
      senderDetails: {
        ...transactionDetailsCard?.data?.senderDetailsCard,
        ...transactionDetailsData?.data?.senderDetailsData,
      },
      recipientDetails: {
        ...recipientPayload,
      },
    },
  };

  const { sendMoney } = transactionData?.data || {};

  const { senderCountry, recipientCountry } = useMemo(() => {
    const senderCountry = sendingCountries?.find(
      (el) => el.iso2 === transactionData?.data?.senderDetails?.country,
    );
    const recipientCountry = receivingCountries?.find((el) => el.iso2 === toCountry);

    return { senderCountry, recipientCountry };
  }, [transactionData?.data, toCountry, sendingCountries, receivingCountries]);

  const { setLoader, loadType, setLoadType } = useAppProvider();

  const changeEmailHandler = (e: any) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    setEmail(receiverEmail || '');
  }, [receiverEmail]);

  const validateEmail = useCallback(
    (email: string) => {
      if (!isRecipient) {
        setValid(true);

        return true;
      }

      const emailRegEx = CONSTANTS.REGEXP.EMAIL;
      // const emailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
      const emailValidation = emailRegEx.test(email);
      setValid(emailValidation);

      return emailValidation;
    },
    [isRecipient],
  );

  useEffect(() => {
    if (email) {
      validateEmail(email);
    }
  }, [email, validateEmail]);

  const setMaskCard = (cardNumber: string) =>
    cardNumber && `${cardNumber.slice(0, 6)}******${cardNumber.slice(-4)}`;

  const senderCardNumber = setMaskCard(transactionData?.data?.senderDetails?.pan as string);
  const recivientCardNumber = setMaskCard(transactionData?.data?.recipientDetails?.cardNumber);

  const arrayWithStateValues: IStepFiveDrawData[][] = [
    [
      {
        title: translate(t, 'your_country_residence'),
        value: `${senderCountry?.name}`,
      },
      {
        title: translate(t, 'your_name'),
        // eslint-disable-next-line max-len
        value: `${transactionData?.data?.senderDetails?.firstName} ${transactionData?.data?.senderDetails?.lastName}`,
      },
      {
        title: translate(t, 'your_card'),
        value: `${senderCardNumber?.match(/.{1,4}/g)?.join(' ')}`,
      },
    ],
    [
      {
        title: translate(t, 'recipient_country'),
        value: `${recipientCountry?.name}`,
      },
      {
        title: translate(t, 'recipient_name'),
        value: `${transactionData?.data?.recipientDetails?.firstName} ${transactionData?.data?.recipientDetails?.lastName}`,
      },
      {
        title: translate(t, 'recipient_card_number_label'),
        value: `${recivientCardNumber?.match(/.{1,4}/g)?.join(' ')}`,
      },
    ],
    [
      {
        title: translate(t, 'amount_send'),
        value: `${sendMoney?.fromAmount} ${sendMoney?.rate?.from}`,
      },
      {
        title: translate(t, 'recipient_recieve'),
        value: `${sendMoney?.toAmount} ${sendMoney?.rate?.to}`,
      },
    ],
    [
      {
        title: translate(t, 'our_fee'),
        value: `${sendMoney?.fee}`,
      },
      {
        title: translate(t, 'total_pay_today'),
        value: `${sendMoney?.debet || sendMoney?.debit}`,
      },
    ],
  ];

  const getFirstTransactionPersonalInfo = () => {
    const {
      firstName,
      lastName,
      country,
      date_of_birth: dateOfBirth,
    } = transactionData?.data?.senderDetails || {};

    !(firstName || lastName || country) &&
      getResourceManager().user.profile.updateProfileData({
        country,
        firstName,
        lastName,
        dateOfBirth,
      });
  };

  const handleSubmit = async () => {
    const isValid = validateEmail(email);

    if (!isValid) return;

    const { senderDetails } = transactionData.data;

    setLoader(true);
    setLoadType('Step Five');
    getResourceManager()
      .transaction.process({
        ...transactionData.data,
        senderDetails: {
          ...senderDetails,
          first_name: senderDetails.firstName,
          last_name: senderDetails.lastName,
          cardId: senderDetails.dataId,
        },
        email: { email },
      })
      .then((res) => {
        if (!res?.status) {
          throw new Error();
        }

        refetchProfileData?.();
        refetchProfileSettings();

        dispatch(updateSendMoneyState({ transactionStatus: TransactionStatus.success }));
        navigate(AppRoutes.private.transferSuccess);
      })
      .catch((err) => {
        if (err.errorType === 2) {
          dispatch(updateSendMoneyState({ transactionStatus: TransactionStatus.denied }));
          navigate(AppRoutes.private.transferDenied);
        } else {
          dispatch(updateSendMoneyState({ transactionStatus: TransactionStatus.denied }));
          navigate(AppRoutes.private.transferDeclined);
        }
      })
      .finally(() => {
        getFirstTransactionPersonalInfo();
        clearSendMoneyAction(dispatch);
        clearSenderDetailsAction(dispatch);
        clearSenderDetailsCardAction(dispatch);
        clearRecipientDetailsAction(dispatch);
        setLoader(false);
        dispatch(setIsPersonalInfoFilled(true));
      });
  };

  useEffect(() => {
    transactionStatus === TransactionStatus.finish
      ? navigate(AppRoutes.moneySteps.sendMoney)
      : dispatch(updateSendMoneyState({ transactionStatus: TransactionStatus.empty }));
  }, []);

  const sendAmountWithCurrency = `${sendMoney?.fromAmount} ${sendMoney?.rate.from}`;

  return (
    <>
      <PrototypeOfStepPages
        smallTitle={<I18n tKey="almost_done" />}
        subtitle={<I18n tKey="review_appove_subtitle" />}
        isAlignLeftPage
      >
        <StyledReviewPage>
          <StyledBackground>
            <StyledInfoIcon>
              {' '}
              <img src={infoIcon} alt="Info" />{' '}
            </StyledInfoIcon>
            <StyledP margin="18px 0 24px">
              <I18n tKey="security_alert_text" />
            </StyledP>
          </StyledBackground>
          {arrayWithStateValues.map((item: IStepFiveDrawData[], index: number) => (
            <ReviewComponentPart key={index} data={item} />
          ))}
          <StyledBackground>
            <StyledInfoIcon>
              {' '}
              <img src={infoIcon} alt="Info" />{' '}
            </StyledInfoIcon>
            <StyledP margin="18px 0 24px">
              <I18n tKey="money_will_be_available" />{' '}
              {`${transactionData?.data?.recipientDetails?.firstName}
              ${transactionData?.data?.recipientDetails?.lastName}`}{' '}
              <I18n tKey="within_minutes_might_take_day_local" />{' '}
            </StyledP>
          </StyledBackground>
          <StyledContainer margin="24px 0 24px">
            <SectionHead>
              <CheckboxContent>
                <input onChange={toggle} type="checkbox" id="needRecipient" checked={isRecipient} />
                <label htmlFor="needRecipient">
                  <img src={checkImg} alt="check" />
                  <TextField className="check-text" variant="P7">
                    <SectionTitle>
                      <I18n tKey="notify_recipient" />?
                    </SectionTitle>
                  </TextField>
                </label>
              </CheckboxContent>
            </SectionHead>
            {isRecipient && (
              <InputContainer className="firstChild">
                <InputField
                  name="email"
                  className="email five_step_email"
                  value={email}
                  onChange={changeEmailHandler}
                  onFocus={() => setIsFocused('email')}
                  onBlur={() => setIsFocused('')}
                  error={!valid}
                  errorMessage={<I18n tKey="enter_correct_email" />}
                />
                <label className="label">
                  <TextField
                    className={!!email || isFocused === 'email' ? 'on-focus' : ''}
                    variant="P7"
                  >
                    <I18n tKey="email" />
                  </TextField>
                </label>
              </InputContainer>
            )}
          </StyledContainer>
          <StyledFooterWithButtons>
            <PrimaryButton
              width="84px"
              onClick={() => {
                navigate(AppRoutes.moneySteps.recipientDetails);
              }}
            >
              <I18n tKey="back" />
            </PrimaryButton>
            <PrimaryButtonComp
              className="send-button"
              click={() => handleSubmit()}
              typeOfLoad={loadType}
              valid
              width=""
              text={
                <>
                  <I18n tKey="send" /> {sendAmountWithCurrency}
                </>
              }
            />
          </StyledFooterWithButtons>
        </StyledReviewPage>
      </PrototypeOfStepPages>
    </>
  );
};
