import { useQueryBuilder } from 'hooks/useQueryBuilder';
import { getResourceManager } from 'resources';

import { CardsDataProps } from './types';

export const useCardsData = (props?: CardsDataProps) => {
  const { options } = props || {};

  return useQueryBuilder({
    key: 'profile-cards-data',
    method: async () => getResourceManager().card.getCardsData(),
    enabled: true,
    options,
  });
};
