import { isNil } from 'lodash';

import { translate } from 'helpers';
import { TranslationDataType } from 'resources/translation';

import { ValidationOptions } from './types';
import { getValidationConstructor } from './validationConstructor';

export const getCardValidation = (
  t: TranslationDataType,
  options: ValidationOptions['card'] | undefined,
) => ({
  standart: getValidationConstructor(t, /^[0-9\s]*$/, 'must_contain_only_digits').test(
    'cards-supported',
    `${translate(t, options?.translation || 'supportedCardsTarget')}`,
    (value) => {
      const hasFirst = value?.match(/^5[1-5]|^[4]/);
      const target = Number(value?.slice(0, 4));

      const hasSecond = Number.isNaN(target) ? false : target >= 2221 && target <= 2720;

      if (!isNil(options?.fn)) {
        const isValid = !isNil(options?.fn) ? options?.fn?.(value || '') : true;

        return !!hasFirst || !!isValid || hasSecond;
      }

      return !!hasFirst || hasSecond;
    },
  ),
});
