import { Oval } from 'react-loader-spinner';

import { Google, Apple } from 'assets/images';
import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';
import { toastAlert, translate } from 'helpers';
import { useAppProvider, useTranslation } from 'providers';
import { getResourceManager } from 'resources';
import { getMonitoring } from 'services/monitoring';

import { SocialButton } from '../styles';

import { SocialWrapper, LineWrapper, Line } from './styles';
import { ISignType } from './types';

export const SocialAuth = ({ type }: ISignType) => {
  const { loader, setLoader, loadType, setLoadType } = useAppProvider();
  const { t } = useTranslation();

  const handleSignInGoogle = async () => {
    setLoader(true);
    setLoadType('Google');

    try {
      const { error } = await getResourceManager().auth.signInWithGoogle();

      if (error) {
        throw error;
      }
    } catch (err) {
      toastAlert('error', translate(t, 'error_something_went_wrong_try_later'));
      getMonitoring()?.captureException(err);
    } finally {
      setLoader(false);
    }
  };

  const handleAppleSignIn = async () => {
    setLoader(true);
    setLoadType('Apple');

    try {
      const { error } = await getResourceManager().auth.signInWithApple();

      if (error) {
        throw error;
      }
    } catch (err) {
      toastAlert('error', translate(t, 'error_something_went_wrong_try_later'));
      getMonitoring()?.captureException(err);
    } finally {
      setLoader(false);
    }
  };

  return (
    <SocialWrapper>
      <LineWrapper className="line-wrapper">
        <Line />
        <TextField variant="P7">
          <I18n tKey="or_upper_case" />
        </TextField>
        <Line />
      </LineWrapper>
      <SocialButton onClick={handleSignInGoogle} top="24">
        {loader && loadType === 'Google' ? (
          <Oval
            height={24}
            width={24}
            color="#3848C6"
            wrapperStyle={{}}
            wrapperClass=""
            visible
            ariaLabel="oval-loading"
            secondaryColor="#DDE0F8"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : (
          <Google />
        )}
        <TextField left="16" variant="H7">
          {type === 'sign_in' && <I18n tKey="sign_in_with_google" />}
          {type === 'sign_up' && <I18n tKey="sign_up_with_google" />}
        </TextField>
      </SocialButton>
      <SocialButton onClick={handleAppleSignIn} top="16">
        {loader && loadType === 'Apple' ? (
          <Oval
            height={24}
            width={24}
            color="#3848C6"
            wrapperStyle={{}}
            wrapperClass=""
            visible
            ariaLabel="oval-loading"
            secondaryColor="#DDE0F8"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : (
          <Apple />
        )}
        <TextField left="16" variant="H7">
          {type === 'sign_in' && <I18n tKey="sign_in_with_apple" />}
          {type === 'sign_up' && <I18n tKey="sign_up_with_apple" />}
        </TextField>
      </SocialButton>
    </SocialWrapper>
  );
};
