import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';

import { PaginationButton, PaginationStyles } from './styles';
import { IProps } from './types';

export const Pagination = ({
  currentPage,
  postsPerPage,
  totalPosts,
  paginate,
  totalPages = 4,
}: IProps) => {
  const pageNumbers = Array.from({ length: Math.ceil(totalPosts / postsPerPage) }, (_, i) => i + 1);

  const nextButton = () => paginate(currentPage + 1);
  const prevButton = () => paginate(currentPage - 1);

  // Get current pages
  const firstPageIndex = 0;

  const indexOfLastPage =
    currentPage <= totalPages
      ? totalPages
      : currentPage <= pageNumbers.length
      ? currentPage
      : totalPages;
  const indexOfFirstPage =
    currentPage >= totalPages ? indexOfLastPage - totalPages : firstPageIndex;
  const currentPages = pageNumbers.slice(indexOfFirstPage, indexOfLastPage);

  return (
    <PaginationStyles>
      <PaginationButton
        className={currentPage > 1 ? 'click_prev' : 'hide_button'}
        onClick={prevButton}
      >
        <TextField variant="P10" color="n900">
          <I18n tKey="prev" />
        </TextField>
      </PaginationButton>
      {currentPages.map((currentpageNumber, index) => (
        <PaginationButton
          key={index}
          className={
            currentpageNumber === currentPage
              ? currentPage !== pageNumbers.length
                ? 'active'
                : 'last_active'
              : ''
          }
          onClick={() => paginate(currentpageNumber)}
        >
          <TextField variant="P10" color="n900">
            {currentpageNumber}
          </TextField>
        </PaginationButton>
      ))}
      <PaginationButton
        className={currentPage < pageNumbers.length ? 'click_next' : 'hide_button'}
        onClick={nextButton}
      >
        <TextField variant="P10" color="n900">
          <I18n tKey="next" />
        </TextField>
      </PaginationButton>
    </PaginationStyles>
  );
};
