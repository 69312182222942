import { Fetcher } from 'utils';

import { CurrencyRepo } from './CurrencyRepo';

export class CurrencyService {
  private repo: CurrencyRepo;

  constructor(fetcher: Fetcher) {
    this.repo = new CurrencyRepo(fetcher);
  }

  public async checkCurrency(currency: string) {
    const data = await this.repo.checkCurrency(currency);

    return data;
  }
}
