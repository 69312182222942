import { useMemo } from 'react';

import { translate } from 'helpers';
import { useTranslation } from 'providers/translationProvider/useTranslation';

import classes from './translation.module.scss';

type Props = {
  tKey?: string;
  fallBacktKey?: string;
  value?: string;
  variables?: Record<string, string>;
};

export const I18n = ({ tKey, fallBacktKey, value, variables = {} }: Props) => {
  const { t, isTranslationManagementAllowed, isEditVisibility, toggleModal } = useTranslation();

  const { finalValue, finalKey } = useMemo(() => {
    let finalValue = '';
    let finalKey = '';

    if (tKey) {
      finalValue = translate(t, tKey, variables);
      finalKey = tKey;
    }

    if (!value && fallBacktKey) {
      finalValue = translate(t, fallBacktKey, variables);
      finalKey = fallBacktKey;
    }

    if (!finalValue && value) {
      finalValue = value;
    }

    return { finalValue, finalKey };
  }, [t, tKey, fallBacktKey, value, variables]);

  const props = useMemo(
    () => ({
      'data-translation-key': finalKey,
    }),
    [finalKey],
  );

  const handleOpen = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    toggleModal(finalKey);
  };

  if (!isTranslationManagementAllowed || !isEditVisibility) {
    return <>{finalValue}</>;
  }

  return (
    <span {...props} className={classes['edit']}>
      {finalValue}

      <span className={classes['edit-button']} onClick={handleOpen}>
        Edit
      </span>
    </span>
  );
};
