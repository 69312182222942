import styled from 'styled-components';

interface ToastWrapperType {
  toastType: 'success' | 'error' | 'loading' | 'blank' | 'custom';
  animate: boolean;
  fixedWidth?: boolean;
}

export const ToastWrapper = styled.div<ToastWrapperType>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  width: ${({ fixedWidth = true }) => (fixedWidth ? '720px' : 'inherit')};
  box-sizing: ${({ fixedWidth = true }) => (fixedWidth ? 'inherit' : 'border-box')};
  background: ${({ toastType }) => (toastType === 'error' ? '#FFEBEB' : '#C2EBD2')};
  border: 1px solid ${({ toastType }) => (toastType === 'error' ? '#FFEBEB' : '#C2EBD2')};
  border-radius: 8px;
  opacity: ${({ animate }) => (animate ? 1 : 0)};
  animation-duration: 1s;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;
