import { IText } from './types';
import { switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';

export const MarginDiv = styled.div<IText>`
  ${switchProp('variant', {
    M46: css`
      margin-bottom: 46px;
    `,

    M32: css`
      margin-bottom: 32px;
    `,
  })}
`;

export const IncreaseMonthlyLimitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 61px;
  margin-bottom: 302px;
  height: calc(50% - 373px);
  @media (max-width: 1279px) {
    justify-content: center;
    height: auto;
  }
  @media (max-width: 647px) {
    padding: 0 10px;
  }
`;

export const ConfirmationButton = styled.div<{ isPreparing?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.isPreparing ? '#DDE0F8' : '#3848C6')};
  border-radius: 6px;
  padding: 16px 24px;
  cursor: pointer;
`;

export const StepPreviewComponent = styled.div`
  height: 100%;
`;
