import { useState, useEffect } from 'react';

import { Exclamatory } from 'assets/images';
import { AgreeCheckbox } from 'components/AgreeCheckbox';
import { TextField } from 'components/TextField';
import { I18n } from 'components/Translation';
import { CONSTANTS } from 'config/constants';
import { IExchangeContent } from 'types/exchange';

import { PrimaryButton } from '../PrimaryButtonComp/style';

import {
  StyledColumnItem,
  StyledWithBackgroundFlex,
  FlexSection,
  CheckboxContainer,
  Line,
} from './style';
import { IData } from './types';

export const TransactionInfoHome = ({ data, showInfo, handleDispatchData }: IData) => {
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [rate, setRate] = useState<IExchangeContent>({
    delivery: '',
    fromAmount: '',
    toAmount: '',
    fee: '',
    debet: '',
    debit: '',
    rate: {
      from: '',
      to: '',
    },
  });

  useEffect(() => {
    if (data?.debet || data?.debit || data?.toAmount) {
      setRate({
        delivery: data?.delivery as string,
        fromAmount: data?.fromAmount as string,
        toAmount: data?.toAmount as string,
        fee: data?.fee as string,
        debet: (data?.debet || data?.debit) as string,
        debit: (data?.debet || data?.debit) as string,
        rate: {
          from: data?.rate?.from as string,
          to: data?.rate?.to as string,
        },
      });
    }
  }, [data]);

  return (
    <>
      {showInfo && (
        <StyledWithBackgroundFlex>
          <Exclamatory />
          <FlexSection>
            <StyledColumnItem>
              <TextField variant="P13" color="n800">
                <I18n tKey="estimated_delivery_local" />
              </TextField>
              <TextField variant="P7" color="n900">
                <I18n tKey="estimated_delivery_instantly" />
              </TextField>
            </StyledColumnItem>

            <StyledColumnItem>
              <TextField variant="P13" color="n800">
                <I18n tKey="estimated_delivery_not_later" />
              </TextField>
              <TextField variant="P7" color="n900">
                {rate.delivery}
              </TextField>
            </StyledColumnItem>
          </FlexSection>
          <StyledColumnItem>
            <TextField variant="P13" color="n800">
              <I18n tKey="transfer_fee" />
            </TextField>
            <TextField variant="P7" color="n900">
              {rate.fee}
            </TextField>
          </StyledColumnItem>
          <StyledColumnItem>
            <TextField variant="P13" color="n800">
              <I18n tKey="debited_from_your_card" />
            </TextField>
            <TextField variant="P4" color="n900" fw="700">
              {rate.debet || rate.debit}
            </TextField>
          </StyledColumnItem>
          <StyledColumnItem>
            <TextField variant="P13" color="n800">
              <I18n tKey="the_recipient_receives" />
            </TextField>
            <TextField variant="P4" color="n900" fw="700">
              {rate.toAmount} {rate.rate.to}
            </TextField>
          </StyledColumnItem>
        </StyledWithBackgroundFlex>
      )}
      <CheckboxContainer>
        <AgreeCheckbox isTermsOnly getIsCheck={(value) => setIsAgree(value)} />
      </CheckboxContainer>
      <Line />
      <PrimaryButton
        disabled={Number(rate.toAmount) === 0 || data?.limitPass === false || !isAgree}
        onClick={() => handleDispatchData(isAgree)}
        width={CONSTANTS.HUNDREED_PERCENT}
      >
        <I18n tKey="continue" />
      </PrimaryButton>
    </>
  );
};
