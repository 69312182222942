import { connection } from 'config';
import { IPostCardData, IResetEmail } from 'pages/steps/type/transaction';
import { IProfileCheck, IProfileData, IPromiseProfile } from 'store/slices/personal';
import { IAddOnlyNewCardData } from 'types/cards';

import { axiosInstance } from './axiosInstance';

export const ProfileApi = {
  updateProfile: async (data: IProfileData): Promise<IPromiseProfile> => {
    const resp = await axiosInstance.post(`${connection.routes.updateProfile}`, data);
    return resp.data;
  },
  setCardURL: async (data: IPostCardData | IAddOnlyNewCardData) => {
    return axiosInstance.post('card/authorize', data);
  },
  makeDefaultCard: async (id: string | undefined) => {
    return axiosInstance.post('card/make-default', { id });
  },
  checkUser: async (data: IProfileCheck) => {
    return axiosInstance.post(`${connection.routes.checkUser}`, data);
  },
  setResetEmail: async (data: IResetEmail) => {
    return axiosInstance.post('user/reset-password-request', data);
  },
};
