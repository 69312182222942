import { IDataProvider } from 'services';

import { AddQuestion, Question } from './types';

export class QuestionRepo {
  private provider: IDataProvider;

  constructor(provider: IDataProvider) {
    this.provider = provider;
  }

  public async addQuestion(payload: Question): Promise<AddQuestion> {
    const { error } = await this.provider.manager.from('questions').insert([payload]);

    return { error };
  }
}
