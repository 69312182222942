import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 600px;
  margin: 48px auto;
  .line {
    margin: 24px 60px 0;
    width: auto;
  }
  @media screen and (max-width: 648px), {
    width: auto;
    margin: 24px 16px;
    .line {
      margin: 24px 0 0;
    }
  }
`;

export const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 24px;
  > :first-child {
    padding-bottom: 8px;
  }
`;

export const InfoWrapper = styled.div`
  margin-top: 32px;
  > :first-child {
    padding-bottom: 16px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
`;

export const InputFields = styled.div`
  width: 480px;
  height: 72px;
  border-radius: 8px;
  @media screen and (max-width: 648px), {
    width: 100%;
  }
  > * {
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  width: auto;
  margin: 20px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > button {
    width: 84px;
    height: 56px;
  }
  @media screen and (max-width: 648px), {
    margin: 20px 0;
  }
`;

export const RepeatRequest = styled.div`
  display: flex;
  gap: 8px;
  > .send-again {
    cursor: pointer;

    :hover {
      color: #222229;
    }
    :active {
      outline: 4px solid rgba(24, 97, 242, 0.24);
      border-radius: 4px;
    }
    :focus {
      color: #293fcb !important;
    }
    :disabled {
      color: #c7c9d1 !important;
    }
  }
`;
