import { useTranslation } from 'providers/translationProvider';
import { TranslationDataType } from 'resources/translation';

export function useValidationSchema<T>(
  getSchema: (t: TranslationDataType, ...args: any[]) => T,
  ...args: any[]
) {
  const { t } = useTranslation();

  return getSchema(t, ...args);
}
