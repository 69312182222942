import axios, { Axios, AxiosRequestConfig } from 'axios';

import { envConfig } from 'config/env';

import { LogoutHandler } from './types';

class Fetcher {
  private requestClient: Axios;

  private handleLogout: LogoutHandler;

  constructor(baseURL: string, handleLogout: LogoutHandler) {
    this.requestClient = axios.create({
      baseURL,
    });
    this.handleLogout = handleLogout;
    this.setUpdateTokenIntercepter();
  }

  /**
   * Interceptors same by axiosInstance
   */
  private setUpdateTokenIntercepter() {
    this.requestClient.interceptors.request.use((config) => {
      const lang = localStorage.getItem('language');

      const tokenObject = localStorage.getItem(envConfig.provider.supabaseApp);
      const token = tokenObject ? JSON.parse(tokenObject).access_token : null;

      return {
        ...config,
        headers: {
          ...config.headers,
          'Content-Type': 'application/json',
          Authorization: token,
          lang: lang || 'en',
        },
      };
    });

    this.requestClient.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response.status === 401) {
          await this.handleLogout();
          localStorage.clear();
          window.location.reload();
        }
        return Promise.reject(error);
      },
    );
  }

  public async fetch<T, C>(config: AxiosRequestConfig<T>) {
    return this.requestClient.request<T, C>(config);
  }
}

export { Fetcher };
