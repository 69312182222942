import styled from 'styled-components';

const ImageWrapper = styled.div<{ height?: string; width?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height ?? '100%'};
  width: ${({ width }) => width ?? '100%'};
  > svg {
    height: ${({ height }) => height ?? '100%'};
  }
`;

export { ImageWrapper };
