import { createSlice } from '@reduxjs/toolkit';

import { Action } from '../../types';

const languageSlice = createSlice({
  name: 'language',
  initialState: {
    language: 'en',
  },

  reducers: {
    changeLanguage(state, action: Action<{ language: string }>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { changeLanguage } = languageSlice.actions;
export const languageReducer = languageSlice.reducer;
